/* tslint:disable:no-var-requires max-line-length */
import { observer } from 'mobx-react';
import 'moment';
import {
    Dialog,
    DialogEventArgs,
} from './dialog';
import * as React from 'react';

export interface Props {
    enteredValue: number;
    productName: string;
    quantityIncrement: number;
    onValueChosen: (chosenNumber: number | false) => void;
}

enum DialogResult {
    Lower  = 'lower',
    Higher = 'higher',
    Cancel = 'cancel',
}

@observer
export class QuantityDialog extends React.Component<Props> {

    public render() {
        return (
            <Dialog<DialogResult> onResult={this.onDialogResult}
                    closable={false}
                    title="Toevoegen aan winkelwagen">
                <p className="dialog-container__dialog__text">
                    Het artikel {this.props.productName} kan niet {this.props.enteredValue} keer worden besteld: een
                    meervoud van {this.props.quantityIncrement} is vereist.
                </p>
                <div className="button-group button-group--compact button-group--dialog">
                    {this.lowerValue === 0 ? null :
                        <button className="button button--primary button-group__button button-group__button--dialog"
                                data-action={DialogResult.Lower}
                                type="button">
                            {this.lowerValue} artikelen bestellen
                        </button>
                    }
                    <button className="button button--primary button-group__button button-group__button--dialog"
                            data-action={DialogResult.Higher}
                            type="button">
                        {this.lowerValue + this.props.quantityIncrement} artikelen bestellen
                    </button>
                    <button className="button button-group__button button--text-primary button-group__button--dialog"
                            data-action={DialogResult.Cancel}
                            type="button">Annuleren
                    </button>
                </div>
            </Dialog>
        );
    }

    public get lowerValue() {
        return this.props.enteredValue - (this.props.enteredValue % this.props.quantityIncrement);
    }

    public get higherValue() {
        return this.lowerValue + this.props.quantityIncrement;
    }

    protected onDialogResult = (evt: DialogEventArgs<DialogResult>) => {
        switch (evt.result) {
            case DialogResult.Lower:
                this.props.onValueChosen(this.lowerValue);
                break;
            case DialogResult.Higher:
                this.props.onValueChosen(this.higherValue);
                break;
            case DialogResult.Cancel:
                this.props.onValueChosen(false);
                break;
        }
    }
}

