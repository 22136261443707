import './styles.scss';
import {state} from './scripts/state';
import {$domLoad} from '../components/src/utils/dom.utils';
import {MediaQueries} from './scripts/media-queries';
import {registerLocale} from 'react-datepicker';
import dateNl from 'date-fns/locale/nl';
import {initDom} from './dom';

registerLocale('nl', dateNl);

MediaQueries.bindMap(state.mediaQueries);

$domLoad(() => {
    initDom();
    MediaQueries.addGlobalClassNameListeners();
});

