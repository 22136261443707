/* tslint:disable:max-line-length */
import * as React from 'react';
import {calendarIcon, renderSvg} from '../../../images/images';
import DatePicker, {ReactDatePickerProps} from 'react-datepicker';
import {formatDate, parseDate} from '../../utils/format';

interface Props extends ReactDatePickerProps {
    inputSuffix?: React.ReactNode;
    narrow?: boolean;
}

interface State {
}

export class DatePickerInput extends React.Component<Props, State> {

    public static format = 'YYYY-MM-DD';

    public static stringToDate(str: string | undefined | null) {
        return str ? parseDate(str, DatePickerInput.format)?.toDate() : undefined;
    }

    public static dateToString(date: Date | undefined | null) {
        return date ? formatDate(date, DatePickerInput.format) : '';
    }

    private static readonly defaultAttributes: Partial<ReactDatePickerProps> = {
        locale: 'nl',
        autoComplete: 'off',
    };

    private inputElement: HTMLInputElement | null = null;

    public focus() {
        this.inputElement?.focus();
    }

    public blur() {
        this.inputElement?.blur();
    }

    public render() {
        return (
            <div className={[
                'input-wrap',
                'input-wrap--bordered',
                'input-wrap--without-padding',
                'input-wrap--with-prefix',
                this.props.inputSuffix ? 'input-wrap--with-suffix' : '',
                this.props.narrow ? 'input-wrap--narrow' : '',
            ].join(' ')}>

                {renderSvg(calendarIcon, {className: 'input-wrap__prefix'})}
                <DatePicker {...this.getDatePickerProps()} />
                {this.props.inputSuffix}
            </div>
        );
    }

    private getDatePickerProps(): ReactDatePickerProps {
        const attributes = {
            ...DatePickerInput.defaultAttributes,
            ...this.props,
            className: 'input-wrap__input ' + (this.props.className || ''),
        };
        delete attributes.narrow;
        delete attributes.inputSuffix;
        if (!('dateFormat' in attributes)) {
            attributes.dateFormat = 'dd-MM-yyyy';
        }
        return attributes;
    }
}
