/* tslint:disable:no-var-requires max-line-length */
import {observer} from 'mobx-react';
import {computed, observable, reaction} from 'mobx';
import {
    BaseOrderInvoice,
    GetInvoiceOverviewSearchCriteria,
    OverviewSearchCriteria,
    PagedList,
} from '../api/interfaces';
import {state} from '../state';
import {MediaQuery} from '../media-queries';
import {SapInvoiceOrderStatus} from '../enums/sapInvoiceOrderStatus';
import {viewPrices} from '../security/representative-claims';
import * as React from 'react';

interface State {
    loading: boolean;
    status: SapInvoiceOrderStatus;
}

export interface Props {
    mediaQueries: Map<MediaQuery, boolean>;
}

export abstract class BaseOverview extends React.Component<Props, State> {

    protected abstract pageTitle: string = '';

    protected searchTimeout?: number;
    protected sortings: number[] = [10, 25, 50];

    protected statuses: SapInvoiceOrderStatus[] = [SapInvoiceOrderStatus.Unknown, SapInvoiceOrderStatus.Pending, SapInvoiceOrderStatus.Processing, SapInvoiceOrderStatus.Complete];

    protected chevron = require('../../images/icons8-collapse-arrow-50.svg');
    protected sortDownIcon = require('../../images/icons8-sort-down-50.svg');

    protected constructor(props: Props) {
        super(props);

        if (!this.showPrices) {
            location.href = state.urls.account;
        }
    }

    @observable
    public pageSize = 10;

    @observable
    public pageNumber = 1;

    @observable
    public id: string = '';

    @observable
    public from: string = '';

    @observable
    public to: string = '';

    @observable
    public status: SapInvoiceOrderStatus = SapInvoiceOrderStatus.Unknown;

    public abstract get searchCriteria(): Partial<GetInvoiceOverviewSearchCriteria>;
    public abstract fetchItems(): void;
    public abstract onItemSelect(id: string): void;
    public abstract renderList(): any;
    public abstract renderItem(item: BaseOrderInvoice): any;
    public abstract renderMobileList(): any;
    public abstract renderFilters(): any;
    public abstract renderHeader(): any;
    @computed
    public get pagingCriteria(): OverviewSearchCriteria {
        return {
            rangeFrom: this.pageNumber,
            rangeTo: this.pageNumber,
            pageSize: this.pageSize,
        };
    }

    @computed
    public get showPrices() {
        return state.customer!.has_access(viewPrices);
    }

    @computed
    public get isFiltering() {
        return Object.values(this.searchCriteria).filter(x => x).length !== 0;
    }

    public componentDidMount(): void {
        reaction(
            () => this.pagingCriteria,
            () => {
                this.fetchItems();
            },
            {
                fireImmediately: true,
            },
        );
        reaction(
            () => this.searchCriteria,
            () => {
                this.pageNumber = 1;
                this.fetchItems();
            },
        );
    }

    public render() {
        if (!state.invoices) {
            return this.renderNoItems();
        }
        return <div
            className={`page ${this.props.mediaQueries.get(MediaQuery.MediumUp) ? 'row  row--rounded-border' : 'row row--dark'} row--no-padding `}>
            <div className="content-wrap order-overview">
                {this.renderHeader()}
                {!this.state.loading && this.renderFilters()}
                {this.props.mediaQueries.get(MediaQuery.MediumUp) ? this.renderList() : this.renderMobileList()}
            </div>
        </div>;
    }

    protected renderNoItems() {
        return this.isFiltering ?
            <div className="content-wrap">
                <div className="page-header">
                    <div className="page-header__subtitle">Er zijn geen zoekresultaten gevonden</div>
                </div>
            </div>
            : <div className="content-wrap">
                <div className="page-header">
                    <div className="page-header__subtitle">U heeft nog geen {this.pageTitle.toLowerCase()}.</div>
                </div>
            </div>;
    }

    protected renderPlaceholders(columns: number) {
        return <tr>
            <td className="--loading" colSpan={columns}>Laden..</td>
        </tr>;
    }

    protected renderPagination(metaData: PagedList) {
        const items: JSX.Element[] = [];
        if (!metaData.isFirstPage) {
            items.push(
                <a className="pagination__button pagination__button--previous"
                   onClick={async (evt) => {
                       evt.preventDefault();
                       this.pageNumber = metaData.pageNumber - 1;
                   }}>
                    <span className="icon pagination__button__icon" dangerouslySetInnerHTML={{__html: this.chevron}}/>
                </a>);
            items.push(
                <a className="pagination__item" href="" onClick={async (evt) => {
                    evt.preventDefault();
                    this.pageNumber = 1;
                }}>1</a>);
        }
        if (metaData.hasPreviousPage) {
            items.push(<a className="pagination__item pagination__item--disabled">...</a>);
            if (!metaData.isFirstPage) {
                items.push(<a href="" onClick={async (evt) => {
                    evt.preventDefault();
                    this.pageNumber = metaData.pageNumber - 1;
                }} className="pagination__item">{metaData.pageNumber - 1}</a>);
            }
        }
        items.push(
            <a href="" onClick={async (evt) => {
                evt.preventDefault();
                this.pageNumber = metaData.pageNumber;
            }} className="pagination__item pagination__item--active">{metaData.pageNumber}</a>);
        if (metaData.hasNextPage) {
            items.push(
                <a href="" onClick={async (evt) => {
                    evt.preventDefault();
                    this.pageNumber = metaData.pageNumber + 1;
                }} className="pagination__item">{metaData.pageNumber + 1}</a>);
            items.push(<a className="pagination__item pagination__item--disabled">...</a>);
            items.push(
                <a href="" onClick={async (evt) => {
                    evt.preventDefault();
                    this.pageNumber = metaData.pageCount;
                }} className="pagination__item">{metaData.pageCount}</a>);
        }
        if (!metaData.isLastPage) {
            items.push(
                <a className="pagination__button pagination__button--next"
                   onClick={async () => {
                       this.pageNumber = metaData.pageNumber + 1;
                   }}>
                    <span className="icon pagination__button__icon" dangerouslySetInnerHTML={{__html: this.chevron}}/>
                </a>);
        }
        return <div className="pagination">{items}</div>;
    }
}
