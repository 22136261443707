export enum SortByType {
    TitleAsc        = 0,
    TitleDesc       = 1,
    GrossPriceAsc   = 2,
    GrossPriceDesc  = 3,
    Default         = 4,
}

export function getSortByTypeLabel(sortByType: SortByType): string {
    switch (sortByType) {
        case SortByType.GrossPriceAsc:
            return 'Brutoprijs (laag-hoog)';
        case SortByType.GrossPriceDesc:
            return 'Brutoprijs (hoog-laag)';
        case SortByType.TitleAsc:
            return 'Alfabetisch (A-Z)';
        case SortByType.TitleDesc:
            return 'Alfabetisch (Z-A)';
        case SortByType.Default:
            return 'Aanbevolen';
    }
}

export function getSortByTypeValue(sortByType: SortByType): string {
    switch (sortByType) {
        case SortByType.GrossPriceAsc:
            return 'grossprice:asc;';
        case SortByType.GrossPriceDesc:
            return 'grossprice:desc;';
        case SortByType.TitleAsc:
            return 'title-ascending';
        case SortByType.TitleDesc:
            return 'title-descending';
        case SortByType.Default:
            return 'badgetype:desc;priority:desc;search.score():desc;lastperiodordercount:desc;name:asc;';
    }
}
