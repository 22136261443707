/* tslint:disable:max-line-length */
import {getForm, submitForm} from '../api/de-klok';
import {FormDefinition, FormElement} from '../api/interfaces';
import {ElementType} from '../enums/elementType';
import {load, ReCaptchaInstance} from 'recaptcha-v3';
import {state} from '../state';
import {ValidationType} from '../enums/validationType';
import {observer} from 'mobx-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface Props {
    name: string;
    recaptchaSiteKey: string;
}

interface State {
    loading: boolean;
    submitting: boolean;
    submitted: boolean;
    error?: string;
    form: FormDefinition | null;
    formData: {[name: string]: string};
}

let formCounter = 0;

@observer
export class GenericForm extends React.Component<Props, State> {

    private readonly formKey: string;
    private recaptcha: ReCaptchaInstance | null = null;

    public constructor(props: Props) {
        super(props);
        this.formKey = 'form_' + (formCounter++);
        this.state = {
            loading: false,
            submitted: false,
            submitting: false,
            form: null,
            formData: {},
        };
        this.loadForm();
    }

    public async componentDidMount() {
        this.recaptcha = await load(this.props.recaptchaSiteKey);
    }

    public async loadForm() {
        this.setState({
            loading: true,
            form: null,
        });
        try {
            const form = await getForm(this.props.name);
            this.setState({
                form,
                loading: false,
            });
        } catch (e) {
            this.setState({
                loading: false,
            });
            throw e;
        }
    }

    public render() {
        if (this.state.loading) {
            return <div className="notice">Bezig met laden...</div>;
        }
        if (!this.state.form) {
            return null;
        }

        if (this.state.submitted) {
            return this.renderContent(<div className="notice notice">{this.state.form!.submittedText || 'Formulier succesvol verzonden'}</div>);
        }

        return this.renderContent((
            <form className="form linear-layout linear-layout--vertical" onSubmit={(evt) => {
                evt.preventDefault();
                this.submitForm();
            }}>

                {this.state.error && <div className="notice notice--error">{this.state.error}</div>}
                {this.state.form.rows.map((row, i) => (
                    <div key={i} className="linear-layout__item">
                        <div className="form-row linear-layout linear-layout--horizontal linear-layout--large-gutters">
                            {row.columns.map(column => (
                                    <div className={[
                                        'linear-layout__item',
                                        column.grow ? 'linear-layout__item--grow' : '',
                                    ].join(' ')} key={column.element.name}>
                                        <label htmlFor={this.formKey + '_' + column.element.name}
                                               className={[
                                                   'form-row__label',
                                                   'label',
                                                   column.element.required ? 'label--required' : '',
                                               ].join(' ')}>
                                            {column.element.label}
                                        </label>
                                        <div className="form-row__input">
                                            {this.renderElement(column.element)}
                                        </div>
                                    </div>
                                ),
                            )}
                        </div>
                    </div>
                    ),
                )}

                <div className="linear-layout__item">
                    <button disabled={!this.isValid() || this.state.submitting}
                            className="button button--primary button--float"
                            type="submit">
                        {this.state.form.submitText || 'Versturen'}
                    </button>
                </div>
            </form>
        ));
    }

    protected renderContent(content: JSX.Element) {
        return <div className="generic-form --mv">
            {this.state.form && this.state.form.title &&
            <h2 className="linear-layout__item row__title row__title--no-padding-left row__title--padding-bottom-small">
                {this.state.form.title}
            </h2>}
            {content}
        </div>;
    }

    protected isValid() {
        return this.state.form!.rows.every(x => x.columns.every(
            col => !col.element.required || !!this.state.formData[col.element.name],
        ));
    }

    protected async submitForm() {
        this.setState({
            submitting: true,
        });
        try {
            const token = await this.recaptcha!.execute('contactRequest');
            const result = await submitForm(this.props.name, token, this.state.formData);
            if (result) {
                this.setState({
                    submitted: true,
                });
            } else {
                this.setState({
                    error: 'Onbekende fout opgetreden bij het versturen',
                });
            }
        } finally {
            this.setState({
                submitting: false,
            });
        }
    }

    private renderElement(element: FormElement) {
        switch (element.type) {
            case ElementType.string:
                const type = element.validation === ValidationType.email ? 'email' : 'text';
                return <input type={type}
                              id={this.formKey + '_' + element.name}
                              placeholder={element.placeholder === undefined ? element.label : element.placeholder}
                              name={element.name}
                              disabled={this.state.submitting}
                              value={this.state.formData[element.name] || element.defaultValue}
                              onChange={evt => this.onInputChange(element, evt.target as HTMLInputElement)}
                              className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>;
            case ElementType.textarea:
                return <textarea
                    id={this.formKey + '_' + element.name}
                    placeholder={element.placeholder === undefined ? element.label : element.placeholder}
                    name={element.name}
                    disabled={this.state.submitting}
                    onChange={evt => this.onInputChange(element, evt.target as HTMLTextAreaElement)}
                    className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width">{
                        this.state.formData[element.name] || element.defaultValue}</textarea>;
        }
    }

    private onInputChange(element: FormElement, htmlElement: HTMLInputElement | HTMLTextAreaElement) {
        this.setState({
            formData: {
                ...this.state.formData,
                [element.name]: htmlElement.value,
            },
        });
    }
}

export function renderGenericForm(element: HTMLElement) {
    return ReactDOM.render(<GenericForm
        recaptchaSiteKey={state.recaptcha!.siteKey}
        name={element.getAttribute('data-name') as string}/>, element);
}
