import apiClient, { getResponseData } from './client';
import {psInFoodData} from './interfaces';

export async function psInFood(ean: string) {
    const data: psInFoodData = {
        ean,
        skip: 0,
        take: 1
    };
    return getResponseData(await apiClient.post('psinfood/search', data));
}
