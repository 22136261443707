import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface Props {
    title?: string;
    features?: string;
    fullScreen?: boolean;
    closeWindowPortal: () => void;
    bodyStyle?: React.CSSProperties;
}

export class WindowPortal extends React.PureComponent<Props> {
    private readonly containerEl: HTMLDivElement;
    private externalWindow: Window | null;

    constructor(props: Props) {
        super(props);
        this.containerEl = document.createElement('div');
        this.externalWindow = null;
    }

    public componentDidMount() {
        this.externalWindow = window.open('', '', this.props.features
            || `width=${screen.availWidth},height=${screen.availHeight},top=0,left=0`)!;
        this.externalWindow.document.body.appendChild(this.containerEl);
        if (this.props.bodyStyle) {
            Object.entries(this.props.bodyStyle).forEach(([key, value]) =>
                this.externalWindow!.document.body.style.setProperty(key, value));
        }
        if (this.props.title) {
            this.externalWindow.document.title = this.props.title;
        }

        this.externalWindow.addEventListener('beforeunload', () => {
            this.props.closeWindowPortal();
        });

        if (this.props.fullScreen) {
            this.externalWindow.document.documentElement.requestFullscreen();
        }
    }

    public componentWillUnmount() {
        this.externalWindow?.close();
    }

    public render() {
        return ReactDOM.createPortal(this.props.children, this.containerEl);
    }
}
