/* tslint:disable:variable-name */
import {action, autorun, computed, observable} from 'mobx';
import {LineItemModel} from './lineItemModel';
import {
    App,
    Cart,
    CartAddress,
    LineItem,
    OrderSimulationRequest,
    OrderSimulationResult,
    PaymentMethod,
    ProductDelivery,
    Vendor,
} from './interfaces';
import {DeepPartial} from '../../../../custom';
import {
    addCoupon,
    addLineItem,
    changeLineItemQuantity,
    createOrder,
    getPaymentMethods,
    removeLineItem,
} from '../api/cart';
import {simulateOrder, updateCartExtras} from '../api/de-klok';
import {CheckoutLineItemModel} from './checkoutLineItemModel';
import {CartExtras} from '../api/interfaces';
import {state} from '../state';
import {toRuby} from '../utils/object';
import {storageClear} from '../storage';
import {VendorType} from '../enums/vendorType';

export enum SapOrderSimulationResultType {
    UnknownError = 0,
    Success = 1,
    SapOffline = 2,
    InvalidSku = 3,
}

export enum PaymentMethodName {
    Ideal = 'iDeal',
    Invoice = 'Op factuur'
}

export enum PaymentMethodCodes {
    Ideal = 'MolliePaymentMethod'
}

export default class CartModel implements Cart {

    @observable
    public loading = false;

    @observable
    public submitting = false;

    @observable
    public items: LineItem[] = [];

    @observable
    public comment: string = '';

    @observable
    public customer_reference: string = '';

    @observable
    public order_data: OrderSimulationResult | null = null;

    @observable
    public first_delivery: LineItemModel[] = [];

    @observable
    public backorder_delivery: LineItemModel[] = [];

    @observable
    public delivery_address: CartAddress | null = null;

    @observable
    public addressId: string = '';

    @observable
    public name: string = '';

    @observable
    public id: string = '';

    @observable
    public track_changes = false;

    @observable
    public active_gateway_code: string | null = null;

    public dKDVendor: Vendor = {
        email: null,
        group_name: null,
        logo_url: null,
        phone_number: null,
        products: null,
        site_url: null,
        id: 'deklokdranken',
        handle: 'deklokdranken',
        name: 'De Klok Dranken',
        vendor_type: VendorType.DeKlokDranken,
        delivery_time_in_days: 10,
        cut_off_time: '2019-09-20T19:31:59Z',
        confirmation_email_address: 'order@deklokdranken.nl',
        minimal_order_price: 100.0,
        minimal_order_price_for_free_transport: 125.0,
        transport_price: 25.0,
        sku: '104455',
        terms_of_service_file_name: '',
    };

    private payment_methods: PaymentMethod[] = [];

    private payment_methods_checked: boolean = false;

    private _orderSimulationTimeout?: number;

    constructor(private app: App, data?: DeepPartial<Cart>) {
        data = data || {};
        if (data.active_gateway_code) {
            this.active_gateway_code = data.active_gateway_code;
        }
        if (data.items) {
            this.updateItems(data.items);
        }
        if (data.comment) {
            this.comment = data.comment;
        }
        if (data.customer_reference) {
            this.customer_reference = data.customer_reference;
        }
        if (data.addressId) {
            this.addressId = data.addressId;
        }
        if (data.order_data) {
            this.order_data = data.order_data as OrderSimulationResult;
        }

        autorun(async () => {
            try {
                this.loading = true;
                if (!this.track_changes) {
                    return;
                }

                if (this.payment_methods.length === 0 && !this.payment_methods_checked) {
                    this.payment_methods = await getPaymentMethods();
                    this.updatePaymentMethods();
                    this.payment_methods_checked = true;
                }

                await this.executeOrderSimulation();
            } finally {
                this.loading = false;
            }
        });
    }

    @computed
    public get paymentMethods() {
        if (state.cart.hasSapErrorOccurred || state.cart.isSingleLightOrder) {
            return this.payment_methods.filter(pm => pm.code !== PaymentMethodCodes.Ideal);
        }
        return this.payment_methods;
    }

    public updatePaymentMethods() {
        const organization = state.CurrentOrganization;

        const invoicePayment = {
            description: PaymentMethodName.Invoice,
            code: '',
            name: PaymentMethodName.Invoice,
            logoUrl: '',
            paymentMethodType: '',
        };

        if (this.payment_methods.length > 0) {

            const idealPayment = this.payment_methods.find(pm => pm.code === PaymentMethodCodes.Ideal);
            if(idealPayment && (idealPayment.description == null || idealPayment.description === '')) {
                idealPayment.description = PaymentMethodName.Ideal;
            }

            if (organization && organization.must_pay_in_advance && idealPayment) {
                this.onPaymentChange(idealPayment);
            } else {
                this.payment_methods.unshift(invoicePayment);

                if (this.active_gateway_code === null) {
                    this.onPaymentChange(invoicePayment);
                }
            }
        }
    }

    @computed
    public get getSapErrorMessage() {
        return 'Er kan op het moment geen verbinding worden gemaakt met ons ERP systeem. '
            + `Hierdoor kunnen we helaas geen prijzen en voorraden ophalen. Bestellen is `
            + `${this.isCartValid ? 'wel gewoon' : 'niet'} mogelijk.`;
    }

    @computed
    public get orderItems() {
        if (this.order_data) {
            return this.order_data.nextDeliveryInfos.concat(this.order_data.backorderDeliveryInfos);
        }
    }

    @computed
    public get should_add_shipping_cost() {
        if (this.app.settings === null || this.total_price === null) {
            return false;
        }

        return this.total_price < this.app.settings.transportSurchargeThreshold;
    }

    public processSimulationData(result: OrderSimulationResult) {
        if (result) {
            this.delivery_address = result.deliveryAddress;

            if (result.nextDeliveryInfos) {
                const skus = result.nextDeliveryInfos.map(i => i.sku);
                const first_items = [...this.items].filter(
                    item => skus.indexOf(item.sku) !== -1)
                    .map(item => new CheckoutLineItemModel(item));
                this.first_delivery = this.updateLineQuantities(first_items, result.nextDeliveryInfos);
            }

            if (result.backorderDeliveryInfos) {
                const skus = result.backorderDeliveryInfos.map(i => i.sku);
                const backorder_items = this.items
                    .filter(item => skus.indexOf(item.sku) !== -1)
                    .map(item => new CheckoutLineItemModel(item));
                this.backorder_delivery = this.updateLineQuantities(backorder_items, result.backorderDeliveryInfos);
            }

            this.items.forEach(item => {
                if (state.cart.orderItems) {
                    const foundItem = state.cart.orderItems.find(orderItem => orderItem.sku === item.sku);
                    item.price = foundItem ? foundItem.price : null;
                }
            });
        }
    }

    public updateItems(lineItems: Array<DeepPartial<LineItem>>) {
        this.items = lineItems.map(x => new LineItemModel(this.app, this, x));
    }

    @computed
    public get hasInvalidSkus() {
        return this.order_data != null && this.order_data.result === SapOrderSimulationResultType.InvalidSku;
    }

    public createInvalidSkusMessage(): string {
        if (this.hasInvalidSkus && this.order_data) {
            const skus = this.order_data.invalidSkus;
            const skuNumbers = skus.join(', ');
            const articleOrArticles = skus.length > 1 ? 'Artikelen' : 'Artikel';
            const theOrIt = skus.length > 1 ? 'de' : 'het';
            const message = `${articleOrArticles} ${skuNumbers} ${skus.length > 1 ? 'zijn' : 'is'} helaas`
                + ` niet beschikbaar. Verwijder ${theOrIt} ${articleOrArticles.toLowerCase()}`
                + ` uit de winkelwagen. Onze klantenservice ontvangt automatisch bericht van deze melding.`;
            return message;
        }
        return '';
    }

    public updateLineQuantities(lineItems: LineItem[], deliveryInfo: ProductDelivery[]) {
        lineItems.forEach(li => {
            const prodInfo = deliveryInfo.find(info => info.sku === li.sku);
            li.quantity = prodInfo ? prodInfo.quantity : li.quantity;
        });
        return lineItems;
    }

    @computed
    public get item_count() {
        return this.items.length;
    }

    @computed
    public get itemsList() {
        return this.items;
    }

    @computed
    public get total_count() {
        return this.items.reduce((prev, curr) => prev + curr.quantity, 0);
    }

    @computed
    public get total_price() {
        return this.items.reduce((prev, curr) => prev + ((curr.price || 0) * curr.quantity), 0);
    }

    @computed
    public get total_tax_price() {
        return this.total_price * .21;
    }

    @computed
    public get isCartValid() {
        if (!this.canProceedToCheckout) {
            return false;
        }
        if (state.cart.hasSapErrorOccurred) {
            return !state.CurrentOrganization!.must_pay_in_advance;
        } else {
            return this.active_gateway_code !== null && state.CurrentOrganization!.can_order;
        }
    }

    @computed
    public get canProceedToCheckout() {
        return this.item_count !== 0 &&
            !this.submitting && !this.loading
            && !this.hasInvalidSkus && state.CurrentOrganization!.can_order
            && this.hasMetVendorMinimalOrderPrice;
    }

    @computed
    public get isSingleLightOrder() {
        return this.items.length > 0
            && this.items.filter(item => item.product?.vendor?.vendor_type !== VendorType.Light).length <= 0;
    }

    @computed
    public get hasSapErrorOccurred() {
        return this.order_data !== null && this.order_data.result === SapOrderSimulationResultType.SapOffline;
    }

    @computed
    public get groupedItemsByVendor(): LineItemModel[][] {
        const arrayToGroup: any[] = this.items;
        const groupedCartItems: any[] = arrayToGroup.reduce((cartItems, item) => {
            const val: string = item.product.vendor ? item.product.vendor.name : 'De Klok Dranken';
            cartItems[val] = [...cartItems[val] || [], item];
            return cartItems;
        }, {});
        const groupedByVendorCartItems: LineItemModel[][] = [];
        for (const key in groupedCartItems) {
            if (!groupedCartItems.hasOwnProperty(key)) {
                continue;
            }
            groupedByVendorCartItems.push(groupedCartItems[key]);
        }
        return groupedByVendorCartItems;
    }

    @computed
    public get hasMetVendorMinimalOrderPrice() {
        const vendorMinimalOrderPriceMet: boolean[] = [];
        this.groupedItemsByVendor.forEach((items: LineItemModel[]) => {
            const filteredItems = items.filter((item: LineItem) => item.product!.vendor != null
                && item.product!.vendor.vendor_type !== VendorType.DeKlokDranken);

            const totalOrderPriceByVendor = filteredItems.reduce((a: number, b: any) =>
                a + ((b.price || ((b.product.gross_price! / 100) * b.quantity)) || 0), 0);
            const vendor = filteredItems.map(v => v.product!.vendor).pop();
            if (vendor) {
                vendorMinimalOrderPriceMet.push((vendor!.minimal_order_price - totalOrderPriceByVendor) > 0);
            }
        });
        return vendorMinimalOrderPriceMet.every(value => !value);
    }

    @action
    public onPaymentChange(payment: PaymentMethod) {
        this.active_gateway_code = payment.code;
    }

    @action
    public async add_line_item(productId: string, quantity: number, forceSimulation: boolean): Promise<void> {
        try {
            this.loading = true;
            const newCart = await addLineItem(productId, quantity);
            this.updateItems(toRuby(newCart.items));
            if (forceSimulation) {
                this.executeOrderSimulation();
            }
        } catch {
            this.loading = false;
        }
    }

    @action
    public async add_coupon(couponCode: string): Promise<void> {
        try {
            this.loading = true;
            await addCoupon(couponCode);
            location.reload();
        } finally {
            this.loading = false;
        }
    }

    @action
    public async update_quantity(lineId: string, quantity: number): Promise<LineItem> {
        const item = this.items.find(x => x.id === lineId);
        if (!item) {
            throw new Error(`No cart line item found with id "${lineId}".`);
        }
        try {
            this.loading = true;
            const newCart = await changeLineItemQuantity(lineId, quantity);
            this.updateItems(toRuby(newCart.items));
            item.quantity = quantity;
        } catch {
            this.loading = false;
        }
        return item;
    }

    @action
    public async update_quantity_by_sku(sku: string, quantity: number): Promise<LineItem> {
        const item = this.items.find(x => x.sku === sku);
        if (!item) {
            throw new Error(`No cart line item found with sku "${sku}".`);
        }
        try {
            this.loading = true;
            const newCart = await changeLineItemQuantity(item.id, quantity);
            this.updateItems(toRuby(newCart.items));
            item.quantity = quantity;
        } catch {
            this.loading = false;
        }
        return item;
    }

    @action
    public async update_quantity_force_sim(sku: string, quantity: number) {
        this.update_quantity_by_sku(sku, quantity);
        this.executeOrderSimulation();
    }

    @action
    public async remove_line_item(lineId: string): Promise<void> {
        try {
            this.loading = true;
            const newCart = await removeLineItem(lineId);
            await this.updateItems(toRuby(newCart.items));
        } catch {
            this.loading = false;
        }
    }

    @action
    public async place_order(): Promise<void> {
        let redirectUrl = this.app.urls.error.replace(':code', `${400}`);

        try {
            this.submitting = true;
            const order = await createOrder();

            if (order && order.data) {

                if (order.data.orderProcessingResult && order.data.orderProcessingResult.redirectUrl) {
                    redirectUrl = order.data.orderProcessingResult.redirectUrl;
                } else if (order.data.order && order.data.order.number) {
                    redirectUrl = this.app.urls.orderConfirmation
                        .replace(':orderNumber', order.data.order.number);
                    storageClear();
                    state.loadBackorderItems(state.current_organization!);
                    state.loadSettings();
                }
            }
        } catch (error) {
            this.submitting = false;
            redirectUrl = this.app.urls.error;
        }
        window.location.href = redirectUrl;
    }

    @action
    public async update_extras(extras: CartExtras) {
        try {
            this.loading = true;
            await updateCartExtras(extras);
            this.comment = extras.comment;
            this.customer_reference = extras.customerReference;
            this.active_gateway_code = extras.gatewayCode;
        } finally {
            this.loading = false;
        }
    }

    public async executeOrderSimulation() {
        if (this.app.isCustomerLoggedIn && this.items && this.items.length > 0) {
            this.items.map(value => {
                if (value.product) {
                    if (value.product.vendor === null) {
                        return value.product.vendor = this.dKDVendor;
                    }
                }
            });
            const simulationQuery: OrderSimulationRequest = {
                products: this.items.filter(value => value.product!.vendor != null
                    && value.product!.vendor.vendor_type !== VendorType.Light).map(x => ({
                    quantity: x.quantity,
                    sku: x.sku,
                    measureUnit: x.measure_unit,
                })),
            };
            if (this._orderSimulationTimeout) {
                clearTimeout(this._orderSimulationTimeout);
            }

            this._orderSimulationTimeout = window.setTimeout(async () => {
                this._orderSimulationTimeout = undefined;
                try {
                    this.loading = true;
                    this.order_data = await simulateOrder(
                        simulationQuery,
                        this.app.has_transport_surcharge_in_backorder,
                    );
                    this.processSimulationData(this.order_data!);
                } finally {
                    this.loading = false;
                }
            }, 50);
        }
    }
}
