/* tslint:disable:max-line-length */
import * as React from 'react';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    inputPrefix?: React.ReactNode;
    inputSuffix?: React.ReactNode;
    narrow?: boolean;
    inline?: boolean;
}

interface State {
}

export class Input extends React.Component<Props, State> {
    private inputElement: HTMLInputElement | null = null;

    public focus() {
        this.inputElement?.focus();
    }

    public blur() {
        this.inputElement?.blur();
    }

    public render() {
        return (
            <div className={[
                'input-wrap',
                'input-wrap--bordered',
                'input-wrap--without-padding',
                this.props.disabled ? 'input-wrap--disabled' : '',
                this.props.inputPrefix ? 'input-wrap--with-prefix' : '',
                this.props.inputSuffix ? 'input-wrap--with-suffix' : '',
                this.props.narrow ? 'input-wrap--narrow' : '',
                ].join(' ')}
                style={{display: this.props.inline ? 'inline-block' : ''}}>
                {this.props.inputPrefix}
                <input ref={x => this.inputElement = x} {...this.getInputAttributes()} />
                {this.props.inputSuffix}
            </div>
        );
    }

    private getInputAttributes(): React.InputHTMLAttributes<HTMLInputElement> {
        const attributes = {
            ...this.props,
            className: 'input-wrap__input ' + (this.props.className || ''),
        };
        delete attributes.narrow;
        delete attributes.inputPrefix;
        delete attributes.inputSuffix;
        return attributes;
    }
}
