import {observable} from 'mobx';
import {NpvSalesGroup, SalesGroup} from './interfaces';
import {ConditionUnit} from '../enums/conditionUnit';
import NpvSalesGroupProductModel from './npvSalesGroupProductModel';
import {NonNullProperties, NullPropertyNames} from '../../../../custom';

export default class NpvSalesGroupModel implements NpvSalesGroup {

    @observable
    public id: string | null = null;

    @observable
    public bonusCondition: number;

    @observable
    public bonusConditionUnit: ConditionUnit;

    @observable
    public netCondition: number;

    @observable
    public netConditionUnit: ConditionUnit;

    @observable
    public salesGroup: SalesGroup;

    @observable
    public averageDiscountPercentage: number | null = null;

    @observable
    public totalDiscountEuros: number | null = null;

    @observable
    public averageSalesPriceMargin: number | null = null;

    @observable
    public totalDiscountPercentage: number | null = null;

    @observable
    public purchasingValue: number | null = null;

    @observable
    public annualRevenue: number | null = null;

    @observable
    public hasValidMargin: boolean | null = null;

    public products = observable<NpvSalesGroupProductModel>([]);

    constructor(data: NonNullProperties<NpvSalesGroup>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        this.salesGroup = data.salesGroup;
        this.netCondition = data.netCondition;
        this.netConditionUnit = data.netConditionUnit;
        this.bonusCondition = data.bonusCondition;
        this.bonusConditionUnit = data.bonusConditionUnit;
        if (data.averageDiscountPercentage !== undefined) {
            this.averageDiscountPercentage = data.averageDiscountPercentage;
        }
        if (data.totalDiscountEuros !== undefined) {
            this.totalDiscountEuros = data.totalDiscountEuros;
        }
        if (data.totalDiscountPercentage !== undefined) {
            this.totalDiscountPercentage = data.totalDiscountPercentage;
        }
        if (data.averageSalesPriceMargin !== undefined) {
            this.averageSalesPriceMargin = data.averageSalesPriceMargin;
        }
        if (data.purchasingValue !== undefined) {
            this.purchasingValue = data.purchasingValue;
        }
        if (data.annualRevenue !== undefined) {
            this.annualRevenue = data.annualRevenue;
        }
        if (data.hasValidMargin !== undefined) {
            this.hasValidMargin = data.hasValidMargin;
        }
        this.products.replace(data.products?.map(x => new NpvSalesGroupProductModel(x)) || []);
    }
}
