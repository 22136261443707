import {InvoiceOverview} from './interfaces';
import {
    GetInvoiceOverviewSearchCriteria,
    Invoice,
    PagedList,
} from '../api/interfaces';
import {action, observable} from 'mobx';
import {getInvoiceOverview} from '../api/de-klok';


export default class InvoiceOverviewModel implements InvoiceOverview {

    @observable
    public loading = false;

    @observable
    public invoices: Invoice[] | null = null;

    @observable
    public metaData: PagedList | null = null;

    // TODO pageNumber en pageSize komen indirect ook al terug in GetOrderOverviewSearchCriteria
    @action
    public async fetchInvoices(getInvoiceOverviewSearchCriteria: GetInvoiceOverviewSearchCriteria,
                               pageNumber: number, pageSize: number): Promise<void> {
        try {
            this.loading = true;
            const result = await getInvoiceOverview(pageNumber, pageSize, getInvoiceOverviewSearchCriteria);
            this.invoices = result.items;
            this.metaData = result.metaData;
        } finally {
            this.loading = false;
        }
    }
}
