import {observer} from 'mobx-react';
import {BaseProductFilters, Props} from './base-product-filters';
import {ITerm} from '../api/interfaces';
import {parseTermsParameterIntoOneArray} from '../utils/url';

export interface OrderListFiltersProps extends Props {
    updateFromUrl: (terms: ITerm[]) => void;
}


@observer
export class OrderListFilters extends BaseProductFilters<OrderListFiltersProps> {

    public constructor(props: OrderListFiltersProps) {
        super(props);
    }

    public componentDidMount() {
        super.componentDidMount();
        this.setState({renderSortOptions: false});
    }

    public componentWillUnmount() {
        super.componentWillUnmount();
    }

    public search(): any {
        const urlTerms = parseTermsParameterIntoOneArray(this.queryParams.terms as string);
        this.props.updateFromUrl(urlTerms!);
    }
}
