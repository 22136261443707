/* tslint:disable:no-var-requires max-line-length */
import {OrderListSelection} from './order-list-selection';
import {observer} from 'mobx-react';
import * as ReactDOM from 'react-dom';
import {state} from '../state';
import {autorun, computed, observable} from 'mobx';
import {
    ITerm,
    OrderList,
    OrderListLineItem,
    OrderListProductSearchResult,
    OrderListSortOrderType,
    SearchCriteria,
} from '../api/interfaces';
import {
    deleteOrderList,
    mergeCartWithOrderList,
    removeItemFromOrderList,
    renameOrderList,
    searchOrderList,
    setOrderCartItemPriority,
} from '../api/de-klok';
import {MediaQuery} from '../media-queries';
import {$getParent} from '../../../components/src/utils/dom.utils';
import * as React from 'react';
import {Dialog, DialogEventArgs} from './dialog';
import {OrderListDetailRow} from './order-list-detail-row';
import {MultiDrag, ReactSortable, Sortable} from 'react-sortablejs';
import {orderListQueue} from '../state/queues';
import {Portal} from 'react-portal';
import * as queryString from 'querystring';
import {parseTermsParameterIntoOneArray, UrlTerm} from '../utils/url';
import {OrderListFilters} from './order-list-filters';
import {debounce} from 'lodash';
import {ContextMenu, ContextMenuTrigger, MenuItem} from 'react-contextmenu';


// mount whatever plugins you'd like to. These are the only current options.
Sortable.mount(new MultiDrag());

const printIcon = require('../../images/icons8-print-50.svg');
const sortDownIcon = require('../../images/icons8-sort-down-50.svg');
const closeIcon = require('../../images/icons8-multiply-50.svg');
const pencilIcon = require('../../images/icons8-pencil-drawing-50.svg');


interface Props {
    mediaQueries: Map<MediaQuery, boolean>;
    noItemsTitle: string | null;
    noItemsSubTitle: string | null;
    query?: string;
}

enum DialogView {
    Show,
    None,
}

enum DialogResult {
    Ok = 'ok',
    Cancel = 'cancel',
}

interface State {
    keyword: string;
    selectedItemIds: string[];
}

const FILTERS_QUERY = 'terms';


@observer
export class OrderListDetail extends React.Component<Props, State> {
    public setSearchTerm = debounce(query => {
        this.loadListItems(query);
    }, 500);

    @computed
    public get isFiltering() {
        return Object.values(this.parsedTerms).some(x => x.name.length > 0) || (this.keyword && this.keyword.length > 0);
    }

    @computed
    private get canChangeOrder() {
        if (this.sort as OrderListSortOrderType !== 'priority') {
            return false;
        }

        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get(FILTERS_QUERY) === null || searchParams.get(FILTERS_QUERY) === '') {
            return true;
        } else {
            return false;
        }
    }

    @computed
    private get currentOrderList() {
        return state.orderList!.currentOrderList!;
    }

    @observable
    public keyword: string = '';

    @observable
    public savingQuantityCount = 0;

    @observable
    public searchResult: OrderListProductSearchResult | null = null;

    @observable
    public dialogView: DialogView = DialogView.None;

    @observable
    public renameTitleDialogView: DialogView = DialogView.None;

    @observable
    public loading = false;

    @observable
    public cartMutating = false;

    @observable
    public loadingResults = false;

    @observable
    public hasClickedOnTitle = false;

    @observable
    public renamedTitle = '';

    @observable
    public sort = 'priority';

    @observable
    public terms: UrlTerm[] = [];

    @observable
    public queryParams = queryString.parse(window.location.search.replace('?', ''));

    @observable
    public parsedTerms: ITerm[] = [];

    private sortings: OrderListSortOrderType[] = ['priority', 'name', 'sku'];
    private searchTimeout?: number;
    private orderListElement: HTMLElement | null = null;
    private requestingMergeWithCart = false;
    private inputElement: HTMLInputElement | null = null;

    public constructor(props: Props) {
        super(props);
        this.state = {
            keyword: '',
            selectedItemIds: [],
        };
        orderListQueue.onBusy = () => this.cartMutating = true;
        orderListQueue.onReady = () => this.cartMutating = false;
        window.addEventListener('beforeunload', e => {
            if (!this.cartMutating) {
                return undefined;
            }

            e.returnValue = 'Er zijn nog acties bezig. Wanneer je de pagina nu verlaat worden sommige wijzigingen mogelijk niet doorgevoerd';
        });
        autorun(() => {
            if (!state.orderList || !state.orderList.currentOrderList) {
                return;
            }
            const productSearchCriteria: SearchCriteria = {
                keyword: this.keyword,
                terms: parseTermsParameterIntoOneArray(this.queryParams.terms as string),
            };
            const id = state.orderList.currentOrderList.id;

            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }
            this.searchTimeout = window.setTimeout(async () => {
                this.searchTimeout = undefined;
                this.loadingResults = true;
                this.searchResult = await searchOrderList(id, {
                    productSearchCriteria,
                    sortOrder: this.sort,
                });
                this.renamedTitle = this.searchResult.orderList.name;
                this.loadingResults = false;
            }, 50);
        });
    }

    public render() {
        if (!state.orderList || state.orderList.orderLists === null) {
            return null;
        }

        if (this.loadingResults) {
            return [
                <div className="content-wrap">
                    <div className="page-header">
                        <h1 className="page-header__title">Een moment geduld a.u.b.</h1>
                        <div className="page-header__subtitle">Uw bestellijst wordt geladen.</div>
                    </div>
                </div>,
                <div className="filter-list__loading">
                    <div className="filter-list__loading--loader"/>
                </div>,
            ];
        }

        if (!this.loadingResults && state.orderList.orderLists.length === 0 && !this.isFiltering) {
            return <div className="page page--cart cart row row--no-padding-top">
                {this.renderBreadcrumb('')}
                <div className="content-wrap">
                    <div className="page-header">
                        <h1 className="page-header__title">Geen bestellijst</h1>
                        <div className="page-header__subtitle">U heeft nog geen bestellijst.</div>
                    </div>
                </div>
            </div>;
        }

        if (!state.orderList!.currentOrderList!) {
            return (
                <div ref={el => this.orderListElement = el}>
                    <OrderListSelection showHearts={false} onSelected={orderList => this.onListSelected(orderList)}/>
                </div>
            );
        }

        if (!this.isFiltering) {
            if ((!this.loadingResults && this.searchResult == null) ||
                (this.searchResult != null && this.searchResult.orderList.items.length === 0)) {
                return [
                    <div className={'orderlist'}>
                        {this.renderBreadcrumb(this.currentOrderList.name)}
                        {this.renderDialog()}
                        {this.renderFilter()}
                        {this.state.keyword ?
                            <div className="page-header">
                                <h1 className="page-header__title">Geen zoekresultaten</h1>
                                <div className="page-header__subtitle">
                                    Er zijn geen zoekresultaten gevonden voor
                                    "<b>{this.state.keyword}</b>"
                                </div>
                                <input type="hidden" autoComplete="off" name="type"
                                       ref={el => this.inputElement = el}
                                       value={this.state.keyword}/>
                                <button className="button button--text-primary page-header__button"
                                        onClick={() => this.clearInput()}>
                                <span className="icon button__icon icon--small"
                                      dangerouslySetInnerHTML={{__html: closeIcon}}/>
                                    Verwijder zoekopdracht
                                </button>
                            </div>
                            :
                            <div className="page-header">
                                <h1 className="page-header__title">{this.props.noItemsTitle}</h1>
                                <div className="page-header__subtitle">{this.props.noItemsSubTitle}
                                    <b>{this.currentOrderList.name}</b>.
                                </div>
                                {this.currentOrderList.canBeDeleted &&
                                <button className="button button--text-primary page-header__button"
                                        onClick={() => this.dialogView = DialogView.Show}>
                                <span className="icon button__icon icon--small"
                                      dangerouslySetInnerHTML={{__html: closeIcon}}/>
                                    Verwijder bestellijst
                                </button>
                                }
                            </div>
                        }
                    </div>,
                ];
            }
        }
        return (
            <div className="orderlist" id="order_list">
                {this.cartMutating && (
                    <Portal node={document.querySelector('.subheader')}>
                        <div className="subheader__loading-indicator "/>
                    </Portal>
                )}
                {this.renderBreadcrumb(this.currentOrderList.name)}
                {this.renderDialog()}
                {this.renderRenameTitleDialog()}
                {this.renderHeader()}
                <div className="search-result">
                    {this.renderFilter()}
                    {this.renderItemList()}
                </div>
                <ContextMenu id="resultWrap">
                    <MenuItem>
                        {this.state.selectedItemIds.length}
                        {this.state.selectedItemIds.length > 1 ? ' Artikelen' : ' Artikel' } versturen naar
                    </MenuItem>
                    <MenuItem
                        onClick={(evt, selectedObject) => this.setItemsToTop(selectedObject)}>
                        Bovenkant van lijst
                    </MenuItem>
                    <MenuItem onClick={(e, selectedObject) => this.setItemsToBottom(selectedObject)}>
                        Onderkant van lijst
                    </MenuItem>
                </ContextMenu>
            </div>);
    }

    public async loadListItems(keyword?: string) {
        this.loadingResults = true;
        const productSearchCriteria: SearchCriteria = {
            keyword,
            terms: this.parsedTerms,
        };
        if (keyword) {
            this.setState({keyword});
        }
        const id = state.orderList!.currentOrderList!.id;
        this.searchResult = await searchOrderList(id, {
            productSearchCriteria,
            sortOrder: this.sort,
        });
        this.loadingResults = false;

    }

    public onListSelected(orderList: OrderList): void {
        const dropdown = $getParent<HTMLAppDropdownElement>(this.orderListElement!, 'app-dropdown');
        if (dropdown) {
            dropdown.closeDropdown(true); // TODO make this work
            if (document.activeElement && (document.activeElement as HTMLButtonElement).blur) {
                (document.activeElement as HTMLButtonElement).blur();
            }
        }
        location.href = state.urls.orderList.replace(':orderListId', orderList.id);
    }

    public updateFromUrl = (terms: ITerm[]) => {
        this.parsedTerms = terms.filter(y => y.name.length > 0);
        this.loadListItems(this.state.keyword);
    }

    private safeLoadListItems() {
        if (!document.head.attachShadow) {
            this.loadListItems(this.state.keyword);
        }
    }

    private async saveTitle(evt: DialogEventArgs<DialogResult>) {
        if (evt.result === DialogResult.Ok) {
            this.loading = true;
            try {
                await renameOrderList(this.currentOrderList.id, {
                    renamedTitle: this.renamedTitle,
                });
            } finally {
                this.currentOrderList.name = this.renamedTitle;
                this.loading = false;
                this.renameTitleDialogView = DialogView.None;
            }
        } else {
            this.renameTitleDialogView = DialogView.None;
        }
    }

    private renderBreadcrumb(listName: string) {
        const wrapper = document.getElementById('list-name-wrap');
        if (wrapper && this.searchResult) {
            wrapper.innerHTML = listName;
        }
    }

    private renderFilter() {
        const searchResult = this.searchResult;
        return <OrderListFilters
            key={'order_list_filters'}
            terms={this.searchResult ? searchResult?.aggregations! : []}
            updateFromUrl={this.updateFromUrl}/>;
    }

    private renderRenameTitleDialog() {
        if (this.renameTitleDialogView === DialogView.Show) {
            return (
                <Dialog<DialogResult> onResult={(evt) => this.saveTitle(evt)} position="bottom"
                                      title="Wijzig titel van bestellijst">
                    <div className="form-row form-row--label-on-top">
                        <label className="label--required form-row__label label--required">Naam bestellijst</label>
                        <input
                            maxLength={30}
                            value={this.renamedTitle}
                            className="input-wrap__input input-wrap__input--bordered input-wrap__input--half-width"
                            onChange={evt => {
                                this.renamedTitle = (evt.target as HTMLInputElement).value;
                            }}/>
                    </div>
                    <div className="form-row">
                        <div className="button-group button-group--compact">
                            <button
                                disabled={this.loading || this.renamedTitle === ''}
                                type="button"
                                className="button button--primary button-group__button button--float"
                                data-action={DialogResult.Ok}>
                                Opslaan
                            </button>
                            <button
                                disabled={this.loading}
                                type="button"
                                className="button button--text-primary button-group__button"
                                data-action={DialogResult.Cancel}>
                                Annuleren
                            </button>
                        </div>
                    </div>
                </Dialog>
            );
        }
    }

    private renderDialog() {
        if (this.dialogView === DialogView.Show) {
            return (
                <Dialog<DialogResult>
                    onResult={evt => this.onDialogResult(evt)}
                    position="bottom"
                    title="Bestellijst verwijderen">
                    <p className="dialog-container__dialog__text">
                        Weet u zeker dat u <b>{this.currentOrderList!.name}</b> wilt verwijderen?
                    </p>
                    <div className="form-row">
                        <div className="button-group button-group--compact">
                            <button
                                disabled={this.loading}
                                className="button button--primary button-group__button button--float"
                                data-action={DialogResult.Ok}>Verwijderen
                            </button>
                            <button
                                disabled={this.loading}
                                className="button button--text-primary button-group__button"
                                data-action={DialogResult.Cancel}>Annuleren
                            </button>
                        </div>
                    </div>
                </Dialog>
            );
        }
    }

    private renderHeader() {
        const orderList = state.orderList!;
        return <div className="orderlist__header --no-print">
            <div className="orderlist__header__info">
                <h1 className="orderlist__header__info__title">
                    {this.currentOrderList.name}
                </h1>
                <div className="orderlist__header__info__buttons">
                    <button className="button button--text-primary orderlist__header__info__buttons__button"
                            onClick={() => window.print()}>
                            <span className="icon button__icon icon--small icon--with-text"
                                  dangerouslySetInnerHTML={{__html: printIcon}}/>
                        Print bestellijst
                    </button>
                    {this.currentOrderList.canBeDeleted && (
                        <>
                            <button className="button button--text-primary orderlist__header__info__buttons__button"
                                    onClick={() => this.renameTitleDialogView = DialogView.Show}>
                                    <span className="icon button__icon icon--small icon--with-text"
                                          dangerouslySetInnerHTML={{__html: pencilIcon}}/>
                                Wijzig titel
                            </button>
                            <button className="button button--text-primary orderlist__header__info__buttons__button"
                                    onClick={() => this.dialogView = DialogView.Show}>
                                <span className="icon button__icon icon--small icon--with-text"
                                      dangerouslySetInnerHTML={{__html: closeIcon}}/>
                                Verwijder bestellijst
                            </button>
                        </>
                    )}
                </div>
            </div>
            <div className="orderlist__header__function-row">
                <div className="orderlist__header__function-row__input">
                    <input type="text"
                           ref={el => this.inputElement = el}
                           placeholder="Zoeken op artikelnaam, nummer..."
                           defaultValue={this.state.keyword}
                           onKeyUp={e => this.setSearchTerm(e.currentTarget.value) }
                           className={`input-wrap__input input-wrap__input--bordered ${this.state.keyword ? '' : 'filters__input-wrap__search'}`}
                           id="product_number"/>
                    {this.state.keyword ? <span className="filters__input-wrap__clear-input" onClick={() => this.clearInput()}/> : ''}
                </div>
                <div className="orderlist__header__function-row__switches switch-container">
                    <label className="switch">
                        <input type="checkbox" checked={orderList.showPriceAndStock}
                               onChange={evt => {
                                   orderList.showPriceAndStock = evt.target.checked;
                                   this.safeLoadListItems();
                               }}/>
                        <span className="toggle round"/>
                    </label>
                    <span className="switch-container__label switch-container__label--padding-small">Prijs en voorraad weergeven</span>
                </div>
                <div className="orderlist__header__function-row__switches switch-container">
                    <label className="switch">
                        <input type="checkbox" checked={orderList.editPreferredQuantities}
                               onChange={evt => {
                                   orderList.editPreferredQuantities = evt.target.checked;
                                   this.safeLoadListItems();
                               }}/>
                        <span className="toggle round"/>
                    </label>
                    <span className="switch-container__label">Voorraadbehoefte bewerken</span>
                </div>
                <div className="filters__head__sort">
                    <app-dropdown inline="false" content-align="center" content-class="dropdown__content--light">
                        <span slot="trigger" className="label">
                            {this.sortLabel(this.sort as OrderListSortOrderType)}
                            <span className="icon icon--text-size dropdown__arrow"
                                  dangerouslySetInnerHTML={{__html: sortDownIcon}}/>
                        </span>
                        {this.sortings.map((x, i) => (
                            <a href="#"
                               key={i}
                               className={this.sort === x ? 'active' : ''}
                               onClick={evt => {
                                   evt.preventDefault();
                                   this.sort = x;
                                   this.loadListItems(this.state.keyword);
                               }}>{this.sortLabel(x)}</a>
                        ))}
                    </app-dropdown>
                </div>
            </div>
        </div>;
    }

    private renderItem(item: OrderListLineItem, index: number) {
        return <OrderListDetailRow
            canChangeOrder={this.canChangeOrder}
            item={item}
            rowNumber={index + 1}
            onRemove={itemId => this.removeItem(itemId)}
            orderListId={this.currentOrderList!.id}
            key={item.id}
            mediaQueries={state.mediaQueries}
            onSavingQuantity={saving => {
                if (saving) {
                    this.setSavingQuantityCount(this.savingQuantityCount + 1);
                } else {
                    this.setSavingQuantityCount(this.savingQuantityCount - 1);
                }
            }}
        />;
    }

    private renderItemList() {
        if (this.currentOrderList === null) {
            return null;
        }
        return (
            this.props.mediaQueries.get(MediaQuery.MediumUp) || this.props.mediaQueries.get(MediaQuery.Print) ?
                <ContextMenuTrigger id="resultWrap" attributes={{style: {width: '100%'}}}
                                    disable={this.state.selectedItemIds.length <= 0}
                                    collect={() => [this.state.selectedItemIds]}>
                    <table className="order-item-list">
                        <thead>
                        <tr>
                            {this.canChangeOrder && <th className="--no-print"></th>}
                            <th className="--no-print"></th>
                            <th className="--no-print"></th>
                            <th className="--all-caps">Artikelnaam</th>
                            {state.orderList!.showPriceAndStock && (
                                <>
                                    <th className="--all-caps --no-print">Prijs</th>
                                    <th className="--all-caps --no-print">Voorraad</th>
                                </>
                            )}
                            <th className="--all-caps">Behoefte</th>
                            <th className="--all-caps">Aantal</th>
                        </tr>
                        </thead>
                        {this.searchResult &&
                        <ReactSortable
                            multiDrag
                            key={'react-sortable'}
                            list={this.searchResult.orderList.items}
                            setList={items => this.replaceItems(items)}
                            onSelect={(evt) => this.selectItems(evt)}
                            onDeselect={(evt) => this.selectItems(evt)}
                            tag={'tbody'}
                            easing={'ease-in-out'}
                            animation={150}
                            onEnd={evt => this.onSortEnd(evt)}
                            handle="[data-sortable-handle]">
                            {this.searchResult.orderList.items.map((item, index) => this.renderItem(item, index))}
                        </ReactSortable>
                        }
                    </table>
                </ContextMenuTrigger> :
                <div className="order-item-list">
                    {this.searchResult &&
                    <ReactSortable
                        multiDrag
                        list={this.searchResult.orderList.items}
                        setList={items => this.replaceItems(items)}
                        tag="div"
                        animation={150}
                        onEnd={evt => this.onSortEnd(evt)}
                        handle="[data-sortable-handle]">
                        {this.searchResult.orderList.items.map((item, index) => this.renderItem(item, index))}
                    </ReactSortable>}
                </div>);

    }

    private setSavingQuantityCount(count: number) {
        this.savingQuantityCount = count;
        if (count === 0) {
            if (this.requestingMergeWithCart) {
                this.mergeOrderListWithCart();
            }
        }
    }

    private async onDialogResult(evt: DialogEventArgs<DialogResult>) {
        try {
            switch (evt.result) {
                case DialogResult.Ok:
                    this.loading = true;
                    await deleteOrderList(this.currentOrderList!.id);
                    window.location.href = state.urls.home;
                    this.dialogView = DialogView.None;
                    break;
                case DialogResult.Cancel:
                    this.dialogView = DialogView.None;
                    break;
            }
        } finally {
            this.loading = false;
        }
    }

    private async removeItem(itemId: string) {
        try {
            this.loading = true;
            await removeItemFromOrderList(this.currentOrderList!.id, itemId);
            const searchResult = {
                ...this.searchResult!,
            };

            const index = searchResult.orderList.items.findIndex(x => x.product.id === itemId);
            if (index > -1) {
                searchResult.orderList.items.splice(index, 1);
            }

            this.searchResult = searchResult;
        } finally {
            this.loading = false;
        }
    }

    private async mergeOrderListWithCart() {
        if (this.savingQuantityCount > 0) {
            this.requestingMergeWithCart = true;
            return;
        }
        this.requestingMergeWithCart = false;
        try {
            this.loading = true;
            await mergeCartWithOrderList(this.currentOrderList!.id);
            window.location.href = state.urls.cart;
        } finally {
            this.loading = false;
        }
    }

    private async onSortEnd(evt: Sortable.SortableEvent) {
        const selectedItems: HTMLElement[] = evt.items as HTMLElement[];
        if (evt.items.length <= 0 && evt.item !== null) {
            const selectedItem = selectedItems.push(evt.item);
        }
        const itemIds = selectedItems.map(item => item.getAttribute('data-id')!);
        await setOrderCartItemPriority(this.currentOrderList!.id, {
            itemIds,
            priority: evt.newIndex!,
        });
    }

    private replaceItems(items: OrderListLineItem[]) {
        if (!this.searchResult) {
            return;
        }
        const searchResult: OrderListProductSearchResult = {
            ...this.searchResult,
            orderList: {
                ...this.searchResult.orderList,
                items,
            },
        };
        searchResult.orderList.items = items;
        this.searchResult = searchResult;
    }

    private clearInput() {
        const query = this.inputElement!.value;
        if (query) {
            this.setState({
                keyword: '',
            });
            this.inputElement!.value = '';
            this.loadListItems('');
        }
        return;
    }

    private sortLabel(orderType: OrderListSortOrderType) {
        switch (orderType) {
            case 'name':
                return 'Naam';
            case 'sku':
                return 'Artikelnummer';
            case 'priority':
                return 'Persoonlijk';
        }
    }

    private setItemsToTop(clickedItem: any) {
        delete clickedItem.target;

        const selectedItemIds = Object.values<string[]>(clickedItem)[0];
        const array = [...this.searchResult!.orderList!.items];
        selectedItemIds
            .forEach(x => array.unshift(array.splice(array.findIndex(elt => elt.id === x), 1)[0]));

        this.setItemsPriority(selectedItemIds, 0);
        this.replaceItems(array);
    }

    private setItemsToBottom(clickedItem: any) {
        delete clickedItem.target;

        const selectedItemIds = Object.values<string[]>(clickedItem)[0];

        const array = [...this.searchResult!.orderList!.items];
        const priority = array[array.length - 1].priority + 1;
        selectedItemIds.forEach(x =>
            array.push(...array.splice(array.findIndex(elt => elt.id === x), 1)));

        this.setItemsPriority(selectedItemIds, priority);
        this.replaceItems(array);
    }

    private async setItemPriority(lineItem: OrderListLineItem, priority: number) {
        await setOrderCartItemPriority(this.currentOrderList!.id, {
            itemIds: [lineItem!.id],
            priority,
        });
    }

    private setItemsPriority(itemIds: string[], priority: number) {
        setOrderCartItemPriority(this.currentOrderList!.id, {
            itemIds,
            priority,
        });
    }

    private selectItems(evt: Sortable.SortableEvent) {
        const items = evt.items as HTMLElement[];
        const selected = items.map(item => item.getAttribute('data-id')!);
        this.setState({selectedItemIds: selected});
    }
}

export function renderOrderListDetail(element: HTMLElement) {
    return ReactDOM.render(<OrderListDetail
        key={'order_list_detail'}
        noItemsTitle={element.getAttribute('no-items-title' as string)}
        noItemsSubTitle={element.getAttribute('no-items-subtitle' as string)}
        mediaQueries={state.mediaQueries}/>, element);
}
