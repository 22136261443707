/* tslint:disable:variable-name */
import {observable} from 'mobx';
import {getPriceAndStock} from '../api/de-klok';
import {App, PriceQuantity, PriceStock} from './interfaces';
import {delay} from '../utils/promise';
import {PriceStockResultProduct} from '../api/interfaces';

interface RequestQueue {
    promise: Promise<PriceStockResultProduct[]>;
    products: PriceQuantity[];
}

export default class PriceStockModel implements PriceStock {
    public static readonly batchSize = 21;

    @observable
    public map = new Map<string, Promise<PriceStockResultProduct>>();

    @observable
    public queue: PriceQuantity[] = [];

    private currentRequestQueue: RequestQueue[] = [];

    private app: App;

    constructor(private application: App) {
        this.app = application;
    }

    public get_price_stock_result(product: PriceQuantity): Promise<PriceStockResultProduct> {
        const mapKey = `${product.sku}|${product.quantity}|${product.measureUnit}`;
        if (!this.map.has(mapKey)) {
            this.queue.push(product);
            let getRequestQueue = this.currentRequestQueue
                .find(x => x.products.length < PriceStockModel.batchSize);
            let queueIndex: number;
            if (!getRequestQueue) {
                const products = [product];
                queueIndex = 0;
                getRequestQueue = {
                    products,
                    promise: this.get_price_stock_results(products)
                        .then(r => {
                            this.currentRequestQueue = this.currentRequestQueue
                                .filter(x => x !== getRequestQueue);
                            return r;
                        }),
                };
                this.currentRequestQueue.push(getRequestQueue);
            } else {
                getRequestQueue.products.push(product);
                queueIndex = getRequestQueue.products.length - 1;
            }
            this.map.set(mapKey, getRequestQueue.promise
                .then(x => x[queueIndex])
                .then(x => ({...x, price: x && x.price !== null && x.price !== undefined ? x.price * 100 : null})),
            );
        }
        return this.map.get(mapKey)!;
    }

    private async get_price_stock_results(products: PriceQuantity[]): Promise<PriceStockResultProduct[]> {
        await delay(50);
        const response = await getPriceAndStock(products);
        return response ? response.result : [];
    }
}
