import {observer} from 'mobx-react';
import {autorun, computed, IObservableArray, observable} from 'mobx';
import {
    deleteSalesGroup,
    getRootCategoryOptions,
    getSalesGroups,
    saveSalesGroup,
    updateSalesGroupPurchasePrices,
} from '../../../api/npv';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {SalesGroupWithCategory, SelectOption} from '../../../models/interfaces';
import {SalesGroupsEdit} from './edit';
import {pencilDrawingIcon, plusIcon, refreshIcon, renderSvg, trashIcon} from '../../../../images/images';
import {formatPercentage} from '../../../utils/format';

interface Props {
    showForm?: boolean | string;
}

@observer
export class SalesGroupsOverview extends React.Component<Props, {}> {

    @observable
    public showForm: boolean | string = false;

    @observable
    private loading = false;

    @observable
    private busy = false;

    @observable
    private items?: SalesGroupWithCategory[];

    @observable
    private rootCategories: SelectOption[] | null = null;

    @computed
    private get editing() {
        if (!this.items || !this.showForm || this.showForm === true) {
            return undefined;
        }
        return this.items.find(x => x.id === this.showForm);
    }

    public constructor(props: Props) {
        super(props);

        if (this.props.showForm) {
            this.showForm = this.props.showForm;
        }

        autorun(async () => {
            try {
                this.loading = true;

                const [salesGroupsResult, rootCategories] = await Promise.all([
                    getSalesGroups(),
                    getRootCategoryOptions(),
                ]);
                this.items = salesGroupsResult;
                this.rootCategories = rootCategories;
            } finally {
                this.loading = false;
            }
        });
    }

    public async refreshItems() {
        this.items = await getSalesGroups();
    }

    public render() {
        if (this.loading) {
            return <div className="notice">Bezig met laden...</div>;
        }
        if (this.showForm) {
            if (this.rootCategories) {
                return <SalesGroupsEdit
                    rootCategories={this.rootCategories}
                    salesGroup={this.editing}
                    onCloseRequested={async saved => {
                        location.hash = '';
                        if (saved) {
                            this.refreshItems();
                        }
                    }}
                    />;
            }
        }
        return <div className="sales-groups">
            <table className="table table--sales-groups">
                <thead>
                <tr>
                    <th>Naam</th>
                    <th>Categorie</th>
                    <th>Marge</th>
                    <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                {this.items && this.items.map(item => (
                    <tr key={item.id}>
                        <td>{item.name}</td>
                        <td>{item.category.name}</td>
                        <td>{formatPercentage(item.margin)}</td>
                        <td align="right">
                            <div className="button-group button-group button-group--compact button-group--inline">
                                <button className="button button--small button-group__button"
                                        title="Marge opnieuw berekenen"
                                        disabled={this.busy}
                                        onClick={() => this.onRefreshClick(item)}>
                                    {renderSvg(refreshIcon, {
                                        className: 'icon',
                                    })}
                                </button>
                                <button className="button button--primary button--small button-group__button"
                                        disabled={this.busy}
                                        onClick={() => window.location.hash = item.id}>
                                    {renderSvg(pencilDrawingIcon, {
                                        className: 'icon',
                                    })}
                                </button>
                                <button className="button button--warn button--small button-group__button"
                                        disabled={this.busy}
                                        onClick={() => this.deleteSalesGroup(item)}>
                                    {renderSvg(trashIcon, {
                                        className: 'icon',
                                    })}
                                </button>
                            </div>
                        </td>
                    </tr>
                ))}
                <tr>
                    <td colSpan={5}>
                        <button className="button button--primary button--small"
                                disabled={this.busy}
                                onClick={() => window.location.hash = '1'}>
                            {renderSvg(plusIcon, {
                                className: 'icon',
                            })}
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>;
    }

    private async deleteSalesGroup(item: SalesGroupWithCategory) {
        if (confirm('Weet je zeker dat je de verkoopgroep wilt verwijderen?')) {
            try {
                this.setState({
                    saving: true,
                });
                this.busy = true;
                await deleteSalesGroup(item.id);
                (this.items as IObservableArray).remove(item);
            } finally {
                this.busy = false;
            }
        }
    }

    private async onRefreshClick(item: SalesGroupWithCategory) {
        try {
            this.busy = true;
            const [salesGroup] = await updateSalesGroupPurchasePrices([item.id]);
            item.margin = salesGroup.margin;
        } finally {
            this.busy = false;
        }
    }
}

export function renderSalesGroupsOverview(element: HTMLElement) {
    const getShowFormFromHash = () => {
        const locationHashMatch = /^#([A-Z0-9-]+|1)$/i.exec(location.hash);
        if (!locationHashMatch) {
            return false;
        }
        if (locationHashMatch[1] === '1') {
            return true;
        }
        return locationHashMatch[1];
    };
    const overview = ReactDOM.render<SalesGroupsOverview>(<SalesGroupsOverview
        showForm={getShowFormFromHash()}/>, element);
    window.addEventListener('hashchange', () => {
        const showForm = getShowFormFromHash();
        (overview as any).showForm = showForm;
    }, false);
    return overview;
}
