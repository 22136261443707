/* tslint:disable:max-line-length */
import * as React from 'react';

export interface Step {
    title: string;
    clickable?: boolean;
    disabled?: boolean;
}

interface Props {
    steps: Step[];
    activeStep?: number;
    onStepClick?: (step: Step, index: number, stepper: Stepper) => void;
}

interface State {
    steps: Step[];
}

export class Stepper extends React.Component<Props, State> {

    public static defaultProps = {
        activeStep: 0,
    };

    public get activeStep() {
        return this.props.activeStep!;
    }

    public constructor(props: Props) {
        super(props);

        if (!this.props.steps?.length) {
            throw new Error('No steps defined.');
        }
        if (this.props.steps.length < this.activeStep + 1) {
            throw new Error(`Cannot make step ${this.activeStep} active because there are only ${this.props.steps.length} steps defined.`);
        }

        this.state = {
            steps: props.steps,
        };
    }

    public render() {
        return <div className="stepper">
            {this.state.steps.map((step, i) => [
                <div key={i + 'step'}
                     onClick={() => this.onStepClick(step, i)}
                     className={[
                         'stepper__step',
                         i < this.activeStep ? 'stepper__step--done' : '',
                         i === this.activeStep ? 'stepper__step--active' : '',
                         this.stepIsClickable(step) ? 'stepper__step--clickable' : '',
                         step.disabled ? 'stepper__step--disabled' : '',
                     ].join(' ')}>
                    <span className="stepper__step__number">{i + 1}</span>
                    <span className="stepper__step__title">{step.title}</span>
                </div>,
                i + 1 !== this.state.steps.length && <div key={i + 'track'} className="stepper__track"/>,
            ])}
        </div>;
    }

    private stepIsClickable(step: Step) {
        return !step.disabled && (step.clickable === undefined || step.clickable) && !!this.props.onStepClick;
    }

    private onStepClick(step: Step, index: number) {
        if (step.disabled) {
            return;
        }
        if (this.stepIsClickable(step)) {
            this.props.onStepClick!(step, index, this);
        }
    }
}
