import {observable} from 'mobx';
import {NpvDiscountScale} from './interfaces';

export default class NpvDiscountScaleModel implements NpvDiscountScale {

    @observable
    public id: string | null;

    @observable
    public fromAmount: number | null;

    @observable
    public toAmount: number | null;

    @observable
    public discountPercentage: number;

    constructor(data: NpvDiscountScale) {
        this.id = data.id;
        this.fromAmount = data.fromAmount;
        this.toAmount = data.toAmount;
        this.discountPercentage = data.discountPercentage;
    }
}
