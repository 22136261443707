/* tslint:disable:no-var-requires max-line-length */
import { Dialog } from './dialog';
import { psInFood } from '../api/ps-in-food-api';
import { PsInFoodAllergenLabel, getPsInFoodAllergen, ContainsClass, NotContainsClass } from '../enums/ps-in-food';
import { PsInFoodDataType, PsInFoodAllergens } from '../models/interfaces';
import { observer } from 'mobx-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface Props {
    productBarcode: string;
    squidexText: string;
}

interface State {
    showDialog: boolean;
    psInFoodData: PsInFoodDataType;
    hasPsInFoodData: boolean;
    loading: boolean;
}

enum DialogResult {
    Cancel = 'cancel'
}

@observer
export class PsInFood extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            showDialog: false,
            hasPsInFoodData: false,
            psInFoodData: {},
            loading: false
        };
    }

    private squidexText = JSON.parse(this.props.squidexText);

    public componentDidMount(): void {
        this.getPsInFood(this.props.productBarcode);
    }

    public render() {
        return (
            <>
                {this.state.loading ? (
                    <button className="button button--bordered">
                        <div className="loading"></div>
                    </button>
                ) : this.state.hasPsInFoodData ? (
                    <button
                        className="button button--bordered"
                        onClick={(e) => {
                            this.setState({ showDialog: true });
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        {this.squidexText.button_text}
                    </button>
                ) : null}

                {this.state.showDialog && this.state.hasPsInFoodData && this.renderDialog()}
            </>
        );
    }

    private renderDialog() {
        return (
            <Dialog<DialogResult> onResult={() => this.setState({ showDialog: false })} position="center" closable>
                <div className="ps-in-food-dialog">
                    <div className="main-header">
                        <h2>{this.squidexText.title_text}</h2>
                        <h3 className="product-title">{this.state.psInFoodData?.productInfo?.name?.value}</h3>
                    </div>
                    <div className="info-header">
                        <h3>{this.squidexText.description_title_text}</h3>
                        <dl>
                            <dt>EAN:</dt>
                            <dd className="mark">{this.state.psInFoodData?.summary?.ean}</dd>
                        </dl>
                    </div>
                    <div className="main-description">
                        <p>{this.state.psInFoodData?.commercialInfo?.commercialStoryText?.value}</p>
                    </div>
                    <div className="additional-description">
                        <div>
                            <h3>{this.squidexText.origin_title_text}</h3>
                            <dl>
                                <dt>Land van herkomst:</dt>
                                <dd>{this.state.psInFoodData?.productInfo?.countryOfOriginName?.value}</dd>
                                <dt>Wettelijke naam:</dt>
                                <dd>{this.state.psInFoodData?.commercialInfo?.legalName?.value}</dd>
                            </dl>
                        </div>
                        <div>
                            <h3>{this.squidexText.ingredients_title_text}</h3>
                            <p>{this.state.psInFoodData?.specificationInfo?.ingredientSet?.ingredientDeclaration?.value}</p>
                        </div>
                    </div>
                    <h3>{this.squidexText.allergens_title_text}</h3>
                    <ul className="labels">
                        <li>
                            {this.squidexText.allergens_contains_text} <span className={ContainsClass}>{PsInFoodAllergenLabel.Contains}</span>
                        </li>
                        <li>
                            {this.squidexText.allergens_may_contain_text} <span className={ContainsClass}>{PsInFoodAllergenLabel.MayContain}</span>
                        </li>
                        <li>
                            {this.squidexText.allergens_not_supplied_text}{' '}
                            <span className={NotContainsClass}>{PsInFoodAllergenLabel.DoesNotContain}</span>
                        </li>
                        <li>
                            {this.squidexText.allergens_not_contain_text}{' '}
                            <span className={NotContainsClass}>{PsInFoodAllergenLabel.NotSpecified}</span>
                        </li>
                    </ul>
                    <div className="allegen-set">
                        {this.state.psInFoodData?.specificationInfo?.allergenSet?.allergens?.map((item: PsInFoodAllergens) =>
                            this.renderAlergenItem(item)
                        )}
                    </div>
                </div>
            </Dialog>
        );
    }

    public renderAlergenItem(item: PsInFoodAllergens) {
        return (
            <div className="allegen-item" key={item.id}>
                <div>{item.name.value}</div>
                <div className={getPsInFoodAllergen(item.levelOfContainmentId).class}>{getPsInFoodAllergen(item.levelOfContainmentId).label}</div>
            </div>
        );
    }

    private async getPsInFood(ean: string) {
        this.setState({ loading: true });
        const psInFoodResult = await psInFood(ean);
        if (psInFoodResult.productData.length > 0) {
            this.setState({ psInFoodData: psInFoodResult.productData[0] });
            this.setState({ hasPsInFoodData: true });
        }
        this.setState({ loading: false });
    }
}

export function renderPsInFood(element: HTMLElement) {
    return ReactDOM.render(
        <PsInFood
            productBarcode={element.getAttribute('data-product-barcode') as string}
            squidexText={element.getAttribute('data-squidex-text') as string}/>, element!
    );
}
