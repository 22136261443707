/* tslint:disable:max-line-length */
import * as React from 'react';
import {renderSvg, sortDownIcon} from '../../../images/images';
import {Input} from './input';

interface Props<Value> {
    className?: string;
    inline?: boolean;
    disabled?: boolean;
    required?: boolean;
    value?: Value | null;
    options: Value[];
    noItems?: React.ReactNode;
    getLabel?: (value: Value) => string;
    onValueSelected?: (value: Value) => void;
}

interface State {
}

export class DropdownInput<Value> extends React.Component<Props<Value>, State> {

    public constructor(props: Props<Value>) {
        super(props);
    }

    public render() {
        if (this.props.disabled) {
            return (
                <div className={`dropdown-input dropdown-input--disabled ${this.props.className}`} style={{display: this.props.inline ? 'inline-block' : ''}}>
                    <span className="label dropdown-input__label">
                        <input type="text"
                               readOnly
                               disabled
                               required={this.props.required}
                               defaultValue={this.props.value !== undefined && this.props.value !== null ? this.getLabel(this.props.value) : '-'}
                               />
                    </span>
                </div>
            );
        }
        return (
            <div className={`dropdown-input ${this.props.className}`}
                 style={{display: this.props.inline ? 'inline-block' : ''}}>
                <app-dropdown
                    content-class="dropdown__content--light form-row__label--padding dropdown-input__dropdown">
                        <span slot="trigger">
                            <Input
                                inputSuffix={renderSvg(sortDownIcon, {
                                    className: 'icon icon--text-size dropdown__arrow input-wrap__suffix',
                                })}
                                style={{cursor: 'default'}}
                                disabled={this.props.disabled}
                                required={this.props.required}
                                onFocus={evt => evt.target.blur()}
                                onChange={() => { /* void */ }}
                                value={this.props.value !== undefined && this.props.value !== null ? this.getLabel(this.props.value) : ''}
                                placeholder={'Selecteer'}
                                />
                        </span>
                    {
                        this.props.options.length
                            ? this.props.options.map((x, i) => (
                                <a href="#"
                                   key={i}
                                   className={`dropdown-input__option ${this.props.value === x ? 'dropdown-input__option--active' : ''}`}
                                   onClick={evt => {
                                       evt.preventDefault();
                                       (evt.target as HTMLAnchorElement)
                                           .closest<any>('app-dropdown')?.closeDropdown(true);
                                       if (this.props.onValueSelected) {
                                           this.props.onValueSelected(x);
                                       }
                                   }}>{this.getLabel(x)}</a>
                            ))
                            : this.props.noItems || <span>Geen items gevonden</span>
                    }
                </app-dropdown>
            </div>
        );
    }

    private getLabel(value: Value) {
        if (this.props.getLabel) {
            return this.props.getLabel(value);
        }
        return String(value);
    }
}
