/* tslint:disable:no-var-requires max-line-length */
import {observer} from 'mobx-react';
import {searchOrganizations} from '../api/de-klok';
import {Organization, SearchOrganizationsResult, SortableSearchCriteria} from '../api/interfaces';
import {state} from '../state';
import * as React from 'react';
import {computed, observable, reaction} from 'mobx';
import { debounce } from 'lodash';

export interface Props {
    showCity?: boolean;
    searchLabel?: string;
    noResultsText?: string;
    generateLink?: (o: Organization) => string;
    onResults?: (res: SearchOrganizationsResult) => void;
    onClick?: (o: Organization, evt: React.MouseEvent) => void;
}

@observer
export class SearchOrganization extends React.Component<Props, { }> {

    @observable
    private query = '';

    @observable
    private result: SearchOrganizationsResult | null = null;

    @observable
    private loading = false;

    @computed
    private get isValidQuery() {
        return this.query.length >= 3;
    }

    private search = debounce(async (query: string) => {
        this.loading = true;
        const searchResults = await searchOrganizations({
            sort: 'asc',
            searchPhrase: query,
            organizationId: state.current_organization!,
            pageNumber: 1,
            pageSize: 9000,
        });

        if (query === this.query) {
            if (this.props.onResults) {
                this.props.onResults(searchResults);
            }
            this.result = searchResults;
            this.loading = false;
        }
    }, 500);

    public constructor(props: Props) {
        super(props);

        reaction(
            () => this.query,
            async query => {
                if (!this.isValidQuery) {
                    return;
                }
                await this.search(query);
            },
        );
    }

    public render() {
        const buttonGroupOrganizationClasses = ['button-group', 'button-group--compact', 'representative-user__buttons'].join(' ');

        return (
            <div className="representative-user">
                <div className={`row row--dark ${this.isValidQuery ? 'row--no-padding-top' : 'row--no-padding'}`}>
                    <div className="row__content">
                        <div className="search">
                            <div className={`input-wrap input-wrap--without-padding`}>
                                <input className="input-wrap__input search__input"
                                       type="text"
                                       autoComplete="off"
                                       autoFocus
                                       name="q"
                                       value={this.query}
                                       onChange={evt => this.query = evt.target.value}
                                       placeholder={this.props.searchLabel || 'Organisatie zoeken...'}/>
                                {this.query ?
                                    <span className="search__clear-input"
                                          onClick={() => this.query = ''}/> : ''}
                            </div>
                        </div>
                    </div>
                </div>

                {this.isValidQuery ? (
                    this.loading ? (
                        <div className="row row--no-padding row--dark">
                            <div className="row__loading"/>
                        </div>
                    ) : (
                        this.result && this.result.items.length > 0 ? (
                            <div className={buttonGroupOrganizationClasses}>
                                {this.result.items.sort((a, b) => a.name.localeCompare(b.name)).map(organization => (
                                    <a href={this.props.generateLink ? this.props.generateLink(organization) : `#${organization.id}`}
                                       key={organization.id}
                                       onClick={(evt) => {
                                           if (this.props.onClick) {
                                               this.props.onClick(organization, evt);
                                           }
                                       }}
                                       className="button-group__button button button--light button--float button--primary-font">
                                        {this.props.showCity ? [
                                            <strong>{organization.name}&nbsp;</strong>,
                                            organization.addresses && organization.addresses.length > 0 &&
                                            <span className="button__subtext">{organization.addresses[0].city}</span>,
                                        ] : organization.name}
                                    </a>))
                                }
                            </div>
                        ) : (
                            <div>{this.props.noResultsText || 'Er zijn geen organisaties gevonden.'}</div>
                        )
                    )
                ) : (
                    <div className="notice">Vul minimaal 3 tekens in om te zoeken</div>
                )}
            </div>
        );
    }
}
