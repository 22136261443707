/* tslint:disable:max-line-length */
import {observer} from 'mobx-react';
import * as React from 'react';
import {MessageBoxMessage} from '../../models/interfaces';
import {state} from '../../state';

interface Props {
    message: MessageBoxMessage;
}

@observer
export class MessageBoxMessageComponent extends React.Component<Props, {}> {

    public render() {
        return (
            <div className="message-box-message">
                <h3 className="message-box-message__title">{this.props.message.title}</h3>
                <div className="message-box-message__content"
                     dangerouslySetInnerHTML={{__html: this.props.message.content}}/>
                {this.props.message.attachments && this.props.message.attachments.length > 0 && (
                    <div className="message-box-message__attachments">
                        <h4 className="message-box-message__attachments__title">Bijlagen</h4>
                        <div className="message-box-message__attachments__list">
                            {this.props.message.attachments.map((attachment, i) => (
                                <a key={i} className="message-box-message__attachments__list__item"
                                   title={`Bijlage '${attachment.fileName}' downloaden`}
                                   href={`${state.urls.api}/de_klok/message-box/attachment/${this.props.message.id}/${attachment.id}`}>
                                    <span className="message-box-message__attachments__list__item__name">
                                        {attachment.fileName}
                                    </span>
                                </a>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    }

}
