/* tslint:disable:no-var-requires max-line-length */
import {observer} from 'mobx-react';
import 'moment';
import {ContactForm} from '../api/interfaces';
import {computed} from 'mobx';
import {load, ReCaptchaInstance} from 'recaptcha-v3';
import {state} from '../state';
import {sendContactForm} from '../api/common';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface State {
    companyName: string;
    name: string;
    email: string;
    phone: string;
    message: string;
    token: string;
    errors?: ModelStateErrors;
}

interface ModelStateErrors {
    [field: string]: string[];
}

interface Props {
    siteKey: string;
}

@observer
export class Contact extends React.Component<Props, State> {

    private formEl: HTMLFormElement | null = null;
    private recaptcha: ReCaptchaInstance | undefined;

    public constructor(props: Props) {
        super(props);
        this.state = {
            companyName: '',
            name: '',
            email: '',
            phone: '',
            message: '',
            token: '',
        };
    }

    public async componentDidMount() {
        this.recaptcha = await load(this.props.siteKey);
    }

    public render() {
        return <form ref={form => this.formEl = form} method="post" onSubmit={(evt) => this.sendContactForm(evt)}>
            <div className="row row--dark row--form">
                <div className="content-wrap content-wrap--small">
                    <h2 className="row__title row__title--no-padding-left row__title--padding-bottom-small">
                        Gegevens
                    </h2>
                    <div className="form-row form-row--label-on-top">
                        <div className="linear-layout linear-layout--horizontal linear-layout--large-gutters">
                            <div className="linear-layout__item linear-layout__item--grow">
                                <label className="form-row__label label--required">Naam</label>
                                <div className="form-row__input">
                                    <input type="text"
                                           value={this.state.name}
                                           onChange={evt => {
                                               this.setState({name: evt.target.value});
                                           }}
                                           required
                                           className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                                </div>
                            </div>
                            <div className="linear-layout__item linear-layout__item--grow">
                                <label htmlFor="company_name" className="form-row__label">Bedrijfsnaam</label>
                                <div className="form-row__input">
                                    <input type="text"
                                           id="company_name"
                                           value={this.state.companyName}
                                           onChange={evt => {
                                               this.setState({companyName: evt.target.value});
                                           }}
                                           name="contact[company_name]"
                                           className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-row form-row--label-on-top">
                        <div className="linear-layout linear-layout--horizontal linear-layout--large-gutters">
                            <div className="linear-layout__item linear-layout__item--grow">
                                <label htmlFor="email" className="label--required form-row__label">E-mailadres</label>
                                <div className="form-row__input">
                                    <input type="email"
                                           id="email"
                                           required
                                           pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                           title="Geef een correct emailadres op"
                                           value={this.state.email}
                                           onChange={evt => {
                                               this.setState({email: evt.target.value});
                                           }}
                                           name="contact[email]"
                                           className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                                </div>
                            </div>
                            <div className="linear-layout__item linear-layout__item--grow">
                                <label htmlFor="phone" className="label--required form-row__label">Telefoonnummer</label>
                                <div className="form-row__input">
                                    <input type="tel"
                                           required
                                           pattern="^\+?[0-9]{10,}$"
                                           id="phone"
                                           title="Voer een correct telefoonnummer in"
                                           value={this.state.phone}
                                           onChange={evt => {
                                               this.setState({phone: evt.target.value});
                                           }}
                                           name="contact[phone]"
                                           className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row row--form">
                <div className="content-wrap content-wrap--small">
                    <div className="form-row form-row--label-on-top">
                        <div className="linear-layout linear-layout--vertical">
                            <div className="linear-layout__item linear-layout__item--grow">
                                <label className="label--required form-row__label" htmlFor="comment">Bericht</label>
                                <div className="form-row__input">
                                    <textarea rows={5}
                                              required
                                              id="comment"
                                              value={this.state.message}
                                              onChange={evt => {
                                                  this.setState({message: evt.target.value});
                                              }}
                                              className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                                </div>
                            </div>
                            <div className="linear-layout__item">
                                <button className="button button--primary button--float" type="submit">Versturen</button>
                            </div>
                            {this.state.errors ?
                                <div>
                                    De volgende fouten zijn opgetreden:
                                    <div className="error">
                                        {this.renderErrors()}
                                    </div>
                                </div>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </form>;
    }

    private isFormValid() {
        let valid = false;

        if (this.formEl) {
            valid = this.formEl.checkValidity();
        }

        return valid;
    }

    private renderErrors() {
        if (!this.state.errors) {
            return null;
        }

        return <div>
            {
                Object.entries(this.state.errors)
                    .map(([field, errors]) => <span key={field}>{field}: {errors.join(', ')}<br/></span>)
            }
        </div>;
    }

    @computed
    private get contactForm(): ContactForm {
        return {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            companyName: this.state.companyName,
            message: this.state.message,
        };
    }

    private async sendContactForm(evt: React.FormEvent) {
        evt.preventDefault();

        const token = await this.recaptcha!.execute('contactRequest');
        try {
            await sendContactForm(this.contactForm, token);
            window.location.href = state.urls.contactThanks;
        } catch (error) {
            this.setState({
                errors: error.response.data,
            });
        }
    }
}

export function renderContact(element: HTMLElement) {
    return ReactDOM.render(
        <Contact
            siteKey={state.recaptcha!.siteKey} />, element);
}
