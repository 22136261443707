import {
    GetOrderDetailQuery, OrderDetail,
} from '../api/interfaces';
import {action, observable} from 'mobx';
import { getOrderDetail } from '../api/de-klok';

export default class OrderDetailModel {

    @observable
    public loading = false;

    @observable
    public order: OrderDetail | null = null;

    @action
    public async fetchOrderDetail(query: GetOrderDetailQuery): Promise<void> {
        try {
            this.loading = true;
            this.order = await getOrderDetail(query);
        } finally {
            this.loading = false;
        }
    }
}
