import {AppLocation} from './interfaces';
import {computed, observable} from 'mobx';

export class LocationModel implements AppLocation {

  @observable
  public hash = location.hash;

  @computed
  public get hashPath() {
    return this.hash.substr(1);
  }

  public constructor() {
    window.addEventListener('hashchange', () => this.hash = window.location.hash);
  }
}
