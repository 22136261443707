/* tslint:disable:max-line-length */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {state} from '../state';
import {Urls} from '../models/interfaces';
import {GetOrderDetailQuery, OrderDetail as OrderDetails, OrderDetailProduct} from '../api/interfaces';
import {MediaQuery} from '../media-queries';
import {formatDate, formatEuroPrice} from '../utils/format';
import {getSapOrderStatusLabel} from '../enums/sapInvoiceOrderStatus';
import {getSapOrderTypeLabel} from '../enums/sapOrderType';
import {observer} from 'mobx-react';
import {observable} from 'mobx';


interface Props {
    mediaQueries: Map<MediaQuery, boolean>;
    urls: Urls;
}

interface State {
    loading: boolean;
}

// tslint:disable-next-line:no-var-requires
const chevronLeft = require('../../images/icons8-chevron-left-50.svg');
// tslint:disable-next-line:no-var-requires
const printIcon = require('../../images/icons8-print-50.svg');

@observer
export class OrderDetail extends React.Component<Props, State> {

    @observable
    private order: OrderDetails | null = null;

    public constructor(props: Props) {
        super(props);
        this.state = {
            loading: state.order!.loading,
        };
    }

    public componentDidMount(): void {
        if (state.order) {
            this.fetchOrderDetails();
        }
    }

    public render() {
        if (this.state.loading) {
            return this.renderPlaceholders();
        }

        return state.order && this.order && (
            <div className="order-detail">
                {this.renderBreadcrumb(this.order.orderId)}
                <div className="content-wrap order-detail__details">
                    <div className="order-detail__header">
                        <h1 className="order-detail__header__title">Bestelling #{this.order.orderId}</h1>
                        <button className="button button--text-primary orderlist__header__buttons__button"
                                onClick={() => window.print()}>
                            <span className="icon button__icon icon--small"
                                  dangerouslySetInnerHTML={{__html: printIcon}}/>
                            Print bestelling
                        </button>
                    </div>
                    {this.renderOrderDetails()}
                    {this.renderFooter()}
                </div>
                <div className="row row--dark order-detail__products">
                    <div className="content-wrap">
                        <h2 className="checkout__products__title">Producten</h2>
                        {this.renderOrderProducts()}
                        {this.renderOrderSummary()}
                        {this.renderFooter()}
                    </div>
                </div>
            </div>
        );
    }

    private renderBreadcrumb(orderId: string) {
        return <nav className="breadcrumb content-wrap" role="navigation" aria-label="breadcrumbs">
            <a href={state.urls.home} title="Terug naar home">Home</a>
            <span className="divider" aria-hidden="true">/</span>
            <a href={state.urls.account}>Account</a>
            <span className="divider" aria-hidden="true">/</span>
            <a href={state.urls.orders}>Bestellingen</a>
            <span className="divider" aria-hidden="true">/</span>
            <span>{orderId}</span>
        </nav>;
    }

    private renderOrderDetails() {
        return <div className="linear-layout linear-layout--grow-items linear-layout--wrap">
            {this.renderOrderDeliveryAddress()}
            <div className="order-detail__details__information">
                <div className="order-detail__details__title">
                    Bestellingdetails
                </div>
                <div className="order-detail__details__information__columns">
                    {this.renderOrderInfo()}
                    {this.renderOrderStatus()}
                    {this.renderOrderPaymentMethod()}
                    {this.renderOrderExtras()}
                </div>
            </div>
        </div>;
    }

    private renderOrderInfo() {
        return this.order && <div className="order-detail__details__information__columns__column">
            <div className="checkout__credentials__references__title">Besteldatum</div>
            <span>{formatDate(this.order.creationDate, 'DD MMMM YYYY HH:mm')}</span>
            <div className="checkout__credentials__references__title">Bestelnummer</div>
            <span>{this.order.orderId}</span>
        </div>
            ;
    }

    private renderOrderStatus() {
        return this.order && (
            <div className="order-detail__details__information__columns__column">
                <div className="checkout__credentials__references__title">Orderstatus</div>
                <span>{getSapOrderStatusLabel(this.order.status)}</span>
                <div className="checkout__credentials__references__title">Ordertype</div>
                <span>{getSapOrderTypeLabel(this.order.type)}</span>
            </div>
        );
    }

    private renderOrderPaymentMethod() {
        return this.order && (
            <div className="order-detail__details__information__columns__column">
                <div className="checkout__credentials__references__title">Betaalmethode</div>
                <span>{this.order.paymentMethod ? this.order.paymentMethod : 'Geen betaalinformatie beschikbaar'}</span>
            </div>
        );
    }

    private renderOrderExtras() {
        return this.order && (
            <div className="order-detail__details__information__columns__column">
                <div className="checkout__credentials__references__title">Uw referentie</div>
                <span>{this.order.orderReferenceNumber ? this.order.orderReferenceNumber : '-'}</span>
                <div className="checkout__credentials__references__title">Opmerking(en)</div>
                <span>{this.order.orderComment ? this.order.orderComment : '-'}</span>
            </div>
        );
    }

    private renderOrderDeliveryAddress() {
        const address = this.order!.address;
        return address && <div className="order-detail__details__address">
            <div className="order-detail__details__title">
                Leveringsadres
            </div>
            <ul className="address__list">
                <li className="address__list--company">{address.companyLine1}</li>
                <li>{address.street} {address.houseNumber}</li>
                <li>{address.zipCode} {address.city?.toUpperCase()}</li>
                <li>{address.country}</li>
                <li>T: {address.telNumber}</li>
            </ul>
        </div>;
    }

    private renderOrderProducts() {
        return this.order &&
        (state.mediaQueries.get(MediaQuery.MediumUp) || state.mediaQueries.get(MediaQuery.Print)) ?
            <table className="table order-detail__products__table">
                <thead>
                <tr>
                    <th>Productnaam</th>
                    <th>Artikelnr</th>
                    <th>Aantal</th>
                    <th>Subtotaal</th>
                </tr>
                </thead>
                {this.order &&
                <tbody>
                {this.order.result.length > 0 ? this.order.result.map(
                    (item) => this.renderItem(item)) : this.renderNoProducts()}
                </tbody>}
            </table> : this.renderMobileOrderProducts();
    }

    private renderMobileOrderProducts() {
        return this.order && this.order.result.length > 0 ? (
            <div className="order-detail__products">{this.order.result.map((item) =>
                <div className="order-detail__products__product">
                    <div className="order-detail__products__product__info">
                            <span className="order-detail__products__product__info__item">
                                <strong>{item.name}</strong>
                            </span>
                        <span className="order-detail__products__product__info__item">{item.sku}</span>
                        <span className="order-detail__products__product__info__item">{item.quantity}x</span>
                    </div>
                    <span className="order-detail__products__product__price">{formatEuroPrice(item.price)}</span>
                </div>)}
            </div>
        ) : this.renderNoProducts();
    }

    private renderNoProducts() {
        return <div className="content-wrap">
            <div className="page-header">
                <div className="page-header__subtitle">Deze order bevat geen producten.</div>
            </div>
        </div>;
    }

    private renderItem(item: OrderDetailProduct) {
        return item && <tr key={item.sku}>
            <td>{item.name}</td>
            <td>{item.sku}</td>
            <td>{item.quantity} {item.unit}</td>
            <td>{formatEuroPrice(item.price)}</td>
        </tr>;
    }

    private renderPlaceholders() {
        return <div className="order-detail">
            {this.renderBreadcrumb('')}
            <div className="content-wrap">
                <div className="order-detail__header">
                    <h1 className="order-detail__header__title">Bestelling</h1>
                </div>
                <div className="order-detail--loading"/>
            </div>
        </div>;
    }

    private renderFooter() {
        return <div className="linear-layout checkout__button-group">
            <a href={state.urls.orders} className="button button--text-primary">
                <span className="icon" dangerouslySetInnerHTML={{__html: chevronLeft}}/>
                Terug naar mijn bestellingen
            </a>
        </div>;
    }

    private renderOrderSummary() {
        const orderDetails = state.order!.order;
        return orderDetails &&
            <div className="linear-layout linear-layout--justify-content-end
            checkout__products__description__price-summary">
                <div className="cart__price__summary">
                    <table className="cart__price__summary__table">
                        <tbody>
                        <tr>
                            <th>Subtotaal</th>
                            <td>{formatEuroPrice(orderDetails.subTotal!)}</td>
                        </tr>
                        <tr>
                            <th>BTW</th>
                            <td>{formatEuroPrice(orderDetails.vat!)}</td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <th>Totaal</th>
                            <td>{formatEuroPrice(orderDetails.grandTotal!)}</td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>;
    }


    private async fetchOrderDetails() {
        this.setState({loading: true});
        const urlParts = window.location.href.split('/');
        const orderNumber = urlParts[urlParts.length - 1];
        const getOrderDetailQuery: GetOrderDetailQuery = {
            orderId: orderNumber,
        };
        await state.order!.fetchOrderDetail(getOrderDetailQuery);
        this.setState({loading: false});
        if (state.order && state.order.order) {
            if (state.order.order.message !== '') {
                window.location.href = state.urls.orders;
                return;
            }
            this.order = state.order!.order;
        }
    }
}

export function renderOrderDetail(element: HTMLElement) {
    if (!state.order) {
        throw new Error(`Cannot render order list overview: 'state.order' not initialized.`);
    }
    return ReactDOM.render(<OrderDetail mediaQueries={state.mediaQueries} urls={state.urls}/>, element);
}
