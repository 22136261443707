function isNullOrUndefined(val: any): val is Exclude<any, null | undefined> {
  return val === null || val === undefined;
}

function guardAgainstNullOrUndefined<T>(val: T | null | undefined, error = `Value cannot be null or undefined.`): T {

  if (isNullOrUndefined(val)) {
    throw new Error(error);
  }
  return val;
}

export function $domLoad(fn: () => void) {
  if (typeof document === 'undefined' || document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn, false);
  }
}

export function $addEventListener<T extends string>(types: T[], elements: HTMLElement[],
                                                    handler: (type: T, e: Event) => void) {
  types.forEach(type => {
    const typeHandler = (evt: Event) => handler(type, evt);
    elements.forEach(element => {
      element.addEventListener(type, typeHandler);
    });
  });
}

export function $childNodes<T extends ChildNode>(element: HTMLElement, filter?: (value: ChildNode) => boolean): T[] {
  const arr = Array.from(element.childNodes);
  return (filter ? arr.filter(filter) : arr) as T[];
}

export function $childElements(element: HTMLElement): HTMLElement[] {
  return $childNodes(element, x => x instanceof HTMLElement);
}

export function $getParent<T extends HTMLElement>(element: Element, parent: string, includeSelf = false): T | null {
  let node = includeSelf ? element : element.parentNode;
  while (node !== null) {
    if (node instanceof HTMLElement && node.matches(parent)) {
      return node as T;
    }
    node = node.parentNode;
  }
  return null;
}

export function $hasParent(element: Element, parent: Element | string, includeSelf = false) {
  let node = includeSelf ? element : element.parentNode;
  while (node != null) {
    if (parent instanceof Element) {
      if (node === parent) {
        return true;
      }
    } else {
      if (node instanceof HTMLElement && node.matches(parent)) {
        return true;
      }
    }
    node = node.parentNode;
  }
  return false;
}

export function $$<T extends HTMLElement>(selector: string, parent: HTMLElement | null = null): T[] {
  return Array.from((parent || document.body).querySelectorAll<T>(selector));
}

export function $customEvent<T>(element: HTMLElement,
                                eventName: string,
                                callback: (data: T, evt: CustomEvent) => void) {
  element.addEventListener(eventName, evt => {
    if (!(evt instanceof CustomEvent)) {
      throw new Error(`The event '${eventName}' on element '${element}' did not trigger a CustomEvent`);
    }
    callback(evt.detail as T, evt);
  });
}

export function $attr(element: HTMLElement, attributeName: string): string {
  return guardAgainstNullOrUndefined(element.getAttribute(attributeName),
    `The attribute "${attributeName}" of element "${element}" is null or undefined.`);
}

export function $attrAsInt(element: HTMLElement, attributeName: string): number {
  return parseInt($attr(element, attributeName), 10);
}
