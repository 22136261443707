/* tslint:disable:no-var-requires max-line-length */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {observer} from 'mobx-react';
import {state} from '../state';
import {MediaQuery} from '../media-queries';
import {CartPriceSummary} from './cart-price-summary';
import 'moment';
import {CheckoutProductTable} from './checkout-product-table';
import {formatDate} from '../utils/format';
import {viewPrices} from '../security/representative-claims';
import {computed} from 'mobx';
import {LineItemModel} from '../models/lineItemModel';
import {VendorType} from '../enums/vendorType';
import {Vendor} from '../models/interfaces';

interface Props {
    mediaQueries: Map<MediaQuery, boolean>;
}

interface State {
    comment: string;
    customerReference: string;
    loading: boolean;
}

const collapseArrow = require('../../images/icons8-collapse-arrow-50.svg');

@observer
export class CartCheckout extends React.Component<Props, State> {

    private readonly initialComment: string;
    private readonly initialCustomerReference: string;
    private readonly initialGatewayCode: string | null;

    constructor(props: Props) {
        super(props);
        this.initialComment = state.cart.comment;
        this.initialCustomerReference = state.cart.customer_reference;
        this.initialGatewayCode = state.cart.active_gateway_code;
        this.state = {
            comment: state.cart.comment,
            customerReference: state.cart.customer_reference,
            loading: state.cart.loading,
        };
    }

    public componentDidMount() {
        if (state.cart.item_count === 0) {
            location.href = state.urls.cart;
        }
        state.cart.track_changes = true;
    }

    public componentWillUnmount(): void {
        state.cart.track_changes = false;
    }

    @computed
    public get showPrices() {
        return state.customer!.has_access(viewPrices) && !state.cart.hasSapErrorOccurred;
    }

    public render() {
        return (
            <form action={state.urls.createOrder} method="post">
                <div className="content-wrap checkout__credentials page-header">
                    {this.renderVerificationHeader()}
                    {state.cart.hasSapErrorOccurred ?
                        <div className="notice notice--error">
                            {state.cart.getSapErrorMessage}
                        </div>
                        : null
                    }
                    {!state.CurrentOrganization!.can_order ?
                        <div className="notice notice--error">
                            Het is niet mogelijk om bestellingen te plaatsen
                            voor <strong>{state.CurrentOrganization!.name}</strong>.
                        </div>
                        : null
                    }
                    {state.customer!.organizations.length > 1 ?
                        <div className="notice notice--warn">
                            Let op! u bestelt op het moment voor <strong>{state.CurrentOrganization!.name}</strong>.
                            <a href={state.urls.account}> Klik hier</a> om een andere organisatie te selecteren.
                        </div>
                        : null
                    }
                    {this.renderCredentialsSection()}
                    {this.renderReturnAndOrderButtons()}
                </div>
                {!state.cart.loading ? this.renderByVendor() :
                    <div className="row row--dark checkout__products">
                        <div className="content-wrap">
                            <div className="--loading"/>
                        </div>
                    </div>}
            </form>
        );
    }

    private renderByVendor() {
        const lines = [];
        state.cart.groupedItemsByVendor.forEach((items: LineItemModel[], index) => (
            lines.push(<div className={`row ${index % 2 === 0 ? 'row--dark' : ''} checkout__products`}>
                <div className="content-wrap">
                    {this.renderVendorLines(items, index)}
                </div>
            </div>)
        ));
        lines.push(<div className="row row--dark checkout__summary">
            <div className="content-wrap">
                <div
                    className="linear-layout linear-layout--justify-content-end checkout__products__description__price-summary">
                    {!state.cart.loading && state.cart.order_data && this.showPrices
                        ? <CartPriceSummary order_data={state.cart.order_data}/>
                        : ''
                    }
                </div>
                {this.renderReturnAndOrderButtons()}
            </div>
        </div>);
        return lines;
    }

    private renderVendorLines(items: LineItemModel[], index: number) {
        const vendorLines: JSX.Element[] = [];
        const vendor = items.map(v => v.product!.vendor).pop();
        if (vendor) {
            vendorLines.push(this.renderProposalHeader(index + 1));
            if (vendor.vendor_type === VendorType.Light) {
                vendorLines.push(
                    this.renderMarketPlaceLightHeader(vendor, items.length),
                    this.renderProductSectionByVendor(items));
            } else {
                vendorLines.push(
                    this.renderProductSection(vendor));
            }
            return vendorLines;
        }
    }

    private renderVerificationHeader() {
        return <h1 className="checkout__credentials__title
            page-header__title page-header__title--no-padding-top">Bestelling Controleren</h1>;
    }


    private renderProposalHeader(index: number) {
        return <h2 className="checkout__products__title">Leveringsvoorstel #{index}</h2>;
    }

    private renderMarketPlaceLightHeader(vendor: Vendor, itemCount: number) {
        return <div className="checkout__products__column__title"><p>{itemCount > 0 ? `${itemCount} ${itemCount > 1
            ? 'artikelen worden' : 'artikel wordt'} geleverd door` : null} <a href={vendor.handle}>{vendor.name}</a></p>
        </div>;
    }

    private renderCredentialsSection() {
        return (
            <div className="linear-layout linear-layout--grow-items linear-layout--wrap">
                {this.renderAddress()}
                {this.renderComments()}
                {this.renderPayment()}
            </div>
        );
    }

    private renderComments() {
        return (
            <div className="checkout__credentials__column checkout__credentials__references">
                <div className="checkout__credentials__references__title ">
                    {'Uw referentie'.toUpperCase()}
                </div>
                <textarea rows={3}
                          className="checkout__credentials__references__input"
                          maxLength={2048}
                          value={this.state.customerReference}
                          disabled={!state.cart.isCartValid}
                          onChange={
                              evt => this.setState({customerReference: evt.target.value})}/>
                <div className="checkout__credentials__references__title">
                    {'Opmerking(en)'.toUpperCase()}
                </div>
                <textarea rows={5}
                          className="checkout__credentials__references__input"
                          maxLength={2048}
                          disabled={!state.cart.isCartValid}
                          value={this.state.comment}
                          onChange={evt => this.setState({comment: evt.target.value})}
                />
            </div>
        );
    }

    private renderAddress() {
        const address = state.cart.delivery_address;
        return <div className="checkout__credentials__column">
            <div className="checkout__credentials__column__title checkout__credentials__column__title--active">
                {'Leveringsadres'.toUpperCase()}
            </div>
            {!state.cart.loading ? (
                    (address ? (
                            <ul className="address__list">
                                <li className="address__list--company">{address.name}</li>
                                <li>{address.line1}</li>
                                <li>{address.zip} {address.city}</li>
                                <li>{address.countryName}</li>
                                <li>T: {address.phone}</li>
                            </ul>
                        ) : <div className="notice notice--warn">Geen adres bekend</div>
                    )
                ) :
                <div>
                    <div className="checkout__credentials__column --loading"/>
                    <div className="checkout__credentials__column --loading"/>
                </div>}
        </div>;
    }

    private renderProductSectionByVendor(items: LineItemModel[]) {
        return (
            <div>
                <div
                    className="linear-layout linear-layout--grow-items linear-layout--wrap checkout__products__container">
                    {state.cart.groupedItemsByVendor && state.cart.groupedItemsByVendor.length > 0
                        ?
                        <div>
                            {this.groupedByVendorItemCheckoutTable(items)}
                        </div>
                        : null
                    }
                </div>
            </div>

        );
    }

    private renderProductSection(vendor: Vendor) {
        const orders = state.cart.first_delivery.filter(value => value.product!.vendor!.id === vendor.id);
        const backorders = state.cart.backorder_delivery.filter(value => value.product!.vendor!.id === vendor.id);
        return (
            <div>
                <div
                    className="linear-layout linear-layout--grow-items linear-layout--wrap checkout__products__container">
                    {
                        orders && orders.length > 0
                            ?
                            <div className="checkout__products__column">
                                <div className="checkout__products__column__title">
                                    {this.getFirstDeliveryTitle(orders)}
                                </div>
                                <CheckoutProductTable items={orders}
                                                      mediaQueries={this.props.mediaQueries}/>
                            </div>
                            : null
                    }
                    {backorders && backorders.length > 0
                        ?
                        <div>
                            <div className="checkout__products__column__title">
                                <p>{this.getBackorderDeliveryTitle(backorders)}</p>
                            </div>
                            <CheckoutProductTable items={backorders}
                                                  mediaQueries={this.props.mediaQueries}/>
                            <div className="checkout__products__description">Artikelen die niet op voorraad staan worden
                                voor u besteld en in backorder geplaatst.
                                Op basis van beschikbaarheid en uw persoonlijke levermoment worden de artikelen aan u
                                geleverd.
                            </div>
                        </div>
                        : null
                    }
                </div>
            </div>

        );
    }

    private groupedByVendorItemCheckoutTable(items: LineItemModel[]) {
        return <CheckoutProductTable items={items}
                                     mediaQueries={this.props.mediaQueries}/>;
    }

    private getFirstDeliveryTitle(items: LineItemModel[]) {
        const firstItems = items;
        const orderData = state.cart.order_data;
        const vendor = items.map(value => value.product!.vendor).pop();
        return vendor && firstItems && firstItems.length > 0 && orderData && !state.cart.hasSapErrorOccurred ?
            <p>{firstItems.length > 0 ? `${firstItems.length} ${firstItems.length > 1
                ? 'artikelen worden' : 'artikel wordt'} geleverd op ${formatDate(orderData.nextDeliveryInfos[0].date)} door ` : null}
                {vendor.handle ? <a href={vendor.handle}>{vendor.name}</a> : <a>{vendor.name}</a>}
            </p> : null;
    }

    private getBackorderDeliveryTitle(items: LineItemModel[]) {
        const backorderItems = items;
        return backorderItems && backorderItems.length > 0 ?
            `${backorderItems.length} ${backorderItems.length > 1
                ? 'artikelen worden' : 'artikel wordt'} voor u besteld en in backorder geplaatst` : null;
    }

    private renderPayment() {
        const activeCode = state.cart.active_gateway_code;
        return (
            <div className="checkout__credentials__column">
                <div className="checkout__credentials__column__title checkout__credentials__column__title--active">
                    {'Betaalwijze'.toUpperCase()}
                </div>
                {!state.cart.loading ?
                    <div
                        className="checkout__credentials__column checkout__credentials__payment linear-layout linear-layout--vertical">
                        {
                            state.cart.paymentMethods.map((pm, i) => (
                                <label key={i} className={`checkout__payment__label ${activeCode === pm.code
                                    ? 'checkout__payment__label--active' : ''}`}>
                                    <input type="radio" name="radio"
                                           checked={activeCode !== null && activeCode === pm.code}
                                           value={pm.code}
                                           disabled={!state.cart.isCartValid}
                                           onChange={() => [state.cart.onPaymentChange(pm)]}/>
                                    {pm.description ? pm.description.toUpperCase() : ''}
                                </label>
                            ))
                        }
                    </div> :
                    <div>
                        <div className="checkout__credentials__column --loading"/>
                        <div className="checkout__credentials__column --loading"/>
                    </div>}
            </div>
        );
    }

    private renderReturnAndOrderButtons() {
        return (
            <div className="row--bottom-block linear-layout checkout__button-group">
                <a href={state.urls.cart} className="button button--text-primary">
                    <span className="icon cart__icon cart__icon--back"
                          dangerouslySetInnerHTML={{__html: collapseArrow}}>
                    </span>
                    {`Terug ${state.mediaQueries.get(MediaQuery.MediumUp) ? 'naar de winkelwagen' : ''}`}
                </a>
                <button type="button"
                        onMouseUp={() => this.createOrder()}
                        disabled={!state.cart.isCartValid}
                        className={`linear-layout__item linear-layout__item--end button button--primary button--float`}>
                    Bestelling versturen
                </button>
            </div>
        );
    }

    private async createOrder() {
        const {
            comment,
            customerReference,
        } = this.state;

        if (this.initialComment !== comment || this.initialCustomerReference !== customerReference
            || this.initialGatewayCode !== state.cart.active_gateway_code) {
            await state.cart.update_extras({
                comment,
                customerReference,
                gatewayCode: state.cart.active_gateway_code,
            });
        }
        await state.cart.place_order();
    }
}

export function renderCartCheckout(element: HTMLElement) {
    if (!state.cart) {
        throw new Error(`Cannot render cart checkout: 'state.cart' not initialized.`);
    }
    return ReactDOM.render(
        <CartCheckout
            mediaQueries={state.mediaQueries}
        />, element);
}
