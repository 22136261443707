/* tslint:disable:max-line-length */
import {observer} from 'mobx-react';
import * as React from 'react';
import {Npv, NpvOrganization} from '../../../models/interfaces';
import {FormRow, FormRowColumn} from '../../common/form-row';
import {Input} from '../../common/input';

interface Props {
    npv: Npv;
    onSubmit: (formData: NpvOrganization) => Promise<void>;
    organization?: NpvOrganization;
    submitText?: string;
    extraButtons?: Array<{text: string; onClick: () => void}>;
}

interface State {
    loading: boolean;
    saving: boolean;
    organizationFormData: Partial<NpvOrganization>;
    addressFormData: Partial<NpvOrganization['address']>;
}

const REQUIRED_ORGANIZATION_FIELDS: Array<keyof NpvOrganization> = [
    'name',
    'accountName',
    'customerGroup',
    'volumeGroup',
    'mainBeerBrand',
    'storeLocation',
    'addWholesalesOm2',
];

const REQUIRED_ADDRESS_FIELDS: Array<keyof NpvOrganization['address']> = [
    'companyLine1',
    'zipCode',
    'city',
];

@observer
export class NpvOrganizationForm extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = {
            loading: false,
            saving: false,
            organizationFormData: props.organization || {},
            addressFormData: props.organization?.address || {},
        };
    }

    public formIsValid() {
        return !this.state.saving &&
            REQUIRED_ORGANIZATION_FIELDS.every(x => !!this.state.organizationFormData[x]) &&
            REQUIRED_ADDRESS_FIELDS.every(x => !!this.state.addressFormData[x]);
    }

    public render() {
        return <form className="npv" method="post" action="#" onSubmit={evt => {
            evt.preventDefault();
            this.onSubmit();
        }}>
            <div className="row row--dark">
                <div className="content-wrap">
                    <h2 className="h h--primary">Klantgegevens</h2>

                    <FormRow columns={[
                        <FormRowColumn
                            required
                            label="Bedrijfsnaam"
                            labelAttributes={{htmlFor: 'npvOrganizationName'}}
                        >
                            <Input
                                id="npvOrganizationName"
                                required
                                title="Bedrijfsnaam"
                                disabled={this.state.saving}
                                value={this.state.organizationFormData.name}
                                onChange={evt => {
                                    this.setState({
                                        organizationFormData: {
                                            ...this.state.organizationFormData,
                                            name: (evt.target as HTMLInputElement).value,
                                        },
                                    });
                                }}
                                name="name"
                            />
                        </FormRowColumn>,
                        <FormRowColumn
                            required
                            label="Klantnaam"
                            labelAttributes={{htmlFor: 'npvOrganizationAccountName'}}
                        >
                            <Input
                                id="npvOrganizationAccountName"
                                required
                                disabled={this.state.saving}
                                title="Klantnaam"
                                value={this.state.organizationFormData.accountName}
                                onChange={evt => {
                                    this.setState({
                                        organizationFormData: {
                                            ...this.state.organizationFormData,
                                            accountName: evt.target.value,
                                        },
                                    });
                                }}
                                name="accountName"
                            />
                        </FormRowColumn>,
                    ]}/>

                    <FormRow columns={[
                        <FormRowColumn
                            required
                            label="Customer group"
                            labelAttributes={{htmlFor: 'npv-organization-customerGroup'}}
                        >
                            <Input
                                id="npv-organization-customerGroup"
                                required
                                disabled={this.state.saving}
                                title="Customer group"
                                value={this.state.organizationFormData.customerGroup}
                                onChange={evt => {
                                    this.setState({
                                        organizationFormData: {
                                            ...this.state.organizationFormData,
                                            customerGroup: (evt.target as HTMLInputElement).value,
                                        },
                                    });
                                }}
                                name="customerGroup"
                            />
                        </FormRowColumn>,
                        <FormRowColumn
                            required
                            label="Volume group"
                            labelAttributes={{htmlFor: 'npv-organization-volumeGroup'}}
                        >
                            <Input
                                id="npv-organization-volumeGroup"
                                required
                                disabled={this.state.saving}
                                title="volumeGroup"
                                value={this.state.organizationFormData.volumeGroup}
                                onChange={evt => {
                                    this.setState({
                                        organizationFormData: {
                                            ...this.state.organizationFormData,
                                            volumeGroup: (evt.target as HTMLInputElement).value,
                                        },
                                    });
                                }}
                                name="volumeGroup"
                            />
                        </FormRowColumn>,
                    ]}/>

                    <FormRow columns={[
                        <FormRowColumn
                            required
                            label="Hoofd biermerk"
                            labelAttributes={{htmlFor: 'npv-organization-mainBeerBrand'}}
                        >
                            <Input
                                id="npv-organization-mainBeerBrand"
                                required
                                disabled={this.state.saving}
                                title="Hoofd biermerk"
                                value={this.state.organizationFormData.mainBeerBrand}
                                onChange={evt => {
                                    this.setState({
                                        organizationFormData: {
                                            ...this.state.organizationFormData,
                                            mainBeerBrand: (evt.target as HTMLInputElement).value,
                                        },
                                    });
                                }}
                                name="mainBeerBrand"
                            />
                        </FormRowColumn>,
                        <FormRowColumn
                            required
                            label="Drankenhandel"
                            labelAttributes={{htmlFor: 'npv-organization-storeLocation'}}
                        >
                            <Input
                                id="npv-organization-storeLocation"
                                required
                                disabled={this.state.saving}
                                title="storeLocation"
                                value={this.state.organizationFormData.storeLocation}
                                onChange={evt => {
                                    this.setState({
                                        organizationFormData: {
                                            ...this.state.organizationFormData,
                                            storeLocation: (evt.target as HTMLInputElement).value,
                                        },
                                    });
                                }}
                                name="storeLocation"
                            />
                        </FormRowColumn>,
                    ]}/>

                    <FormRow columns={[
                        <FormRowColumn
                            required
                            label="Add. Wholesaler OM 2"
                            labelAttributes={{htmlFor: 'npv-organization-addWholesalesOm2'}}
                        >
                            <Input
                                id="npv-organization-addWholesalesOm2"
                                required
                                disabled={this.state.saving}
                                title="Add. Wholesaler OM 2"
                                value={this.state.organizationFormData.addWholesalesOm2}
                                onChange={evt => {
                                    this.setState({
                                        organizationFormData: {
                                            ...this.state.organizationFormData,
                                            addWholesalesOm2: (evt.target as HTMLInputElement).value,
                                        },
                                    });
                                }}
                                name="addWholesalesOm2"
                            />
                        </FormRowColumn>,
                    ]}/>

                </div>
            </div>
            <div className="row">
                <div className="content-wrap">

                    <h3>Vestigingsadres</h3>

                    <FormRow columns={[
                        <FormRowColumn
                            required
                            label="Straat + huisnummer"
                            labelAttributes={{htmlFor: 'npv-organization-address-companyLine1'}}
                        >
                            <Input
                                id="npv-organization-address-companyLine1"
                                disabled={this.state.saving}
                                required
                                title="Straat + huisnummer"
                                value={this.state.addressFormData.companyLine1}
                                onChange={evt => {
                                    this.setState({
                                        addressFormData: {
                                            ...this.state.addressFormData,
                                            companyLine1: (evt.target as HTMLInputElement).value,
                                        },
                                    });
                                }}
                                name="companyLine1"
                            />
                        </FormRowColumn>,
                    ]}/>

                    <FormRow columns={[
                        <FormRowColumn
                            required
                            label="Postcode"
                            labelAttributes={{htmlFor: 'npv-organization-address-zipCode'}}
                        >
                            <Input
                                id="npv-organization-address-zipCode"
                                disabled={this.state.saving}
                                required
                                title="Postcode"
                                value={this.state.addressFormData.zipCode}
                                onChange={evt => {
                                    this.setState({
                                        addressFormData: {
                                            ...this.state.addressFormData,
                                            zipCode: (evt.target as HTMLInputElement).value,
                                        },
                                    });
                                }}
                                name="zipCode"
                            />
                        </FormRowColumn>,
                        <FormRowColumn
                            required
                            label="Stad"
                            labelAttributes={{htmlFor: 'npv-organization-address-city'}}
                        >
                            <Input
                                id="npv-organization-address-city"
                                disabled={this.state.saving}
                                required
                                title="Stad"
                                value={this.state.addressFormData.city}
                                onChange={evt => {
                                    this.setState({
                                        addressFormData: {
                                            ...this.state.addressFormData,
                                            city: (evt.target as HTMLInputElement).value,
                                        },
                                    });
                                }}
                                name="city"
                            />
                        </FormRowColumn>,
                    ]}/>

                    <div className="button-group --mt">
                        <button type="submit"
                                className="button-group__button button button--primary"
                                disabled={!this.formIsValid()}
                            >
                            { this.props.submitText || 'Opslaan' }
                        </button>

                        {(this.props.extraButtons || []).map((btn, i) => (
                            <button key={i}
                                    type="button"
                                    onClick={btn.onClick}
                                    className="button-group__button button">
                                {btn.text}
                            </button>
                        ))}
                    </div>

                </div>
            </div>

        </form>;
    }

    protected async onSubmit() {
        try {
            this.setState({
                saving: true,
            });
            await this.props.onSubmit({
                ...(this.state.organizationFormData as Required<NpvOrganization>),
                address: (this.state.addressFormData as Required<NpvOrganization['address']>),
            });
        } finally {
            this.setState({
                saving: false,
            });
        }
    }
}
