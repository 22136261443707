import {observable} from 'mobx';
import {Npv, NpvDiscountScale, NpvProfitLossAccount, NpvSignature, PreNpv} from './interfaces';
import {DeliveryMoment} from '../enums/deliveryMoment';
import {NpvType} from '../enums/npvType';
import {NpvContractType} from '../enums/npvContractType';
import NpvSalesGroupModel from './npvSalesGroupModel';
import NpvDiscountScaleModel from './npvDiscountScaleModel';
import {NpvSponsoringType} from '../enums/npvSponsoringType';
import {NpvStatus} from '../enums/npvStatus';

export default class NpvModel implements Npv {

    @observable
    public contactEmail: string | null = null;

    @observable
    public npvType: NpvType | null = null;

    @observable
    public contractType: NpvContractType = NpvContractType.Unknown;

    @observable
    public contactName: string | null = null;

    @observable
    public contractDuration: number = 5;

    @observable
    public deliveryMoment: DeliveryMoment = DeliveryMoment.None;

    public discountScales = observable<NpvDiscountScale>([]);

    @observable
    public id: string | null = null;

    @observable
    public reference: string | null = null;

    @observable
    public invoiceEmail: string | null = null;

    @observable
    public preNpv: PreNpv | null = null;

    public salesGroups = observable<NpvSalesGroupModel>([]);

    @observable
    public sponsoring: number | null = null;

    @observable
    public sponsoringPaymentDate: string | null = null;

    @observable
    public sponsoringType: NpvSponsoringType | null = null;

    @observable
    public yearlyDrops: number | null = null;

    @observable
    public loaded = false;

    @observable
    public annualRevenue = 0;

    @observable
    public purchasingValue = 0;

    @observable
    public totalDiscountEuros = 0;

    @observable
    public totalDiscountPercentage = 0;

    @observable
    public status: NpvStatus = NpvStatus.Preparation;

    @observable
    public profitLossAccounts: NpvProfitLossAccount[] = [];

    @observable
    public signatureData: NpvSignature | null = null;

    @observable
    public rejectComment: string | null = null;

    constructor(data?: Npv) {
        if (data) {
            this.setData(data);
        }
    }

    public setData(data: Npv) {
        this.id = data.id;
        this.reference = data.reference;
        this.contractDuration = data.contractDuration;
        this.contractType = data.contractType;
        this.deliveryMoment = data.deliveryMoment;
        this.contactEmail = data.contactEmail;
        this.contactName = data.contactName;
        this.invoiceEmail = data.invoiceEmail;
        this.npvType = data.npvType;
        this.preNpv = data.preNpv;
        this.salesGroups.replace(data.salesGroups?.map(x => new NpvSalesGroupModel(x)) || []);
        this.discountScales.replace(data.discountScales?.map(x => new NpvDiscountScaleModel(x)) || []);
        this.sponsoring = data.sponsoring;
        this.sponsoringPaymentDate = data.sponsoringPaymentDate;
        this.sponsoringType = data.sponsoringType;
        this.yearlyDrops = data.yearlyDrops;
        this.annualRevenue = data.annualRevenue;
        this.purchasingValue = data.purchasingValue;
        this.totalDiscountEuros = data.totalDiscountEuros;
        this.totalDiscountPercentage = data.totalDiscountPercentage;
        this.status = data.status;
        this.profitLossAccounts = data.profitLossAccounts;
        this.signatureData = data.signatureData;
        this.rejectComment = data.rejectComment;
        this.loaded = true;
    }
}
