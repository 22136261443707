export enum SapInvoiceOrderStatus {
    Unknown       = 0,
    Pending       = 1,
    Processing    = 2,
    Complete      = 3,
}

export function getSapOrderStatusLabel(sapOrderStatus: SapInvoiceOrderStatus): string {
    switch (sapOrderStatus) {
        case SapInvoiceOrderStatus.Pending:
            return 'Wachtende';
        case SapInvoiceOrderStatus.Processing:
            return 'Verwerken';
        case SapInvoiceOrderStatus.Complete:
            return 'Voltooid';
        case SapInvoiceOrderStatus.Unknown:
            return 'Toon alles';
    }
}

export function getSapOrderStatusLabelColor(sapOrderStatus: SapInvoiceOrderStatus) {
    switch (sapOrderStatus) {
        case SapInvoiceOrderStatus.Unknown:
            return '#CE3037';
        case SapInvoiceOrderStatus.Pending:
            return '#f59d40';
        case SapInvoiceOrderStatus.Processing:
            return '#f59d40';
        case SapInvoiceOrderStatus.Complete:
            return '#8BB525';
    }
}
