/* tslint:disable */
import * as React from 'react';
import {observer} from 'mobx-react';
import {computed, observable, reaction} from 'mobx';
import {NpvFormStep, NpvStepProps} from './form-step';
import {DropdownInput} from '../../common/dropdown-input';
import {FormRow, FormRowColumn} from '../../common/form-row';
import {Category, NpvSalesGroup, NpvSalesGroupProduct, SalesGroup, SalesGroupProduct} from '../../../models/interfaces';
import {ConditionUnit, getLabel} from '../../../enums/conditionUnit';
import {Input} from '../../common/input';
import {getRootCategoryOptions, getSalesGroupProducts, getSalesGroups, supportMco} from '../../../api/npv';
import {KeysOfType} from '../../../../../../custom';
import NpvSalesGroupModel from '../../../models/npvSalesGroupModel';
import {Dialog, DialogEventArgs} from '../../dialog';
import {Textarea} from '../../common/textarea';
import {minusIcon, plusIcon, renderSvg} from '../../../../images/images';
import NpvSalesGroupProductModel from '../../../models/npvSalesGroupProductModel';
import {debounce} from 'lodash';
import {formatEuroPrice, formatPercentage} from '../../../utils/format';

interface NpvSalesGroupData {
    category: string | null;
    salesGroup: string | null;
    netCondition: number;
    netConditionUnit: ConditionUnit;
    bonusCondition: number;
    bonusConditionUnit: ConditionUnit;
    products: NpvSalesGroupProductModel[] | null;
}

interface CommonNpvSalesGroup {
    salesGroup: SalesGroup;
    netCondition: number;
    netConditionUnit: ConditionUnit;
    bonusCondition: number;
    bonusConditionUnit: ConditionUnit;
    products: NpvSalesGroupProductModel[];
    // salesPriceMargin: number | null;
    // totalDiscountPercentage: number | null;
    // hasValidMargin: boolean;
}

interface State {
    showMcoDialog: boolean;
    sendingToMco: boolean;
    mcoSent: boolean;
    mcoComment: string;
}

enum DialogResult {
    Ok = 'ok',
    Cancel = 'cancel',
}

@observer
export class NpvForm2 extends NpvFormStep<State> {

    @observable
    private newSalesGroupData: NpvSalesGroupData = {
        category: null,
        salesGroup: null,
        netCondition: 0,
        netConditionUnit: ConditionUnit.Percentage,
        bonusCondition: 0,
        bonusConditionUnit: ConditionUnit.Percentage,
        products: null,
    };

    @observable
    private categories = new Map<string, Category>();

    @observable
    private salesGroups = new Map<string, SalesGroup>();

    @observable
    private salesGroupProducts = new Map<string, SalesGroupProduct[]>();

    @observable
    private expandedSalesGroups: string[] = [];

    constructor(props: NpvStepProps) {
        super(props);

        this.state = {
            mcoComment: '',
            showMcoDialog: false,
            sendingToMco: false,
            mcoSent: false,
        };
    }

    @computed
    public get selectedSalesGroup(): SalesGroup | undefined {
        if (this.newSalesGroupData.salesGroup) {
            return this.salesGroups.get(this.newSalesGroupData.salesGroup);
        }
    }

    @computed
    public get availableSalesGroup() {
        const allSalesGroups = this.salesGroups.values();
        const salesGroups = this.props.npv.salesGroups.map(x => x.salesGroup);
        const salesGroupIds = salesGroups.map(x => x.id);
        return Array.from(allSalesGroups).filter(x => !salesGroupIds.includes(x.id));
    }

    public async componentDidMount() {
        const categories = await getRootCategoryOptions();
        categories.forEach(x => this.categories.set(x.id, {id: x.id, name: x.label}));

        reaction(
            () => this.newSalesGroupData.category,
            async cat => {
                if (cat) {
                    this.salesGroups.clear();
                    const salesGroups = await getSalesGroups(cat);
                    salesGroups.forEach(x => this.salesGroups.set(x.id, x));
                }
            },
        );

        reaction(
            () => this.newSalesGroupData.salesGroup,
            async salesGroup => {
                if (salesGroup) {
                    const salesGroupProducts = await this.loadSalesGroupProducts(salesGroup);
                    this.newSalesGroupData.products = salesGroupProducts.map(x =>
                        new NpvSalesGroupProductModel({
                            id: null,
                            productId: x.productId,
                            productName: x.name,
                            customConditions: false,
                            grossPrice: x.grossPrice!,
                            purchasePrice: x.purchasePrice || null,
                            volume: 0,
                            bonusCondition: this.newSalesGroupData.bonusCondition!,
                            bonusConditionUnit: this.newSalesGroupData.bonusConditionUnit,
                            netCondition: this.newSalesGroupData.netCondition!,
                            netConditionUnit: this.newSalesGroupData.netConditionUnit,
                            hasValidMargin: null,
                            salesPriceMargin: null,
                            totalDiscountPercentage: null,
                        }),
                    );
                }
            },
        );

        this.initRefreshOnChange();
    }

    public render() {
        return (

            <div className="npv-form__step npv-form__step--2">
                <div className="row row--dark">
                    <form className="content-wrap" action="" onSubmit={evt => this.onNewSalesGroupSubmit(evt)}>
                        <h2 className="h h--primary">Verkoopgroep toevoegen</h2>

                        <FormRow grow={false} columns={[
                            this.categories ? (
                                <FormRowColumn
                                    label="Categorie"
                                    required={true}>
                                    <DropdownInput
                                        value={
                                            this.newSalesGroupData.category
                                                ? this.categories.get(this.newSalesGroupData.category)
                                                : undefined
                                        }
                                        required
                                        disabled={this.props.disabled}
                                        options={Array.from(this.categories.values())}
                                        getLabel={x => x.name}
                                        onValueSelected={x => this.patchNewSalesGroupData({
                                            category: x.id,
                                        })}
                                    />
                                </FormRowColumn>
                            ) : <span className="notice">Laden...</span>,
                            <FormRowColumn
                                label="Verkoopgroep"
                                required={true}>
                                <DropdownInput
                                    disabled={this.props.disabled || !this.newSalesGroupData.category || !this.salesGroups}
                                    value={
                                        this.newSalesGroupData.salesGroup
                                            ? this.salesGroups!.get(this.newSalesGroupData.salesGroup)
                                            : undefined
                                    }
                                    required
                                    options={this.availableSalesGroup}
                                    getLabel={x => x.name}
                                    onValueSelected={x => this.patchNewSalesGroupData({
                                        salesGroup: x.id,
                                    })}
                                />
                            </FormRowColumn>,

                            <FormRowColumn
                                label="Netto conditie"
                                labelAttributes={{htmlFor: 'new_netCondition'}}
                                required={true}>
                                {this.renderConditionFormInput('new_', this.newSalesGroupData, 'netCondition', 'netConditionUnit')}
                            </FormRowColumn>,
                            <FormRowColumn
                                label="Bonus conditie"
                                labelAttributes={{htmlFor: 'new_bonusCondition'}}
                                required={true}>
                                {this.renderConditionFormInput('new_', this.newSalesGroupData, 'bonusCondition', 'bonusConditionUnit')}
                            </FormRowColumn>,
                        ]}/>

                        {this.selectedSalesGroup && (
                            <>
                                <h3>Producten in '{this.selectedSalesGroup.name}'</h3>

                                {this.newSalesGroupData.products && this.renderSalesGroupProducts({
                                    ...this.newSalesGroupData,
                                    products: this.newSalesGroupData.products!,
                                    salesGroup: this.selectedSalesGroup,
                                }, false)}

                                <button className="button button--primary --m0"
                                        disabled={this.props.disabled}
                                        type="submit">
                                    Verkoopgroep toevoegen
                                </button>
                            </>
                        )}
                    </form>


                </div>

                <div className="row">
                    <div className="content-wrap --relative">

                        <table className="table">
                            <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>Categorie</th>
                                <th>Verkoopgroep</th>
                                <th>Netto conditie</th>
                                <th>Bonus conditie</th>
                                <th>&nbsp;</th>
                                <th>&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.npv.salesGroups.map((npvSalesGroup, i) => this.renderNpvSalesGroup(npvSalesGroup, i))}
                            </tbody>
                        </table>

                        {this.props.buttons}

                        {this.state.mcoSent ? (
                            <div className="notice notice--success notice--compact notice--radius --absolute"
                                 style={{bottom: '0', right: '0'}}>
                                Aanvraag verstuurd
                            </div>
                        ) : (
                            <React.Fragment>
                                {this.props.npv.salesGroups.length > 0 && (
                                    <button
                                        type="button"
                                        disabled={this.props.disabled}
                                        className="button button--circle button--orange --absolute"
                                        style={{bottom: '-2em', right: '0'}}
                                        onClick={() => this.setState({showMcoDialog: true})}>
                                        Support<br/>MCO
                                    </button>
                                )}
                                {this.state.showMcoDialog && (
                                    <Dialog<DialogResult> onResult={evt => this.onSupportMcoDialogResult(evt)} title="Support MCO">
                                        <label htmlFor="mco_comment" className="dialog-container__dialog__text">
                                            Vul eventueel een opmerking in voor MCO.
                                        </label>
                                        <Textarea
                                            id="mco_comment"
                                            disabled={this.props.disabled}
                                            placeholder="Opmerking"
                                            value={this.state.mcoComment}
                                            onChange={evt => this.setState({mcoComment: evt.target.value})}/>
                                        <div className="button-group button-group--compact">
                                            <button
                                                disabled={this.props.disabled}
                                                className="button button--primary button-group__button button--float"
                                                data-action={DialogResult.Ok}>
                                                Naar MCO versturen
                                            </button>
                                            <button
                                                disabled={this.props.disabled}
                                                className="button button--text-primary button-group__button"
                                                data-action={DialogResult.Cancel}>
                                                Annuleren
                                            </button>
                                        </div>
                                    </Dialog>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    private initRefreshOnChange() {
        const refreshSalesGroupFields: Array<keyof NpvSalesGroup> = [
            'netCondition',
            'netConditionUnit',
            'bonusCondition',
            'bonusConditionUnit',
        ];
        const refreshProductFields: Array<keyof NpvSalesGroupProduct> = [
            'netCondition',
            'netConditionUnit',
            'bonusCondition',
            'bonusConditionUnit',
            'customConditions',
            'volume',
        ];
        let prevState: string | null = null;
        const refreshDebounced = debounce(async (state: string) => {
            if (prevState === state) {
                return;
            }
            prevState = state;
            await this.props.requestRefresh();
        }, 500);
        reaction(
            () => {
                return JSON.stringify(
                    this.props.npv.salesGroups.map(salesGroup => [
                        [salesGroup.id, refreshSalesGroupFields.map(field => salesGroup[field])],
                        salesGroup.products.map(product => [
                            product.id,
                            refreshProductFields.map(field => product[field]),
                        ]),
                    ]),
                );
            },
            refreshDebounced,
        );
    }

    private async loadSalesGroupProducts(salesGroupId: string) {
        if (!this.salesGroupProducts.has(salesGroupId)) {
            const salesGroupProducts = await getSalesGroupProducts(salesGroupId);
            this.salesGroupProducts.set(salesGroupId, salesGroupProducts);
        }
        return this.salesGroupProducts.get(salesGroupId)!;
    }

    private renderNpvSalesGroup(npvSalesGroup: NpvSalesGroupModel, i: number) {
        const expanded = this.expandedSalesGroups.includes(npvSalesGroup.salesGroup.id);
        return [
            <tr key={i}>
                <td>
                    {!expanded ? (
                        <span onClick={async () => {
                            this.expandedSalesGroups.push(npvSalesGroup.salesGroup.id);
                            await this.loadSalesGroupProducts(npvSalesGroup.salesGroup.id);
                        }}>
                            {renderSvg(plusIcon)}
                        </span>
                    ) : (
                        <span onClick={() => {
                            this.expandedSalesGroups = this.expandedSalesGroups
                                .filter(x => x !== npvSalesGroup.salesGroup.id);
                        }}>
                            {renderSvg(minusIcon)}
                        </span>
                    )}
                </td>
                <td>{this.categories.get(npvSalesGroup.salesGroup.categoryId)?.name}</td>
                <td>{npvSalesGroup.salesGroup.name}</td>
                <td>{this.renderConditionFormInput(i.toString(), npvSalesGroup, 'netCondition', 'netConditionUnit')}</td>
                <td>{this.renderConditionFormInput(i.toString(), npvSalesGroup, 'bonusCondition', 'bonusConditionUnit')}</td>
                <td align="center">
                    <span className={`badge badge--${npvSalesGroup.hasValidMargin ? 'success' : 'error'}`}>
                        {formatPercentage(npvSalesGroup.totalDiscountPercentage)}
                        &nbsp;/&nbsp;
                        {formatPercentage(npvSalesGroup.averageSalesPriceMargin)}
                    </span>
                </td>
                <td>
                    <button className="button button--small button--warn"
                            disabled={this.props.disabled}
                            type="button"
                            onClick={() => {
                                if (confirm(`Weet je zeker dat je de verkoopgroep "${npvSalesGroup.salesGroup.name}" wilt verwijderen?`)) {
                                    this.props.npv.salesGroups.splice(i, 1);
                                }
                            }}>
                        x
                    </button>
                </td>
            </tr>,
            expanded && (
                <tr key={i + '_products'}>
                    <td></td>
                    <td colSpan={6}>
                        {this.renderSalesGroupProducts(npvSalesGroup)}
                    </td>
                </tr>
            ),
        ];
    }

    private renderSalesGroupProducts(npvSalesGroup: CommonNpvSalesGroup, showStatus = true) {
        return (
            <table className="table table--overview" style={{width: '100%'}}>
                <thead>
                <tr>
                    <th>Product</th>
                    <th>Stuks</th>
                    <th>Verkoopprijs</th>
                    <th>Eigen condities</th>
                    <th>Netto conditie</th>
                    <th>Bonus conditie</th>
                    {showStatus && <th>&nbsp;</th>}
                </tr>
                </thead>
                <tbody>
                {npvSalesGroup.products.map((npvSalesGroupProduct, i) => (
                    <tr key={i}>
                        <td style={{fontWeight: npvSalesGroupProduct.volume > 0 ? 'bold' : 'inherit'}}>
                            {npvSalesGroupProduct.productName}
                        </td>
                        <td>
                            <Input narrow
                                   disabled={this.props.disabled}
                                   value={npvSalesGroupProduct.volume}
                                   onChange={evt => npvSalesGroupProduct.volume = parseFloat(evt.target.value)}/>
                        </td>
                        <td>€ {npvSalesGroupProduct.grossPrice}</td>
                        <td>
                            <input type="checkbox"
                                   disabled={this.props.disabled}
                                   checked={npvSalesGroupProduct.customConditions}
                                   onChange={evt => npvSalesGroupProduct.customConditions = evt.target.checked}/>
                        </td>
                        {npvSalesGroupProduct.customConditions ? (
                            <>
                                <td>
                                    {this.renderConditionFormInput(
                                        `prod_${i}_`,
                                        npvSalesGroupProduct,
                                        'netCondition',
                                        'netConditionUnit',
                                        !npvSalesGroupProduct.customConditions,
                                    )}
                                </td>
                                <td>
                                    {this.renderConditionFormInput(
                                        `prod_${i}_`,
                                        npvSalesGroupProduct,
                                        'bonusCondition',
                                        'bonusConditionUnit',
                                        !npvSalesGroupProduct.customConditions,
                                    )}
                                </td>
                            </>
                        ) : (
                            <>
                                <td>
                                    {npvSalesGroup.netConditionUnit === ConditionUnit.Euro ?
                                        formatEuroPrice(npvSalesGroup.netCondition)
                                        :
                                        formatPercentage(npvSalesGroup.netCondition)
                                    }
                                </td>
                                <td>
                                    {npvSalesGroup.bonusConditionUnit === ConditionUnit.Euro ?
                                        formatEuroPrice(npvSalesGroup.bonusCondition)
                                        :
                                        formatPercentage(npvSalesGroup.bonusCondition)
                                    }
                                </td>
                            </>
                        )}
                        {showStatus && (
                            <td align="center">
                                <span
                                    className={`badge badge--${npvSalesGroupProduct.hasValidMargin ? 'success' : 'error'}`}>
                                    {formatPercentage(npvSalesGroupProduct.totalDiscountPercentage)}
                                    &nbsp;/&nbsp;
                                    {formatPercentage(npvSalesGroupProduct.salesPriceMargin)}
                                </span>
                            </td>
                        )}
                    </tr>
                ))}
                </tbody>
            </table>
        );
    }

    private renderConditionFormInput<T extends any>(
        prefix: string,
        data: T,
        conditionKey: KeysOfType<T, number | undefined>,
        conditionUnitKey: KeysOfType<T, ConditionUnit>,
        disabled = false) {
        const units = [ConditionUnit.Percentage, ConditionUnit.Euro];
        return (
            <Input type="number"
                   required
                   disabled={disabled || this.props.disabled}
                   id={prefix + conditionKey}
                   narrow={true}
                // @ts-ignore
                   value={
                       data[conditionKey] !== undefined
                           // @ts-ignore
                           ? (data[conditionUnitKey] === ConditionUnit.Percentage ? Math.round(data[conditionKey] * 100) : data[conditionKey])
                           : undefined
                   }
                   step="0.01"
                   onChange={evt => {
                       if (!evt.target.value) {
                           // @ts-ignore
                           data[conditionKey] = undefined;
                       } else {
                           const nr = parseFloat(evt.target.value);
                           // @ts-ignore
                           if (data[conditionUnitKey] === ConditionUnit.Percentage) {
                               // @ts-ignore
                               data[conditionKey] = (nr / 100).toPrecision(2);
                           } else {
                               // @ts-ignore
                               data[conditionKey] = nr;
                           }
                       }
                   }}
                   onKeyDown={evt => {
                       if (evt.shiftKey && evt.keyCode === 53) { // %
                           // @ts-ignore
                           data[conditionUnitKey] = ConditionUnit.Percentage;
                           evt.preventDefault();
                       } else if (evt.altKey && evt.ctrlKey && evt.keyCode === 53) { // €
                           // @ts-ignore
                           data[conditionUnitKey] = ConditionUnit.Euro;
                           evt.preventDefault();
                       }
                   }}
                   name={conditionKey as string}
                   inputSuffix={(
                       <div className="input-wrap__units">
                           {units.map(x => (
                               <button type="button"
                                       disabled={disabled || this.props.disabled}
                                       key={x}
                                   // @ts-ignore
                                       onClick={() => data[conditionUnitKey] = x}
                                   // @ts-ignore
                                       className={`input-wrap__units__unit ${x === data[conditionUnitKey] ? 'input-wrap__units__unit--active' : ''}`}>
                                   {getLabel(x)}
                               </button>
                           ))}
                       </div>
                   )}/>
        );
    }

    private patchNewSalesGroupData(data: Partial<NpvSalesGroupData>) {
        this.newSalesGroupData = {
            ...this.newSalesGroupData,
            ...data,
        };
    }

    private onNewSalesGroupSubmit(evt: React.FormEvent<HTMLFormElement>) {
        evt.preventDefault();
        const data = this.newSalesGroupData as Required<NpvSalesGroupData>;

        this.props.npv.salesGroups.push(new NpvSalesGroupModel({
            salesGroup: this.selectedSalesGroup!,
            netCondition: data.netCondition,
            netConditionUnit: data.netConditionUnit,
            bonusCondition: data.bonusCondition,
            bonusConditionUnit: data.bonusConditionUnit,
            products: data.products!,
        }));

        this.newSalesGroupData.salesGroup = null;
    }

    private async onSupportMcoDialogResult(result: DialogEventArgs<DialogResult>) {
        if (result.result === DialogResult.Ok) {
            try {
                this.setState({
                    sendingToMco: true,
                });

                const {id} = await this.props.requestApply();
                await supportMco(id!, this.state.mcoComment);

                this.setState({
                    sendingToMco: false,
                    mcoSent: true,
                });
            } catch (e) {
                this.setState({
                    sendingToMco: false,
                });
                throw e;
            }
        }

        this.setState({
            showMcoDialog: false,
            mcoComment: '',
        });
    }
}
