/* tslint:disable:max-line-length */
// /* tslint:disable:max-line-length */
import {observer} from 'mobx-react';
import {state} from '../../../state';
import {Npv, PreNpv as PreNpvModel} from '../../../models/interfaces';
import NpvModel from '../../../models/npvModel';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import DatePicker from 'react-datepicker';
import {calendarIcon, euroIcon, renderSvg} from '../../../../images/images';
import {Column, defaultColumns, NpvOverview} from '../overview';
import {savePreNpv} from '../../../api/npv';
import {FormRow, FormRowColumn} from '../../common/form-row';
import {DatePickerInput} from '../../common/date-picker-input';
import {Input} from '../../common/input';

interface Props {
    npv: Npv;
    organization: string;
}

type FormData<T> = {
    [P in keyof T]?:
    T[P] extends Date ? Date :
        string;
};

interface State {
    loading: boolean;
    saving: boolean;
    formData: FormData<PreNpvModel>;
}

@observer
export class PreNpv extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = {
            loading: false,
            saving: false,
            formData: {},
        };
    }

    public render() {
        return <div className="npv">
            <div className="row">
                {this.renderCurrentNpvs()}
            </div>
            <div className="row row--dark">
                {this.renderPreNpvForm()}
            </div>
        </div>;
    }

    protected async save(continueNpv: boolean) {
        try {
            this.setState({
                saving: true,
            });

            const formData = this.state.formData as Required<State['formData']>;
            const savedId = await savePreNpv(this.props.organization, {
                annualMargin: parseFloat(formData.annualMargin),
                firstLoad: formData.firstLoad,
            });
            location.href = state.urls.npv + (continueNpv ? `/edit?id=${savedId}` : '');
        } catch (e) {
            this.setState({
                saving: false,
            });
            throw e;
        }
    }

    private renderCurrentNpvs() {
        return <div className="content-wrap">
            <h3 className="h h--grey-2">Huidige NCW's van klant</h3>
            <NpvOverview
                hideSearch={true}
                visibleColumns={defaultColumns ^ Column.OrganizationName ^ Column.City | Column.CreateDate}
                searchCriteria={{organizationId: this.props.organization}}
                onLoading={loading => this.setState({loading})}/>
        </div>;
    }

    private renderPreNpvForm() {
        if (this.state.loading) {
            return null;
        }
        return <div className="content-wrap">
            <h2 className="h h--primary">Pre-NCW</h2>

            <FormRow grow={true} columns={[
                <FormRowColumn
                    label="Geschatte eerste inlading"
                    labelAttributes={{htmlFor: 'pre-npv-firstLoad'}}
                    >
                    <DatePickerInput
                        id="pre-npv-firstLoad"
                        required
                        title="Geschatte eerste inlading"
                        locale="nl"
                        autoComplete="off"
                        disabled={this.state.saving}
                        selected={DatePickerInput.stringToDate(this.state.formData.firstLoad)}
                        onChange={date => {
                            this.setState({
                                formData: {
                                    ...this.state.formData,
                                    firstLoad: DatePickerInput.dateToString(date),
                                },
                            });
                        }}
                        name="firstLoad" />
                </FormRowColumn>,
                <FormRowColumn
                    label="Geschatte bruto marge per jaar"
                    labelAttributes={{htmlFor: 'pre-npv-annualMargin'}}
                    >
                    <Input
                        id="pre-npv-annualMargin"
                        required
                        disabled={this.state.saving}
                        title="Geschatte bruto marge per jaar"
                        value={this.state.formData.annualMargin}
                        onChange={evt => {
                            this.setState({
                                formData: {
                                    ...this.state.formData,
                                    annualMargin: evt.target.value,
                                },
                            });
                        }}
                        name="annualMargin"
                        inputPrefix={renderSvg(euroIcon, {className: 'input-wrap__prefix'})}
                    />
                </FormRowColumn>,
                ]}
                />

            <div className="button-group button-group--compact --mt">
                <button className="button-group__button button button--primary"
                        disabled={this.state.saving}
                        type="button"
                        onClick={() => this.save(true)}>
                    Opslaan &amp; verder naar NCW
                </button>
                <button className="button-group__button button button--text"
                        disabled={this.state.saving}
                        type="button"
                        onClick={() => this.save(false)}>
                    Opslaan &amp; sluiten
                </button>
            </div>
        </div>;
    }
}

export function renderPreNpv(element: HTMLElement) {
    if (!state.npv) {
        state.npv = new NpvModel();
    }
    (window as any).state = state.npv;
    const organizationId = element.getAttribute('data-organization');
    if (organizationId) {
        ReactDOM.render(<PreNpv organization={organizationId} npv={state.npv}/>, element);
    }
}
