/* tslint:disable:no-var-requires */
import * as React from 'react';

export const blackTwitterIcon = require('./icons8-black-twitter-logo-50.svg');
export const buyIcon = require('./icons8-buy-50.svg');
export const calendarIcon = require('./icons8-calendar.svg');
export const caretArrowheadFacingDown = require('./icons8-caret-arrowhead-facing-down-50.svg');
export const checkoutIcon = require('./icons8-checkout-50.svg');
export const chevronLeftIcon = require('./icons8-chevron-left-50.svg');
export const chevronRightIcon = require('./icons8-chevron-right-50.svg');
export const collapseArrowIcon = require('./icons8-collapse-arrow-50.svg');
export const configuratorIcon = require('./icons8-configurator-50.svg');
export const dataTransferIcon = require('./icons8-data-transfer-50.svg');
export const euroIcon = require('./icons8-euro.svg');
export const eventIcon = require('./icons8-event-50.svg');
export const expandArrowIcon = require('./icons8-expand-arrow-50.svg');
export const explanationMarkIcon = require('./icons8-explanation-mark-50.svg');
export const facebookLogoIcon = require('./icons8-facebook-logo-50.svg');
export const heartOutlineIcon = require('./icons8-heart-outline-50.svg');
export const heartOutline = require('./icons8-heart-outline.svg');
export const infoIcon = require('./icons8-info-50.svg');
export const instagramIcon = require('./icons8-instagram-50.svg');
export const likeIcon = require('./icons8-like-50.svg');
export const linkedinIcon = require('./icons8-linkedin-50.svg');
export const menuIcon = require('./icons8-menu-50.svg');
export const refreshIcon = require('./icons8-refresh.svg');
export const minusIcon = require('./icons8-minus-50.svg');
export const multiplyIcon = require('./icons8-multiply-50.svg');
export const pencilDrawingIcon = require('./icons8-pencil-drawing-50.svg');
export const plusIcon = require('./icons8-plus-50.svg');
export const printIcon = require('./icons8-print-50.svg');
export const receiveIcon = require('./icons8-receive-50.svg');
export const returnPurchaseIcon = require('./icons8-return-purchase-50.svg');
export const saveIcon = require('./icons8-save.svg');
export const searchIcon = require('./icons8-search-50.svg');
export const shopIcon = require('./icons8-shop.svg');
export const shoppingCartIcon = require('./icons8-shopping-cart-50.svg');
export const signOutIcon = require('./icons8-sign-out-50.svg');
export const sliderIcon = require('./icons8-slider.svg');
export const sortDownIcon = require('./icons8-sort-down-50.svg');
export const sortUpIcon = require('./icons8-sort-up-50.svg');
export const trashIcon = require('./icons8-trash.svg');
export const userAvatarIcon = require('./icons8-user-avatar-50.svg');
export const loadingIcon = require('./icons8-loading.svg');
export const expandIcon = require('./icons8-expand.svg');
export const dkdHeaderLogo = require('./dkd_woordmerk.svg');
export const okIcon = require('./icons8-ok-50.svg');
export const cancelIcon = require('./icons8-cancel-50.svg');

export function renderSvg(content: string, attributes?: React.HTMLAttributes<HTMLSpanElement>) {
    return <span dangerouslySetInnerHTML={{__html: content}} {...attributes} />;
}
