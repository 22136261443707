export enum DeliveryMoment {
    None = 0,
    MondayMorning = 1 << 0,
    MondayAfternoon = 1 << 1,
    TuesdayMorning = 1 << 2,
    TuesdayAfternoon = 1 << 3,
    WednesdayMorning = 1 << 4,
    WednesdayAfternoon = 1 << 5,
    ThursdayMorning = 1 << 6,
    ThursdayAfternoon = 1 << 7,
    FridayMorning = 1 << 8,
    FridayAfternoon = 1 << 9,
    SaturdayMorning = 1 << 10,
    SaturdayAfternoon = 1 << 11,
    SundayMorning = 1 << 12,
    SundayAfternoon = 1 << 13,
}
