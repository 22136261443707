/* tslint:disable:variable-name */
import {DeepPartial} from '../../../../custom';
import CartModel from '../models/cartModel';
import {computed, observable} from 'mobx';
import NotificationsModel from '../models/notificationModel';
import CustomerModel from './customerModel';
import {MediaQuery} from '../media-queries';
import {
    App,
    BackorderItem,
    BackOrderWrapper,
    BusinessLocation, CatalogCategory,
    Category,
    LeadGenerationOptions, Search, SearchChanged,
    Urls,
} from './interfaces';
import PriceStockModel from './priceStockModel';
import {getBackorder, getSettings} from '../api/de-klok';
import {storageClear, storageGetObject, storageGetOrCreateObject, storageRemove, storageSetObject} from '../storage';
import OrderListModel from './orderListModel';
import {LocationModel} from './locationModel';
import {AppSettings} from '../api/interfaces';
import OrderOverviewModel from './orderOverviewModel';
import {state} from '../state';
import OrderDetailModel from './orderDetailModel';
import InvoiceOverviewModel from './invoiceOverviewModel';
import InvoiceDetailModel from './invoiceDetailModel';
import NpvModel from './npvModel';
import {FilterGroup} from '../components/base-product-filters';

const STORAGE_BACKORDER_KEY = 'backorder';
const STORAGE_SETTINGS_KEY = 'settings';

export class AppModel implements App {

    public recaptcha: App['recaptcha'] = null;

    public categories: Category[];
    public branches: LeadGenerationOptions;
    public channels: LeadGenerationOptions;
    public interests: LeadGenerationOptions;

    public business_location: BusinessLocation | null = null;

    @observable
    public mediaQueries = new Map<MediaQuery, boolean>();

    @observable
    public current_organization: string | null = null;

    @observable
    public backorderArray: BackorderItem[] = [];

    @observable
    public settings: AppSettings | null = null;

    @observable
    public location = new LocationModel();
    public price_stock: PriceStockModel;
    public cart: CartModel;
    public customer: CustomerModel | null = null;
    public notifications = new NotificationsModel();
    public urls: Urls;
    public orderList: OrderListModel | null = null;
    public orders: OrderOverviewModel | null = null;
    public invoices: InvoiceOverviewModel | null = null;
    public order: OrderDetailModel | null = null;
    public invoice: InvoiceDetailModel | null = null;
    public search: Search | null = null;
    public searchTerm: string = '';
    public searchChanged: SearchChanged | null = null;

    @observable
    public npv: NpvModel | null = null;

    private backorder_refresh_minutes = 5;

    public constructor(data?: DeepPartial<App>) {
        data = data || {};

        this.recaptcha = data.recaptcha as Required<App['recaptcha']>;
        this.categories = data.categories as Category[] || [];
        this.branches = data.branches as LeadGenerationOptions;
        this.channels = data.channels as LeadGenerationOptions;
        this.interests = data.interests as LeadGenerationOptions;
        this.searchChanged = data.searchChanged as SearchChanged;
        if (data.customer) {
            this.customer = new CustomerModel(data.customer);
            this.loadSettings();
            this.orderList = new OrderListModel(this);
            this.orders = new OrderOverviewModel();
            this.order = new OrderDetailModel();
            this.invoices = new InvoiceOverviewModel();
            this.invoice = new InvoiceDetailModel();
            this.business_location = data.business_location as BusinessLocation;
            if (this.backorderArray) {
                this.loadBackorderItems(this.current_organization!);
            }
        } else {
            storageClear();
        }
        this.current_organization = data.current_organization || null;
        this.cart = new CartModel(this, data.cart);
        this.urls = data.urls as Urls;
        this.price_stock = new PriceStockModel(this);
    }

    @computed
    public get backorder(): Map<string, BackorderItem[]> {
        return (this.backorderArray || []).reduce((prev, acc) => {
            const items = prev.get(acc.sku);
            if (items) {
                items.push(acc);
            } else {
                prev.set(acc.sku, [acc]);
            }
            return prev;
        }, new Map<string, BackorderItem[]>());
    }

    @computed
    public get CurrentOrganization() {
        if (state.customer) {
            return state.customer.organizations
                .find(x => x.id === state.current_organization);
        } else {
            return null;
        }
    }

    @computed
    public get has_transport_surcharge_in_backorder() {
        if (!this.settings) {
            return false;
        }
        return this.backorder.has(this.settings.transportSurchargeSku);
    }

    public async loadBackorderItems(organization: string) {
        const storageKey = STORAGE_BACKORDER_KEY + '_' + organization;
        let result = await this.getOrCreateBackorderItems(storageKey);

        if (result && result.retrieved) {

            if (this.differenceInMinutes(new Date(result.retrieved), new Date()) >= this.backorder_refresh_minutes) {
                storageRemove(storageKey);
                result = await this.getOrCreateBackorderItems(organization);
            }
        } else {
            result = await this.getOrCreateBackorderItems(organization);
        }
        this.backorderArray = result.items;
    }

    public async loadSettings() {
        this.settings = await storageGetOrCreateObject<AppSettings>(STORAGE_SETTINGS_KEY,
            () => getSettings());
    }

    @computed
    public get isCustomerLoggedIn(): boolean {
        return this.customer !== null;
    }

    private async getOrCreateBackorderItems(storageKey: string) {
        let data = storageGetObject<BackOrderWrapper>(storageKey);

        if (!data) {
            data = {
                retrieved: new Date().toString(),
                items: await getBackorder(),
            };
            storageSetObject(storageKey, data);
        }
        return data;
    }

    private differenceInMinutes(d1: Date, d2: Date) {
        const d2Time = d2.getTime();
        const d1Time = d1.getTime();
        return Math.floor(((d2Time - d1Time) / 1000) / 60);
    }

}
