import * as React from 'react'
import { render } from 'react-dom'
import { collapseArrowIcon, renderSvg } from '../../../images/images'

interface Props {
    moreButton: string
    lessButton: string
    shortText: string
    hiddenText: string
}

interface State {
    expanded: boolean
    closedHeight: number
}

export class ExpandableText extends React.Component<Props, State> {
    private closedText = React.createRef<HTMLDivElement>()

    public constructor(props: Props) {
        super(props)
        this.state = {
            expanded: false,
            closedHeight: 0
        }
    }

    public render() {
        return (
            <div className={`expandable-text ${this.state.expanded ? 'expandable-text--expanded' : ''}`}>
                <div className="expandable-text__rich-text rich-text" dangerouslySetInnerHTML={{ __html: this.props.shortText }} />
                {this.props.hiddenText.length > 0 && (
                    <div>
                        <div className="expandable-text__container" style={{ height: this.height() }}>
                            <div
                                ref={this.closedText}
                                className="expandable-text__rich-text rich-text"
                                dangerouslySetInnerHTML={{ __html: this.props.hiddenText }}
                            />
                        </div>
                        <button className="expandable-text__button" onClick={() => this.toggleExpanded()}>
                            {this.state.expanded ? this.props.lessButton : this.props.moreButton}
                            {renderSvg(collapseArrowIcon, { className: 'expandable-text__button__icon' })}
                        </button>
                    </div>
                )}
            </div>
        )
    }

    private toggleExpanded() {
        this.setState({ expanded: !this.state.expanded })
    }

    public height(): string {
        return this.state.expanded ? `${this.state.closedHeight}px` : `0px`
    }

    public setContentHeights() {
        this.setState({ closedHeight: this.closedText.current?.clientHeight || 0 })
    }

    componentDidMount() {
        if(!this.props.hiddenText) return
        this.setContentHeights()
        window.addEventListener('resize',() =>  this.setContentHeights())
    }
}

export function renderExpandableText(element: HTMLElement) {
    return render(
        <ExpandableText
            moreButton={element.getAttribute('more-button' as string) || 'Toon meer'}
            lessButton={element.getAttribute('less-button' as string) || 'Toon minder'}
            shortText={element.getAttribute('short-text' as string) || ''}
            hiddenText={element.getAttribute('hidden-text' as string) || ''}
        />,
        element!
    )
}
