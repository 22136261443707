import * as React from 'react';
import NpvModel from '../../../models/npvModel';

export interface NpvStepProps {
    npv: NpvModel;
    buttons: React.ReactNode;
    disabled?: boolean;
    requestApply: () => Promise<NpvModel>;
    requestRefresh: () => Promise<NpvModel>;
}

export class NpvFormStep<State = {},
    TProps extends NpvStepProps = NpvStepProps> extends React.Component<TProps, State> {

    public isValid(): boolean | Promise<boolean> {
        return true;
    }

    protected onDataLoaded() {
        // for subclasses
    }
}
