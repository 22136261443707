/* tslint:disable:no-var-requires max-line-length */
import {observer} from 'mobx-react';
import {Contact, CreateContactData, UpdateContactOrganizationsData} from '../../api/interfaces';
import {
    createContact,
    getContactByEmail,
    getUserRoleLabel,
    getUserRoles,
    updateContactOrganizations,
    UserRole,
} from '../../api/user-management';
import {state} from '../../state';
import * as React from 'react';

interface Props {
    user?: string;
}

interface State {
    loading: boolean;
    email: string;
    firstName: string;
    lastName: string;
    role: UserRole | null;
    organizations: string[];
    currentContact: Contact | null;
    showDetail: boolean;
    error: string | null;
}

@observer
export class UserManagementForm extends React.Component<Props, State> {
    private readonly initialState: State;

    public constructor(props: Props) {
        super(props);
        this.state = this.initialState = {
            loading: false,
            email: props && props.user || '',
            firstName: '',
            lastName: '',
            role: null,
            organizations: [],
            currentContact: null,
            showDetail: false,
            error: null,
        };

        if (this.state.email) {
            this.updateCurrentContact();
        }
    }

    public render() {
        return (
            <div className="">
                <div className="row row--no-padding-top">
                    <div className="row__content content-wrap">
                        <div className="page-header page-header--medium">
                            <h1 className="page-header__title">Nieuwe gebruiker</h1>
                            <div className="page-header__text">
                                Nadat wij uw aanvraag hebben ontvangen wordt deze door ons gecontroleerd.
                                Indien uw aanvraag is goedgekeurd, wordt dit account binnen 1 werkdag geactiveerd en
                                ontvangt u van ons een activatie e-mail. Met de activatie link in deze e-mail kunt u het
                                wachtwoord van uw account instellen. Direct daarna kunt u beginnen met bestellen.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row row--dark row--padding-half">
                    <div className="row__content content-wrap content-wrap--small">
                        <h2 className="row__title row__title--padding-bottom-small row__title--no-padding-left">
                            Persoonlijke gegevens
                        </h2>
                        <div className="form-row form-row--label-on-top">

                            {this.state.error && <div className="notice notice--error">{this.state.error}</div>}
                            <label htmlFor="email"
                                   className="hidden-label label--required form-row__label">E-mailadres</label>
                            <div className="form-row__input">
                                <div className="input-wrap input-wrap--without-padding">
                                    <input type="email"
                                           name="email"
                                           disabled={this.props.user !== undefined}
                                           onKeyUp={evt => {
                                               if (evt.key === 'Enter') {
                                                   this.updateCurrentContact();
                                               }
                                           }}
                                           onChange={(evt) => {
                                               this.setState({
                                                   email: evt.target.value,
                                                   currentContact: null,
                                               });
                                           }}
                                           className="input-wrap__input input-wrap__input--bordered input-wrap__input--half-width"
                                           value={this.state.email}
                                           autoFocus/>
                                </div>
                            </div>
                        </div>
                        {!this.state.showDetail ?
                            <div className="form-row">
                                <div className="button-group button-group--compact button-group--initial-margin-top">
                                    <button className="button button-group__button button--float button--primary"
                                            onClick={() => this.updateCurrentContact()}>
                                        Controleer e-mailadres
                                    </button>
                                    <button type="button" onClick={() => location.href = '#'}
                                            className="button button-group__button">
                                        Annuleren
                                    </button>
                                </div>
                            </div>
                            :
                            <div className="user-management__form__detail">
                                {!this.state.currentContact &&
                                <div className="form-row form-row--label-on-top">
                                    <div className="linear-layout linear-layout--horizontal">
                                        <div className="linear-layout__item linear-layout__item--grow">
                                            <label className="hidden-label label--required form-row__label">Voornaam</label>
                                            <div className="form-row__input">
                                                <input type="text"
                                                       name="firstName"
                                                       onChange={(evt) => this.setState({firstName: evt.target.value})}
                                                       className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"
                                                       value={this.state.firstName}/>
                                            </div>
                                        </div>
                                        <div className="linear-layout__item linear-layout__item--grow">
                                            <label
                                                className="hidden-label label--required form-row__label">Achternaam</label>
                                            <div className="form-row__input">
                                                <input type="text"
                                                       name="lastName"
                                                       onChange={(evt) => {
                                                           this.setState({lastName: evt.target.value});
                                                       }}
                                                       className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"
                                                       value={this.state.lastName}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        }
                    </div>
                </div>
                {this.state.showDetail && (
                    <div className="row row--padding-half">
                        <div className="row__content content-wrap content-wrap--small">
                            <div className="linear-layout linear-layout--horizontal">
                                {!this.state.currentContact && (
                                    <div className="linear-layout__item linear-layout__item--grow">
                                        <div className="form-row__title">
                                            SELECTEER GEBRUIKERSROL
                                        </div>
                                        <div className="form-row">
                                            <div className="linear-layout linear-layout--vertical">
                                                {getUserRoles().filter((x => x !== UserRole.Representative)).map(x =>
                                                    <label key={x}>
                                                        <input type="radio"
                                                               name="radio"
                                                               value={x}
                                                               onChange={() => this.setRole(x)}
                                                               checked={this.state.role === x}/>
                                                        {getUserRoleLabel(x).toUpperCase()}
                                                    </label>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="linear-layout__item linear-layout__item--grow">
                                    <div className="form-row__title">
                                        TOEGANG TOT
                                    </div>
                                    <div className="form-row">
                                        <div className="linear-layout linear-layout--vertical">
                                            {state.customer!.organizations.map(x => {
                                                return <label key={x.id}>
                                                    <input type="checkbox"
                                                           name="checkbox"
                                                           value={x.id}
                                                           onChange={evt => this.toggleOrganization(x.id, evt.target.checked)}
                                                           checked={this.state.organizations.indexOf(x.id) !== -1}/>
                                                    {x.name.toUpperCase()}
                                                </label>;
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row row--padding-half">
                                <div className="button-group button-group--compact">
                                    <button disabled={!this.isValid || this.state.loading} type="button"
                                            onClick={() => this.createContact()}
                                            className="button button-group__button button--primary button--float">
                                        {this.state.currentContact ? 'Organisaties bijwerken' : 'Gebruiker aanmaken'}
                                    </button>
                                    <button type="button" onClick={() => location.href = '#'}
                                            className="button button-group__button button--float">
                                        Annuleren
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    private get isValid(): boolean {
        if (this.state.email === '' ||
            this.state.organizations.length === 0) {
            return false;
        }

        if (!this.state.currentContact) {
            if (this.state.role === null || this.state.firstName === '' || this.state.lastName === '') {
                return false;
            }
        }

        return true;
    }

    private toggleOrganization(organizationId: string, add: boolean) {
        if (add) {
            this.setState({
                organizations: [
                    ...this.state.organizations,
                    organizationId,
                ],
            });
        } else {
            this.setState({
                organizations: [
                    ...this.state.organizations.filter(x => x !== organizationId),
                ],
            });
        }
    }

    private setRole(role: UserRole) {
        this.setState({role});
    }

    private async updateCurrentContact() {
        if (this.state.email) {
            const contact = await getContactByEmail(this.state.email);
            if (contact) {
                this.setState({
                    currentContact: contact,
                    email: contact.email,
                    organizations: contact.organizationsIds || [],
                    showDetail: true,
                });
            } else {
                this.setState({
                    currentContact: null,
                    showDetail: true,
                });
            }
        } else {
            this.setState({
                currentContact: null,
                showDetail: true,
            });
        }
    }

    private async createContact() {
      this.setState({
            loading: true,
            error: null,
        });
      try {
        if (this.state.currentContact) {
                const contact: UpdateContactOrganizationsData = {
                    emailAddress: this.state.email,
                    linkOrganizations: this.state.organizations,
                };
                await updateContactOrganizations(contact);
            } else {
                const contact: CreateContactData = {
                    emailAddress: this.state.email,
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    organizations: this.state.organizations,
                    role: this.state.role!,
                };
                await createContact(contact);
            }

        this.setState(this.initialState);
        } catch (err) {
          this.setState({
                loading: false,
                error: err.message,
            });
          throw err;
        }

      window.location.href = '#';
    }
}
