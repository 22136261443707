/* tslint:disable:no-var-requires max-line-length */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { observer } from 'mobx-react';
import { ScrollingCarousel as ScrollingCarouselComponent } from '@trendyol-js/react-carousel';
import { chevronLeftIcon, chevronRightIcon, renderSvg } from '../../images/images';

interface Props {
    caruoselWrapperClassName: string;
    children: any;
}

@observer
export class ScrollingCarousel extends React.Component<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public componentDidMount(): void {
        [...this.props.children.childNodes].map((item:any) => {
            document.querySelector(`.${this.props.caruoselWrapperClassName}>[data-testid="carousel"]>div`)?.appendChild(item);
        })  
    }

    public render() {
        return (
            <div className={this.props.caruoselWrapperClassName}>
                <ScrollingCarouselComponent leftIcon={renderSvg(chevronLeftIcon, {className: 'icon'})}
                    rightIcon={renderSvg(chevronRightIcon, {className: 'icon'})}>
                </ScrollingCarouselComponent>
            </div>
        );
    }
}

export function renderScrollingCarousel(element: HTMLElement) {
    return ReactDOM.render(
        <ScrollingCarousel caruoselWrapperClassName={element.getAttribute('data-class-name') as string}>
            {(element.firstElementChild)}
        </ScrollingCarousel>,
        element!
    );
}