/* tslint:disable:no-var-requires max-line-length */
import {OrderListLineItem, PriceStockResultProduct} from '../api/interfaces';
import {Props as QuantityDialogProps, QuantityDialog} from './quantity-dialog';
import {changeOrderListItemPreferredOrderQuantity} from '../api/de-klok';
import {state} from '../state';
import {PriceQuantity} from '../models/interfaces';
import {formatDate, formatPrice} from '../utils/format';
import {MediaQuery} from '../media-queries';
import {autorun, IReactionDisposer} from 'mobx';
import {Dialog, DialogEventArgs} from './dialog';
import * as React from 'react';
import {likeIcon, plusIcon, renderSvg} from '../../images/images';
import {orderListQueue} from '../state/queues';

const moveIcon = require('../../images/icons8-data-transfer-50.svg');
const closeIcon = require('../../images/icons8-multiply-50.svg');

interface Props {
    item: OrderListLineItem;
    canChangeOrder: boolean;
    rowNumber: number;
    orderListId: string;
    onRemove: (itemId: string) => Promise<void>;
    mediaQueries: Map<MediaQuery, boolean>;
    onSavingQuantity?: (saving: boolean) => void;
}

interface State {
    deleting: boolean;
    loading: boolean;
    addingToCart: boolean;
    loadingPriceStock: boolean;
    priceStockResult: PriceStockResultProduct | null;
    savingPreferredQuantity: boolean;
    showQuantityDialog: QuantityDialogProps | false;
    savingQuantity: boolean;
    hasClickedQuantity: boolean;
    quantity: number;
    prefQuantity: number;
    dialogView: DialogView;
}

enum DialogView {
    None,
    InBackorder,
    QuantityIncrement,
    Delete,
}

enum DialogResult {
    Ok = 'ok',
    Cancel = 'cancel',
}

let stop = true;

const scroll = (step: number) => {
    if (!stop) {
        window.scrollBy({left: 0, top: step, behavior: 'smooth'});
    }
};

const handleDragEnter = (e: React.DragEvent<HTMLTableRowElement>) => {
    stop = false;
    if (e.clientY <= e.currentTarget.getBoundingClientRect().height) {
        scroll(-e.currentTarget.getBoundingClientRect().height);
    } else if (window.innerHeight - e.clientY <= e.currentTarget.getBoundingClientRect().height) {
        scroll(e.currentTarget.getBoundingClientRect().height);
    } else {
        stop = true;
    }

};

const handleDragLeave = () => {
    stop = true;
};

export class OrderListDetailRow extends React.Component<Props, State> {
    private priceStockAutoRunDisposer?: IReactionDisposer;
    private _requestAddToCart = false;
    private isQuantityClicked?: number;
    private quantityTimeOut?: number = undefined;

    public constructor(props: Props) {
        super(props);
        this.state = {
            deleting: false,
            loading: false,
            priceStockResult: null,
            addingToCart: false,
            loadingPriceStock: false,
            savingQuantity: false,
            savingPreferredQuantity: false,
            showQuantityDialog: false,
            hasClickedQuantity: false,
            quantity: 0,
            prefQuantity: props.item.preferredOrderQuantity,
            dialogView: DialogView.None,
        };
    }

    public async componentDidMount() {

        const item = this.props.item;
        const cartItem = state.cart.items.find(x => x.sku === item.sku);
        if (cartItem) {
            this.setState({
                quantity: cartItem!.quantity,
                prefQuantity: item.preferredOrderQuantity,
            });
        }
        this.priceStockAutoRunDisposer = autorun(async () => {
            if (state.orderList!.showPriceAndStock && this.props.item.product.isBuyable) {
                const priceQuantity: PriceQuantity = {
                    sku: this.props.item.sku,
                    quantity: 1,
                    measureUnit: this.props.item.measureUnit,
                };
                this.setState({
                    loadingPriceStock: true,
                });
                try {
                    const priceStockResult = await state.price_stock.get_price_stock_result(priceQuantity);
                    this.setState({
                        priceStockResult,
                        loadingPriceStock: false,
                    });
                } catch (error) {
                    this.setState({
                        loadingPriceStock: false,
                    });
                    throw error;
                }
            } else {
                this.setState({
                    priceStockResult: null,
                });
            }
        });
    }

    public componentWillUnmount(): void {
        if (this.priceStockAutoRunDisposer) {
            this.priceStockAutoRunDisposer();
            this.priceStockAutoRunDisposer = undefined;
        }
    }

    public renderVendor(item: OrderListLineItem) {
        if (!item.product.vendor) {
            return null;
        }
        const vendorSpan = (
            <span className="vendor">
                Verkoper:&nbsp;
                <a className="vendor__url"
                   href={state.urls.home + item.product.vendor.seoInfo.slug.substring(1)}
                   title={item.product.vendor.seoInfo.title}>
                    {item.product.vendor.name}
                </a>
            </span>
        );
        if (this.isLarge) {
            return <div>{vendorSpan}</div>;
        } else {
            return vendorSpan;
        }
    }

    public get isLarge() {
        return this.props.mediaQueries.get(MediaQuery.MediumUp) || this.props.mediaQueries.get(MediaQuery.Print);
    }

    public render() {
        const item = this.props.item;
        const increment = item.product!.quantityIncrement > 0 ? item.product!.quantityIncrement : 1;
        return (
            this.isLarge ?
                <tr className={`order-item-list__item ${item.isNew ? ' order-item-list__item--new' : ''} ${this.state.loadingPriceStock ? 'order-item-list__item--loading-prices' : ''}`}
                    key={`line_` + item.id} data-id={item.id}
                    onDrag={(e) => handleDragEnter(e)}
                    onDragEnd={(e) => handleDragLeave}
                    >
                    {this.renderBackorderDialog()}
                    {this.state.showQuantityDialog && <QuantityDialog {...this.state.showQuantityDialog} />}
                    {this.props.canChangeOrder &&
                    <td className="order-item-list__item__column order-item-list__item__column--small order-item-list__item__column--text-align-center --no-print"
                        onMouseDown={() => item.isNew = false}
                        data-sortable-handle
                    >
                    <span className="icon icon--text-size button__icon move-icon"
                          dangerouslySetInnerHTML={{__html: moveIcon}}/>
                    </td>
                    }
                    <td className="order-item-list__item__column order-item-list__item__column--small --no-print">
                        {item.product.hasImage &&
                        <img className="order-item-list__item__image" {...item.product.imageAttributes} />
                        }
                    </td>
                    <td className="order-item-list__item__column --no-print order-item-list__item__column--small">
                        <button
                            className="order-list-item-to-cart__item order-list-item-to-cart__item--delete"
                            aria-label="Product toevoegen" aria-disabled="false"
                            disabled={this.state.deleting}
                            type="button"
                            onClick={() => {
                                this.removeItem(item.product.id);
                            }}>
                            {renderSvg(likeIcon, {
                                className: 'icon order-list-item-to-cart__item__icon order-list-item-to-cart__item__icon--delete',
                            })}
                        </button>
                    </td>
                    <td className={'order-item-list__item__column'}>
                        <div className="order-item-list__item__product_info">
                            <a  href={item.product.url}
                                className="order-item-list__item__product_info__title">{item.name}
                                {item.product.badgeText &&
                                    <span className={`badge badge__text-variant
                                        ${item.product.isPromotion ? 'badge__text-variant--secondary' : 'badge__text-variant--primary'}
                                        order-item-list__item__product_info__badge --no-print`}>{item.product.badgeText || 'Actie'}
                                    </span>
                                }
                            </a>
                            <span className="order-item-list__item__product_info__sku">{item.sku}</span>
                        </div>
                    </td>
                    {state.orderList!.showPriceAndStock && (
                        <>
                            <td className="order-item-list__item__column order-item-list__item__column--price --no-print">
                                <span className="order-item-list__item__price">
                                    {this.state.priceStockResult && formatPrice(this.state.priceStockResult.price)}
                                </span>
                            </td>
                            <td className="order-item-list__item__column --no-print">
                                <div className="order-item-list__item__stock">
                                    <span style={{
                                        color: this.state.priceStockResult?.stockColor,
                                    }}>{this.state.priceStockResult?.stockTitle}</span>
                                    {!state.orderList!.showPriceAndStock && (
                                        this.renderVendor(item)
                                    )}
                                </div>
                            </td>
                        </>
                    )}
                    <td className="order-item-list__item__column order-item-list__item__column--small">
                        <input
                            className="order-item-list__item--pref-quantity-input"
                            type="number"
                            id="prefQuantity"
                            value={this.state.prefQuantity}
                            min="0"
                            max="10000"
                            disabled={this.savingPreferredQuantity || this.state.deleting || !state.orderList!.editPreferredQuantities}
                            onFocus={evt => (evt.target as HTMLInputElement).select()}
                            onChange={(evt) => {
                                if (!isNaN(parseInt((evt.target as HTMLInputElement).value, 10))) {
                                    this.setState({
                                        prefQuantity: parseInt((evt.target as HTMLInputElement).value, 10),
                                    });
                                }
                            }}
                            onKeyPress={(evt) => {
                                if (!/^[0-9]*$/.test(evt.key)) {
                                    evt.preventDefault();
                                }
                            }}
                            onBlur={() => {
                                this.updatePreferredQuantity(this.state.prefQuantity);
                            }}/>
                    </td>
                    <td className="order-item-list__item__column order-item-list__item__column--medium">
                        <div
                            hidden={!item.product!.isBuyable}
                            className={`order-list-item-to-cart ${this.state.hasClickedQuantity ? 'order-list-item-to-cart--full-width' : ''}`}>
                            <button
                                className="order-list-item-to-cart__item order-list-item-to-cart__item--minus --no-print"
                                aria-label="Product toevoegen" aria-disabled="false" type="button"
                                onClick={() => {
                                    if (this.state.quantity > 0) {
                                        this.setState({
                                            quantity: this.state.quantity - increment,
                                        });
                                    }
                                    this.checkBeforeAddToCart(item.sku);
                                    this.setTimer();
                                }}>
                                <span className="icon order-list-item-to-cart__item__icon"
                                      dangerouslySetInnerHTML={{__html: require('../../images/icons8-minus-50.svg')}}/>
                            </button>
                            <input
                                className="order-list-item-to-cart__item order-list-item-to-cart__item--quantity-input"
                                type="number"
                                aria-label="hoeveelheid aanpassen"
                                name={`quantity[${item.sku}]`}
                                value={this.state.quantity}
                                step={item.product!.quantityIncrement}
                                min={item.product!.quantityIncrement}
                                max={10000}
                                disabled={this.savingQuantity || this.state.deleting}
                                tabIndex={this.props.rowNumber}
                                onFocus={evt => {
                                    evt.target.select();
                                    this.setState({
                                        hasClickedQuantity: true,
                                    });
                                }}
                                onBlur={() => this.setTimer()}
                                onKeyPress={(evt) => {
                                    if (!/^[0-9]*$/.test(evt.key)) {
                                        evt.preventDefault();
                                    }
                                }}
                                onChange={(evt) => {
                                    if (!isNaN(parseInt((evt.target as HTMLInputElement).value, 10))) {
                                        this.setState({
                                            quantity: parseInt((evt.target as HTMLInputElement).value, 10),
                                        });
                                        setTimeout(() => {
                                            this._requestAddToCart = true;
                                            this.checkBeforeAddToCart(item.sku);
                                        });
                                    }
                                }}
                                onClick={() => {
                                    this.setState({
                                        hasClickedQuantity: true,
                                    });
                                    clearTimeout(this.isQuantityClicked);
                                }}/>
                            <button
                                className="order-list-item-to-cart__item order-list-item-to-cart__item--plus --no-print"
                                disabled={this.savingQuantity || this.state.deleting}
                                aria-label="Product toevoegen" aria-disabled="false" type="button"
                                onClick={() => {
                                    this.setState({
                                        hasClickedQuantity: true,
                                        quantity: this.state.quantity + increment,
                                    });
                                    setTimeout(() => {
                                        this.checkBeforeAddToCart(item.sku);
                                        this.setTimer();
                                    });
                                }}>
                                <span className="icon order-list-item-to-cart__item__icon"
                                      dangerouslySetInnerHTML={{__html: plusIcon}}/>
                            </button>
                        </div>
                    </td>
                </tr> :
                <div
                    className={`order-item-list__item ${item.isNew ? ' order-item-list__item--new' : ''} ${this.state.loadingPriceStock ? 'order-item-list__item--loading-prices' : ''}`}
                    key={`line_` + item.id} data-id={item.id}>
                    {this.renderBackorderDialog()}
                    {this.state.showQuantityDialog && <QuantityDialog {...this.state.showQuantityDialog} />}
                    <div className="order-item-list__item__header">
                        {this.props.canChangeOrder &&
                        <div className="order-item-list__item__content__move"
                             data-sortable-handle={this.props.canChangeOrder}
                             onMouseEnter={(evt) => (evt.target as HTMLElement).parentElement!.classList.add('move-hover')}
                             onMouseLeave={(evt) => (evt.target as HTMLElement).parentElement!.classList.remove('move-hover')}>
                                    <span className="icon icon--text-size button__icon move-icon"
                                          dangerouslySetInnerHTML={{__html: moveIcon}}/>
                        </div>}
                        {item.product.badgeText &&
                        <span className={`badge badge__text-variant
                                        ${item.product.isPromotion ? 'badge__text-variant--secondary' : 'badge__text-variant--primary'}
                                        order-item-list__item__product_info__badge`}>{item.product.badgeText || 'Actie'}
                                    </span>
                        }
                        {item.product && <button type="button"
                                                 disabled={this.state.deleting}
                                                 className="button button--text button--warn order-item-list__item__content__remove"
                                                 onClick={() => this.setState({dialogView: DialogView.Delete})}>
                            <span className="icon button__icon" dangerouslySetInnerHTML={{__html: closeIcon}}/>
                        </button>}
                    </div>
                    <div className="order-item-list__item__content">
                        <div className="order-item-list__item__content__right">
                            {item.product.hasImage &&
                            <img className="order-item-list__item__image" {...item.product.imageAttributes} />}
                        </div>
                        <div className="order-item-list__item__content__info">
                            <div className="order-item-list__item__content__info__name">{item.name}</div>
                            <div style={{display: 'flex'}}>
                                {state.orderList!.showPriceAndStock && this.state.priceStockResult &&
                                    [
                                        <div className="order-item-list__item__content__price-stock__value">
                                            {this.state.priceStockResult && formatPrice(this.state.priceStockResult.price)}
                                        </div>,
                                        <span className="order-item-list__item__content__price-stock__stock"
                                              style={{color: this.state.priceStockResult.stockColor}}>
                                            {this.state.priceStockResult.stockTitle}
                                        </span>,
                                    ]
                                }
                            </div>
                        </div>
                    </div>
                    <div className="order-item-list__item__footer">
                        <div
                            className={`order-list-item-to-cart ${this.state.hasClickedQuantity ? 'order-list-item-to-cart--full-width' : ''}`}>
                            <p>Behoefte</p>
                            <div>
                                <button
                                    hidden={!state.orderList!.editPreferredQuantities}
                                    className="order-list-item-to-cart__item order-list-item-to-cart__item--minus"
                                    aria-label="Product toevoegen" aria-disabled="false" type="button"
                                    onClick={() => this.updatePreferredQuantity(item.preferredOrderQuantity - increment)}>
                                    <span className="icon order-list-item-to-cart__item__icon"
                                          dangerouslySetInnerHTML={{__html: require('../../images/icons8-minus-50.svg')}}/>
                                </button>
                                <input
                                    className={`order-list-item-to-cart__item order-item-list__item--pref-quantity-input ${!state.orderList!.editPreferredQuantities ? 'order-item-list__item--pref-quantity-input--left order-list-item-to-cart__item--disabled' : ''}`}
                                    type="number"
                                    aria-label="gewenste hoeveelheid aanpassen"
                                    name={`pref_quantity[${item.sku}]`}
                                    value={item.preferredOrderQuantity}
                                    step={item.product!.quantityIncrement}
                                    min={item.product!.quantityIncrement}
                                    max="10000"
                                    disabled={this.savingPreferredQuantity || this.state.deleting || !state.orderList!.editPreferredQuantities}
                                    tabIndex={this.props.rowNumber}
                                    onFocus={evt => (evt.target as HTMLInputElement).select()}
                                    onChange={evt => this.updatePreferredQuantity(
                                        parseInt((evt.target as HTMLInputElement).value, 10))}/>
                                <button
                                    hidden={!state.orderList!.editPreferredQuantities}
                                    className="order-list-item-to-cart__item order-list-item-to-cart__item--plus"
                                    disabled={this.savingQuantity || this.state.deleting}
                                    aria-label="Product toevoegen" aria-disabled="false" type="button"
                                    onClick={() => this.updatePreferredQuantity(item.preferredOrderQuantity + increment)}>
                                    <span className="icon order-list-item-to-cart__item__icon"
                                          dangerouslySetInnerHTML={{__html: require('../../images/icons8-plus-50.svg')}}/>
                                </button>
                            </div>
                        </div>
                        <div 
                            hidden={!item.product!.isBuyable}
                            className="order-list-item-to-cart">
                            <p>Aantal</p>
                            <div>
                                <button
                                    className="order-list-item-to-cart__item order-list-item-to-cart__item--minus"
                                    aria-label="Product toevoegen" aria-disabled="false" type="button"
                                    onClick={() => {
                                        if (this.state.quantity >= 1) {
                                            this.setState({
                                                quantity: this.state.quantity - this.productQuantityIncrement(),
                                            });
                                        }
                                        if (this.state.quantity > 1) {
                                            this.setTimer();
                                            this.checkBeforeAddToCart(item.sku);
                                        }
                                    }}>
                                    <span className="icon order-list-item-to-cart__item__icon"
                                          dangerouslySetInnerHTML={{__html: require('../../images/icons8-minus-50.svg')}}/>
                                </button>
                                <input
                                    className="order-list-item-to-cart__item order-list-item-to-cart__item--quantity-input"
                                    type="number"
                                    aria-label="hoeveelheid aanpassen"
                                    name={`quantity[${item.sku}]`}
                                    value={this.state.quantity}
                                    step={item.product!.quantityIncrement}
                                    min={item.product!.quantityIncrement}
                                    max={10000}
                                    disabled={this.savingQuantity || this.state.deleting}
                                    tabIndex={this.props.rowNumber}
                                    onFocus={evt => {
                                        (evt.target as HTMLInputElement).select();
                                        this.setState({
                                            hasClickedQuantity: true,
                                        });
                                    }}
                                    onBlur={() => this.setTimer()}
                                    onKeyPress={(evt) => {
                                        if (!/^[0-9]*$/.test(evt.key)) {
                                            evt.preventDefault();
                                        }
                                    }}
                                    onChange={(evt) => {
                                        if (!isNaN(parseInt((evt.target as HTMLInputElement).value, 10))) {
                                            this.setState({
                                                quantity: parseInt((evt.target as HTMLInputElement).value, 10),
                                            });
                                            setTimeout(() => {
                                                this._requestAddToCart = true;
                                                this.checkBeforeAddToCart(item.sku);
                                            });
                                        }
                                    }}
                                    onClick={() => {
                                        this.setState({
                                            hasClickedQuantity: true,
                                        });
                                        clearTimeout(this.isQuantityClicked);
                                    }}/>
                                <button
                                    className="order-list-item-to-cart__item order-list-item-to-cart__item--plus"
                                    disabled={this.savingQuantity || this.state.deleting}
                                    aria-label="Product toevoegen" aria-disabled="false" type="button"
                                    onClick={() => {
                                        this.setState({
                                            hasClickedQuantity: true,
                                            quantity: this.state.quantity + this.productQuantityIncrement(),
                                        });
                                        this.setTimer();
                                        this.checkBeforeAddToCart(item.sku);
                                    }}>
                                    <span className="icon order-list-item-to-cart__item__icon"
                                          dangerouslySetInnerHTML={{__html: require('../../images/icons8-plus-50.svg')}}/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }

    public get backorderItems() {
        return state.backorderArray.filter(x => x.sku === this.props.item.sku);
    }

    public get isProductInBackorder() {
        return this.backorderItems.length !== 0;
    }

    public get totalBackorderItemsQuantity() {
        return this.backorderItems.reduce((acc, prev) => prev.quantity + acc, 0);
    }

    public get backorderItemDate() {
        const mapped = this.backorderItems.map(x => formatDate(x.estimatedDeliveryDate));
        mapped.sort((a: string, b: string) => {
            return new Date(a).getTime() - new Date(b).getTime();
        });
        return mapped && mapped.length > 0 ? mapped[0] : null;
    }

    private async updatePreferredQuantity(preferredQuantity: number) {
        const line = this.props.item;
        const prevQuantity = line.preferredOrderQuantity;
        try {
            this.setState({savingPreferredQuantity: true});
            line.preferredOrderQuantity = preferredQuantity;
            await changeOrderListItemPreferredOrderQuantity(this.props.orderListId, line.id, preferredQuantity);
        } catch (error) {
            line.preferredOrderQuantity = prevQuantity;
            throw error;
        } finally {
            this.setState({savingPreferredQuantity: false});
        }
    }

    private productQuantityIncrement(): number {
        return (this.props.item.product.quantityIncrement != null && this.props.item.product.quantityIncrement > 0) ? this.props.item.product.quantityIncrement : 1;
    }

    protected get savingQuantity() {
        return this.state.savingQuantity;
    }
    protected get savingPreferredQuantity() {
        return this.state.savingPreferredQuantity;
    }

    private setTimer() {
        if (this.isQuantityClicked) {
            clearTimeout(this.isQuantityClicked);
        }

        this.isQuantityClicked = window.setTimeout(() => {
            this.isQuantityClicked = undefined;
            this.setState({
                hasClickedQuantity: false,
            });
        }, 2500);
    }

    private async checkBeforeAddToCart(itemSku: string) {

        setTimeout(async () => {
            const item = state.cart.items.find(x => x.sku === itemSku);
            if (this.state.quantity === 0) {
                this.setState({dialogView: DialogView.None});
                if (item) {
                    await state.cart.remove_line_item(item.id);
                    state.notifications.push(`${this.props.item.name} is verwijderd uit uw winkelwagen.`);
                }
                return;
            }
            if (this.state.quantity > 10000) {
                state.notifications.push(`U kunt niet meer dan 10000 van dit product toevoegen aan de winkelwagen`);
                this.setState({
                    quantity: 10000,
                });
                this.checkBeforeAddToCart(itemSku);
            } else if (this.state.quantity < 1) {
                state.notifications.push(`U kunt geen ${this.state.quantity} producten toevoegen aan de winkelwagen`);
                this.setState({
                    quantity: 0,
                });
            } else if (this.state.quantity % this.props.item.product.quantityIncrement > 0) {
                this.setState({dialogView: DialogView.QuantityIncrement});
            } else if (this.isProductInBackorder) {
                this.setState({dialogView: DialogView.InBackorder});
            } else {
                this.setState({dialogView: DialogView.None});

                await orderListQueue.add(async () => {

                    const itemCheck = state.cart.items.find(x => x.sku === itemSku);
                    if (itemCheck) {
                        this.setState({dialogView: DialogView.None});
                        if (this.quantityTimeOut) {
                            clearTimeout(this.quantityTimeOut);
                        }
                        const quantity = this.state.quantity;
                        if (quantity !== this.state.quantity) {
                            // If the state isn't actual
                            return;
                        }
                        this.quantityTimeOut = undefined;
                        await state.cart.update_quantity_by_sku(itemSku, this.state.quantity);
                        state.notifications.push(`${this.props.item.name} is bijgewerkt in uw winkelwagen.`);
                    } else {
                        await this.addToCart();
                    }

                });

            }
        });
    }

    private async addToCart() {
        // delay to when saving quantity is done
        if (this.savingQuantity) {
            this._requestAddToCart = true;
            return;
        }
        const quantity = this.state.quantity;
        if (quantity !== this.state.quantity) {
            // If the state isn't actual
            return;
        }
        this.quantityTimeOut = undefined;
        this.setState({addingToCart: true});
        try {
            await state.cart.add_line_item(this.props.item.product.id, this.state.quantity, false);
            if (quantity !== this.state.quantity) {
                // If the state isn't actual
                return;
            }
            state.notifications.push(`${this.props.item.name} is toegevoegd aan uw winkelwagen.`);
        } finally {
            this.setState({addingToCart: false});
        }
    }

    private async removeItem(itemId: string) {
        this.setState({deleting: false});
        try {
            await this.props.onRemove(itemId);
        } finally {
            this.setState({deleting: true});
        }
    }

    private renderBackorderDialog() {
        switch (this.state.dialogView) {
            case DialogView.Delete:
                return <Dialog<DialogResult>
                    onResult={this.onDeleteDialogResult}
                    closable={false}
                    title="Artikel verwijderen">
                    <p className="dialog-container__dialog__text">
                        Weet u zeker dat u dit artikel wilt verwijderen?
                    </p>
                    <div className="button-group button-group--compact">
                        <button
                            disabled={this.state.loading}
                            className="button button--primary button-group__button button--float"
                            data-action={DialogResult.Ok}>Verwijderen
                        </button>
                        <button
                            disabled={this.state.loading}
                            className="button button--text-primary button-group__button"
                            data-action={DialogResult.Cancel}>Annuleren
                        </button>
                    </div>
                </Dialog>;
            case DialogView.InBackorder:
                return (
                    <Dialog<DialogResult>
                        onResult={this.onDialogResult}
                        closable={false}
                        title="Dit Artikel staat reeds in backorder">
                        <p className="dialog-container__dialog__text">
                            Let op! U heeft van dit product reeds {this.totalBackorderItemsQuantity} bij ons in
                            bestelling staan. {this.backorderItems.length > 1
                            ? `(verwachte leverdatum ${this.backorderItemDate})`
                            : ''}
                            <br/>Weet u zeker dat u dit artikel wilt toevoegen aan uw winkelwagen?
                        </p>
                        <div className="button-group button-group--compact">
                            <button
                                disabled={this.state.loading}
                                className="button button--primary button-group__button button--float"
                                data-action={DialogResult.Ok}>Artikel toevoegen
                            </button>
                            <button
                                disabled={this.state.loading}
                                className="button button--text-primary button-group__button"
                                data-action={DialogResult.Cancel}>Annuleren
                            </button>
                        </div>
                    </Dialog>
                );
            case DialogView.QuantityIncrement:
                return (
                    <QuantityDialog enteredValue={!Number.isNaN(this.state.quantity) ? this.state.quantity : 0}
                                    productName={this.props.item.name}
                                    quantityIncrement={this.props.item.product.quantityIncrement}
                                    onValueChosen={this.onQuantityChanged}/>
                );
        }
    }

    private onQuantityChanged = async (chosenNumber: number | false) => {
        await orderListQueue.add(async () => {
            const item = state.cart.items.find(x => x.sku === this.props.item.sku);
            if (chosenNumber === false) {
                if (item && item.quantity >= 1) {
                    this.setState({
                        dialogView: DialogView.None,
                        quantity: item.quantity,
                    });
                    this.setState({quantity: item.quantity});
                } else {
                    this.setState({
                        dialogView: DialogView.None,
                        quantity: 0,
                    });
                }
                return;
            }

            this.setState({
                quantity: chosenNumber,
                dialogView: DialogView.None,
            });
            await this.checkBeforeAddToCart(this.props.item.sku);
        });
    }

    private onDeleteDialogResult = async (evt: DialogEventArgs<DialogResult>) => {
        const item = state.cart.items.find(x => x.sku === this.props.item.sku);
        switch (evt.result) {
            case DialogResult.Cancel:
                this.setState({dialogView: DialogView.None});
                break;
            case DialogResult.Ok:
                this.removeItem(item!.id);
                this.setState({dialogView: DialogView.None});

        }
    }

    private onDialogResult = async (evt: DialogEventArgs<DialogResult>) => {
        const item = state.cart.items.find(x => x.sku === this.props.item.sku);
        switch (evt.result) {
            case DialogResult.Ok:
                if (item) {
                    await state.cart.update_quantity_by_sku(item.sku, this.state.quantity);
                    state.notifications.push(`${this.props.item.name} is bijgewerkt in uw winkelwagen.`);
                } else {
                    await this.addToCart();
                    state.notifications.push(`${this.props.item.name} is toegevoegd aan uw winkelwagen.`);
                }
                break;
            case DialogResult.Cancel:
                if (item) {
                    this.setState({quantity: item.quantity});
                }
                break;
        }
        this.setTimer();
        this.setState({dialogView: DialogView.None});
    }
}
