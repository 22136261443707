/* tslint:disable:max-line-length */
import * as React from 'react';
import {FilterListGroupTag} from './filter-list-group-tag';
import {renderSvg, sortDownIcon, sortUpIcon} from '../../images/images';
import {FilterGroup, FilterTag} from './base-product-filters';

interface Props {
  group: FilterGroup;
  collapsedLength: number;
  collapsed: boolean;
  hidden: boolean;
  updateFromUrl: (newUrl?: string) => void;
  createUrl: (filterGroup: FilterGroup, tag: FilterTag) => string;
}

interface State {
  hidden: boolean;
  collapsed: boolean;
  searchTerm: string;
}

export class FilterListGroup extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      hidden: this.props.hidden,
      collapsed: this.props.collapsed,
      searchTerm: '',
    };
  }

  public render() {
    return (
      <div className="filter-list__content">
        <div className="filter-list__content__header" onClick={() => this.toggleHidden()}>
          <div className="filter-list__content__header__title">{this.props.group.label}</div>
          <div className="filter-list__content__header__icon">
            {renderSvg(this.state.hidden ? sortDownIcon : sortUpIcon, {
              className: 'filter-list__content__header__icon',
            })}
          </div>
        </div>
        <div
          className={`filter-list__content__search ${this.state.collapsed || this.state.hidden ? 'filter-list__content__search--hidden' : ''}`}>
          <input
              key={`search__input__${this.props.group.label.toLowerCase()}`}
              name={`search__input__${this.props.group.label.toLowerCase()}`}
              onChange={data => this.setState({searchTerm: data.target.value})}
              className={`input-wrap__input input-wrap__input--small input-wrap__input--bordered`}
              id={`search__input__${this.props.group.label.toLowerCase()}`}
              type="text"
              placeholder="Zoeken..."/>
        </div>

        <div
            className={`filter-list__content__tags ${this.state.hidden ? 'filter-list__content__tags--hidden' : ''}`}>
          {this.props.group.items
              .filter((x: FilterTag) => this.isIncluded(x))?.sort((a, b) => {
                const aIsApplied = a.isApplied ? 1 : 0;
                const bIsApplied = b.isApplied ? 1 : 0;
                return bIsApplied - aIsApplied; })
              .map((tag, index) => {
                return <FilterListGroupTag
                    updateFromUrl={this.props.updateFromUrl}
                    checked={tag.isApplied}
                    createUrl={() => this.props.createUrl(this.props.group, tag)}
                    hidden={this.state.collapsed && index >= this.props.collapsedLength}
                    key={`${this.props.group.field.toLowerCase()}-${index}`}
                    tag={tag}/>;
              })}
        </div>

        {this.props.group.items.length >= this.props.collapsedLength && !this.state.hidden ? <div
            className={`filter-list__content__show_all`}
            onClick={() => this.toggleShow()}>
          {this.state.collapsed ?
              <div className="filter-list__content__show_all__title">Toon alles</div> :
              <div className="filter-list__content__show_all__title">Toon minder</div>
          }
          <div className="filter-list__content__show_all__icon">
            {renderSvg(this.state.collapsed ? sortDownIcon : sortUpIcon, {
              className: 'filter-list__content__show_all__icon',
            })}
          </div>
        </div> : null}
      </div>
    );
  }

  private isIncluded(tag: FilterTag) {
    return tag.label.toLowerCase().includes(this.state.searchTerm.toLowerCase());
  }

  private toggleHidden() {
    this.setState({hidden: !this.state.hidden});
  }

  private toggleShow() {
    this.setState({collapsed: !this.state.collapsed});
  }
}
