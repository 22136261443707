/* tslint:disable:no-var-requires max-line-length */
import {observer} from 'mobx-react';
import {searchCategories, searchProducts, suggest} from '../api/catalog';
import {state} from '../state';
import {$hasParent} from '../../../components/src/utils/dom.utils';
import {
    ProductSearchResult,
    SearchCriteria,
    SearchResultCategory,
    SuggestCriteria,
    SuggestSearchResult,
} from '../api/interfaces';
import {MediaQuery} from '../media-queries';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {debounce} from 'lodash';
interface Props {
    query?: string;
    resultCount?: number;
}

interface State {
    query: string;
    loadingProducts: boolean;
    loadingCategories: boolean;
    loadingSuggestions: boolean;
    showResults: boolean;
    suggestResult: SuggestSearchResult | null;
    productsResult: ProductSearchResult | null;
    categoriesResult: SearchResultCategory[] | null;
    resultCount: number;
}

@observer
export class ProductSearch extends React.Component<Props, State> {
    public setSearchTerm = debounce(query => {
        this.onChange(query);
    }, 500);

    private formElement: HTMLFormElement | null = null;
    private inputElement: HTMLInputElement | null = null;
    private inputWrapElement: HTMLDivElement | null = null;
    private resultElement: HTMLDivElement | null = null;

    public constructor(props: Props) {
        super(props);
        this.state = {
            query: this.props ? this.props.query || '' : '',
            loadingProducts: false,
            loadingSuggestions: false,
            loadingCategories: false,
            showResults: false,
            suggestResult: null,
            productsResult: null,
            categoriesResult: null,
            resultCount: 25,
        };
    }

    public componentDidMount(): void {
        if (this.props.query) {
            this.setState({
                query: this.props.query,
                showResults: false,
            });
        }
    }

    public componentDidUpdate(): void {
        document.addEventListener('click', this.onDocumentClick, true);
        const inputBoundingRect = this.inputWrapElement!.getBoundingClientRect();
        if (this.resultElement) {
            if (state.mediaQueries.get(MediaQuery.Small)) {
                this.resultElement.style.left = '-' + (inputBoundingRect.left) + 'px';
            }
            this.resultElement.style.top = this.inputWrapElement!.offsetHeight + 'px';
        }
    }

    public componentWillUnmount(): void {
        document.removeEventListener('click', this.onDocumentClick, true);
    }




    public render() {
        return (
            <form className="search"
                  ref={el => this.formElement = el}
                  action={state.urls.search}
                  autoComplete="new-password"
                  onSubmit={e => {
                      if (!this.state.query) {
                          e.preventDefault();
                      } else {
                          this.setState({
                              loadingProducts: true,
                              showResults: false,
                          });
                      }
                  }}>
                <input type="hidden" autoComplete="off" name="type" value="product"/>
                <div
                    className={`input-wrap input-wrap--without-padding${this.state.loadingProducts ? ' input-wrap--loading' : ''}`}
                    ref={el => this.inputWrapElement = el}>
                    <input className={`input-wrap__input input-wrap__input--small search__input
                        ${state.mediaQueries.get(MediaQuery.Small) ? 'input-wrap__input--full-width' : ''}
                        ${this.state.query ? '' : 'search__input--search'}`}
                           ref={el => this.inputElement = el}
                           id="search__input"
                           type="text"
                           autoComplete="off"
                           name="q"
                           defaultValue={this.state.query}
                           onFocus={() => this.onInputFocus()}
                           onKeyUp={e => this.setSearchTerm(e.currentTarget.value) }
                           placeholder="Zoeken..."/>
                    {this.state.query ? <span className="search__clear-input" onClick={() => this.clearInput()}/> : ''}
                </div>
                {this.state.showResults && (
                    <div className="search__results"
                         ref={el => this.resultElement = el}>
                        <div className="content-wrap">
                            <div
                                className={`search__results__container ${state.mediaQueries.get(MediaQuery.Small) ? '' : 'content-wrap'}`}>
                                <h5 className="search__results__title">Zoekresultaten</h5>
                                {!this.state.loadingSuggestions ? [
                                    this.state.suggestResult !== null && this.state.suggestResult.suggestions.length !== 0 &&
                                    this.state.suggestResult.suggestions.map((suggestion, i) => (
                                        <a href="#"
                                           key={`${i}__${suggestion}`}
                                           className="search__results__container__result">
                                            <div className="search__results__container__result__info">
                                                <p className="search__results__container__suggestion"
                                                   onClick={(evt) => this.search(evt, suggestion)}
                                                   dangerouslySetInnerHTML={{__html: `${suggestion}`}}/>
                                            </div>
                                        </a>
                                    )),
                                    this.state.productsResult !== null && this.state.productsResult.items.length > 0 && [
                                        this.state.productsResult.items.map((product, i) => (
                                            <a href="#"
                                               key={`${i}__${product.id}`}
                                               className="search__results__container__result">
                                                <div className="search__results__container__result__info">
                                                    <p className="search__results__container__suggestion"
                                                       onClick={(evt) => this.search(evt, product.name)}
                                                       dangerouslySetInnerHTML={{__html: `${this.boldString(product.name, this.state.query)}`}}
                                                    />
                                                </div>
                                            </a>
                                        )),
                                        <div className="search__results__container__footer">
                                            <button type="submit" className="button button--primary button--float">
                                                Alle zoekresultaten
                                            </button>
                                        </div>,
                                    ],
                                    this.state.categoriesResult !== null && this.state.categoriesResult.length > 0 && [
                                        <h5 className="search__results__title">Categorieen</h5>,
                                        <div className="search__results__container__footer button-group button-group--compact">
                                            {this.state.categoriesResult.map((cat, i) => (
                                                <a href={cat.seoPath}
                                                   key={`${i}__${cat.name}`}
                                                   className="button button-group__button button--primary button--float">
                                                    {cat.name}
                                                </a>
                                            ))}
                                        </div>],
                                ] : [<div className="search__results__container__placeholder"/>]}
                                {this.state.productsResult?.items.length === 0 &&
                                 this.state.suggestResult?.suggestions.length === 0 &&
                                 !this.state.loadingSuggestions &&
                                <div key="no_results_found"
                                   className="search__results__container__result">
                                    <div className="search__results__container__result__info">
                                        <p className="search__results__container__suggestion">Geen resultaten gevonden</p>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                )}
            </form>
        );
    }

    protected onInputFocus() {
        document.documentElement.classList.add('--search-expanded');
        if (this.state.query) {
            if (!this.state.showResults) {
                this.setState({
                    showResults: true,
                });
                this.onChange(this.state.query);
            }
        } else {
            this.setState({
                showResults: false,
            });
        }
    }

    protected onDocumentClick = (evt: MouseEvent) => {
        if (!$hasParent(evt.target as HTMLElement, this.formElement!) &&
            !$hasParent(evt.target as HTMLElement, '.dialog-container')) {
            this.setState({showResults: false});
            document.documentElement.classList.remove('--search-expanded');
        }
    }

    private clearInput() {
        const query = this.inputElement!.value;
        if (query) {
            this.setState({
                query: '',
                showResults: false,
            });
            this.inputElement!.value = '';
        }
        return;
    }

    private boldString(str: string, find: string) {
        const reg = new RegExp(find, 'gi');
        str = str.replace(/\s\s+/g, ' ');
        return str.replace(reg, '<b>$&</b>');
    }

    private search(evt: React.MouseEvent<any>, suggestion: string) {
        evt.preventDefault();
        let suggestionString = this.stripHtml(suggestion);
        suggestionString = suggestionString.replace(/\s+/g, ' ').trim();
        window.location.href = state.urls.search + '?type=product&q=' + encodeURIComponent(suggestionString);
    }

    private stripHtml(html: string) {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || '';
    }

    private async onChange(query: string) {
        if (state.mediaQueries.get(MediaQuery.Small)) {
            document.documentElement.classList.add('--search-expanded');
        }

        if (query) {
            this.setState({
                query,
                showResults: true,
                loadingProducts: true,
                loadingSuggestions: true,
                loadingCategories: true,
            });
            this.searchSuggestions();
            this.searchProducts();
        } else {
            this.setState({
                query: '',
                showResults: false,
            });
        }
    }

    private async searchSuggestions() {
        const suggestCriteria: SuggestCriteria = {
            keyword: this.state.query,
            isFuzzySearch: true,
            highlight: true,
            top: 5,
        };
        const suggestResult = await suggest(suggestCriteria);
        this.setState({suggestResult, loadingSuggestions: false});
    }

    private async searchProducts() {
        const searchCriteria: SearchCriteria = {
            sort_by: 'priority:desc;search.score():desc;lastperiodordercount:desc;name:asc;',
            keyword: this.state.query,
            isFuzzySearch: false,
            pageSize: this.state.resultCount,
            start: 0,
            includeAggregations: [],
        };
        const productsResult = await searchProducts(searchCriteria);
        if (productsResult) {
            if (productsResult.items.length > 10) {
                productsResult.items = productsResult.items.slice(0, 10);
            }
            this.setState({productsResult, loadingProducts: false});
            this.searchCategories(productsResult.categoryIds);
        }
    }

    private async searchCategories(categoryIds: string[]) {
        const categoriesResult = await searchCategories(categoryIds);
        if (categoriesResult) {
            this.setState({categoriesResult, loadingCategories: false});
        }
    }

}

export function renderProductSearch(element: HTMLElement) {
    return ReactDOM.render(<ProductSearch query={element.getAttribute('query') as string}/>,
        element);
}
