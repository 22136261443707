import { observer } from 'mobx-react';
import { state } from '../state';
import { Dialog } from './dialog';
import { OrderListSelection } from './order-list-selection';
import {
    addItemToOrderList,
    removeItemFromOrderList,
} from '../api/de-klok';
import {
    AddItemToOrderListBody,
    OrderList,
} from '../api/interfaces';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface Props {
    productId: string;
    orderListIds?: string[] | null;
}

interface State {
    loading: boolean;
    showDialog: boolean;
}

@observer
export class AddToList extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {
            showDialog: false,
            loading: false,
        };
        state.orderList!.productLists.set(props.productId, props.orderListIds || []);
    }

    public get orderListIds(): string[] {
        return state.orderList!.productLists.get(this.props.productId)!;
    }

    public set orderListIds(lists: string[]) {
        state.orderList!.productLists.set(this.props.productId, lists);
    }

    public render() {
        return (
            <div onClick={(evt) => {
                this.setState({ showDialog: true });
                evt.preventDefault();
                evt.stopPropagation();
            }}>
                 <span className={`order-lists__icon ${this.orderListIds.length > 0
                     ? 'order-lists__icon--active' : ''}`}
                       style={{ cursor: 'pointer' }}
                       dangerouslySetInnerHTML={{
                           __html: require('../../images/icons8-heart-outline.svg'),
                       }}/>
                {this.state.showDialog && (
                    <Dialog position="bottom"
                            onResult={() => this.setState({ showDialog: false })} title="Toevoegen aan een bestellijst">
                        <OrderListSelection
                            disabled={this.state.loading}
                            showHearts={true}
                            orderListIds={this.orderListIds}
                            onSelected={(list, isInList) => this.onListSelected(list, isInList)}/>
                    </Dialog>
                )}
            </div>
        );
    }

    private async onListSelected(orderList: OrderList, isInList: boolean) {
        const body: AddItemToOrderListBody = { productId: this.props.productId };
        if (isInList) {
            this.setState({ loading: true });
            await removeItemFromOrderList(orderList.id, this.props.productId);
            this.orderListIds = this.orderListIds.filter(x => x !== orderList.id);
            this.setState({
                showDialog: false,
                loading: false,
            });
            state.notifications.push(`Het product is verwijderd van uw lijst`);
        } else {
            this.setState({ loading: true });
            await addItemToOrderList(orderList.id, body);
            this.orderListIds = this.orderListIds.concat([orderList.id]);
            this.setState({
                showDialog: false,
                loading: false,
            });
            state.notifications.push(`Het product is toegevoegd aan uw lijst`);
        }
    }
}

export function renderAddToList(element: HTMLElement) {
    return ReactDOM.render(
        <AddToList
            orderListIds={JSON.parse(element.getAttribute('data-product-orderlist-ids') as string)}
            productId={element.getAttribute('data-product-id') as string}/>, element!);
}
