/* tslint:disable:no-var-requires max-line-length */
import {observer} from 'mobx-react';
import {state} from '../state';
import {Urls,} from '../models/interfaces';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface Props {
    urls: Urls;
}

interface State {
    selection: Selection;
}

@observer
export class MenuDropdown extends React.Component<Props, State> {
    private element: HTMLDivElement | null = null;

    public constructor(props: Props) {
        super(props);
    }

    public render() {
        return (
            <div className="account-dropdown" ref={el => this.element = el}>
                <div className="button-group account-dropdown__buttons button-group--fit-2">
                    <a href={this.props.urls.ourAssortment}
                       className="button-group__button button button--secondary button--float">
                        Ons Assortiment
                    </a>
                    <a href={this.props.urls.promotions}
                       className="button-group__button button button--secondary button--float">
                        Acties
                    </a>
                    <a href={this.props.urls.newsAndInspiration}
                       className="button-group__button button button--secondary button--float">
                        Nieuws & Inspiratie
                    </a>
                    <a href={state.urls.customerService}
                       className="button-group__button button button--secondary button--float">
                        Klantenservice
                    </a>
                </div>
            </div>
        );
    }
}

export function renderMenuDropdown(element: HTMLElement) {
    return ReactDOM.render(
        <MenuDropdown
            urls={state.urls}
        />, element!);
}
