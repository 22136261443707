/* tslint:disable:no-var-requires max-line-length */
import {observer} from 'mobx-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {state} from '../state';
import {MediaQuery} from '../media-queries';
import {BackorderItem} from '../models/interfaces';
import {getSapOrderTypeIcon, getSapOrderTypeLabel} from '../enums/sapOrderType';
import {formatDate, formatEuroPrice} from '../utils/format';
import {getSapOrderStatusLabel, getSapOrderStatusLabelColor} from '../enums/sapInvoiceOrderStatus';

interface Props {
    mediaQueries: Map<MediaQuery, boolean>;
}

interface State {
    loading: boolean;
}


@observer
export class BackOrders extends React.Component<Props, State> {

    protected chevron = require('../../images/icons8-collapse-arrow-50.svg');

    public componentDidMount(): void {
        if (state.backorderArray) {
            state.loadBackorderItems(state.current_organization!);
        }
    }

    public render() {
        if (!state.backorderArray) {
            return this.renderNoItems();
        }
        return <div className={`page ${this.props.mediaQueries.get(MediaQuery.MediumUp) ? 'row' : 'row row--dark'} row--no-padding-top `}>
            <div className="content-wrap order-overview">
                {this.renderHeader()}
                {this.props.mediaQueries.get(MediaQuery.MediumUp) ? this.renderList() : this.renderMobileList()}
            </div>
        </div>;
    }

    public renderList() {
        return <table className="order-overview__table">
            <thead>
            <tr>
                <th>Ordernummer</th>
                <th>Artikelnummer</th>
                <th>Naam</th>
                <th>Aantal</th>
                <th>Verwachte leverdatum</th>
            </tr>
            </thead>
            <tbody>
            {state.backorderArray.length > 0 ?
                state.backorderArray.map((item) => this.renderItem(item)) : this.renderNoItems()}
            </tbody>
        </table>;
    }

    public renderItem(item: BackorderItem) {
        return <tr key={item.orderId + '_' + item.sku}>
            <td><a href="" onClick={(evt) => {
                evt.preventDefault();
                this.onItemSelect(item.orderId);
            }}>{item.orderId}</a></td>
            <td>{item.sku}</td>
            <td>{item.name}</td>
            <td>{item.quantity}</td>
            <td>{formatDate(item.estimatedDeliveryDate, 'DD/MM/YYYY')}</td>
        </tr>;
    }

    public renderMobileList() {
        return state.backorderArray.length > 0 ?
            <div className="order-overview__list">{state.backorderArray.map((item, i) =>
                <div key={i} className="order-overview__list__order" onClick={(evt) => {
                    evt.preventDefault();
                    this.onItemSelect(item.orderId);
                }}>
                    <div className="order-overview__list__order__info">
                        <span>{item.name}</span>
                        <span>{item.sku}</span>
                        <span>{formatDate(item.estimatedDeliveryDate, 'll')}</span>
                    </div>
                    <div className="order-overview__list__order__total">
                        <span className="order-overview__list__order__total order-overview__list__order__total--price">
                            {item.quantity}x</span>
                        <span className="icon order-overview__list__order__total__icon"
                              dangerouslySetInnerHTML={{__html: this.chevron}}/>
                    </div>
                </div>)}
            </div> : this.renderNoItems();
    }

    public async onItemSelect(orderId: string) {
        location.href = state.urls.orderDetail.replace(':id', orderId);
    }

    protected renderNoItems() {
        return <div className="content-wrap">
            <div className="page-header">
                <div className="page-header__subtitle">Er staan geen artikelen in backorder.</div>
            </div>
        </div>;
    }

    protected renderHeader() {
        return <div className="page-header">
            {state.backorderArray.length > 0 &&
            <div className="page-header__subtitle">
                Er {state.backorderArray.length === 1 ? 'staat ' : 'staan '}
                {state.backorderArray.length}
                {state.backorderArray.length === 1 ? ' artikel' : ' artikelen'} in backorder
            </div>}
        </div>;
    }
}

export function renderBackOrders(element: HTMLElement) {
    return ReactDOM.render(<BackOrders mediaQueries={state.mediaQueries}/>, element);
}
