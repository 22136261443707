export enum Permission {
    Impersonation = 'deklok:storefront:impersonation',
    UserManagement = 'deklok:storefront:user_management',
    Price = 'deklok:storefront:price',
    OrderHistory = 'deklok:storefront:order_history',
    InvoiceHistory = 'deklok:storefront:invoice_history',
    NetPresentValue = 'deklok:storefront:npv',
    NetPresentValueVerify = 'deklok:storefront:npv:verify',
    SalesGroupManage = 'deklok:storefront:sales_group:manage',
}
