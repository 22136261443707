export enum NpvSponsoringType {
    Yearly = 'Yearly',
    OneTime = 'OneTime',
}

export function getLabel(val: NpvSponsoringType): string {
    switch (val) {
        case NpvSponsoringType.Yearly:
            return 'Per jaar';
        case NpvSponsoringType.OneTime:
            return 'Eenmalig';
    }
}

export function getOptions(): NpvSponsoringType[] {
    return [NpvSponsoringType.Yearly, NpvSponsoringType.OneTime];
}
