/* tslint:disable:max-line-length */
import * as React from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {NpvFormStep, NpvStepProps} from './form-step';
import {NpvOrganization} from '../../../models/interfaces';
import {getNpvOrganization} from '../../../api/npv';
import {NpvAgreement} from './npv-agreement';

@observer
export class NpvForm5 extends NpvFormStep {
    @observable
    private loading = true;

    @observable
    private organization: NpvOrganization | null = null;

    constructor(props: NpvStepProps) {
        super(props);
    }

    public async componentDidMount() {
        try {
            this.organization = await getNpvOrganization(this.props.npv.id!);
        } finally {
            this.loading = false;
        }
    }

    public render() {

        if (this.loading) {
            return (
                <div className="npv content-wrap">
                    <div className="notice">Bezig met laden...</div>
                </div>
            );
        }

        return (
            <NpvAgreement id={this.props.npv.id!} />
        );
    }
}
