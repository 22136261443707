export enum NpvStatus {
    Preparation = 'Preparation',
    VerificationRequested = 'VerificationRequested',
    Rejected = 'Rejected',
    ReadyForSignature = 'ReadyForSignature',
    Signed = 'Signed',
}

export function getLabel(val: NpvStatus): string {
    switch (val) {
        case NpvStatus.Preparation:
            return 'Voorbereiding';
        case NpvStatus.VerificationRequested:
            return 'Ingediend ter controle';
        case NpvStatus.Rejected:
            return 'Afgekeurd';
        case NpvStatus.ReadyForSignature:
            return 'Klaar voor ondertekenen';
        case NpvStatus.Signed:
            return 'Ondertekend';
    }
}
