/* tslint:disable:no-var-requires max-line-length */
import {state} from '../state';
import {Address, Customer, Organization} from '../models/interfaces';
import {AddressType} from '../enums/addressType';
import {ChangePasswordModel, ChangePasswordResult, UpdateUserModel} from '../api/interfaces';
import {changePassword, updateUser} from '../api/user-management';
import {observer} from 'mobx-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface Props {
    customer: Customer;
    currentOrganization: Organization | null;
}

interface State {
    editDetails: boolean;
    editPassword: boolean;
    loading: boolean;
    addresses: Address[] | null;
    changePasswordResult: ChangePasswordResult | null;
    password: string;
    newPassword: string;
    newPasswordConfirm: string;
    phoneNumber: string | null;
    userName: string | null;
    firstName: string | null;
    lastName: string | null;
    organizations: Organization[] | null;
    preferredOrganizationId: string | null;
}

const chevronLeft = require('../../images/icons8-chevron-left-50.svg');

@observer
export class AccountDetails extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {
            loading: state.customer!.loading,
            editDetails: false,
            editPassword: false,
            addresses: null,
            changePasswordResult: null,
            password: '',
            newPassword: '',
            newPasswordConfirm: '',
            phoneNumber: state.customer!.phone_number,
            userName: state.customer!.user_name,
            firstName: state.customer!.first_name,
            lastName: state.customer!.last_name,
            organizations: state.customer!.organizations,
            preferredOrganizationId: state.customer?.preferred_organization || '',
        };
    }

    public componentDidMount(): void {
        this.populateAddresses();
    }

    public render() {
        if (!this.props.customer) {
            throw new Error(`Cannot render customer details: 'state.customer' not initialized.`);
        }
        const customer = this.props.customer;
        return (
            <div>
                <div className="page row row--no-padding-top row--rounded-border">
                    <div className="account-details content-wrap">
                        {this.state.editDetails ? this.renderEditDetailsRow(customer) : this.renderPersonalDetailsRow(customer)}
                    </div>
                </div>
                <div className="page row row--no-padding-top row--dark">
                    <div className="content-wrap content-wrap--small">
                        <div className="account-details__row">
                            <h2 className="account-details__row__title">Contact Gegevens</h2>
                            <div className="account-details__row__items">
                                <div className="account-details__column account-details__column--one-second">
                                    <span className="account-details__column__title">Uw toegewezen vestiging </span>
                                    {state.business_location && <ul className="address__list">
                                        <li className="address__list--company">
                                            <strong>{state.business_location.name}</strong>
                                        </li>
                                        <li>{state.business_location.address}</li>
                                        <li>{state.business_location.postal_code} {state.business_location.city.toUpperCase()}</li>
                                        <li><a
                                            href={`mailto:${state.business_location.email}`}>{state.business_location.email}</a>
                                        </li>
                                        <li>{state.business_location.phone}</li>
                                    </ul>}
                                </div>
                                <div className="account-details__column account-details__column--one-second">
                                    <span className="account-details__column__title">Uw vertegenwoordiger</span>
                                    <ul className="address__list">
                                        <li className="address__list--company">
                                            <strong>
                                                <a href={state.urls.ourTeam}>
                                                    {state.CurrentOrganization!.account_manager}
                                                </a>
                                            </strong>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderEditDetailsRow(customer: Customer) {
        if (!customer) {
            return null;
        }
        return (
            <>
                <form className="account-details__form"
                      onSubmit={(evt) => {
                          this.updateUser();
                          evt.preventDefault();
                      }}>
                    <div className="account-details__row account-details__row--edit">
                        <label htmlFor="customer_first_name"
                               className="form-row__label label--required">Voornaam</label>
                        <input type="text"
                               required
                               maxLength={30}
                               className="input-wrap__input input-wrap__input--bordered"
                               value={this.state.firstName!}
                               onChange={evt => {
                                   this.setState({firstName: evt.target.value});
                               }}
                               id="customer_first_name"/>
                    </div>
                    <div className="account-details__row account-details__row--edit">
                        <label htmlFor="customer_last_name"
                               className="form-row__label label--required">Achternaam</label>
                        <input type="text"
                               required
                               className="input-wrap__input input-wrap__input--bordered"
                               value={this.state.lastName!}
                               maxLength={30}
                               onChange={evt => {
                                   this.setState({lastName: evt.target.value});
                               }}
                               id="customer_last_name"/>
                    </div>
                    <div className="account-details__row account-details__row--edit">
                        <label htmlFor="customer_email_address"
                               className="form-row__label label--required">E-mailadres</label>
                        <input type="email"
                               pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                               title="Geef een correct emailadres op"
                               required
                               className="input-wrap__input input-wrap__input--bordered"
                               value={this.state.userName!}
                               onChange={evt => {
                                   this.setState({userName: evt.target.value});
                               }}
                               id="customer_email_address"/>
                    </div>
                    <div className="account-details__row account-details__row--edit">
                        <label htmlFor="customer_telephone_number"
                               className="form-row__label label--required">Telefoonnummer</label>
                        <input type="tel"
                               pattern="\+?[0-9]+"
                               title="Voer een correct telefoonnummer in"
                               required
                               className="input-wrap__input input-wrap__input--bordered"
                               value={this.state.phoneNumber!}
                               onChange={evt => {
                                   this.setState({phoneNumber: evt.target.value});
                               }}
                               id="customer_telephone_number"/>
                    </div>
                    <div className="account-details__row account-details__row--edit">
                        <label htmlFor="customer_preferred_organization"
                               className="form-row__label label--required">Standaard Afzetpunt</label>
                        <select className="input-wrap__input input-wrap__input--bordered"
                                id="customer_preferred_organization"
                                onChange={evt => this.setState({preferredOrganizationId: evt.target.value})}
                                placeholder="Standaard afzetpunt">
                            <option value="">-</option>
                            {this.props.customer.organizations.map(x =>
                                <option value={x.sap_customer_id}
                                        key={x.sap_customer_id}
                                        selected={x.sap_customer_id === this.state.preferredOrganizationId}>{x.name}</option>)}
                        </select>
                    </div>
                    <div className="account-details__row account-details__row--edit">
                        <div className="switch-container">
                            <label className="switch">
                                <input type="checkbox" checked={this.state.editPassword}
                                       onChange={evt => {
                                           this.setState({editPassword: evt.target.checked});
                                       }}/>
                                <span className="toggle round"/>
                            </label>
                            <span className="switch-container__label">Wachtwoord wijzigen</span>
                        </div>
                    </div>
                    <div className="account-details__row account-details__row--edit linear-layout">
                        <a onClick={() => this.setState({editDetails: false})}
                           className="button button--text-primary">
                            <span className="icon" dangerouslySetInnerHTML={{__html: chevronLeft}}/>
                            Annuleren
                        </a>
                        <button
                            disabled={this.state.loading}
                            type="submit"
                            className="button button--primary button--float linear-layout__item linear-layout__item--end">
                            Bevestigen
                        </button>
                    </div>
                </form>
                {this.state.editPassword && (
                    <>
                        <div className="account-details__row account-details__row--edit">
                            <label htmlFor="customer_old_password" className="form-row__label label--required">Huidig
                                wachtwoord</label>
                            <input type="password"
                                   onChange={
                                       evt => this.setState({password: evt.target.value})}
                                   autoComplete="off"
                                   className="input-wrap__input input-wrap__input--bordered"
                                   id="customer_old_password"/>
                        </div>
                        <div className="account-details__row account-details__row--edit">
                            <label htmlFor="customer_new_password" className="form-row__label label--required">Nieuw
                                wachtwoord</label>
                            <input type="password"
                                   onChange={
                                       evt => this.setState({newPassword: evt.target.value})}
                                   autoComplete="off"
                                   className="input-wrap__input input-wrap__input--bordered"
                                   id="customer_new_password"/>
                        </div>
                        <div className="account-details__row account-details__row--edit">
                            <label htmlFor="customer_confirm_new_password" className="form-row__label label--required">Bevestig
                                nieuw wachtwoord</label>
                            <input type="password"
                                   onChange={
                                       evt => this.setState({newPasswordConfirm: evt.target.value})}
                                   autoComplete="off"
                                   className="input-wrap__input input-wrap__input--bordered"
                                   id="customer_confirm_new_password"/>
                        </div>
                    </>
                )}
                {this.state.changePasswordResult && !this.state.changePasswordResult.succeeded && (
                    <div className="account-details__row account-details__row--error">
                        {this.state.changePasswordResult.errors.join(', ')}
                    </div>
                )}
            </>
        );
    }

    private renderPersonalDetailsRow(customer: Customer) {
        return (
            <>
                {/*<h1 className="account-details__title content-wrap">Mijn gegevens</h1>*/}
                <div className="account-details__row">
                    <div className="account-details__row__items">
                        <div className="account-details__column account-details__column--one-third">
                            <span className="account-details__column__title">Persoonlijke gegevens</span>
                            {this.renderPersonalDetails(customer)}
                        </div>
                        <div className="account-details__column account-details__column--one-third">
                            <span className="account-details__column__title">Leveringsadres</span>
                            {this.renderAddress(AddressType.Billing)}
                        </div>
                        <div className="account-details__column account-details__column--one-third">
                            <span className="account-details__column__title">Factuuradres</span>
                            {this.renderAddress(AddressType.Shipping)}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    private renderPersonalDetails(customer: Customer) {
        if (!customer) {
            return null;
        }
        return customer && (
            <ul className="address__list">
                <li className="address__list--company"><strong>{customer.roles[0]}</strong></li>
                {state.CurrentOrganization && <li>#{state.CurrentOrganization.sap_customer_id}</li>}
                <li>{customer.first_name} {customer.last_name}</li>
                <li>{customer.user_name}</li>
                <li>{customer.phone_number}</li>
                {customer.preferred_organization && <li>Voorkeurs afzetpunt: {customer.organizations
                    .find(x => x.sap_customer_id === customer.preferred_organization)!.name}</li>
                }
                <li onClick={() => this.setState({editDetails: true})}
                    className="account-details__edit">Gegevens wijzigen
                </li>
            </ul>
        );
    }

    private renderAddress(addressType: AddressType) {
        if (!this.state.addresses) {
            return null;
        }
        if (this.state.loading) {
            return <div className="address__list--loading"/>;
        }
        const address = this.state.addresses.find(x => x.addressType === addressType);
        return address ? (
            <ul className="address__list">
                <li className="address__list--company">
                    <b>{address.companyLine1}</b>
                </li>
                <li>{address.street}</li>
                <li>{address.zipCode} {address.city?.toUpperCase()}</li>
                <li>{address.country}</li>
            </ul>
        ) : <div className="notice">Niet bekend</div>;
    }

    private async populateAddresses() {
        const response = await this.props.customer.fetchAddresses();
        if (response) {
            this.setState({addresses: response.result});
        }
    }

    private async updateUser() {
        if (this.state.editPassword) {
            if (this.state.newPassword !== this.state.newPasswordConfirm) {
                this.setState({
                    changePasswordResult: {
                        succeeded: false,
                        errors: ['De ingevoerde nieuwe wachtwoorden komen niet overeen'],
                    },
                });
            } else {
                await this.changePassword();
                if (this.state.changePasswordResult!.succeeded) {
                    await this.updateUserDetails();
                }
            }
        } else {
            await this.updateUserDetails();
        }
    }

    private async updateUserDetails() {
        const updateUserModel: UpdateUserModel = {
            id: null,
            email: this.state.userName,
            phoneNumber: this.state.phoneNumber,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            roles: null,
            preferredOrganization: this.state.preferredOrganizationId,
        };
        this.setState({loading: true});
        await updateUser(updateUserModel);
        this.props.customer.user_name = this.state.userName;
        this.props.customer.phone_number = this.state.phoneNumber;
        this.props.customer.first_name = this.state.firstName;
        this.props.customer.last_name = this.state.lastName;
        this.props.customer.preferred_organization = this.state.preferredOrganizationId;
        this.props.customer.name = this.state.firstName + ' ' + this.state.lastName;
        state.notifications.push(`Uw gegevens zijn bijgewerkt`);
        this.setState({editDetails: false, editPassword: false, loading: false});
    }

    private async changePassword() {
        const changePasswordModel: ChangePasswordModel = {
            oldPassword: this.state.password,
            newPassword: this.state.newPassword,
            newPasswordConfirm: this.state.newPasswordConfirm,
        };
        const result = await changePassword(changePasswordModel);
        if (result) {
            this.setState({changePasswordResult: result});
        }
    }

}

export function renderAccountDetails(element: HTMLElement) {
    return ReactDOM.render(
        <AccountDetails
            customer={state.customer!}
            currentOrganization={state.CurrentOrganization!}
        />, element!);
}
