import {observer} from 'mobx-react';
import {state} from '../state';
import OrderListModel from '../models/orderListModel';
import {OrderListSelection} from './order-list-selection';
import {Urls} from '../models/interfaces';
import {OrderList} from '../api/interfaces';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface Props {
    urls: Urls;
}

interface State {
    dialogOpen: boolean;
}

@observer
export class OrderListOverview extends React.Component<Props, State> {

    private orderList: OrderListModel;
    private orderListElement: HTMLDivElement | null = null;

    public constructor(props: Props) {
        super(props);
        this.state = {dialogOpen: false};
        this.orderList = state.orderList!;
    }

    public render() {
        if (!this.orderList.orderLists) {
            return null;
        }
        return (
            <div ref={el => this.orderListElement = el}>
                <OrderListSelection
                    showHearts={false}
                    onSelected={orderList => this.onListSelected(orderList)}/>
            </div>
        );
    }

    public onListSelected(orderList: OrderList): void {
        location.href = this.props.urls.orderList.replace(':orderListId', orderList.id);
        if (this.orderListElement) {
            const dropdown = this.orderListElement.closest('app-dropdown');
            if (dropdown) {
                dropdown.closeDropdown(true);
            }
        }
    }
}

export function renderOrderListOverview(element: HTMLElement) {
    if (!state.orderList) {
        throw new Error(`Cannot render order list overview: 'state.orderlists' not initialized.`);
    }
    return ReactDOM.render(<OrderListOverview urls={state.urls}/>, element);
}
