import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {observer} from 'mobx-react';
import {state} from '../state';
import {MediaQuery} from '../media-queries';

interface Props {
    mediaQueries: Map<MediaQuery, boolean>;
    returnUrl: string;
}

interface State {
    acceptedUpdatedTerms: boolean;
}

@observer
export class UpdatedTerms extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            acceptedUpdatedTerms: false,
        };
    }

    public componentDidMount(): void {
        if (this.state.acceptedUpdatedTerms) {
            location.href = state.urls.home;
        }
    }

    public render() {
        return (
            <div className="content-wrap">
                <div className="page-header first-login__page-header">
                    <h1 className="page-header__title">
                        {`Welkom terug ${state.customer ? state.customer.first_name : ''}`}</h1>
                    <div className="page-header__subtitle">
                        De algemene voorwaarden zijn gewijzigd
                    </div>
                    <div className="page-header__text first-login__page-header__text">
                        Op de bestellingen die u bij De Klok plaatst zijn haar algemene voorwaarden van toepassing.
                        De algemene voorwaarden van De Klok zijn gewijzigd.
                        De nieuwe algemene voorwaarden treft u hier aan.
                        De algemene voorwaarden maken deel uit van iedere transactie die De Klok in de toekomst met u
                        aan zal gaan.
                        <br/>
                        <br/>
                        Om door te gaan naar de home pagina dient u de
                        <a className="first-login__page-header__text__references"
                           href={state.urls.home + 'c/algemene-voorwaarden'} target="_blank"> Algemene Voorwaarden </a>
                        en de <a className="first-login__page-header__text__references"
                                 href={state.urls.home + 'c/privacy-policy'} target="_blank"> Privacy Policy</a> te
                        lezen en te accepteren.
                    </div>
                    <div>
                        <div className="switch-container">
                            <label className="switch">
                                <input type="checkbox"
                                       onChange={evt => {
                                           this.setState(
                                               {acceptedUpdatedTerms: (evt.target as HTMLInputElement).checked});
                                       }}/>
                                <span className="toggle round"/>
                            </label>
                            <span className="switch-container__label">
                            Ik heb de Algemene Voorwaarden en de Privacy Policy gelezen en ga hiermee akkoord</span>
                        </div>
                    </div>
                    <button
                        type="button"
                        className="button button--primary button--float page-header__button"
                        onMouseUp={() => this.acceptUpdatedTerms()}
                        disabled={!this.state.acceptedUpdatedTerms}>
                        Opslaan
                    </button>
                </div>
            </div>
        );
    }

    private async acceptUpdatedTerms() {
        window.location.href = state.urls.acceptUpdatedTerms + '?returnUrl=' + encodeURIComponent(this.props.returnUrl);
    }
}

export function renderUpdatedTerms(element: HTMLElement) {
    const returnUrl = element.getAttribute('data-return-url');
    if (!returnUrl) {
        throw new Error('No return url set for FirstLogin');
    }
    return ReactDOM.render(
        <UpdatedTerms
            mediaQueries={state.mediaQueries}
            returnUrl={returnUrl}
        />, element);
}
