/* tslint:disable:no-var-requires max-line-length */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {observer} from 'mobx-react';
import {state} from '../state';
import {MediaQuery} from '../media-queries';
import {Cart as CartState, LineItem, Vendor} from '../models/interfaces';
import {CartPriceSummary} from './cart-price-summary';
import {formatDate, formatEuroPrice, formatPrice} from '../utils/format';
import {Dialog} from './dialog';
import {Props as QuantityDialogProps, QuantityDialog} from './quantity-dialog';
import {viewPrices} from '../security/representative-claims';
import {computed} from 'mobx';
import {VendorType} from '../enums/vendorType';
import {CartItemQuantity} from './cart-item-quantity';

const closeIcon = require('../../images/icons8-multiply-50.svg');

interface Props {
    cart: CartState;
    mediaQueries: Map<MediaQuery, boolean>;
    noItemsTitle: string | null;
    noItemsSubTitle: string | null;
    noItemsText: string | null;
    noItemsButtonText: string | null;
    noItemsButtonUrl: string | null;
}

interface State {
    showBackorders: boolean;
    showQuantityDialog: QuantityDialogProps | false;
    deleting: number;
    terms: string[];
}

const collapseArrow = require('../../images/icons8-collapse-arrow-50.svg');

@observer
export class Cart extends React.Component<Props, State> {
    private sortedBySku: boolean = true;
    private sortedByName: boolean = true;

    public constructor(props: Props) {
        super(props);
        this.state = {
            showBackorders: false,
            showQuantityDialog: false,
            deleting: 0,
            terms: [state.cart.dKDVendor.id],
        };
        if (state.backorderArray) {
            state.loadBackorderItems(state.current_organization!);
        }
    }

    public componentDidMount(): void {
        this.props.cart.track_changes = true;
    }

    public componentWillUnmount(): void {
        this.props.cart.track_changes = false;
    }

    @computed
    public get showPrices() {
        return this.accessToPrices && !state.cart.hasSapErrorOccurred;
    }

    @computed
    public get accessToPrices() {
        return state.customer!.has_access(viewPrices);
    }

    public renderQuantity(item: LineItem) {
        return item.product &&
            <div className={`cart__buttons`}>
                <CartItemQuantity
                    item={item}
                    disabled={this.props.cart.loading || item.is_gift}
                    saveQuantity={async (value) => await this.updateQuantity(item, value)}
                />
        </div>;
    }

    public renderProduct(line: LineItem) {
        return [
            line.product ?
                <div className="table-line-item">
                    <a className="product-title" href={line.product.url}>
                        {line.name}
                    </a>
                    {line.product && line.product.badge_text != null && this.renderLabel(line)}
                </div> : line.name,
        ];
    }

    public renderLabel(line: LineItem) {
        if (line.product!.is_promotion) {
            return <span className="badge badge--secondary">{line.product!.badge_text || 'Actie'}</span>;
        } else if (line.product?.badge_text) {
            return <span className="badge" data-title={line.product.badge_text}>{line.product.badge_text}</span>;
        }
        return null;
    }

    public renderPrice(line: LineItem, calculatePrice: boolean) {
        if (line && line.product && line.product.vendor &&
            line.product!.vendor!.vendor_type === VendorType.Light) {
            const productPrice = calculatePrice ? line.product!.gross_price : line.product!.gross_price! * line.quantity;
            return state.mediaQueries.get(MediaQuery.MediumUp) ?
                <td>{formatPrice(productPrice)}</td> : <div>{formatPrice(productPrice)}</div>;
        }
        if (this.props.cart && this.props.cart.order_data) {
            const items = state.cart.orderItems;
            if (items) {
                const item = items.find(i => i.sku === line.sku);
                if (item) {
                    const productPrice = item.price && calculatePrice ? (item.price / line.quantity) : item.price;
                    return state.mediaQueries.get(MediaQuery.MediumUp) ?
                        <td>{formatEuroPrice(productPrice)}</td> : <div>{formatEuroPrice(productPrice)}</div>;
                }
            }
        }
        return null;
    }

    public renderMarketplaceLightStock(line: LineItem) {
        if (line.product!.buyable) {
            if (line.in_stock_quantity > 0) {
                return <td className="stock-table-cell">
                    <div className="stock-status stock-status--in-stock">
                        <span>&#8226;</span>
                        <p>Op voorraad</p>
                    </div>
                </td>;
            } else if (line.in_stock_quantity <= 0) {
                return <td className="stock-table-cell">
                    <div className="stock-status stock-status--out-of-stock">
                        <span>&#8226;</span>
                        <p>Niet op voorraad</p>
                    </div>
                </td>;
            }
        }
        return <td className="stock-table-cell">
            <div className="stock-status stock-status--not-buyable">
                <span>&#8226;</span>
                <p>Niet meer leverbaar</p>
            </div>
        </td>;
    }

    public renderStock(line: LineItem) {
        const status: JSX.Element[] = [];
        if (line && line.product && line.product.vendor &&
            line.product!.vendor!.vendor_type === VendorType.Light) {
            return this.renderMarketplaceLightStock(line);
        }
        if (this.props.cart && this.props.cart.order_data) {
            const upcoming = this.props.cart.order_data.nextDeliveryInfos;
            const backorder = this.props.cart.order_data.backorderDeliveryInfos;

            if (upcoming) {
                const isUpcoming = upcoming.find(i => i.sku === line.sku);
                if (isUpcoming) {

                    const quantity = isUpcoming.quantity;

                    status.push(
                        <div>
                        <span className="stock-info stock-info--in-stock">
                            {quantity} {quantity === 1 ? 'artikel' : 'artikelen'}</span> {quantity === 1
                            ? 'wordt'
                            : 'worden'} geleverd
                            op {formatDate(isUpcoming.date)}
                        </div>,
                    );
                }
            }

            if (backorder) {
                const isBackorder = backorder.find(i => i.sku === line.sku);

                if (isBackorder) {

                    const quantity = isBackorder.quantity;

                    status.push(
                        <div>
                        <span className="stock-info stock-info--backorder">
                            {quantity} {quantity === 1 ? 'artikel' : 'artikelen'}</span> {quantity === 1
                            ? 'wordt'
                            : 'worden'} in backorder geplaatst
                        </div>,
                    );
                }
            }
        }
        return state.mediaQueries.get(MediaQuery.MediumUp) ? <td className="stock-table-cell">{status}</td> : status;
    }

    public renderBackorderItems() {
        if (!this.state.showBackorders) {
            return;
        }
        const backorderItems = state.backorderArray;
        return (
            <Dialog
                position={'center'}
                onResult={() => this.setState({ showBackorders: false })}>
                <div className="backorder">
                    <table className="table backorder__table">
                        <thead>
                        <tr>
                            <th>Naam</th>
                            <th>Artikelnummer</th>
                            <th>Aantal</th>
                            <th>Order</th>
                            <th>Verwachte leverdatum</th>
                        </tr>
                        </thead>
                        <tbody>
                        {backorderItems.map(backorderItem => (
                            <tr key={backorderItem.sku}>
                                <td>{backorderItem.name}</td>
                                <td>{backorderItem.sku}</td>
                                <td>{backorderItem.quantity}</td>
                                <td>{backorderItem.orderId}</td>
                                <td>{formatDate(backorderItem.estimatedDeliveryDate)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div className="button-group">
                        <button className="button" data-action>Sluit</button>
                    </div>
                </div>
            </Dialog>
        );
    }

    public renderDeleteButton(line: LineItem) {
        if (line.is_gift) {
            return null;
        }
        return (
            <button type="button"
                    className="button button--text button--with-icon button--warn"
                    onClick={() => this.removeLine(line)}>
                <span className="icon button__icon" dangerouslySetInnerHTML={{ __html: closeIcon }}/>
            </button>
        );
    }

    public renderTransportCosts(items: LineItem[]) {
        const vendor = items[0].product!.vendor;
        if (vendor) {
            switch (vendor!.vendor_type) {
                case VendorType.DeKlokDranken:
                    break;
                case VendorType.Full:
                case VendorType.Light:
                    const totalOrderPrice =  items.reduce((a: number, b: any) =>
                        a + ((b.price || ((b.product.gross_price! / 100) * b.quantity)) || 0), 0);
                    const requiredMinimalOrderPrice = (vendor.minimal_order_price - totalOrderPrice) > 0;
                    const hasFreeTransport = (vendor.minimal_order_price_for_free_transport - totalOrderPrice) < 0;
                    if (requiredMinimalOrderPrice) {
                        return state.mediaQueries.get(MediaQuery.MediumUp) ?  <td className="vendor-transport__text vendor-transport__text--error">
                            De minimale bestelprijs is
                            <strong>{formatEuroPrice(vendor.minimal_order_price)}</strong>
                            , voeg nog voor
                            <strong>{formatEuroPrice(vendor.minimal_order_price - totalOrderPrice)}</strong>
                            aan uw winkelwagen toe.
                        </td> : <div className="vendor-transport__text vendor-transport__text--error">
                            De minimale bestelprijs is
                            <strong>{formatEuroPrice(vendor.minimal_order_price)}</strong>
                            , voeg nog voor
                            <strong>{formatEuroPrice(vendor.minimal_order_price - totalOrderPrice)}</strong>
                            aan uw winkelwagen toe.</div>;
                    }
                    if (state.mediaQueries.get(MediaQuery.MediumUp)) {
                        return hasFreeTransport ?
                            <td className="vendor-transport__text">
                                <strong>Gratis geleverd</strong>
                            </td> : <td className="vendor-transport__text">
                                Transporttoeslag
                                <strong>{formatEuroPrice(vendor.transport_price)}</strong>
                                , voeg nog voor
                                <strong>{formatEuroPrice(vendor.minimal_order_price_for_free_transport - totalOrderPrice)}</strong>
                                aan uw winkelwagen toe en uw bestelling wordt gratis geleverd.
                            </td>;
                    }
                    return hasFreeTransport ?
                        <div className="vendor-transport__text">
                            <strong>Gratis geleverd</strong>
                        </div> : <div className="vendor-transport__text vendor-transport__text--add-more">
                            Transporttoeslag
                            <strong>{formatEuroPrice(vendor.transport_price)}</strong>
                            , voeg nog voor
                            <strong>{formatEuroPrice(vendor.minimal_order_price_for_free_transport - totalOrderPrice)}</strong>
                            aan uw winkelwagen toe en uw bestelling wordt gratis geleverd.
                        </div>;
            }
        }
        const order_data = this.props.cart.order_data;
        if (state.mediaQueries.get(MediaQuery.MediumUp)) {
            return order_data !== null &&
            order_data.remainingForFreeTransport &&
            order_data.remainingForFreeTransport > 0 &&
            order_data.shippingCosts &&
            this.props.cart.item_count > 0 ?
                <td className="vendor-transport__text">
                    Transporttoeslag
                    <strong>{formatEuroPrice(order_data.shippingCosts)}</strong>
                    , voeg nog voor
                    <strong>{formatEuroPrice(order_data.remainingForFreeTransport)}</strong>
                    aan uw winkelwagen en uw bestelling wordt gratis geleverd.
                </td>
                : <td className="vendor-transport__text">
                    <strong>Gratis geleverd</strong>
                </td>;
        }
        return order_data !== null &&
            order_data.remainingForFreeTransport &&
            order_data.remainingForFreeTransport > 0 &&
            order_data.shippingCosts &&
            this.props.cart.item_count > 0 ?
                <div className="vendor-transport__text">
                    Transporttoeslag
                    <strong>{formatEuroPrice(order_data.shippingCosts)}</strong>
                    , voeg nog voor
                    <strong>{formatEuroPrice(order_data.remainingForFreeTransport)}</strong>
                    aan uw winkelwagen en uw bestelling wordt gratis geleverd.
                </div>
            : <div className="vendor-transport__text">
                <strong>Gratis geleverd</strong>
            </div>;
    }

    public renderVendor(vendor: Vendor, items: LineItem[]) {
        return vendor && <tr className="vendor vendor--dark">
            <td style={{width: '30%'}} className="vendor__text">Geleverd door: <strong className="vendor__info">{vendor.name}</strong></td>
            {this.renderTransportCosts(items)}
            <td style={{width: '10%'}} />
            <td/>
            <td/>
            <td/>
            <td/>
        </tr>;
    }

    @computed
    public get areTermsNotAccepted() {
        let cartItems = state.cart.items.map(x => x.product!.vendor != null && x.product!.vendor!.id);
        if (cartItems.findIndex(x => x === state.cart.dKDVendor.id) === -1) {
            cartItems.push(state.cart.dKDVendor.id);
        }
        cartItems = cartItems.filter((x, i, a) => a.indexOf(x) === i);
        const vendorTos = this.state.terms;

        return cartItems.length === vendorTos.length && vendorTos.every(el => vendorTos.includes(el));
    }

    public vendorTermsCheck() {
        const lines: any[] = [];
        state.cart.groupedItemsByVendor.forEach((items: LineItem[]) => {
            const vendor = items[0].product!.vendor;
            if (vendor && vendor.vendor_type !== VendorType.DeKlokDranken) {
                const fileUrl = state.urls.vendorAssets.replace(':fileName', vendor.terms_of_service_file_name);
                lines.push(<div className="switch-container switch-container--padding-right filters__head__switch-container">
                    <label className="switch">
                        <input type="checkbox" checked={this.state.terms.indexOf(vendor.id) > -1}
                               onChange={(event) => {
                            const array = [...this.state.terms];
                            const index = array.indexOf(vendor.id);
                            if (index !== -1) {
                                array.splice(index, 1);
                                this.setState({terms: array});
                            } else {
                                array.push(vendor.id);
                                this.setState({terms: array});
                            }
                        }}/>
                        <span className="toggle round"/>
                    </label>
                    { vendor.terms_of_service_file_name !== null ? <span className="switch-container__label switch-container__label--padding-small">Ik ga akkoord met de <a target="_blank" href={fileUrl}>algemene voorwaarden</a> van {vendor!.name}</span>
                        :
                        <span className="switch-container__label switch-container__label--padding-small">Ik ga akkoord met de <a>algemene voorwaarden</a> van {vendor!.name}</span>}
                </div>);
            }
        });
        return <div className="cart__terms-of-service">
            <div className="cart__terms-of-service__vendor-terms">{lines}</div>
        </div>;
    }

    public renderVendorMobile(vendor: Vendor, items: LineItem[]) {
        return vendor && <div className="vendor vendor--dark">
            <p className="vendor__text">Geleverd door: <a className="vendor__url" href={vendor.handle}>{vendor.name}</a>
            </p>
            {this.renderTransportCosts(items)}
        </div>;
    }

    public renderLinesByVendorMobile() {
        const lines: any[] = [];
        state.cart.groupedItemsByVendor.forEach((items: LineItem[]) => {
            if (items) {
                items[0].product!.vendor ? lines.push(this.renderVendorMobile(items[0].product!.vendor, items)) : lines.push(
                    <div className="vendor vendor--dark">
                        <p className="vendor__text">
                            Geleverd door: <a className="vendor__url" href={state.cart.dKDVendor.handle}>{state.cart.dKDVendor.name}</a>
                        </p>
                        {this.renderTransportCosts(items)}
                    </div>,
                );
                items.forEach(item => {
                    lines.push(
                        <div className="item" key={item.id}>
                            {this.renderProduct(item)}
                            <div className="item-info">
                                <div className="sku">{item.sku}</div>
                                <div className="prices-quantity">
                                    {
                                        !this.props.cart.loading
                                            ?
                                            this.showPrices
                                                ? this.renderPrice(item, true)
                                                : <div>-</div>
                                            : <div><div className="--loading">&nbsp;</div></div>
                                    }
                                    x
                                    {this.renderQuantity(item)}
                                    =
                                    {
                                        !this.props.cart.loading
                                            ? this.showPrices
                                            ? this.renderPrice(item, false)
                                            : <div>-</div>
                                            : <div><div className="--loading">&nbsp;</div></div>
                                    }
                                </div>
                            </div>
                            {
                                !this.props.cart.loading
                                    ?
                                    !state.cart.hasSapErrorOccurred
                                        ? <div className="stock">{this.renderStock(item)}</div> : null
                                    : <div className="stock"><div className="--loading">&nbsp;</div></div>
                            }
                            {this.renderDeleteButton(item)}
                        </div>);
                });
            }
        });
        return lines;
    }

    public renderLinesByVendor() {
        const lines: JSX.Element[] = [];
        state.cart.groupedItemsByVendor.forEach((items: LineItem[]) => {
            items.forEach(item => {
               return <tr className="item" key={item.id}>
                   <td>{this.renderProduct(item)}</td>
                   <td>{item.sku}</td>
                   {
                       !this.props.cart.loading
                           ?
                           this.showPrices
                               ? <td>{this.renderPrice(item, true)}</td>
                               : <td>-</td>
                           : <td><div className="--loading">&nbsp;</div></td>
                   }
                   <td>{this.renderQuantity(item)}</td>
                   {
                       !this.props.cart.loading
                           ?
                           !state.cart.hasSapErrorOccurred
                               ? this.renderStock(item) : null
                           : <td><div className="--loading">&nbsp;</div></td>
                   }
                   {
                       !this.props.cart.loading
                           ? this.showPrices
                           ? <td>{this.renderPrice(item, false)}</td>
                           : <td>-</td>
                           : <td><div className="--loading">&nbsp;</div></td>
                   }
                   <td>{this.renderDeleteButton(item)}</td>
               </tr>;
            });
            if (items) {
                items[0].product!.vendor ? lines.push(this.renderVendor(items[0].product!.vendor, items)) : lines.push(
                    <tr className="vendor vendor--dark">
                        <td style={{width: '30%'}} className="vendor__text">Geleverd door: <a className="vendor__url" href={state.cart.dKDVendor.handle}>{state.cart.dKDVendor.name}</a></td>
                        {this.renderTransportCosts(items)}
                        <td style={{width: '10%'}} />
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                    </tr>,
                );
                items.map((item: LineItem) => {
                    lines.push(<tr className="item" key={item.id}>
                        <td>{this.renderProduct(item)}</td>
                        <td>{item.sku}</td>
                        {
                            !this.props.cart.loading
                                ?
                                this.showPrices
                                    ? this.renderPrice(item, true)
                                    : <td>-</td>
                                : <td><div className="--loading">&nbsp;</div></td>
                        }
                        <td>{this.renderQuantity(item)}</td>
                        {
                            !this.props.cart.loading
                                ?
                                !state.cart.hasSapErrorOccurred
                                    ? this.renderStock(item) : null
                                : <td><div className="--loading">&nbsp;</div></td>
                        }
                        {
                            !this.props.cart.loading
                                ? this.showPrices
                                ? this.renderPrice(item, false)
                                : <td>-</td>
                                : <td><div className="--loading">&nbsp;</div></td>
                        }
                        <td>{this.renderDeleteButton(item)}</td>
                    </tr>);
                });
                if (state.cart.groupedItemsByVendor.length > 1) {
                    const totalOrderPriceByVendor = items.reduce((a: number, b: any) =>
                        a + ((b.price || ((b.product.gross_price! / 100) * b.quantity)) || 0), 0);
                    lines.push(
                        <tr className="item">
                            <td/>
                            <td/>
                            <td/>
                            <td/>
                            <td><strong>Subtotaal</strong></td>
                            {
                                !this.props.cart.loading
                                    ? this.showPrices
                                    ? <td>
                                        <strong>{formatEuroPrice(totalOrderPriceByVendor)}</strong>
                                    </td>
                                    : <td>-</td>
                                    : <td><div className="--loading">&nbsp;</div></td>
                            }
                            <td/>
                        </tr>,
                    );
                }
            }
        });
        return lines;
    }


    public render() {
        const cart = this.props.cart;
        return (
            <div className={`loading-container ${cart.loading ? 'loading-container--loading' : ''}`}>
                {this.state.showQuantityDialog && <QuantityDialog {...this.state.showQuantityDialog} />}
                {this.props.cart.item_count === 0
                    ?  <div className="page-header">
                        <h1 className="page-header__title page-header__title--no-padding-top">{this.props.noItemsTitle}</h1>
                        <div className="page-header__subtitle" dangerouslySetInnerHTML={{__html: this.props.noItemsSubTitle!}}/>
                        <div className="page-header__text" dangerouslySetInnerHTML={{__html: this.props.noItemsText!}}/>
                        <a href={this.props.noItemsButtonUrl!} className="button button--primary button--float page-header__button">{this.props.noItemsButtonText}</a>
                    </div>
                    :
                    <div className="page-header">
                        <h1 className="page-header__title page-header__title--no-padding-top">Winkelwagen</h1>
                        <div className="page-header__subtitle">
                            Er {cart.item_count === 1
                            ? 'zit 1 artikel'
                            : `zitten ${cart.item_count} artikelen`} met een totale hoeveelheid van {cart.total_count} in uw
                            winkelwagen.
                        </div>
                    </div>}
                {state.backorder.size > 0 ? <div className="notice"><p className="cart__notification"><strong>Let
                        op! </strong> Er {state.backorder.size === 1 ? `staat ook nog 1 artikel` : `staan ook nog ${state.backorder.size} artikelen`} in
                        backorder. Klik <a
                            className="link link--bold"
                            href="#show-backorder" onClick={this.onShowBackorderClick}>hier</a> om deze
                        artikelen te bekijken.<br/></p></div>
                    : ''}
                {cart.order_data !== null && cart.order_data.remainingForFreeTransport && cart.order_data.remainingForFreeTransport > 0 && this.props.cart.item_count > 0 ?
                    <div className="notice">
                        <span>Voeg nog voor <strong>{formatEuroPrice(cart.order_data.remainingForFreeTransport)}</strong> toe aan uw winkelwagen en uw bestelling wordt gratis geleverd.</span>
                    </div>
                    : null
                }
                {this.props.cart.hasInvalidSkus ?
                    <div className="notice notice--error">
                        {this.props.cart.createInvalidSkusMessage()}
                    </div>
                    : null
                }
                {state.cart.hasSapErrorOccurred ?
                    <div className="notice notice--error">
                        {this.props.cart.getSapErrorMessage}
                    </div>
                    : null
                }
                {!state.CurrentOrganization!.can_order ?
                    <div className="notice notice--error">
                        Het is niet mogelijk om bestellingen te plaatsen voor <strong>{state.CurrentOrganization!.name}</strong>.
                    </div>
                    : null
                }
                {state.customer!.organizations.length > 1 ?
                    <div className="notice notice--warn">
                        Let op! u bestelt op het moment voor <strong>{state.CurrentOrganization!.name}</strong>.
                        <a href={state.urls.account}> Klik hier</a> om een andere organisatie te selecteren.
                    </div>
                    : null
                }
                {this.renderBackorderItems()}
                {
                    this.props.cart.items.length > 0 && [
                        this.props.mediaQueries.get(MediaQuery.MediumUp)
                            ?
                            <table className="table table--cart">
                                <thead>
                                <tr>
                                    <th onClick={() => this.sortByName()}><a href="#" className="sort-by">Naam</a></th>
                                    <th className="sort-by" onClick={() => this.sortBySku()}><a href="#" className="sort-by">Artikelnummer</a></th>
                                    <th>Prijs</th>
                                    <th>Aantal</th>
                                    <th>Voorraadstatus</th>
                                    <th>Subtotaal</th>
                                    <th>&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.renderLinesByVendor()}
                                </tbody>
                            </table>
                            :
                            <div className="cart-list">
                                {this.renderLinesByVendorMobile()}
                            </div>,
                        <div className="cart__footer">
                            {
                                !this.accessToPrices
                                 ? null
                                 : this.props.cart.loading && this.showPrices
                                    ? <div className="cart__price__summary">
                                        <table className="cart__price__summary__table cart__summary">
                                            <tbody>
                                            <tr>
                                                <th>Subtotaal</th>
                                                <td>
                                                    <div className="--loading">&nbsp;</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Transporttoeslag</th>
                                                <td>
                                                    <div className="--loading">&nbsp;</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Emballage</th>
                                                <td>
                                                    <div className="--loading">&nbsp;</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>BTW</th>
                                                <td>
                                                    <div className="--loading">&nbsp;</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                <th>Totaal</th>
                                                <td>
                                                    <div className="--loading">&nbsp;</div>
                                                </td>
                                            </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                    : !state.cart.hasSapErrorOccurred && cart.order_data
                                        ? <CartPriceSummary order_data={cart.order_data}/>
                                        : null
                            }
                        </div>,
                        this.vendorTermsCheck(),
                        <div className="row row--bottom-block linear-layout">
                            <a href={state.urls.home} className="button button--text-primary">
                                <span className="icon cart__icon cart__icon--back" dangerouslySetInnerHTML={{__html: collapseArrow}}>
                                </span>
                                Verder winkelen
                            </a>
                            <button
                                disabled={!state.cart.isCartValid || !this.areTermsNotAccepted}
                                onClick={() => {
                                    window.location.href = state.urls.cartCheckout;
                                }}
                                className="button button--primary button--float linear-layout__item linear-layout__item--end">
                                Naar bestellen
                            </button>
                        </div>,
                    ]
                }
            </div>
        );
    }

    protected onShowBackorderClick = (evt: React.MouseEvent) => {
        evt.preventDefault();
        this.setState({ showBackorders: true });
    }

    private async removeLine(line: LineItem) {
        try {
            if (line.product!.vendor != null && line.product!.vendor!.vendor_type !== VendorType.DeKlokDranken) {
                const vendor = line.product!.vendor;
                const array = [...this.state.terms];
                const index = array.indexOf(vendor.id);
                if (index !== -1) {
                    array.splice(index, 1);
                    this.setState({terms: array});
                }
            }
            this.setState({deleting: this.state.deleting + 1});
            await this.props.cart.remove_line_item(line.id);
        } finally {
            this.setState({deleting: this.state.deleting - 1});
            if (this.state.deleting < 1) {
                await this.props.cart.executeOrderSimulation();
            }
        }
    }

    private async updateQuantity(line: LineItem, quantity: number | false) {
        if (quantity === false) {
            return;
        }
        const product = line.product!;

        if (isNaN(quantity) || quantity <= 0) {
            this.setState({
                showQuantityDialog: {
                    enteredValue: 0,
                    productName: product.name,
                    quantityIncrement: product.quantity_increment,
                    onValueChosen: async () => {
                        this.setState({ showQuantityDialog: false });
                        await this.updateQuantity(line, product.quantity_increment);
                    },
                },
            });
            return;
        }

        if (quantity % product.quantity_increment !== 0) {
            this.setState({
                showQuantityDialog: {
                    enteredValue: quantity,
                    productName: product.name,
                    quantityIncrement: product.quantity_increment,
                    onValueChosen: async chosenNumber => {
                        this.setState({ showQuantityDialog: false });
                        await this.updateQuantity(line, chosenNumber);
                    },
                },
            });
            return;
        }

        await this.props.cart.update_quantity(
            line.id,
            quantity,
        );
        await this.props.cart.executeOrderSimulation();
    }

    private sortByName() {
        const sortedByName = state.cart.items.slice().sort((a, b) => a.name.localeCompare(b.name));
        state.cart.items =  this.sortedByName ? sortedByName : sortedByName.reverse();
        this.sortedByName = !this.sortedByName;
    }

    private sortBySku() {
        const sortedByName = state.cart.items.slice().sort((a, b) => (a.sku.substr(0, 6)).localeCompare(b.sku.substr(0, 6)));
        state.cart.items =  this.sortedBySku ? sortedByName : sortedByName.reverse();
        this.sortedBySku = !this.sortedBySku;
    }
}

export function renderCart(element: HTMLElement) {
    if (!state.cart) {
        throw new Error(`Cannot render cart: 'state.cart' not initialized.`);
    }
    return ReactDOM.render(
        <Cart
            noItemsTitle={element.getAttribute('no-items-title' as string)}
            noItemsSubTitle={element.getAttribute('no-items-subtitle' as string)}
            noItemsText={element.getAttribute('no-items-text' as string)}
            noItemsButtonText={element.getAttribute('no-items-button-text' as string)}
            noItemsButtonUrl={element.getAttribute('no-items-button-url' as string)}
            cart={state.cart}
            mediaQueries={state.mediaQueries}
        />, element);
}
