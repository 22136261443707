/* tslint:disable:max-line-length */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {viewHttpClient} from '../api/client';
import {initDom} from '../../dom';
import * as queryString from 'querystring';
import {observer} from 'mobx-react';
import {BaseProductFilters, FilterGroup, Props, SearchResult, urlResultCache} from './base-product-filters';

export interface CollectionFiltersProps extends Props {}


@observer
export class CollectionFilters extends BaseProductFilters<CollectionFiltersProps> {

    public constructor(props: CollectionFiltersProps) {
        super(props);
    }

    public async doSearch() {
        const url = window.location.href;
        if (!urlResultCache[url]) {
            const result = await viewHttpClient.get<SearchResult>(url + '&ajax=1');
            urlResultCache[url] = result.data;
        }
        return urlResultCache[url];
    }

    public async search() {
        this.setState({isLoading: true});

        try {
            const result = await this.doSearch();
            const context: FilterGroup[] = result.context;
            if (context === null) {
                return;
            }
            this.setState({
                terms: context,
            });

            const tempNode = document.createElement('div');
            tempNode.innerHTML = result.body;

            ['#resultWrap', '.pagination', '#noResultWrap', '#searchResultCount', '#paginationContainer'].forEach(selector => {
                const newResultWrap = tempNode.querySelector(selector);
                const resultWrap = document.querySelector<HTMLElement>(selector);

                if (resultWrap) {
                    resultWrap.innerHTML = newResultWrap ? newResultWrap.innerHTML : '<div id="{{selector}}"></div>';
                    initDom(resultWrap);
                }
            });
            document.getElementById('collectionFilters')!.scrollIntoView({block: 'start', behavior: 'smooth'});

        } finally {
            this.setState({isLoading: false});
        }
    }


}

export function renderFilterList(element: HTMLElement) {
    const contextString = element.getAttribute('data-context');
    const filterList = ReactDOM.render(
        <CollectionFilters
            key="filter_list"
            query={element.getAttribute('data-query') ?? undefined}
            terms={contextString ? JSON.parse(contextString) : []}
        />, element!);
    window.onpopstate = () => (filterList as any).updateFromUrl();
    return filterList;
}
