import * as React from 'react';
import {minusIcon, plusIcon, renderSvg} from '../../images/images';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    onNumberChange?: (nr: number) => void;
    value: number | string | undefined;
};

interface State {
    minusEnabled: boolean;
    plusEnabled: boolean;
}

export class NumberInput extends React.Component<Props, State> {
    private inputElement: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);
        this.inputElement = React.createRef();

        this.state = this.getEnabledState();
    }

    public componentDidUpdate(prevProps: Props) {
        if (prevProps.value !== this.props.value ||
            prevProps.min !== this.props.min ||
            prevProps.max !== this.props.max) {
            this.setState(this.getEnabledState());
        }
    }

    public stepDown() {
        this.inputElement.current?.stepDown();
        this.triggerNumberChange();
    }

    public stepUp() {
        this.inputElement.current?.stepUp();
        this.triggerNumberChange();
    }

    public render() {
        return <div className="number-input" onWheel={x => x.deltaY < 0 ? this.stepUp() : this.stepDown()}>
            <button className="number-input__item number-input__item--minus"
                    disabled={!this.state.minusEnabled || this.props.disabled}
                    onClick={() => this.stepDown()}
                    type="button">
                {renderSvg(minusIcon, {
                    className: 'icon number-input__item__icon number-input__item__icon--light',
                })}
            </button>
            <input
                ref={this.inputElement}
                {...this.getInputProps()} />
            <button className="number-input__item number-input__item--plus"
                    disabled={!this.state.plusEnabled || this.props.disabled}
                    onClick={() => this.stepUp()}
                    type="button">
                {renderSvg(plusIcon, {
                    className: 'icon number-input__item__icon number-input__item__icon--light',
                })}
            </button>
        </div>;
    }

    private getEnabledState(): Pick<State, 'minusEnabled' | 'plusEnabled'> {
        const nr = this.valueAsNumber(this.props.value, 0);
        const min = this.valueAsNumber(this.props.min, Number.MIN_SAFE_INTEGER);
        const max = this.valueAsNumber(this.props.max, Number.MAX_SAFE_INTEGER);
        return {
            minusEnabled: nr > min,
            plusEnabled: nr < max,
        };
    }

    private valueAsNumber<TDefault>(numberValue: string | number | undefined,
                                    defaultValue: TDefault): number | TDefault {
        if (numberValue === undefined) {
            return defaultValue;
        }
        if (typeof numberValue === 'number') {
            return numberValue;
        }
        return parseFloat(numberValue);
    }

    private getInputProps() {
        const props: Props = {
            ...this.props,
            type: 'number',
            onChange: evt => {
                if (this.props.onChange) {
                    this.props.onChange(evt);
                }
                if (!evt.defaultPrevented) {
                    this.triggerNumberChange();
                }
            },
        };
        delete props.onNumberChange;
        props.className = props.className + ' number-input__input';
        return props;
}

    private triggerNumberChange() {
        if (this.props.onNumberChange && this.inputElement.current) {
            this.props.onNumberChange(this.inputElement.current.valueAsNumber);
        }
    }
}
