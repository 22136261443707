import apiClient, { getResponseData } from './client';
import {
    ProductSearchResult,
    SearchCriteria, SearchResultCategory,
    SuggestCriteria, SuggestSearchResult,
} from './interfaces';

export async function searchProducts(criteria: SearchCriteria) {
    return getResponseData(await apiClient.post<ProductSearchResult>('catalog/search', criteria));
}

export async function searchCategories(categoryIds: string[]) {
    return getResponseData(await apiClient.post<SearchResultCategory[]>('catalog/categories', categoryIds));
}

export async function suggest(criteria: SuggestCriteria) {
    return getResponseData(await apiClient.post<SuggestSearchResult>('catalog/suggest', criteria));
}
