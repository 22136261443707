/* tslint:disable:variable-name */
import {
    autorun,
    computed,
    observable,
} from 'mobx';
import {formatEuroPrice, formatPrice} from '../utils/format';
import {
    App,
    Cart,
    LineItem,
    Product,
} from './interfaces';
import { DeepPartial } from '../../../../custom';
import { ProductModel } from './productModel';

export class LineItemModel implements LineItem {

    @observable
    public id: string = '';

    @observable
    public name: string = '';

    @observable
    public sku: string = '';

    @observable
    public measure_unit: string = '';

    @observable
    public price: number | null = null;

    @observable
    public is_gift = false;

    @observable
    public quantity: number = 1;

    @observable
    public in_stock_quantity: number = 0;

    @observable
    public product: Product | null = null;

    @computed
    public get final_price(): string | null {
        if (this.is_gift) {
            return formatEuroPrice(0);
        }
        return this.price === null ? null : formatPrice(this.price);
    }

    @computed
    public get final_line_price(): string | null {
        if (this.is_gift) {
            return formatEuroPrice(0);
        }
        return this.price === null ? null : formatPrice(this.price * this.quantity);
    }

    public constructor(app: App, cart: Cart, data: DeepPartial<LineItem>) {
        if (data.id) {
            this.id = data.id;
        }
        if (data.name) {
            this.name = data.name;
        }
        if (data.sku) {
            this.sku = data.sku;
        }
        if (data.measure_unit) {
            this.measure_unit = data.measure_unit;
        }
        if (data.is_gift) {
            this.is_gift = data.is_gift;
        }
        if (data.price) {
            this.price = data.price;
        }
        if (data.quantity) {
            this.quantity = data.quantity;
        }
        if (data.product) {
            this.product = new ProductModel(data.product!);
        }
        if (data.in_stock_quantity) {
            this.in_stock_quantity = data.in_stock_quantity;
        }
    }
}
