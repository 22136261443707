import * as React from 'react';
import * as queryString from 'querystring';
import {createAbsoluteUrl, createTermsParameter, parseTermsParameter} from '../utils/url';
import {state} from '../state';
import {MediaQuery} from '../media-queries';
import {multiplyIcon, renderSvg, sliderIcon} from '../../images/images';
import {FilterListSort} from './filter-list-sort';
import {FilterListGroup} from './filter-list-group';
import {getSortByTypeValue, SortByType} from '../enums/sortByType';
import {OrderListFilterMenu} from './order-list-filter-menu';

export interface Props {
    collapseLength?: number;
    terms: FilterGroup[];
    query?: string;
}

export interface FilterGroup {
    field: string;
    label: string;
    items: FilterTag[];
}

export interface FilterTag {
    label: string;
    value: string;
    count?: string;
    isApplied: boolean;
}

interface State {
    terms: FilterGroup[];
    page: number;
    showFilterDialog: boolean;
    isLoading: boolean;
    renderSortOptions: boolean;
}

export interface SearchResult {
    context: FilterGroup[];
    body: string;
}

export const urlResultCache: { [url: string]: SearchResult } = {};

export const getPageFromQueryParams = (queryParams: any) => {
  return parseInt(queryParams.page as string, 10) ?? 1;
};



export abstract class BaseProductFilters<T extends Props> extends React.Component<T, State> {

    public queryParams = queryString.parse(window.location.search.replace('?', ''));
    private readonly collapseLength: number;
    private elementRef = React.createRef<HTMLDivElement>();


    protected constructor(props: T) {
        super(props);
        this.collapseLength = props.collapseLength || 4;

        this.state = {
            terms: props.terms,
            page: getPageFromQueryParams(this.queryParams),
            showFilterDialog: false,
            isLoading: false,
            renderSortOptions: true,
        };

        urlResultCache[window.location.href] = {
            context: props.terms,
            body: document.getElementById('contentWrap')!.innerHTML,
        };
    }

    public abstract search(): any;

    public componentDidMount() {
        document.querySelector('#contentWrap')!.addEventListener('click', this._selector);
    }

    public componentWillUnmount() {
        document.querySelector('#contentWrap')!.removeEventListener('click', this._selector);
    }

    public updateFromUrl = (newUrl?: string) => {
        if (newUrl) {
            history.pushState({origin: 'filter_list'}, '', newUrl);
        }
        this.queryParams = queryString.parse(window.location.search.replace('?', ''));
        const urlTerms = parseTermsParameter(this.queryParams.terms as string);
        this.setState({
            page: getPageFromQueryParams(this.queryParams),
            terms: this.updateActiveTerms((group, item) =>
                urlTerms.some(x => x.field === group.field && x.values.includes(item.value))),
        });
        this.search();
    }

    public render() {
      const terms = this.state.terms;
      if (state.mediaQueries.get(MediaQuery.Small)) {
            return (
                <div ref={this.elementRef}
                     className={`filter-list ${this.state.showFilterDialog ? '' : 'filter-list--collapsed'}`}
                     id="filter_list">
                    <div
                        className={`filter-list__header ${this.state.showFilterDialog ? '' : 'filter-list__header--collapsed'}`}>
                        <button
                            hidden={this.state.showFilterDialog}
                            className={`button button--primary button--with-icon filter-list__button`}
                            onClick={() => this.setState({showFilterDialog: !this.state.showFilterDialog})}>
                            <h5 className="h--white">Filteren
                                {renderSvg(sliderIcon, {
                                    className: 'icon icon--text-size',
                                })}
                            </h5>
                        </button>
                        {this.state.showFilterDialog && (
                            <>
                                <a className={`filter-list__header__buttons`}
                                   onClick={() => this.setState({showFilterDialog: !this.state.showFilterDialog})}>
                                    {renderSvg(multiplyIcon, {
                                        className: 'icon',
                                    })}
                                </a>
                                <h3 className="h--white filter-list__header__title">Filteren</h3>
                                <span className="filter-list__header__reset"
                                      onClick={() => this.resetTerms()}>
                                        Wis alle filters
                                </span>
                            </>
                        )}
                    </div>
                    <div
                        className={`filter-list__groups ${this.state.showFilterDialog ? '' : 'filter-list__groups--hidden'}`}>
                        {this.state.renderSortOptions && <FilterListSort
                            hidden={true}
                            title={'Sorteren op'}/>
                        }
                        {terms.map((group, index) => (
                            <FilterListGroup
                                updateFromUrl={this.updateFromUrl}
                                createUrl={this.createUrlForTag}
                                collapsedLength={this.collapseLength}
                                key={group.field + index}
                                group={group}
                                collapsed={true}
                                hidden={true}
                            />
                        ))}
                        <div className="filter-list__content">
                            <button
                                hidden={!this.state.showFilterDialog}
                                className={`button button--primary button--with-icon filter-list__button
                            ${this.state.showFilterDialog ? 'filter-list__button--align-bottom' : ''}`}
                                onClick={() => this.setState({showFilterDialog: !this.state.showFilterDialog})}>
                                <h5 className="h--white">Filteren
                                    {renderSvg(sliderIcon, {
                                        className: 'icon icon--text-size',
                                    })}
                                </h5>
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
      return (
            <div ref={this.elementRef} className="filter-list --no-print" id="filter_list">
                {terms.length > 0 && (
                    <>
                        <div className="filter-list__header">
                            <div className="filter-list__header__title">
                                <h3>
                                    {renderSvg(sliderIcon, {
                                        className: 'icon icon--text-size',
                                    })}
                                    Filteren
                                </h3>
                            </div>
                            <div className="filter-list__header__reset" onClick={() => this.resetTerms()}>
                                herstel alles

                                {renderSvg(multiplyIcon, {
                                    className: 'icon icon--text-size',
                                })}
                            </div>
                        </div>
                        {this.state.renderSortOptions && <FilterListSort
                            hidden={true}
                            key="FilterListSort"
                            title={'Sorteren op'}/>
                        }
                    </>
                )}
                {terms.map((group, index) => (
                    <FilterListGroup
                        updateFromUrl={this.updateFromUrl}
                        createUrl={this.createUrlForTag}
                        collapsedLength={this.collapseLength}
                        key={group.field + index}
                        group={group}
                        collapsed={true}
                        hidden={index > 4}
                    />
                ))}
                {this.state.isLoading ?
                    <div className="filter-list__loading">
                        <div className="filter-list__loading--loader"/>
                    </div> : null}
            </div>
        );
    }

    private async resetTerms() {
        this.updateFromUrl(
            createAbsoluteUrl({
                ...this.queryParams,
                page: 1,
                sort_by: this.state.renderSortOptions ?
                    getSortByTypeValue(SortByType.Default) : '', // Always reset to default sorting
                terms: null,
                q: this.props.query,
            }),
        );
    }

    private createUrlForTag = (group: FilterGroup, tag: FilterTag) => {
        const groups = this.updateActiveTerms(
            (checkGroup, checkTag) => checkGroup.field === group.field && checkTag.value === tag.value
                ? !checkTag.isApplied
                : checkTag.isApplied,
        );

        if (tag.isApplied && group.field === '__outline') {
            // When deselecting a category, all the subcategories should also be deselected
            const outlineGroups = groups.filter(x => x.field === '__outline');
            if (outlineGroups.length) {
                const subOutlines = outlineGroups
                    .reduce((acc, curr) => acc.concat(curr.items), [] as FilterTag[])
                    .filter(x => x.isApplied && x.value.indexOf(tag.value) === 0);
                subOutlines.forEach(x => x.isApplied = false);
            }
        }

        return createAbsoluteUrl({
            ...this.queryParams,
            q: this.props.query,
            page: 1,
            terms: createTermsParameter(groups),
        });
    }

    private _selector = (evt: Event) => {
      const pageLink = evt.target instanceof Element ? evt.target.closest('[data-page]') : null;
      if (pageLink && !pageLink.classList.contains('pagination__item--disabled')
            && pageLink instanceof HTMLAnchorElement) {
            this.updateFromUrl(pageLink.href);
            evt.preventDefault();
        }
    }

    private updateActiveTerms(activeEvaluator: (group: FilterGroup, tag: FilterTag) => boolean): FilterGroup[] {
        return [
            ...this.state.terms.map(y => ({
                    ...y,
                    items: [
                        ...y.items.map(z => ({
                            ...z,
                            isApplied: activeEvaluator(y, z),
                        })),
                    ],
                }),
            ),
        ];
    }
}
