
export function getAbsoluteOffset(element: HTMLElement) {
    let x = element.offsetLeft;
    let y = element.offsetTop;
    if (element.offsetParent instanceof HTMLElement) {
        const parentOffset = getAbsoluteOffset(element.offsetParent);
        x += parentOffset.x;
        y += parentOffset.y;
    }
    return {
        x,
        y,
    };
}
