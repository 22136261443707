import * as React from 'react';
import { render } from 'react-dom';

interface Props {
    month: string | null;
}

const MONTH_MAP: any = {
    "January": "januari",
    "February": "februari", 
    "March": "maart",
    "April": "april",
    "May": "mei",
    "June": "juni",
    "July": "juli",
    "August": "augustus",
    "September": "september",
    "October": "oktober",
    "November": "november",
    "December": "december",
}

export class DateMonthDisplay extends React.Component<Props, {}> {
    public constructor(props: Props) {
        super(props);
    }

    public render() {
        return this.getFormattedDate();
    }

    private getFormattedDate(): string {
        const translatedMonth : string = this.props.month !== null ? MONTH_MAP[this.props.month] : '';
        return translatedMonth;
    }
}

export function renderDateMonthDisplay(element: HTMLElement) {
    return render(<DateMonthDisplay month={element.getAttribute('month' as string) || null} />, element!);
}

