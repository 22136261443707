/* tslint:disable:max-line-length */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { viewHttpClient } from '../api/client';
import { initDom } from '../../dom';
import * as queryString from 'querystring';
import { observer } from 'mobx-react';
import { FilterGroup, FilterTag, getPageFromQueryParams } from './base-product-filters';
import { createAbsoluteUrl, createSearchParameter, parseSearchParameter } from '../utils/url';
import { state } from '../state';
import { MediaQuery } from '../media-queries';
import { multiplyIcon, renderSvg, sliderIcon } from '../../images/images';
import { FilterListGroup } from './filter-list-group';

export interface NewsFiltersProps {
    tags: string[];
    productCategories: string[];
}

interface State {
    tags: FilterGroup;
    productCategories: FilterGroup;
    page: number;
    showFilterDialog: boolean;
    isLoading: boolean;
    isFilterOpen: boolean;
    isSearching: boolean;
}

@observer
export class NewsFilters extends React.Component<NewsFiltersProps, State> {
    public queryParams = queryString.parse(window.location.search.replace('?', ''));
    private readonly collapseLength: number = 4;
    private elementRef = React.createRef<HTMLDivElement>();

    private readonly PRODUCTCATEGORIES_LABEL: string = 'Product categorie';
    private readonly TAGS_LABEL: string = 'Tags';

    private urlResultCache: { [url: string]: string } = {};

    public constructor(props: NewsFiltersProps) {
        super(props);

        this.state = {
            tags: this.createFilterGroup(props.tags, this.TAGS_LABEL),
            productCategories: this.createFilterGroup(props.productCategories, this.PRODUCTCATEGORIES_LABEL),
            page: getPageFromQueryParams(this.queryParams),
            showFilterDialog: false,
            isLoading: false,
            isFilterOpen: false,
            isSearching: this.initializeIsSearched()
        };

        this.urlResultCache[window.location.href] = document.getElementById('contentWrap')!.innerHTML;
    }

    public componentDidMount() {
        document.querySelector('#contentWrap')!.addEventListener('click', this._selector);
    }

    public componentWillUnmount() {
        document.querySelector('#contentWrap')!.removeEventListener('click', this._selector);
    }

    public async doSearch() {
        const url = window.location.href;
        if (!this.urlResultCache[url]) {
            const result = await viewHttpClient.get(url);
            this.urlResultCache[url] = result.data;
        }

        return this.urlResultCache[url];
    }

    public async search() {
        this.setState({ isLoading: true });

        try {
            const result: any = await this.doSearch();

            const tempNode = document.createElement('div');
            tempNode.innerHTML = result;

            ['#newsOverviewTiles', '.pagination', '#paginationContainer'].forEach((selector) => {
                const newResultWrap = tempNode.querySelector(selector);
                const resultWrap = document.querySelector<HTMLElement>(selector);

                if (resultWrap) {
                    resultWrap.innerHTML = newResultWrap ? newResultWrap.innerHTML : '<div id="{{selector}}"></div>';
                    initDom(resultWrap);
                }
            });

            document.getElementById('newsFilters')!.scrollIntoView({ block: 'start', behavior: 'smooth' });

            this.setState({
              isSearching: this.isMinimalOneSelected()
            });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    public updateFromUrl = (newUrl?: string) => {
        if (newUrl) {
            history.pushState({ origin: 'filter_list' }, '', newUrl);
        }
        this.queryParams = queryString.parse(window.location.search.replace('?', ''));
        const urlTags = parseSearchParameter(this.queryParams.t as string);
        const urlProductCategories = parseSearchParameter(this.queryParams.cat as string);
        this.setState({
            page: getPageFromQueryParams(this.queryParams),
            productCategories: this.updateActiveSeachItems(this.PRODUCTCATEGORIES_LABEL, (group, item) => urlProductCategories.includes(item.value)),
            tags: this.updateActiveSeachItems(this.TAGS_LABEL, (group, item) => urlTags.includes(item.value))
        });

        this.search();
    }

    public render() {
        const tags = this.state.tags;
        const productCategories = this.state.productCategories;
        const searchable = tags || productCategories;
        if (state.mediaQueries.get(MediaQuery.Small)) {
            return (
                <div ref={this.elementRef} className={`filter-list ${this.state.showFilterDialog ? '' : 'filter-list--collapsed'}`} id="filter_list">
                    <div className={`filter-list__header ${this.state.showFilterDialog ? '' : 'filter-list__header--collapsed'}`}>
                        <button
                            hidden={this.state.showFilterDialog}
                            className={`button button--primary button--with-icon filter-list__button`}
                            onClick={() => this.setState({ showFilterDialog: !this.state.showFilterDialog })}
                        >
                            <h5 className="h--white">
                                Filteren
                                {renderSvg(sliderIcon, {
                                    className: 'icon icon--text-size'
                                })}
                            </h5>
                        </button>
                        {this.state.showFilterDialog && (
                            <>
                                <a
                                    className={`filter-list__header__buttons`}
                                    onClick={() => this.setState({ showFilterDialog: !this.state.showFilterDialog })}
                                >
                                    {renderSvg(multiplyIcon, {
                                        className: 'icon'
                                    })}
                                </a>
                                <h3 className="h--white filter-list__header__title">Filteren</h3>
                                <span className="filter-list__header__reset" onClick={() => this.reset()}>
                                    Wis alle filters
                                </span>
                            </>
                        )}
                    </div>
                    <div className={`filter-list__groups ${this.state.showFilterDialog ? '' : 'filter-list__groups--hidden'}`}>
                        {productCategories && (
                            <FilterListGroup
                                updateFromUrl={this.updateFromUrl}
                                createUrl={this.createUrlForTag}
                                collapsedLength={this.collapseLength}
                                key={productCategories.field + 0}
                                group={productCategories}
                                collapsed={true}
                                hidden={false}
                            />
                        )}
                        {tags && (
                            <FilterListGroup
                                updateFromUrl={this.updateFromUrl}
                                createUrl={this.createUrlForTag}
                                collapsedLength={this.collapseLength}
                                key={tags.field + 1}
                                group={tags}
                                collapsed={true}
                                hidden={false}
                            />
                        )}
                        <div className="filter-list__content">
                            <button
                                hidden={!this.state.showFilterDialog}
                                className={`button button--primary button--with-icon filter-list__button
                          ${this.state.showFilterDialog ? 'filter-list__button--align-bottom' : ''}`}
                                onClick={() => this.setState({ showFilterDialog: !this.state.showFilterDialog })}
                            >
                                <h5 className="h--white">
                                    Filteren
                                    {renderSvg(sliderIcon, {
                                        className: 'icon icon--text-size'
                                    })}
                                </h5>
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div ref={this.elementRef} className="news-filter__container --no-print" id="filter_list">
                {searchable && (
                    <div className="filter-list__header" >
                        <div className="filter-list__header__title">
                            <h3>
                                {renderSvg(sliderIcon, {
                                    className: 'icon icon--text-size'
                                })}
                                Filteren
                            </h3>
                        </div>
                            <div style={{display: this.state.isSearching ? 'block' : 'none'}} className="filter-list__header__reset" onClick={() => this.reset()}>
                                herstel alles
                                {renderSvg(multiplyIcon, {
                                    className: 'icon icon--text-size'
                                })}
                            </div>
                    </div>
                )}
                {productCategories && (
                    <FilterListGroup
                        updateFromUrl={this.updateFromUrl}
                        createUrl={this.createUrlForTag}
                        collapsedLength={this.collapseLength}
                        key={productCategories.field + 0}
                        group={productCategories}
                        collapsed={true}
                        hidden={false}
                    />
                )}
                {tags && (
                    <FilterListGroup
                        updateFromUrl={this.updateFromUrl}
                        createUrl={this.createUrlForTag}
                        collapsedLength={this.collapseLength}
                        key={tags.field + 1}
                        group={tags}
                        collapsed={true}
                        hidden={false}
                    />
                )}
                {this.state.isLoading ? (
                    <div className="filter-list__loading">
                        <div className="filter-list__loading--loader" />
                    </div>
                ) : null}
            </div>
        );
    }

    private createUrlForTag = (group: FilterGroup, tag: FilterTag) => {
        const activeEvaluator = (checkGroup: FilterGroup, checkTag: FilterTag) =>
            checkTag.value === tag.value ? !checkTag.isApplied : checkTag.isApplied;
        const productCategorienGroups =
            group.label === this.PRODUCTCATEGORIES_LABEL
                ? this.updateActiveSeachItems(this.PRODUCTCATEGORIES_LABEL, activeEvaluator)
                : this.state.productCategories;

        const tagsGroups = group.label === this.TAGS_LABEL ? this.updateActiveSeachItems(this.TAGS_LABEL, activeEvaluator) : this.state.tags;
        const cat = createSearchParameter(productCategorienGroups.items);
        const t = createSearchParameter(tagsGroups.items);

        const queryParams: any = {
            page: 1
        };
        if (cat) queryParams.cat = cat;
        if (t) queryParams.t = t;

        return createAbsoluteUrl(queryParams).replace('%2C', ',');
    }

    private _selector = (evt: Event) => {
        const pageLink = evt.target instanceof Element ? evt.target.closest('[data-page]') : null;
        if (pageLink && !pageLink.classList.contains('pagination__item--disabled') && pageLink instanceof HTMLAnchorElement) {
            this.updateFromUrl(pageLink.href);
            evt.preventDefault();
        }
    }

    private updateActiveSeachItems(label: string, activeEvaluator: (group: FilterGroup, tag: FilterTag) => boolean): FilterGroup {
        if (label === this.PRODUCTCATEGORIES_LABEL) {
            return {
                ...this.state.productCategories,
                items: this.state.productCategories.items.map((item) => ({
                    ...item,
                    isApplied: activeEvaluator(this.state.productCategories, item)
                }))
            };
        }
        return {
            ...this.state.tags,
            items: this.state.tags.items.map((item) => ({
                ...item,
                isApplied: activeEvaluator(this.state.productCategories, item)
            }))
        };
    }

    private async reset() {
        this.updateFromUrl(
            createAbsoluteUrl({
                page: 1
            })
        );
    }

    private isMinimalOneSelected() {
        const selectedTag = this.state.tags.items.filter((item) => item.isApplied);
        const selectedProductCategories = this.state.productCategories.items.filter((item) => {console.log(item); return item.isApplied; });
        return selectedTag.length > 0 || selectedProductCategories.length > 0;
    }

    private initializeIsSearched() {
      this.queryParams = queryString.parse(window.location.search.replace('?', ''));
      const urlTags = parseSearchParameter(this.queryParams.t as string);
      const urlProductCategories = parseSearchParameter(this.queryParams.cat as string);
      return urlTags.length > 0 || urlProductCategories.length > 0;
    }

    private createFilterGroup(listOfItems: string[], label: string) {
        this.queryParams = queryString.parse(window.location.search.replace('?', ''));
        const urlTags = parseSearchParameter(this.queryParams.t as string);
        const urlProductCategories = parseSearchParameter(this.queryParams.cat as string);

        const result: FilterGroup = { field: label, label, items: [] as FilterTag[] };
        listOfItems.forEach((item: string) => {
            const isApplied = label === this.PRODUCTCATEGORIES_LABEL ? urlProductCategories.includes(item) : urlTags.includes(item);
            const filterTag = { label: item, value: item, isApplied } as FilterTag;
            result.items.push(filterTag);
        });

        return result;
    }
}

export function renderNewsFilterList(element: HTMLElement) {
    const tags = element.getAttribute('tags');
    const productCategories = element.getAttribute('product-categories');
    const filterList = ReactDOM.render(
        <NewsFilters
            key="filter_list"
            tags={tags ? JSON.parse(tags) : []}
            productCategories={productCategories ? JSON.parse(productCategories) : []}
        />,
        element!
    );
    window.onpopstate = () => (filterList as any).updateFromUrl();
    return filterList;
}
