/* tslint:disable:no-var-requires max-line-length */
import {observer} from 'mobx-react';
import {computed, observable} from 'mobx';
import {BaseOrderInvoice, GetOrderOverviewSearchCriteria} from '../api/interfaces';
import {state} from '../state';
import {formatDate, formatEuroPrice} from '../utils/format';
import {
    getSapOrderStatusLabel,
    getSapOrderStatusLabelColor,
    SapInvoiceOrderStatus,
} from '../enums/sapInvoiceOrderStatus';
import {getSapOrderTypeIcon, getSapOrderTypeLabel} from '../enums/sapOrderType';
import {BaseOverview, Props} from './base-overview';
import {MediaQuery} from '../media-queries';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

@observer
export class OrderOverview extends BaseOverview {

    @computed
    public get searchCriteria(): Partial<GetOrderOverviewSearchCriteria> {
        return {
            orderNumber: this.orderNumber,
            referenceNumber: this.referenceNumber,
            to: this.to,
            from: this.from,
            status: this.status,
        };
    }

    @observable
    public orderNumber: string = '';

    @observable
    public referenceNumber: string = '';

    protected pageTitle: string;

    public constructor(props: Props) {
        super(props);
        this.state = {
            loading: state.orders!.loading,
            status: this.status,
        };
        this.pageTitle = 'Bestellingen';
    }

    public fetchItems() {
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }

        this.searchTimeout = window.setTimeout(async () => {
            this.searchTimeout = undefined;
            
            this.setState({
                loading: true,
            });

            await state.orders!.fetchOrders(
                {...this.searchCriteria, ...this.pagingCriteria},
                this.pageNumber,
                this.pageSize,
            );

            this.setState({
                loading: false,
            });
        }, 1500);
    }

    public async onItemSelect(orderId: string) {
        location.href = state.urls.orderDetail.replace(':id', orderId);
    }

    public renderList() {
        return state.orders && (
            <>
                <table className="order-overview__table">
                    <thead>
                    <tr>
                        <th>Soort</th>
                        <th>Ordernummer</th>
                        <th>Referentie</th>
                        <th>Datum</th>
                        <th>Totaal bedrag</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    {state.orders && state.orders.orders && !state.orders.loading ?
                        <tbody>
                        {state.orders.orders.length > 0 ? state.orders.orders.map((item) => this.renderItem(item)) : this.renderNoItems()}
                        </tbody> :
                        <tbody>{this.renderPlaceholders(6)}</tbody>}
                </table>
                <div className="row row--padding-half">
                    <div className="row__content linear-layout linear-layout--justify-content-center">
                        {state.orders.metaData && this.renderPagination(state.orders.metaData)}</div>
                </div>
            </>
        );
    }

    public renderFilters() {
        return <div className="filters__head filters__head--wrap">
            <div className="filters__input-wrap filters__input-wrap--one-third">
                <label htmlFor="order_reference" className="filters__input-wrap__label">Orderreferentie</label>
                <input type="text"
                       placeholder="Orderreferentie"
                       autoComplete="hellno"
                       name="fakenameforcancellingautocomplete"
                       value={this.referenceNumber}
                       onChange={(evt) => this.referenceNumber = (evt.target as HTMLInputElement).value}
                       className="input-wrap__input input-wrap__input--bordered filters__input-wrap__search"
                       id="order_reference"/>
                {this.referenceNumber.length > 0 &&
                <span className="filters__input-wrap__clear-input"
                      onClick={() => this.referenceNumber = ''}/>
                }
            </div>
            <div className="filters__input-wrap filters__input-wrap--one-third">
                <label htmlFor="order_number" className="filters__input-wrap__label">Ordernummer</label>
                <input type="text"
                       placeholder="Ordernummer"
                       value={this.orderNumber}
                       onChange={(evt) => this.orderNumber = (evt.target as HTMLInputElement).value}
                       className="input-wrap__input input-wrap__input--bordered filters__input-wrap__search"
                       id="order_number"/>
                {this.orderNumber.length > 0 &&
                <span className="filters__input-wrap__clear-input"
                      onClick={() => this.orderNumber = ''}/>
                }
            </div>
            <div className="filters__input-wrap filters__input-wrap--one-third">
                <label htmlFor="date_from" className="filters__input-wrap__label">Datum van</label>
                <input type="date"
                       placeholder="Van"
                       value={this.from}
                       onChange={(evt) => this.from = (evt.target as HTMLInputElement).value}
                       className="input-wrap__input input-wrap__input--bordered"
                       id="date_from"/>
            </div>
            <div className="filters__input-wrap filters__input-wrap--one-third">
                <label htmlFor="date_to" className="filters__input-wrap__label">Datum tot</label>
                <input type="date"
                       placeholder="Tot"
                       value={this.to}
                       onChange={(evt) => this.to = (evt.target as HTMLInputElement).value}
                       className="input-wrap__input input-wrap__input--bordered"
                       id="date_to"/>
            </div>
            <div className="filters__dropdown">
                <span className="filters__dropdown__label">Status</span>
                <app-pick-list className="pick-list-item--active dropdown">
                        <span slot="trigger"
                              className={`label filters__tags__tag__label filters__tags__tag__label--padded ${state.mediaQueries.get(MediaQuery.Small) ? '' : 'filters__tags__tag__label--dark'}`}>
                            {getSapOrderStatusLabel(this.status as SapInvoiceOrderStatus)}
                            <span className="icon icon--text-size dropdown__arrow"
                                  dangerouslySetInnerHTML={{__html: this.sortDownIcon}}/>
                        </span>
                    {this.statuses.map((status, index) => {
                        return (
                            <app-pick-list-item key={index}>
                                <a href="#"
                                   className={this.status === status ? 'active' : ''}
                                   onClick={evt => {
                                       evt.preventDefault();
                                       this.status = status;
                                   }}>{getSapOrderStatusLabel(status)}</a>
                            </app-pick-list-item>
                        );
                    })}
                </app-pick-list>
            </div>
            <div className="filters__head__sort filters__head__sort--compact">
                <app-dropdown content-class="dropdown__content--light">
                    <span slot="trigger" className="label">
                        {this.pageSize}
                        <span className="order-overview__filters__sort__label">Per pagina</span>
                        <span className="icon icon--text-size dropdown__arrow"
                              dangerouslySetInnerHTML={{__html: this.sortDownIcon}}/>
                    </span>
                    {this.sortings.map((x, i) => (
                        <a href="#"
                           key={i}
                           className={this.pageSize === x ? 'active' : ''}
                           onClick={evt => {
                               evt.preventDefault();
                               this.pageSize = x;
                               this.pageNumber = state.orders!.metaData!.pageNumber;
                           }}>{x}</a>
                    ))}
                </app-dropdown>
            </div>
        </div>;
    }

    public renderItem(item: BaseOrderInvoice) {
        return <tr key={item.id}>
            <td><span className="icon order-overview__list__order__status" title={getSapOrderTypeLabel(item.type)}
                      dangerouslySetInnerHTML={{__html: getSapOrderTypeIcon(item.type)}}/></td>
            <td><a href="" onClick={(evt) => {
                evt.preventDefault();
                this.onItemSelect(item.id);
            }}>{item.id}</a></td>
            <td>{item.referenceNumber}</td>
            <td>{formatDate(item.date, 'DD/MM/YYYY')}</td>
            <td>{formatEuroPrice(+item.total)}</td>
            <td style={{color: getSapOrderStatusLabelColor(item.status)}}>{getSapOrderStatusLabel(item.status)}</td>
        </tr>;
    }

    public renderMobileList() {
        return (
            state.orders && (
                <>
                    {state.orders.orders && state.orders.orders.length > 0 ? (
                        <div className="order-overview__list">{state.orders.orders && state.orders.orders.map((item) =>
                            <div key={item.id} className="order-overview__list__order" onClick={(evt) => {
                                evt.preventDefault();
                                this.onItemSelect(item.id);
                            }}>
                        <span className="icon order-overview__list__order__status"
                              dangerouslySetInnerHTML={{__html: getSapOrderTypeIcon(item.type)}}/>
                                <div className="order-overview__list__order__info">
                                    <span>{formatDate(item.date, 'll')}</span>
                                    <span>{item.referenceNumber}</span>
                                    <span
                                        className="order-overview__list__order__info order-overview__list__order__info--status"
                                        style={{color: getSapOrderStatusLabelColor(item.status)}}>{getSapOrderStatusLabel(item.status)}</span>
                                </div>
                                <div className="order-overview__list__order__total">
                        <span className="order-overview__list__order__total order-overview__list__order__total--price">
                            {formatEuroPrice(+item.total)}</span>
                                    <span className="icon order-overview__list__order__total__icon"
                                          dangerouslySetInnerHTML={{__html: this.chevron}}/>
                                </div>
                            </div>)}
                        </div>
                    ) : this.renderNoItems()}
                    <div className="row row--dark">
                        <div className="row__content linear-layout linear-layout--justify-content-center">
                            {state.orders.metaData && this.renderPagination(state.orders.metaData)}</div>
                    </div>
                </>
            ));
    }

    public renderHeader() {
        return state.orders && <div className="page-header">
            {state.orders.metaData &&
            <div className="page-header__subtitle order-overview__subtitle">
                {this.pageTitle} {state.orders.metaData.lastItemOnPage === 0 ? 0 : state.orders.metaData.firstItemOnPage} tot {state.orders.metaData.lastItemOnPage} van {state.orders.metaData.totalItemCount} in
                totaal
            </div>}
        </div>;
    }
}

export function renderOrderOverview(element: HTMLElement) {
    return ReactDOM.render(<OrderOverview mediaQueries={state.mediaQueries}/>, element);
}
