import { observable } from 'mobx';
import { Organization } from './interfaces';

export default class OrganizationModel implements Organization {

    @observable
    public id: string;

    @observable
    public name: string;

    @observable
    // tslint:disable-next-line:variable-name
    public account_manager: string;

    @observable
    // tslint:disable-next-line:variable-name
    public sap_customer_id: string;

    @observable
    // tslint:disable-next-line:variable-name
    public must_pay_in_advance: boolean = false;

    @observable
    // tslint:disable-next-line:variable-name
    public can_order: boolean;

    public constructor(data: Organization) {
        this.id = data.id;
        this.name = data.name;
        this.account_manager = data.account_manager;
        this.sap_customer_id = data.sap_customer_id;
        this.must_pay_in_advance = data.must_pay_in_advance;
        this.can_order = data.can_order;
    }

}
