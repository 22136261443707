/* tslint:disable:max-line-length */
import * as React from 'react';
import {NumberInput} from '../../number-input';
import {observer} from 'mobx-react';
import {autorun, observable} from 'mobx';
import {getLabel, getOptions, NpvContractType} from '../../../enums/npvContractType';
import {NpvFormStep, NpvStepProps} from './form-step';
import {DropdownInput} from '../../common/dropdown-input';
import {FormRow, FormRowColumn} from '../../common/form-row';

interface FormData {
    contractType: NpvContractType | null;
    contractDuration: number;
    yearlyDrops: number;
}

@observer
export class NpvForm1 extends NpvFormStep {

    @observable
    private readonly formData: FormData;

    public constructor(props: NpvStepProps) {
        super(props);

        this.formData = {
            contractType: this.props.npv.contractType,
            contractDuration: this.props.npv.contractDuration,
            yearlyDrops: this.props.npv.yearlyDrops || 150,
        };

        autorun(() => {
            Object.assign(this.props.npv, this.formData);
        });
    }

    public render() {
        return (

            <div className="npv-form__step npv-form__step--1">
                <div className="row row--dark">
                    <div className="content-wrap">
                        <h2 className="h h--primary">Algemeen</h2>

                        <FormRow columns={[
                            <FormRowColumn
                                key={'contractType'}
                                required={true}
                                label="Type contract">
                                <DropdownInput
                                    disabled={this.props.disabled}
                                    options={getOptions()}
                                    value={this.formData.contractType}
                                    getLabel={getLabel}
                                    onValueSelected={(x) => this.formData.contractType = x}
                                />
                            </FormRowColumn>,
                            <FormRowColumn
                                key={'contractYears'}
                                label="Contract jaren"
                                labelAttributes={{htmlFor: 'contractYears'}}
                                required={true}>
                                <NumberInput
                                    required
                                    disabled={this.props.disabled}
                                    id="contractYears"
                                    value={this.formData.contractDuration}
                                    onNumberChange={nr => this.formData.contractDuration = nr}
                                    min={1}
                                    max={5}
                                />
                            </FormRowColumn>,
                            <FormRowColumn
                                key={'yearlyDrops'}
                                label="Aantal leveringen per jaar"
                                labelAttributes={{htmlFor: 'yearlyDrops'}}
                                required={true}>
                                <NumberInput
                                    required
                                    disabled={this.props.disabled}
                                    id="yearlyDrops"
                                    value={this.formData.yearlyDrops}
                                    onNumberChange={nr => this.formData.yearlyDrops = nr}
                                    min={1}
                                />
                            </FormRowColumn>,
                        ]}/>

                        {this.props.buttons}
                    </div>
                </div>
            </div>
        );
    }
}
