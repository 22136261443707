import {observable} from 'mobx';
import {NpvSalesGroupProduct, SalesGroupProduct} from './interfaces';
import {ConditionUnit} from '../enums/conditionUnit';

export default class NpvSalesGroupProductModel implements NpvSalesGroupProduct {

    @observable
    public id: string | null = null;

    @observable
    public bonusCondition: number;

    @observable
    public bonusConditionUnit: ConditionUnit;

    @observable
    public netCondition: number;

    @observable
    public netConditionUnit: ConditionUnit;

    @observable
    public volume: number;

    @observable
    public productId: string;

    @observable
    public productName: string;

    @observable
    public grossPrice: number | null = null;

    @observable
    public customConditions: boolean;

    @observable
    public purchasePrice: number | null = null;

    @observable
    public totalDiscountPercentage: number | null = null;

    @observable
    public salesPriceMargin: number | null = null;

    @observable
    public hasValidMargin: boolean | null = null;

    constructor(data: NpvSalesGroupProduct) {
        this.id = data.id;
        this.volume = data.volume;
        this.productId = data.productId;
        this.productName = data.productName;
        this.grossPrice = data.grossPrice;
        this.purchasePrice = data.purchasePrice;
        this.customConditions = data.customConditions;
        this.netCondition = data.netCondition;
        this.netConditionUnit = data.netConditionUnit;
        this.bonusCondition = data.bonusCondition;
        this.bonusConditionUnit = data.bonusConditionUnit;
        this.totalDiscountPercentage = data.totalDiscountPercentage;
        this.salesPriceMargin = data.salesPriceMargin;
        this.hasValidMargin = data.hasValidMargin;
    }
}
