import {observer} from 'mobx-react';
import {autorun, observable, reaction} from 'mobx';
import {getSalesGroupProducts, saveSalesGroup} from '../../../api/npv';
import * as React from 'react';
import {SalesGroup, SelectOption} from '../../../models/interfaces';
import {FormRow, FormRowColumn} from '../../common/form-row';
import {Input} from '../../common/input';
import {ReactSortable} from 'react-sortablejs';
import {dataTransferIcon, loadingIcon, renderSvg, trashIcon} from '../../../../images/images';
import * as Autosuggest from 'react-autosuggest';
import {searchProducts} from '../../../api/catalog';
import {debounce} from 'lodash';
import { v4 as uuidv4 } from 'uuid';

interface Props {
    rootCategories: SelectOption[];
    salesGroup?: SalesGroup;
    onCloseRequested: (saved?: boolean) => void;
}

interface AvailableProduct {
    id: string;
    name: string;
}

interface InnerSalesGroupProduct {
    id: string;
    productId: string;
    name: string;
    order: number;
}

@observer
export class SalesGroupsEdit extends React.Component<Props, {}> {

    @observable
    public disabled = false;

    @observable
    private readonly formData: {
        id?: string,
        name?: string,
        categoryId?: string,
    };

    private products = observable(new Array<InnerSalesGroupProduct>());
    private availableProducts = observable(new Array<AvailableProduct>());

    @observable
    private productSearchKeyword = '';

    @observable
    private availableProductsLoading = false;

    private loadAvailableProducts = debounce(async (keyword: string) => {
        this.availableProductsLoading = true;
        const productsSearchResult = await searchProducts({
            keyword,
            outlines: this.formData.categoryId ? [this.formData.categoryId] : [],
            pageSize: 15,
        });
        if (this.productSearchKeyword !== keyword) {
            return;
        }
        this.availableProducts.replace(productsSearchResult.items
            .filter(x => !this.products.some(y => y.productId === x.id))
            .map(x => ({
                id: x.id,
                name: x.name,
            })),
        );
        this.availableProductsLoading = false;
    }, 500);

    public constructor(props: Props) {
        super(props);

        if (this.props.salesGroup) {
            this.formData = {
                id: this.props.salesGroup.id,
                name: this.props.salesGroup.name,
                categoryId: this.props.salesGroup.categoryId,
            };

            autorun(async () => {
                const salesGroupProducts = await getSalesGroupProducts(this.props.salesGroup!.id);
                this.products.replace(salesGroupProducts.map(x => ({
                    name: x.name,
                    id: x.id,
                    productId: x.productId,
                    order: x.order,
                })));
            });
        } else {
            this.formData = {};
        }

        reaction(() => this.formData.categoryId, () => {
            this.products.clear();
        });
    }

    public render() {
        return <form className="sales-group-form" onSubmit={evt => this.onSalesGroupSubmit(evt)}>

            <div className="linear-layout linear-layout--grow-items linear-layout--large-gutters">
                <div className="linear-layout__item">
                    <h3>
                        {this.props.salesGroup?.id ? (
                            <>Verkoopgroep '{this.props.salesGroup?.name}' bewerken</>
                        ) : (
                            <>Nieuwe verkoopgroep</>
                        )}
                    </h3>
                    <FormRow columns={[
                        <FormRowColumn
                            label="Naam"
                            labelAttributes={{htmlFor: 'salesGroupName'}}>
                            <Input id="salesGroupName"
                                   disabled={this.disabled}
                                   value={this.formData.name}
                                   onChange={evt => this.formData.name = evt.target.value}
                            />
                        </FormRowColumn>,
                    ]}/>
                    <FormRow columns={[
                        <FormRowColumn
                            label="Categorie"
                            labelAttributes={{htmlFor: 'salesGroupCategory'}}>
                            <select className="input-wrap__input input-wrap__input--bordered"
                                    id="salesGroupCategory"
                                    disabled={this.disabled}
                                    onChange={evt => this.formData.categoryId = evt.target.value}
                                    placeholder="Categorie">
                                <option value="">-</option>
                                {this.props.rootCategories.map(x =>
                                    <option value={x.id}
                                            key={x.id}
                                            selected={x.id === this.formData.categoryId}>{x.label}</option>)}
                            </select>
                        </FormRowColumn>,
                    ]}/>
                </div>
                {!!this.formData.categoryId && (
                    <div className="linear-layout__item">
                    <h3>Producten</h3>
                    {this.products && this.products.length > 0 && (
                        <table className="table table--bordered">
                            <thead>
                            <tr>
                                <th></th>
                                <th>Naam</th>
                                <th></th>
                            </tr>
                            </thead>
                            <ReactSortable
                                list={this.products}
                                tag="tbody"
                                setList={list => this.products.replace(list)}
                                handle=".--sort-handle">
                                {this.products.map(product => (
                                    <tr key={product.id}>
                                        <td valign="middle" className="--sort-handle">
                                            {renderSvg(dataTransferIcon, {
                                                className: 'icon',
                                            })}
                                        </td>
                                        <td valign="middle">
                                            {product.name}
                                        </td>
                                        <td align="right">
                                            <button className="button button--warn button--small"
                                                    disabled={this.disabled}
                                                    onClick={() => this.products.remove(product)}>
                                                {renderSvg(trashIcon, {
                                                    className: 'icon',
                                                })}
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </ReactSortable>
                        </table>
                    )}
                    <h4>Product toevoegen</h4>
                    <div className={`input-wrap input-wrap--bordered input-wrap--without-padding`}>
                        <Autosuggest<AvailableProduct>
                            suggestions={this.availableProducts}
                            onSuggestionsFetchRequested={async (req) => await this.loadAvailableProducts(req.value)}
                            onSuggestionsClearRequested={() => this.availableProducts.clear()}
                            getSuggestionValue={x => x.name}
                            renderSuggestion={x => x.name}
                            onSuggestionSelected={(evt, data) => {
                                this.products.push({
                                    id: uuidv4(),
                                    productId: data.suggestion.id,
                                    name: data.suggestion.name,
                                    order: this.products.length,
                                });
                                this.productSearchKeyword = '';
                            }}
                            inputProps={{
                                className: 'input-wrap__input input-wrap__input--full-width',
                                value: this.productSearchKeyword,
                                onChange: (evt, changeEvent) => this.productSearchKeyword = changeEvent.newValue,
                            }}
                        />
                        {this.availableProductsLoading && renderSvg(loadingIcon, {
                            className: 'input-wrap__inset-suffix icon',
                        })}
                    </div>
                </div>
                )}
            </div>

            <div className="button-group button-group--compact --mv">
                <button className="button-group__button button button--primary"
                        disabled={this.disabled}
                        type="submit">
                    Opslaan
                </button>
                <button className="button-group__button button button--text"
                        disabled={this.disabled}
                        type="button"
                        onClick={() => this.props.onCloseRequested()}>
                    Annuleren
                </button>
            </div>


        </form>;
    }

    private async onSalesGroupSubmit(evt: React.FormEvent<HTMLFormElement>) {
        try {
            this.disabled = true;
            evt.preventDefault();
            await saveSalesGroup({
                ...this.formData,
                products: this.products.map((x, i) => ({
                    id: x.id,
                    order: i,
                    productId: x.productId,
                })),
            });
            this.props.onCloseRequested(true);
        } catch (e) {
            this.disabled = false;
            throw e;
        }
    }
}
