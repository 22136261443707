import { AppModel } from '../models/app';
import { DeepPartial } from '../../../../custom';
import { App } from '../models/interfaces';

declare global {
    interface Window {
        STORE_DATA: DeepPartial<App>;
    }
}

export const state = new AppModel(window.STORE_DATA);
