export enum SapOrderType {
    Outgoing      = 0,
    Retour        = 1,
}

export function getSapOrderTypeIcon(sapOrderType: SapOrderType) {
    switch (sapOrderType) {
        case SapOrderType.Outgoing:
            return require('../../images/icons8-checkout-50.svg');
        case SapOrderType.Retour:
            return require('../../images/icons8-receive-50.svg');
    }
}

export function getSapOrderTypeLabel(sapOrderType: SapOrderType): string {
    switch (sapOrderType) {
        case SapOrderType.Outgoing:
            return 'Heen';
        case SapOrderType.Retour:
            return 'Retour';
        default:
            return 'Onbekend';
    }
}


