import {
    action,
    computed,
    observable,
} from 'mobx';
import {
    App,
    OrderListOverview,
} from './interfaces';
import { state } from '../state';
import {
    createOrderList,
    deleteListsByIds,
    getOrderLists,
} from '../api/de-klok';
import {
    CreateCartBody,
    OrderList,
} from '../api/interfaces';
import { MediaQuery } from '../media-queries';
import {OrderListType} from '../enums/orderListType';



export default class OrderListModel implements OrderListOverview {

    @observable
    public loading = false;

    @observable
    public orderLists: OrderList[] | null = null;

    @observable
    public showPriceAndStock: boolean = false;

    @observable
    public editPreferredQuantities: boolean = false;

    @observable
    public productLists = new Map<string, string[]>();

    constructor(private app: App) {
        this.fetchOrderLists();
    }

    // todo rename currentOrderList
    @computed
    public get currentOrderList(): OrderList | null {
        if (!this.orderLists) {
            return null;
        }
        if (window.location.href.indexOf('listId=') > -1) {
            const listParam = window.location.href.match(/listId=(.+)[.+^/\\]/);
            if (listParam) {
                const listId = listParam[1];
                const orderList = this.orderLists.find(a => a.id === listId);
                return orderList ? orderList : null;
            }
        }
        return null;
    }


    @computed
    public get selectedOrderList(): OrderList | null {
        if (!this.orderLists || !this.app.location.hash) {
            return null;
        }
        if (window.location.href.indexOf('listId=') > -1) {
            const listParam = window.location.href.match(/listId=(.+)[.+^/\\]/);
            if (listParam) {
                const listId = listParam[1];
                const orderList = this.orderLists.find(a => a.id === listId);
                return orderList ? orderList : null;
            }
        }
        return null;
    }

    @action
    public async createNewOrderList(listName: string, productId?: string) {
        return await this.create_new_list(listName, productId);
    }

    @action
    public async removeCurrentOrderList(): Promise<void> {
        if (this.currentOrderList) {
            await this.remove_lists([ this.currentOrderList.id ]);
        }
    }

    @action
    public async fetchOrderLists(): Promise<void> {
        try {
            this.loading = true;
            const result = await getOrderLists();
            this.orderLists = result.sort((a, b) => a.type - b.type);
        } finally {
            this.loading = false;
        }
    }

    @action
    private async create_new_list(cartName: string, productId?: string) {
        try {
            this.loading = true;
            const createCartBody: CreateCartBody = { productId, cartName };
            const createdList = await createOrderList(createCartBody);
            this.orderLists = [ ...(this.orderLists || []), createdList ];
            return createdList;
        } finally {
            this.loading = false;
        }
    }

    @action
    private async remove_lists(ids: string[]): Promise<void> {
        try {
            this.loading = true;
            await deleteListsByIds(ids);
            await this.fetchOrderLists();
        } finally {
            this.loading = false;
        }
    }
}
