import apiClient, {getResponseData} from './client';
import {
    ChangePasswordModel,
    ChangePasswordResult,
    Contact,
    CreateContactData,
    SearchContactsResult,
    SortableSearchCriteria, UpdateContactOrganizationsData,
    UpdateUserModel,
} from './interfaces';

export enum UserRole {
    AssistantBuyer = 'assitant_buyer',
    Buyer = 'buyer',
    LeadBuyer = 'lead_buyer',
    Representative = 'representative',
}

export function getUserRoles() {
    return [
        UserRole.LeadBuyer,
        UserRole.Buyer,
        UserRole.AssistantBuyer,
        UserRole.Representative,
    ];
}

export function getUserRoleLabel(userRole: UserRole) {
    switch (userRole) {
        case UserRole.AssistantBuyer:
            return 'Assistent Inkoper';
        case UserRole.Buyer:
            return 'Inkoper';
        case UserRole.LeadBuyer:
            return 'Hoofd Inkoper';
        case UserRole.Representative:
            return 'Representative';
    }
}

export async function searchContacts(criteria: SortableSearchCriteria) {
    return getResponseData(await apiClient.post<SearchContactsResult>('de_klok/user_management/search', criteria));
}

export async function updateUser(updateUserModel: UpdateUserModel) {
    await apiClient.post('account/', updateUserModel);
}

export async function changePassword(changePasswordBody: ChangePasswordModel) {
    return getResponseData(await apiClient.post<ChangePasswordResult>('account/password/', changePasswordBody));
}

export async function createContact(data: CreateContactData) {
  try {
    return getResponseData(await apiClient.post<Contact>('de_klok/user_management', data));
  } catch (error) {
    const response = error.response;
    let errorMessage = '';
    let errorResponse = response;
    if (response.status === 400 && response.data.EmailAddress) {
      errorMessage = response.data.EmailAddress;
    } else {
      errorResponse = JSON.parse(response.data.message);
      errorMessage = errorResponse.exceptionMessage ? errorResponse.exceptionMessage : errorResponse.message;
      if (errorMessage.indexOf('EntityValidationErrors') >= 0) {
        errorMessage =  'Ongeldig e-mail adres';
      } else if (errorMessage.indexOf('Contact with e-mail address') >= 0 &&  errorMessage.indexOf('already exists') >= 0) {
        errorMessage = 'Het e-mail adres bestaat al';
      }
    }

    throw  {message: errorMessage, error: errorResponse};
  }
}

export async function updateContactOrganizations(data: UpdateContactOrganizationsData) {
    return getResponseData(await apiClient.put<Contact[]>('de_klok/user_management', data));
}

export async function getContactByEmail(email: string) {
    return getResponseData(await apiClient.get<Contact | null>('de_klok/user_management/by_email', {
        params: {
            email,
        },
    }));
}

