import * as React from 'react';
import {createRef} from 'react';
import {Urls} from '../models/interfaces';
import {dkdHeaderLogo, heartOutline, menuIcon, multiplyIcon, userAvatarIcon,} from '../../images/images';
import {state} from '../state';
import {ProductSearch} from './product-search';
import {CartQuantity} from './cart-quantity';
import {MediaQuery} from '../media-queries';

export interface BaseMobileHeaderProps {
    urls: Urls;
    data?: any;
}

interface State {
    isMenuOpen: boolean;
}

export abstract class BaseMobileHeader<T extends BaseMobileHeaderProps> extends React.Component<T, State> {
    protected abstract title: string = 'Menu';
    protected readonly node: React.RefObject<HTMLDivElement>;

    protected constructor(props: T) {
        super(props);
        this.node = createRef();
        this.state = {
            isMenuOpen: false,
        };
    }

    public abstract renderContent(): any;
    public abstract renderHeader(): any;
    public abstract renderFooter(): any;

    public componentDidMount(): void {
        if (state.mediaQueries.get(MediaQuery.MediumDown)) {
            document.addEventListener('click', this.onDocumentClick, true);
        }
    }

    public componentWillUnmount(): void {
        if (state.mediaQueries.get(MediaQuery.MediumDown)) {
            document.removeEventListener('click', this.onDocumentClick, true);
        }
    }

    public render() {
        if (!state.mediaQueries.get(MediaQuery.MediumDown)) {
            return <></>;
        }
        return <header role="banner" className="mobile-header">
            <div className="mobile-header__menu">
                <a onClick={() => this.toggleMenu()}
                   className="mobile-header__menu__button icon icon--small"
                   dangerouslySetInnerHTML={{__html: menuIcon}}>
                </a>

                <div className={`mobile-header__menu__items ${this.state.isMenuOpen ? 'mobile-header__menu__items--visible' : ''}`}
                     ref={this.node}>
                    <div className="mobile-header__menu__items__header">
                        <a onClick={() => this.toggleMenu()}
                           className="mobile-header__menu__button icon icon--small"
                           dangerouslySetInnerHTML={{__html: menuIcon}}>
                        </a>
                        <h3 className="h--white">{this.title}</h3>
                        <a onClick={() => this.toggleMenu()}
                           className="mobile-header__menu__items__header__close-button icon icon--small"
                           dangerouslySetInnerHTML={{__html: multiplyIcon}}>
                        </a>
                    </div>
                    <div style={{padding: '0 1.143em', overflowX: 'scroll'}}>
                        <div className="mobile-header__menu__items__heading">
                            {this.renderHeader()}
                        </div>
                        {this.renderContent()}
                        <div className="mobile-header__menu__items__footer">
                            {this.renderFooter()}
                        </div>
                    </div>
                </div>
            </div>

            <a href={this.props.urls.home}
               className="mobile-header__logo"
               dangerouslySetInnerHTML={{__html: dkdHeaderLogo}}>
            </a>
            <div className="mobile-header__buttons">
                {state.isCustomerLoggedIn &&
                    <a href={this.props.urls.orderLists} dangerouslySetInnerHTML={{__html: heartOutline}}
                                                className="mobile-header__buttons__button icon icon--small"/>
                }
                {state.isCustomerLoggedIn &&
                    <CartQuantity cart={state.cart} mediaQueries={state.mediaQueries} urls={state.urls}/>
                }
                <a href={this.props.urls.account}
                   dangerouslySetInnerHTML={{__html: userAvatarIcon}}
                   className="mobile-header__buttons__button icon icon--small"/>
            </div>
            <div className="mobile-header__search">
                <ProductSearch/>
            </div>
        </header>;
    }

    protected onDocumentClick = (e: any) => {
        if (this.node.current!.contains(e.target)) {
            // inside click
            return;
        }
        this.closeMenu();
    }

    protected toggleMenu() {
        this.state.isMenuOpen ? this.closeMenu() : this.openMenu();
    }

    protected openMenu() {
        this.setState({isMenuOpen: true});
        document.documentElement.classList.add('--menu-open');
        document.body.style.overflow = 'hidden';
    }

    protected closeMenu() {
        this.setState({isMenuOpen: false});
        document.documentElement.classList.remove('--menu-open');
        document.body.style.overflow = 'unset';
    }
}
