import { FilterGroup, FilterTag } from '../components/base-product-filters';
import * as queryString from 'querystring';
import { ITerm } from '../api/interfaces';

export interface UrlTerm {
    field: string;
    values: string[];
}

export function createTermsParameter(groups: FilterGroup[]) {
    return groups
        .filter((x) => x.items.some((y) => y.isApplied))
        .map(
            (x) =>
                `${x.field}:${x.items
                    .filter((y) => y.isApplied)
                    .map((y) => y.value.replace(/,/g, '_comma_'))
                    .join(',')}`,
        )
        .join(';');
}

export function createSearchParameter(items: FilterTag[]) {
    return items
        .filter((item) => item.isApplied)
        .map((item) => item.value.replace(/,/g, '_comma_'))
        .join(',');
}

export function parseTermsParameter(paramsParameter: string): UrlTerm[] {
    return (
        paramsParameter
            ?.split(';')
            .map((x) => x.split(':', 2))
            .map(
                ([field, valueString]) =>
                    ({
                        field,
                        values: valueString?.split(',').map((x) => x.replace(/_comma_/g, ','))
                    } as UrlTerm),
            ) || []
    );
}

export function parseSearchParameter(paramsParameter: string): string[] {
    return paramsParameter?.split(',').map((x) => x.replace(/_comma_/g, ',')) || [];
}

export function parseTermsParameterAsSingleParams(queryParams: string) {
  return parseTermsParametersIntoGroups(queryParams)?.pop();
}

export function parseTermsParameterIntoOneArray(queryParams: string) {
  return parseTermsParametersIntoGroups(queryParams)?.flat();
}

function parseTermsParametersIntoGroups(queryParams: string) {
  return queryParams
        ?.split(';')
        .map((x) => x.split(':', 2))
        .map(
            ([name, valueString]) =>
                valueString
                    ?.split(',')
                    .map((x) => x.replace(/_comma_/g, ','))
                    .map((value) => ({ name, value } as ITerm)) || [],
        );
}

export function createAbsoluteUrl(params: queryString.ParsedUrlQueryInput) {
    return window.location.origin + window.location.pathname + '?' + createQueryString(params);
}

export function createQueryString(params: queryString.ParsedUrlQueryInput) {
  return queryString.stringify(params);
}
