/* tslint:disable:max-line-length */
import {observer} from 'mobx-react';
import {observable, reaction} from 'mobx';
import {searchNpv} from '../../api/npv';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {formatDate} from '../../utils/format';
import {state} from '../../state';
import {NpvSearchCriteria, NpvSearchResult} from '../../models/interfaces';
import {pencilDrawingIcon, renderSvg} from '../../../images/images';
import {getLabel} from '../../enums/npvStatus';

interface Props {
    visibleColumns?: Column;
    hideSearch?: boolean;
    searchCriteria?: NpvSearchCriteria;
    onLoading?: (loading: boolean) => void;
}

interface State {
    loading: boolean;
    // searchCriteria: NpvSearchCriteria;
    searchResult?: NpvSearchResult;
    visibleColumns: Column;
}

export enum Column {
    OrganizationName = 1 << 0,
    City = 1 << 1,
    FirstLoad = 1 << 2,
    AnnualMargin = 1 << 3,
    Status = 1 << 4,
    CreateDate = 1 << 5,
    Type = 1 << 6,
}

export const defaultColumns: Column =
    Column.OrganizationName |
    Column.City |
    Column.FirstLoad |
    Column.AnnualMargin |
    Column.Type |
    Column.Status;

@observer
export class NpvOverview extends React.Component<Props, State> {

    @observable
    public searchCriteria: NpvSearchCriteria;

    public constructor(props: Props) {
        super(props);

        this.state = {
            loading: true,
            visibleColumns: props.visibleColumns || defaultColumns,
        };

        this.searchCriteria = this.props.searchCriteria || {};

        reaction(
            () => [this.searchCriteria.searchQuery, this.searchCriteria.createdBy],
            async ([searchQuery, createdBy]) => {

                try {
                    const searchResult = await searchNpv({
                        ...this.searchCriteria,
                        searchQuery,
                        createdBy,
                    });
                    this.setLoading(false);
                    this.setState({
                        searchResult: searchResult!,
                    });
                } catch (e) {
                    this.setLoading(false);
                }
            },
            {
                fireImmediately: true,
            },
        );
    }

    public render() {
        return <div className="npv">
            {!this.props.hideSearch &&
            <form className="search">
                <div className="input-wrap">
                    <input type="text"
                           className="input-wrap__input input-wrap__input--bordered filters__input-wrap__search"
                           placeholder="Zoeken op klantnaam of vestigingsplaats..."
                           value={this.searchCriteria.searchQuery}
                           onChange={evt => {
                               this.searchCriteria.searchQuery = (evt.target as HTMLInputElement).value;
                           }}/>
                </div>
                <div className="switch-container switch-container--padding-right filters__head__switch-container">
                    <label className="switch">
                        <input type="checkbox" id="ownNpv" checked={!!this.searchCriteria.createdBy} onChange={evt => {
                            this.searchCriteria.createdBy = (evt.target as HTMLInputElement).checked
                                ? state.customer!.contactId!
                                : undefined;
                        }}/>
                        <span className="toggle round"></span>
                    </label>
                    <label className="switch-container__label switch-container__label--padding-small" htmlFor="ownNpv">
                        Alleen eigen NCW's
                    </label>
                </div>
            </form>
            }
            {this.state.loading
                ? <div className="notice">Bezig met laden...</div>
                : <table className="table table--npv">
                    <thead>
                    <tr>
                        {this.colVisible(Column.CreateDate) && <th>Aangemaakt</th>}
                        {this.colVisible(Column.OrganizationName) && <th>Klantnaam</th>}
                        {this.colVisible(Column.City) && <th>Vestigingsplaats</th>}
                        {this.colVisible(Column.FirstLoad) && <th>Eerste inlading</th>}
                        {this.colVisible(Column.AnnualMargin) && <th>Geschatte jaarmarge</th>}
                        {this.colVisible(Column.Status) && <th>Status</th>}
                        {this.colVisible(Column.Type) && <th>&nbsp;</th>}
                        <th>&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.searchResult && this.state.searchResult.items.length
                        ? this.state.searchResult.items.map(item => <tr key={item.id}>
                                {this.colVisible(Column.CreateDate) && <td title={formatDate(item.createdDate, 'D-M-YYYY HH:mm')}>{formatDate(item.createdDate, 'D MMMM YYYY')}</td>}
                                {this.colVisible(Column.OrganizationName) && <td>{item.organizationName}</td>}
                                {this.colVisible(Column.City) && <td>{item.city}</td>}
                                {this.colVisible(Column.FirstLoad) && <td>{formatDate(item.firstLoad, 'D MMMM YYYY')}</td>}
                                {this.colVisible(Column.AnnualMargin) && <td>{item.annualMargin}</td>}
                                {this.colVisible(Column.Status) && <td>{getLabel(item.status)}</td>}
                                {this.colVisible(Column.Type) && <td>{item.npvType}</td>}
                                <td>
                                    <a href={`${state.urls.npv}/edit?id=${item.id}`}
                                       className="button button--primary button--small">
                                        {renderSvg(pencilDrawingIcon, {className: 'icon'})}
                                    </a>
                                </td>
                            </tr>,
                        )
                        : <tr>
                            <td colSpan={8}>Geen resultaten gevonden</td>
                        </tr>}
                    </tbody>
                </table>
            }
        </div>;
    }

    private colVisible(column: Column) {
        return (this.state.visibleColumns & column) !== 0;
    }

    private setLoading(loading = true) {
        if (this.props.onLoading) {
            this.props.onLoading(loading);
        }
        this.setState({
            loading,
        });
    }
}

export function renderNpvOverview(element: HTMLElement) {
    return ReactDOM.render(<NpvOverview/>, element);
}
