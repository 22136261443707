import apiClient from './client';
import {ContactForm, ContactFormData } from './interfaces';

export async function sendContactForm(form: ContactForm, token: string) {
    const data: ContactFormData = {
        form,
        token,
    };
    await apiClient.post('de_klok/common/contact', data);
}
