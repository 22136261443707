/* tslint:disable:no-var-requires max-line-length */

import {observer} from 'mobx-react';
import {state} from '../state';
import OrderListModel from '../models/orderListModel';
import {Dialog, DialogEventArgs} from './dialog';
import {OrderList} from '../api/interfaces';
import {OrderListType} from '../enums/orderListType';
import * as React from 'react';

const plusIcon = require('../../images/icons8-plus-50.svg');
const heartIcon = require('../../images/icons8-heart-outline.svg');

interface Props {
    onSelected: (list: OrderList, isInList: boolean, created: boolean) => void;
    orderListIds?: string[] | null;
    showHearts?: boolean;
    disabled?: boolean;
}

interface State {
    showAddListDialog: boolean;
    newListName: string;
    loading: boolean;
}

enum DialogResult {
    Ok = 'ok',
    Cancel = 'cancel',
}

@observer
export class OrderListSelection extends React.Component<Props, State> {

    private orderList: OrderListModel;

    public constructor(props: Props) {
        super(props);

        this.state = {
            showAddListDialog: false,
            newListName: '',
            loading: false,
        };
        this.orderList = state.orderList!;
    }

    public render() {
        if (!this.orderList.orderLists) {
            return null;
        }
        const currentOrderList = state.orderList ? state.orderList.selectedOrderList : null;
        return (
            <div className="order-lists button-group">
                {!this.state.showAddListDialog ? [
                        <button key={'newList'} onClick={() => this.setState({showAddListDialog: true})}
                                type="button"
                                disabled={this.props.disabled}
                                className={`button button-group__button button--secondary order-lists__button
                            ${this.props.showHearts ? 'order-lists__button--create-order-list' : ''}`}>
                            <span className="button__content">Nieuwe bestellijst</span>
                            <span className="icon button__icon" dangerouslySetInnerHTML={{__html: plusIcon}}/>
                        </button>,
                        this.orderList.orderLists.map(list => {
                            const isInList = this.isInOrderList(list.id);
                            return (
                                <button onClick={() => this.props.onSelected(list, isInList, false)}
                                        key={list.id}
                                        type="button"
                                        disabled={this.props.disabled}
                                        className={`button button--float
                                    ${this.props.showHearts ? 'button--dark' : 'button--secondary'}
                                    ${currentOrderList && currentOrderList.id === list.id ? 'button--active' : ''}
                                    ${list.type === OrderListType.SapOrderList ? 'order-lists__button--my-order-list' : ''}
                                    order-lists__button button-group__button`}>
                                    {list.name}
                                    {this.props.showHearts ?
                                        [this.props.orderListIds ?
                                            <div>
                                                {isInList ?
                                                    <span className="order-lists__icon order-lists__icon--active"
                                                          dangerouslySetInnerHTML={{__html: heartIcon}}/> : null
                                                }
                                            </div>
                                            : <span className="order-lists__icon"
                                                    dangerouslySetInnerHTML={{__html: heartIcon}}/>] : ''}
                                </button>
                            );
                        }),
                    ] :
                    <Dialog<DialogResult>
                        onResult={evt => this.onAddListResult(evt)}
                        position="bottom"
                            title="Nieuwe bestellijst">
                        <div className="form-row form-row--label-on-top">
                            <label className="label--required form-row__label label--required">Naam bestellijst</label>
                            <input
                                maxLength={30}
                                className="input-wrap__input input-wrap__input--bordered input-wrap__input--half-width"
                                onChange={evt => {
                                    this.setState({newListName: (evt.target as HTMLInputElement).value});
                                }}/>
                        </div>
                        <div className="form-row">
                            <div className="button-group button-group--compact">
                                <button
                                    disabled={this.state.loading || this.state.newListName === ''}
                                    type="button"
                                    className="button button--primary button-group__button button--float"
                                    data-action={DialogResult.Ok}>
                                    Toevoegen
                                </button>
                                <button
                                    disabled={this.state.loading}
                                    type="button"
                                    className="button button--text-primary button-group__button"
                                    data-action={DialogResult.Cancel}>
                                    Annuleren
                                </button>
                            </div>
                        </div>
                    </Dialog>}
            </div>);
    }

    private isInOrderList(id: string) {
        if (this.props.orderListIds) {
            return this.props.orderListIds!.indexOf(id) > -1;
        }
        return false;
    }

    private async onAddListResult(evt: DialogEventArgs<DialogResult>) {
        if (evt.result === DialogResult.Ok) {
            this.setState({loading: true});
            try {
                const orderList = await this.orderList.createNewOrderList(this.state.newListName);
                this.props.onSelected(orderList, false, true);
            } finally {
                this.setState({
                    loading: false,
                    showAddListDialog: false,
                });
            }
        } else {
            this.setState({showAddListDialog: false});
        }
    }
}
