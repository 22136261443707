import * as React from 'react';
import { render } from 'react-dom';
import { formatDate } from '../../utils/format';

interface Props {
    date: string | null;
}

export class DateDisplay extends React.Component<Props, {}> {
    public constructor(props: Props) {
        super(props);
    }

    public render() {
        return this.getFormattedDate();
    }

    private getFormattedDate(): string {
        if (!this.props.date) return '';
        let date: Date | number = Date.parse(this.props.date);
        if (isNaN(date)) return '';

        date = new Date(date);
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        if (this.isSameDate(date, today)) return 'Vandaag';
        else if (this.isSameDate(date, yesterday)) return 'Gisteren';
        if (today.getFullYear() - date.getFullYear() > 0) return formatDate(date, 'D MMMM YYYY');
        return formatDate(date);
    }

    private isSameDate(firstDate: Date, secondDate: Date) {
        return (
            firstDate.getDate() === secondDate.getDate() &&
            firstDate.getFullYear() === secondDate.getFullYear() &&
            firstDate.getMonth() === secondDate.getMonth()
        );
    }
}

export function renderDateDisplay(element: HTMLElement) {
    return render(<DateDisplay date={element.getAttribute('date' as string) || null} />, element!);
}
