/* tslint:disable:variable-name */
import {
  action,
  computed,
  observable,
} from 'mobx';
import {Notification, Notifications} from './interfaces';

export default class NotificationsModel implements Notifications {
  public queue = observable<Notification>([]);

  @observable
  public current: Notification | null = null;

  private timeout: number | undefined;

  @computed
  public get has() {
    return this.queue.length > 0;
  }

  @action
  public take(): Notification | null {
    this.current = this.queue.shift() || null;
    return this.current;
  }

  @action
  public push(text: string) {
    clearTimeout(this.timeout);
    this.timeout = window.setTimeout(() => this.take(), 5000);

    this.take();
    this.queue.push({
      text,
    });
    if (!this.current) {
      this.take();
    }
  }
}
