export function toRuby<T>(o: object): T;
export function toRuby(o: any): any {
    if (o instanceof Array) {
        return o.map(value => {
            if (typeof value === 'object') {
                value = toRuby(value);
            }
            return value;
        });
    } else {
        const newObject: {[key: string]: any} = {};
        for (const origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                const newKey = origKey.replace(/[A-Z]/g, r => '_' + r.toLowerCase());
                let value = o[origKey];
                if (value instanceof Array || (value !== null && value.constructor === Object)) {
                    value = toRuby(value);
                }
                newObject[newKey] = value;
            }
        }
        return newObject;
    }
}
