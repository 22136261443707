/* tslint:disable:max-line-length */
import * as React from 'react';

interface RowProps {
    columns: Array<RowColumn | React.ReactNode>;
    grow?: boolean;
}

export enum RowColumnAlign {
    start = 'start',
    end = 'end',
}

interface RowColumn {
    align?: RowColumnAlign;
    content: React.ReactNode;
    grow?: boolean;
}

interface FormRowColumnProps {
    label?: string;
    required?: boolean;
    labelAttributes?: React.LabelHTMLAttributes<HTMLLabelElement>;
}

function isRowColumn(obj: RowColumn | React.ReactNode): obj is RowColumn {
    return 'content' in (obj as RowColumn);
}

export function FormRow(props: RowProps) {
    const defaultGrow = props.grow === undefined ? true : props.grow;
    const rowColumns: RowColumn[] = props.columns.map(x => isRowColumn(x) ? x : {content: x});
    return (
        <div className="form-row form-row--label-on-top">
            <div className="linear-layout linear-layout--wrap linear-layout--horizontal linear-layout--large-gutters">
                {rowColumns.map((x, i) => (
                    <div key={i}
                         className={[
                             'linear-layout__item',
                             x.grow || defaultGrow && 'linear-layout__item--grow',
                             x.align && ('linear-layout__item--align-' + x.align),
                         ].filter(c => c).join(' ')}>
                        {x.content}
                    </div>
                ))}
            </div>
        </div>
    );
}

// tslint:disable-next-line:variable-name
export const FormRowColumn: React.FunctionComponent<FormRowColumnProps> = props => (
    <div className="form-row__column">
        {props.label &&
        <label className="label--required form-row__label" {...props.labelAttributes}>{props.label}</label>
        }
        <div className="form-row__input">
            {props.children}
        </div>
    </div>
);
