/* tslint:disable:no-var-requires max-line-length */
import { observer } from 'mobx-react';
import { state } from '../state';
import {Notifications} from '../models/interfaces';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface NotificationProps {
    notifications: Notifications;
}

@observer
export class NotificationContainer extends React.Component<NotificationProps> {
    public render() {
        const notification = this.props.notifications.current;
        if (!notification) {
            return null;
        }

        return (
            <div onMouseOver={() => this.props.notifications.take()} className="notification-container__notification">
                {notification.text}
            </div>
        );
    }
}

export function renderNotificationContainer(element: HTMLElement) {
    return ReactDOM.render(<NotificationContainer notifications={state.notifications}/>, element);
}
