export enum VendorType {
    Light = 0,
    Full = 1,
    DeKlokDranken = 2,
}

export function getVendorTypeLabel(vendorType: VendorType): string {
    switch (vendorType) {
        case VendorType.Light:
            return 'Light';
        case VendorType.Full:
            return 'Full';
        case VendorType.DeKlokDranken:
            return 'De Klok Dranken';
        default:
            return 'Onbekend';
    }
}


