/* tslint:disable:max-line-length */
import * as React from 'react';

interface RadioGroupOption<T extends string | string[] | number = any> {
    label: string;
    value: T;
}

interface Props<T extends string | string[] | number = any> {
    name: string;
    required?: boolean;
    disabled?: boolean;
    options: Array<RadioGroupOption<T>>;
    value?: T;
    onChange: (option: T, evt: React.ChangeEvent<HTMLInputElement>) => void;
}

export class RadioGroup<T extends string | string[] | number = any> extends React.Component<Props<T>, {}> {
    private inputElement: HTMLInputElement | null = null;

    public focus() {
        this.inputElement?.focus();
    }

    public blur() {
        this.inputElement?.blur();
    }

    public render() {
        return (
            <div className={[
                'radio-group',
            ].join(' ')}>
                {this.props.options.map((option, i) => (
                    <label key={i} className="radio-group__option">
                        <input type="radio"
                               name={this.props.name}
                               value={option.value}
                               required={this.props.required}
                               disabled={this.props.disabled}
                               checked={option.value === this.props.value}
                               onChange={evt => this.props.onChange(option.value, evt)}
                        />
                        <span className="radio-group__option__text">{option.label}</span>
                    </label>
                ))}
            </div>
        );
    }
}
