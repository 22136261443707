/* tslint:disable:max-line-length */
import * as React from 'react';
import {observer} from 'mobx-react';
import {autorun, observable, reaction, when} from 'mobx';
import {NpvFormStep, NpvStepProps} from './form-step';
import {Npv, NpvOrganization, PreNpv} from '../../../models/interfaces';
import {FormRow, FormRowColumn} from '../../common/form-row';
import {DatePickerInput} from '../../common/date-picker-input';
import {DeliveryMoment} from '../../../enums/deliveryMoment';
import {Input} from '../../common/input';
import {getNpvOrganization, saveNpvOrganization} from '../../../api/npv';
import {NpvOrganizationForm} from '../organization/npv-organization-form';
import {formatEuroPrice, formatPercentage} from '../../../utils/format';
import NpvSalesGroupModel from '../../../models/npvSalesGroupModel';
import {NpvSponsoringType} from '../../../enums/npvSponsoringType';

type DeliveryFormData =
    Pick<Npv, 'deliveryMoment'> &
    Pick<PreNpv, 'firstLoad'>;

@observer
export class NpvForm4 extends NpvFormStep {

    @observable
    private deliveryFormData: DeliveryFormData;

    @observable
    private differentInvoiceEmail = false;

    @observable
    private loadingOrganization = true;

    @observable
    private editOrganization = false;

    @observable
    private organization: NpvOrganization | null = null;

    private contactForm = React.createRef<HTMLFormElement>();
    private deliveryForm = React.createRef<HTMLFormElement>();

    constructor(props: NpvStepProps) {
        super(props);

        this.state = {};

        this.deliveryFormData = {
            firstLoad: props.npv.preNpv?.firstLoad ?? '',
            deliveryMoment: props.npv.deliveryMoment,
        };
        autorun(() => {
            const npv = this.props.npv;
            if (npv.preNpv) {
                npv.preNpv.firstLoad = this.deliveryFormData.firstLoad;
            }
            npv.deliveryMoment = this.deliveryFormData.deliveryMoment;
        });
        when(
            () => this.props.npv.loaded,
            () => this.differentInvoiceEmail = this.props.npv.contactEmail !== this.props.npv.invoiceEmail,
        );
        reaction(() => this.differentInvoiceEmail,
            differentInvoiceEmail => {
                if (!differentInvoiceEmail) {
                    this.props.npv.invoiceEmail = this.props.npv.contactEmail;
                }
            });
        reaction(
            () => this.editOrganization,
            editing => {
                if (editing) {
                    window.scrollTo(0, 0);
                }
            },
        );
        this.loadOrganization();
    }

    public async loadOrganization() {
        this.loadingOrganization = true;
        try {
            this.organization = await getNpvOrganization(this.props.npv.id!);
        } finally {
            this.loadingOrganization = false;
        }
    }

    public render() {
        const contractYears = Array.from({length: this.props.npv.contractDuration}).map((_, i) => i + 1);

        return (

            <div className="npv-form__step npv-form__step--3">

                {!this.editOrganization ? (
                    <React.Fragment>
                        <div className={`row row--dark`}>
                            <div className="content-wrap">
                                <h2 className="h h--primary">Winst- / verliesrekening</h2>
                                <p>Je hebt gekozen voor een contract van {this.props.npv.contractDuration} jaar, wilt u
                                    dit
                                    nog <a href="#1" className="link link--bold">wijzigen</a>?</p>

                                <table className="table table--overview">
                                    <thead>
                                    <tr>
                                        <th>&nbsp;</th>
                                        {contractYears.map(year => (
                                            <th key={year}>
                                                Jaar {year}
                                            </th>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.props.npv.salesGroups.map((salesGroup, row) => (
                                        <tr key={'row' + row}>
                                            <td>{salesGroup.salesGroup.name}</td>
                                            {contractYears.map(year => (
                                                <td key={`row${row}y${year}`}>
                                                    {formatEuroPrice(this.getContractYearRevenue(year, salesGroup))}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                    <tr>
                                        <td>Omzet</td>
                                        {this.props.npv.profitLossAccounts.map(x => (
                                            <td key={x.year}>
                                                {formatEuroPrice(x.annualRevenue)}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>Kortingen</td>
                                        {this.props.npv.profitLossAccounts.map(x => (
                                            <td key={x.year}>
                                                {formatEuroPrice(x.totalDiscountEuros)}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>Sponsoring</td>
                                        {this.props.npv.profitLossAccounts.map(x => (
                                            <td key={x.year}>
                                                {formatEuroPrice(x.sponsoringEuros)}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>Inkoop</td>
                                        {this.props.npv.profitLossAccounts.map(x => (
                                            <td key={x.year}>
                                                {formatEuroPrice(x.purchasingValue)}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr className="table__row--strong">
                                        <td>Marge</td>
                                        {this.props.npv.profitLossAccounts.map(x => (
                                            <td key={x.year}>
                                                {formatEuroPrice(x.marginEuros)} ({formatPercentage(x.marginPercentage)})
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>Interne logistieke kosten</td>
                                        {this.props.npv.profitLossAccounts.map(x => (
                                            <td key={x.year}>
                                                {formatEuroPrice(x.internalLogisticsCost)}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>Externe logistieke kosten</td>
                                        {this.props.npv.profitLossAccounts.map(x => (
                                            <td key={x.year}>
                                                {formatEuroPrice(x.externalLogisticsCost)}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>Commerciele kosten</td>
                                        {this.props.npv.profitLossAccounts.map(x => (
                                            <td key={x.year}>
                                                {formatEuroPrice(x.commercialCost)}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>Overhead kosten</td>
                                        {this.props.npv.profitLossAccounts.map(x => (
                                            <td key={x.year}>
                                                {formatEuroPrice(x.overheadCost)}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>Debiteurenrisico</td>
                                        {this.props.npv.profitLossAccounts.map(x => (
                                            <td key={x.year}>
                                                {formatEuroPrice(x.debtorRiskCost)}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr className="table__row--strong">
                                        <td>EBITA</td>
                                        {this.props.npv.profitLossAccounts.map(x => (
                                            <td key={x.year}>
                                                {formatEuroPrice(x.ebita)}
                                            </td>
                                        ))}
                                    </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>

                        <form className={`row`} ref={this.deliveryForm}>
                            <div className="content-wrap --relative">
                                <div
                                    className="linear-layout linear-layout--horizontal linear-layout--grow-items linear-layout--large-gutters">
                                    <div className="linear-layout__item">
                                        <p>Klopt de datum van de eerste inlading nog steeds?</p>

                                        <FormRowColumn
                                            label="Eerste inlading"
                                            labelAttributes={{htmlFor: 'firstLoad'}}>
                                            <DatePickerInput
                                                id="firstLoad"
                                                required
                                                title="Eerste inlading"
                                                locale="nl"
                                                autoComplete="off"
                                                disabled={this.props.disabled}
                                                selected={DatePickerInput.stringToDate(this.deliveryFormData.firstLoad)}
                                                onChange={date => this.deliveryFormData.firstLoad = DatePickerInput.dateToString(date)}
                                                name="firstLoad"/>
                                        </FormRowColumn>
                                    </div>
                                    <div className="linear-layout__item">
                                        <div className="form-row__column">
                                            <label className="form-row__label">
                                                Leverdagen
                                            </label>
                                            <table className="table">
                                                <tbody>
                                                {this.renderDeliveryMomentRow('Maandag', DeliveryMoment.MondayMorning, DeliveryMoment.MondayAfternoon)}
                                                {this.renderDeliveryMomentRow('Dinsdag', DeliveryMoment.TuesdayMorning, DeliveryMoment.TuesdayAfternoon)}
                                                {this.renderDeliveryMomentRow('Woensdag', DeliveryMoment.WednesdayMorning, DeliveryMoment.WednesdayAfternoon)}
                                                {this.renderDeliveryMomentRow('Donderdag', DeliveryMoment.ThursdayMorning, DeliveryMoment.ThursdayAfternoon)}
                                                {this.renderDeliveryMomentRow('Vrijdag', DeliveryMoment.FridayMorning, DeliveryMoment.FridayAfternoon)}
                                                {this.renderDeliveryMomentRow('Zaterdag', DeliveryMoment.SaturdayMorning, DeliveryMoment.SaturdayAfternoon)}
                                                {this.renderDeliveryMomentRow('Zondag', DeliveryMoment.SundayMorning, DeliveryMoment.SundayAfternoon)}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className={`row row--dark`}>
                            <div className="content-wrap --relative">
                                <h3>Contactgegevens</h3>

                                <form ref={this.contactForm}>

                                    <FormRow columns={[
                                        <FormRowColumn
                                            label="Naam contactpersoon"
                                            required={true}>
                                            <Input
                                                name="contactName"
                                                required
                                                value={this.props.npv.contactName ?? ''}
                                                onChange={evt => this.props.npv.contactName = evt.target.value ?? ''}
                                                disabled={this.props.disabled}
                                            />
                                        </FormRowColumn>,
                                        <FormRowColumn
                                            label="E-mailadres"
                                            required={true}>
                                            <Input
                                                name="contactEmail"
                                                required
                                                value={this.props.npv.contactEmail ?? ''}
                                                onChange={evt => this.props.npv.contactEmail = evt.target.value ?? ''}
                                                disabled={this.props.disabled}
                                            />
                                            {this.props.npv.contactEmail &&
                                            <label className="label">
                                                <input
                                                    disabled={this.props.disabled}
                                                    type="checkbox"
                                                    checked={this.differentInvoiceEmail}
                                                    onChange={evt => {
                                                        this.differentInvoiceEmail = evt.target.checked;
                                                    }}/>
                                                Vink aan indien e-mailadres voor de facturen afwijkt van bestellen
                                            </label>
                                            }
                                            {this.differentInvoiceEmail &&
                                            <FormRowColumn
                                                label="Factuur e-mailadres"
                                                required={true}>
                                                <Input
                                                    name="invoiceEmail"
                                                    required
                                                    value={this.props.npv.invoiceEmail ?? ''}
                                                    onChange={evt => this.props.npv.invoiceEmail = evt.target.value ?? ''}
                                                    disabled={this.props.disabled}
                                                />
                                            </FormRowColumn>
                                            }
                                        </FormRowColumn>,
                                    ]}/>
                                </form>
                            </div>
                        </div>

                        <div className="row">
                            <div className="content-wrap --relative">
                                <h3>
                                    Klantgegevens
                                    {!this.props.disabled &&
                                    <small>
                                        <a href="#"
                                           className="link"
                                           onClick={evt => {
                                               evt.preventDefault();
                                               this.editOrganization = true;
                                           }}>
                                            bewerken
                                        </a>
                                    </small>
                                    }
                                </h3>

                                {this.loadingOrganization ? <div className="notice">Bezig met laden...</div> : (
                                    <div
                                        className="linear-layout linear-layout--horizontal linear-layout--large-gutters">
                                        <div className="linear-layout__item">
                                            <h4>{this.organization?.name}</h4>
                                            <strong>{this.organization?.accountName}</strong><br/>
                                            {this.organization?.customerGroup || '-'}<br/>
                                            {this.organization?.volumeGroup || '-'}<br/>
                                            {this.organization?.mainBeerBrand || '-'}<br/>
                                            {this.organization?.storeLocation || '-'}<br/>
                                            {this.organization?.addWholesalesOm2 || '-'}
                                        </div>
                                        <div className="linear-layout__item">
                                            <h4>Vestigingsadres</h4>
                                            {this.organization?.address.companyLine1 || '-'}<br/>
                                            {this.organization?.address.zipCode || '-'}<br/>
                                            {this.organization?.address.city || '-'}
                                        </div>
                                    </div>
                                )}

                                {this.props.buttons}

                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <NpvOrganizationForm
                            npv={this.props.npv}
                            organization={this.organization!}
                            onSubmit={formData => this.onOrganizationSubmit(formData)}
                            extraButtons={[
                                {
                                    text: 'Annuleren',
                                    onClick: () => this.editOrganization = false,
                                },
                            ]}/>
                    </React.Fragment>
                )}
            </div>
        );
    }

    public isValid(): boolean | Promise<boolean> {
        for (const formRef of [this.deliveryForm, this.contactForm]) {
            if (formRef.current && !formRef.current.reportValidity()) {
                return false;
            }
        }
        return super.isValid();
    }

    protected renderDeliveryMomentRow(dayName: string, morning: DeliveryMoment, afternoon: DeliveryMoment) {
        return (
            <tr>
                <td>
                    <label
                        style={{cursor: 'pointer'}}
                        onClick={() => this.deliveryFormData.deliveryMoment ^= morning ^ afternoon}>{dayName}</label>
                </td>
                <td>
                    <label className="label">
                        <input type="checkbox"
                               value={morning}
                               disabled={this.props.disabled}
                               onChange={() => this.deliveryFormData.deliveryMoment ^= morning}
                               checked={(this.deliveryFormData.deliveryMoment & morning) !== 0}
                        />
                        Ochtend
                    </label>
                </td>
                <td>
                    <label className="label">
                        <input type="checkbox"
                               value={afternoon}
                               disabled={this.props.disabled}
                               onChange={() => this.deliveryFormData.deliveryMoment ^= afternoon}
                               checked={(this.deliveryFormData.deliveryMoment & afternoon) !== 0}
                        />
                        Middag
                    </label>
                </td>
            </tr>
        );
    }

    private async onOrganizationSubmit(formData: NpvOrganization) {
        try {
            this.loadingOrganization = true;
            this.organization = await saveNpvOrganization(this.props.npv.id!, formData);
            this.editOrganization = false;
        } finally {
            this.loadingOrganization = false;
        }
    }

    private onSubmit(evt: React.FormEvent<HTMLFormElement>) {
        // TODO
    }

    private getContractYearRevenue(year: number, salesGroup: NpvSalesGroupModel) {
        return salesGroup.annualRevenue || 0;
    }

    private getTotalContractYearRevenue(year: number) {
        const revenue = this.props.npv.annualRevenue;
        return revenue - this.getSponsoring(year);
    }

    private getSponsoring(year: number) {
        return year === 1 || this.props.npv.sponsoringType === NpvSponsoringType.Yearly
            ? (this.props.npv.sponsoring || 0) * -1 : 0;
    }
}
