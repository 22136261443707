/* tslint:disable:max-line-length */
import * as React from 'react';
import {FilterTag} from './base-product-filters';

interface Props {
  tag: FilterTag;
  hidden: boolean;
  checked: boolean;
  createUrl: (tag: FilterTag) => string;
  updateFromUrl: (newUrl?: string) => void;
}

interface State {
}

export class FilterListGroupTag extends React.Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      checked: props.checked,
    };
  }

  public render() {
    const url = this.props.createUrl(this.props.tag);
    const onClick = (evt: React.MouseEvent<HTMLAnchorElement>) => {
      evt.preventDefault();
      this.props.updateFromUrl(url);
    };
    return <a className={`filter-list__content__tag ${this.props.hidden ? 'filter-list__content__tag--hidden' : ''}`}
              href={url}
              onClick={onClick}>
      <div className={`filter-list__content__tag__checkbox ${this.props.checked ? 'filter-list__content__tag__checkbox--checked' : ''} `}>
      </div>
      <div className="filter-list__content__tag__name">
        {this.props.tag.label} {this.props.tag.count && <span>({this.props.tag.count})</span>}
      </div>
    </a>;
  }
}
