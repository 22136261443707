import { ClaimRequirement } from './claim-requirement';
import { Permission } from './permission';

export const impersonateOrganization = ClaimRequirement.byAll(Permission.Impersonation);
export const manageUsers = ClaimRequirement.byAll(Permission.UserManagement);
export const viewPrices = ClaimRequirement.byAll(Permission.Price);
export const viewOrderHistory = ClaimRequirement.byAll(Permission.OrderHistory);
export const viewInvoiceHistory = ClaimRequirement.byAll(Permission.InvoiceHistory);
export const salesGroupManage = ClaimRequirement.byAll(Permission.SalesGroupManage);
export const netPresentValue = ClaimRequirement.byAll(Permission.NetPresentValue);
export const netPresentValueVerify = ClaimRequirement.byAll(Permission.NetPresentValueVerify);

