/* tslint:disable:no-var-requires max-line-length */
import {observer} from 'mobx-react';
import {state} from '../state';
import {Customer, MessageBoxMessage, Urls} from '../models/interfaces';
import {MediaQuery} from '../media-queries';
import {SearchOrganization} from './search-organization';
import {
    impersonateOrganization,
    manageUsers,
    viewInvoiceHistory,
    viewOrderHistory,
} from '../security/representative-claims';
import {storageClear} from '../storage';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {AccountDetails} from './account-details';
import {OrderOverview} from './order-overview';
import {BackOrders} from './back-orders';
import {InvoiceOverview} from './invoice-overview';
import {UserManagement} from './user-management';
import {autorun, observable} from 'mobx';
import {MessageBox} from './message-box/overview';
import {getMessagaBoxMessages} from '../api/npv';
import {PowerBIReport} from './powerbi/report';
import {TermsAndConditions} from './terms-and-conditions';

interface Props {
    customer: Customer;
    currentOrganization: string | null;
    urls: Urls;
    mediaQueries: Map<MediaQuery, boolean>;
    statusTitle: string | null;
    statusActiveText: string | null;
    statusInactiveText: string | null;
    statusText: string | null;
    privacyTitle: string | null;
    privacyText: string | null;
    privacyLinkText: string | null;
}

interface State {
}

enum Selection {
    MobileMenuSelection,
    AccountSelection,
    OrderSelection,
    OrganizationSelection,
    OpenOrdersSelection,
    InvoiceSelection,
    UserManagementSelection,
    RepresentativeSelection,
    MessageBox,
    Dashboard,
    TermsAndConditions,
}

@observer
export class AccountDropdown extends React.Component<Props, State> {
    private element: HTMLDivElement | null = null;
    private customer = state.customer!;

    @observable
    private selection: Selection | null = null;

    @observable
    private loading = false;

    @observable
    private messages: MessageBoxMessage[] | null = null;

    private get buttonGroupClasses() {
        return [
            'button-group',
            'button-group--wrap-text',
            state.mediaQueries.get(MediaQuery.Small)
                ? 'button-group--fit-2'
                : 'account-dropdown__buttons account-dropdown__buttons--vertical',
        ].join(' ');
    }

    private get buttonGroupOrganizationClasses() {
        return [
            'button-group',
            'account-dropdown__buttons',
            state.mediaQueries.get(MediaQuery.Small)
                ? ''
                : 'button-group--compact',
        ].join(' ');
    }

    public constructor(props: Props) {
        super(props);
        autorun(() => {
            if (state.mediaQueries.get(MediaQuery.Small)) {
                this.selection = Selection.MobileMenuSelection;
            } else {
                this.selection = Selection.AccountSelection;
            }
        });

        this.loadMessagesData();
    }

    public async loadMessagesData() {
        this.loading = true;
        try {
            this.messages = await getMessagaBoxMessages();
        } finally {
            this.loading = false;
        }
    }

    public componentDidMount(): void {
        if (this.element) {
            const dropdown = this.element.closest('app-dropdown');
            if (dropdown) {
                dropdown.addEventListener('closed', this.onDropDownClosed);
            }
        }
    }

    private get currentOrganizationName(): string {
        const organization = this.props.customer.organizations.find(o => (this.props.currentOrganization === o.id));
        if (organization) {
            return organization.name;
        }
        return '';
    }

    public render() {
        let selection = null;
        switch (this.selection) {
            case Selection.MobileMenuSelection:
                selection = this.renderMenuSelection();
                break;
            case Selection.MessageBox:
                selection = this.renderMessageBoxSelection();
                break;
            case Selection.AccountSelection:
                selection = this.renderAccountSelection();
                break;
            case Selection.OrderSelection:
                selection = this.renderOrderSelection();
                break;
            case Selection.OrganizationSelection:
                selection = this.renderOrganizationSelection();
                break;
            case Selection.OpenOrdersSelection:
                selection = this.renderOpenOrderSelection();
                break;
            case Selection.InvoiceSelection:
                selection = this.renderInvoiceSelection();
                break;
            case Selection.UserManagementSelection:
                selection = this.renderUserManagement();
                break;
            case Selection.Dashboard:
                selection = this.renderDashboard();
                break;
            case Selection.TermsAndConditions:
                selection = this.renderTermsAndConditions();
                break;
            case Selection.RepresentativeSelection:
                selection = <SearchOrganization
                    generateLink={org => state.urls.absoluteOrganization.replace('__', org.id)}
                    onClick={() => storageClear()}/>;
                break;
            default:
                return null;
        }

        if (state.mediaQueries.get(MediaQuery.Small)) {
            return <div>
                {this.selection !== Selection.MobileMenuSelection &&
                <a href="#" onClick={() => this.selection = Selection.MobileMenuSelection}>Terug naar
                    menu</a>
                }
                <div>{selection}</div>
            </div>;
        }

        return (
            <div
                className={`account-dropdown ${(this.selection === Selection.Dashboard || this.selection === Selection.RepresentativeSelection) ? 'account-dropdown--full-width' : ''}`}
                ref={el => this.element = el}>
                <div className={this.buttonGroupClasses}>
                    <a onClick={() => this.selection = Selection.AccountSelection}
                       className={`button-group__button button button--secondary account-dropdown__buttons__button
                       ${this.selection === Selection.AccountSelection ? 'button--active' : 'button--float'}`}>
                        Mijn gegevens
                    </a>
                    <a onClick={() => this.selection = Selection.Dashboard}
                       className={`button-group__button button button--secondary account-dropdown__buttons__button
                       ${this.selection === Selection.Dashboard ? 'button--active' : 'button--float'}`}>
                        Dashboard
                    </a>
                    {this.customer.has_access(viewOrderHistory) &&
                    <a onClick={() => this.selection = Selection.OrderSelection}
                       className={`button-group__button button button--secondary account-dropdown__buttons__button
                       ${this.selection === Selection.OrderSelection ? 'button--active' : 'button--float'}`}>
                        Bestellingen
                    </a>}
                    {this.customer.has_access(viewOrderHistory) &&
                    <a onClick={() => this.selection = Selection.OpenOrdersSelection}
                       className={`button-group__button button button--secondary account-dropdown__buttons__button
                       ${this.selection === Selection.OpenOrdersSelection ? 'button--active' : 'button--float'}`}>
                        Open bestellingen
                    </a>}
                    {this.customer.organizations.length > 1 || this.customer.has_access(impersonateOrganization) ? [
                        <a type="button"
                           key={0}
                           className={`button-group__button button button--secondary account-dropdown__buttons__button
                                ${this.selection === Selection.OrganizationSelection ? 'button--active' : 'button--float'}`}
                           onClick={() => this.selection = Selection.OrganizationSelection}
                        >
                            Selecteer afzetpunt
                        </a>,
                    ] : []}

                    {this.customer.has_access(viewInvoiceHistory) &&
                    <a onClick={() => this.selection = Selection.InvoiceSelection}
                       className={`button-group__button button button--secondary account-dropdown__buttons__button
                       ${this.selection === Selection.InvoiceSelection ? 'button--active' : 'button--float'}`}>
                        Facturen
                    </a>}
                    {this.customer.has_access(manageUsers) &&
                    <a onClick={() => this.selection = Selection.UserManagementSelection}
                       className={`button-group__button button button--secondary account-dropdown__buttons__button
                       ${this.selection === Selection.UserManagementSelection ? 'button--active' : 'button--float'}`}>
                        Gebruikersbeheer
                    </a>}
                    {/*
                        {this.customer.has_access(salesGroupManage) &&
                        <a href={state.urls.salesGroups} className="button-group__button button button--secondary button--float">
                            Verkoopgroepen
                        </a>}
                        {this.customer.has_access(netPresentValue) &&
                        <a href={state.urls.npv} className="button-group__button button button--secondary button--float">
                            NCW
                        </a>}
                    */}
                    <a onClick={() => this.selection = Selection.MessageBox}
                       className={`button-group__button button button--secondary account-dropdown__buttons__button
                       ${this.selection === Selection.MessageBox ? 'button--active' : 'button--float'}`}>
                        Berichten {this.messages && <span>&nbsp;({this.messages.length})</span>}
                    </a>
                    <a onClick={() => this.selection = Selection.TermsAndConditions}
                       className={`button-group__button button button--secondary account-dropdown__buttons__button
                       ${this.selection === Selection.TermsAndConditions ? 'button--active' : 'button--float'}`}>
                        Algemene voorwaarden
                    </a>
                </div>
                <div className="account-dropdown__content">
                    <div className="account-dropdown__account-info">
                        <div className="account-dropdown__account-info__titles">
                            <h2 className="account-dropdown__title">{this.getTitle}</h2>
                            <h3 className="account-dropdown__title account-dropdown__title--align-right">Welkom {this.customer.name}</h3>
                        </div>
                        {!(this.selection === Selection.RepresentativeSelection) &&
                        <div>
                            <p className="account-dropdown__organization">{this.currentOrganizationName}</p>
                            <a href={this.props.urls.logout}
                            className="button button--with-icon button--small button--text-primary">
                            <span className="icon icon--text-size account-dropdown__subtitle"
                                dangerouslySetInnerHTML={{__html: require('../../images/icons8-sign-out-50.svg')}}/>
                                Uitloggen
                            </a>
                        </div>}
                    </div>
                    <div className="account-dropdown__content__container">{selection}</div>

                </div>
            </div>);
    }

    private renderMenuSelection() {
        return <div className="account-dropdown" ref={el => this.element = el}>
            <div className={this.buttonGroupClasses}>
                <a onClick={() => this.selection = Selection.AccountSelection}
                   className="button-group__button button button--secondary button--float account-dropdown__buttons__button">
                    Mijn gegevens
                </a>
                <a onClick={() => this.selection = Selection.Dashboard}
                   className="button-group__button button button--secondary button--float account-dropdown__buttons__button">
                    Dashboard
                </a>
                {this.customer.has_access(viewOrderHistory) &&
                <a onClick={() => this.selection = Selection.OrderSelection}
                   className="button-group__button button button--secondary button--float account-dropdown__buttons__button">
                    Bestellingen
                </a>}
                {this.customer.organizations.length > 1 || this.customer.has_access(impersonateOrganization) ? [
                    <a type="button"
                       key={0}
                       className="button-group__button button button--secondary button--float account-dropdown__buttons__button"
                       onClick={() => this.selection = Selection.OrganizationSelection}
                    >
                        Selecteer afzetpunt
                    </a>,
                ] : []}
                {this.customer.has_access(viewOrderHistory) &&
                <a onClick={() => this.selection = Selection.OpenOrdersSelection}
                   className="button-group__button button button--secondary button--float account-dropdown__buttons__button">
                    Open bestellingen
                </a>}
                {this.customer.has_access(viewInvoiceHistory) &&
                <a onClick={() => this.selection = Selection.InvoiceSelection}
                   className="button-group__button button button--secondary button--float account-dropdown__buttons__button">
                    Facturen
                </a>}
                <a onClick={() => this.selection = Selection.MessageBox}
                   className="button-group__button button button--secondary button--float account-dropdown__buttons__button">
                    Berichten {this.messages && <span>&nbsp;({this.messages.length})</span>}
                </a>
                {this.customer.has_access(manageUsers) &&
                <a onClick={() => this.selection = Selection.UserManagementSelection}
                   className="button-group__button button button--secondary button--float account-dropdown__buttons__button">
                    Gebruikersbeheer
                </a>}
                <a onClick={() => this.selection = Selection.TermsAndConditions}
                   className="button-group__button button button--secondary button--float account-dropdown__buttons__button">
                    Algemene voorwaarden
                </a>
            </div>
            <div className="account-dropdown__account-info">
                <h3 className="account-dropdown__title">Welkom {this.customer.name}</h3>
                <p className="account-dropdown__organization">{this.currentOrganizationName}</p>
                <a href={this.props.urls.logout}
                   className="button button--with-icon button--small button--text-primary">
                    <span className="icon icon--text-size account-dropdown__subtitle"
                          dangerouslySetInnerHTML={{__html: require('../../images/icons8-sign-out-50.svg')}}/>
                    Uitloggen
                </a>
            </div>
        </div>;
    }

    // ToDo: move to a component file
    private renderAccountSelection() {
        return <AccountDetails currentOrganization={state.CurrentOrganization!} customer={state.customer!}/>;
    }

    private renderMessageBoxSelection() {
        return <MessageBox loading={this.loading} messages={this.messages || undefined}/>;
    }

    private renderOrderSelection() {
        return <OrderOverview mediaQueries={state.mediaQueries}/>;
    }

    private renderInvoiceSelection() {
        return <InvoiceOverview mediaQueries={state.mediaQueries}/>;
    }

    private renderUserManagement() {
        return <UserManagement/>;
    }

    private renderDashboard() {
        return <PowerBIReport />;
    }

    private renderTermsAndConditions() {
        return <TermsAndConditions
                    customer={state.customer!}
                    statusTitle={this.props.statusTitle}
                    statusActiveText={this.props.statusActiveText}
                    statusInactiveText={this.props.statusInactiveText}
                    statusText={this.props.statusText}
                    privacyTitle={this.props.privacyTitle}
                    privacyText={this.props.privacyText}
                    privacyLinkText={this.props.privacyLinkText}/>;
    }

    private renderOpenOrderSelection() {
        return <BackOrders mediaQueries={state.mediaQueries}/>;
    }

    // ToDo: move to a component file
    private renderOrganizationSelection() {
        return <div className="account-dropdown__switch-organization">
            {this.props.mediaQueries.get(MediaQuery.Small) &&
            <h3 className="account-dropdown__switch-organization__title">Selecteer een afzetpunt:</h3>
            }
            <div className={this.buttonGroupOrganizationClasses}>
                {this.customer.organizations.map((organization, i) => (
                    this.props.currentOrganization === organization.id ?
                        <button key={i}
                                className="button-group__button button button--secondary button--float button--active button--primary-font">
                            {organization.name}
                        </button>
                        :
                        <a key={i} href={this.props.urls.absoluteOrganization.replace('__', organization.id)}
                           onClick={() => storageClear()}
                           className="button-group__button button button--float button--primary-font">
                            {organization.name}
                        </a>
                ))}
                {this.customer.has_access(impersonateOrganization) &&
                <button
                    className="button-group__button button button--secondary button--float button--primary-font account-dropdown__switch-organization__search_customer"
                    onClick={() => this.selection = Selection.RepresentativeSelection}>
                    Klant zoeken
                </button>
                }
            </div>
        </div>;
    }

    private get getTitle() {
        switch (this.selection) {
            case Selection.MessageBox:
                return 'Berichtenbox';
            case Selection.OrderSelection:
                return 'Bestellingen';
            case Selection.OpenOrdersSelection:
                return 'Open bestellingen';
            case Selection.InvoiceSelection:
                return 'Facturen';
            case Selection.UserManagementSelection:
                return 'Gebruikersbeheer';
            case Selection.AccountSelection:
                return 'Mijn gegevens';
            case Selection.OrganizationSelection:
                return 'Selecteer afzetpunt';
            case Selection.RepresentativeSelection:
                return 'Mijn gegevens';
            case Selection.TermsAndConditions:
                return 'Algemene voorwaarden';
            default:
                return '';
        }
    }

    private onDropDownClosed = () => {
        this.selection = Selection.MessageBox;
    };
}

export function renderAccountDropdown(element: HTMLElement) {
    return ReactDOM.render(
        <AccountDropdown customer={state.customer!}
                         currentOrganization={state.current_organization}
                         urls={state.urls}
                         mediaQueries={state.mediaQueries}
                         statusTitle={element.getAttribute('status-title')}
                         statusActiveText={element.getAttribute('status-active-text')}
                         statusInactiveText={element.getAttribute('status-inactive-text')}
                         statusText={element.getAttribute('status-text')}
                         privacyTitle={element.getAttribute('privacy-title')}
                         privacyText={element.getAttribute('privacy-text')}
                         privacyLinkText={element.getAttribute('privacy-link-text')}
        />, element!);
}