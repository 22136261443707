import {$$, $attr} from '../../../components/src/utils/dom.utils';
import {renderCart} from './cart';
import {renderAddToCart} from './add-to-cart';
import {renderAddToCartV2} from './add-to-cart-v2';
import {renderNotificationContainer} from './notification-container';
import {renderCartQuantity} from './cart-quantity';
import {renderCartCheckout} from './cart-checkout';
import {renderAccountDropdown} from './account-dropdown';
import {renderOrderListDetail} from './order-list-detail';
import {renderProductSearch} from './product-search';
import {renderAddToList} from './add-to-list';
import {renderOrderListOverview} from './order-list-overview';
import {renderAccountDetails} from './account-details';
import {renderOrderOverview} from './order-overview';
import {renderOrderDetail} from './order-detail';
import {renderUserManagement} from './user-management';
import {renderTermsAndConditions} from './terms-and-conditions';
import {renderFirstLogin} from './first-login';
import {renderRegistration} from './registration';
import {renderInvoiceOverview} from './invoice-overview';
import {renderInvoiceDetails} from './invoice-details';
import {renderBackOrders} from './back-orders';
import {renderContact} from './contact';
import {renderMenuDropdown} from './menu-dropdown';
import {renderUpdatedTerms} from './updated-terms';
import {renderSalesGroupsOverview} from './npv/sales-groups/overview';
import {renderNpvOverview} from './npv/overview';
import {renderNpvSearchCustomer} from './npv/new/search-customer';
import {renderNpvNewProspect} from './npv/new/new-prospect';
import {renderPreNpv} from './npv/new/pre-npv';
import {renderNpvForm} from './npv/edit/npv-form';
import {renderGenericForm} from './generic-form';
import {renderNpvAgreement} from './npv/edit/npv-agreement';
import {renderFilterList} from './collection-filters';
import {renderNoResults} from './no-results';
import {renderMobileHeader} from './mobile-header';
import {renderExpandableText} from './content-components/expandable-text';
import {renderDateDisplay} from './common/date-display';
import {renderDateMonthDisplay} from './common/date-month-display';
import {renderNewsFilterList} from './news-filters';
import {renderPsInFood} from './ps-in-food';
import {renderScrollingCarousel} from './scrolling-carousel';


export const componentRenderers: { [component: string]: (element: HTMLElement) => void } = {
    Cart: renderCart,
    AddToCart: renderAddToCart,
    AddToCartV2: renderAddToCartV2,
    AddToList: renderAddToList,
    NotificationContainer: renderNotificationContainer,
    CartQuantity: renderCartQuantity,
    CartCheckout: renderCartCheckout,
    AccountDropdown: renderAccountDropdown,
    ProductSearch: renderProductSearch,
    DeKlokOrderList: renderOrderListDetail,
    OrderListOverview: renderOrderListOverview,
    AccountDetails: renderAccountDetails,
    OrderOverview: renderOrderOverview,
    InvoiceOverview: renderInvoiceOverview,
    InvoiceDetails: renderInvoiceDetails,
    OrderDetail: renderOrderDetail,
    UserManagement: renderUserManagement,
    TermsAndConditions: renderTermsAndConditions,
    FirstLogin: renderFirstLogin,
    UpdatedTerms: renderUpdatedTerms,
    Registration: renderRegistration,
    BackOrders: renderBackOrders,
    Contact: renderContact,
    Menu: renderMenuDropdown,
    SalesGroups: renderSalesGroupsOverview,
    NPV: renderNpvOverview,
    NewNPVSearchCustomer: renderNpvSearchCustomer,
    NewNPVNewProspect: renderNpvNewProspect,
    PreNpv: renderPreNpv,
    NpvForm: renderNpvForm,
    NpvAgreement: renderNpvAgreement,
    GenericForm: renderGenericForm,
    CollectionFilters: renderFilterList,
    NoResults: renderNoResults,
    MobileHeader: renderMobileHeader,
    ExpandableText: renderExpandableText,
    DateDisplay: renderDateDisplay,
    DateMonthDisplay: renderDateMonthDisplay,
    NewsFilters: renderNewsFilterList,
    PsInFood: renderPsInFood,
    ScrollingCarousel: renderScrollingCarousel,
};

export function renderComponentElements(parent: HTMLElement | null = null, attributeName = 'data-component') {
    return $$<HTMLElement>(`[${attributeName}]`, parent).forEach(el => {
        const component = $attr(el, attributeName);
        componentRenderers[component](el);
    });
}
