import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {observer} from 'mobx-react';
import {state} from '../state';

interface Props {
    query: string | null;
}

interface State {}

@observer
export class NoResults extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public render() {
        return state.searchChanged?.sChanged && <div className="row__content">
            <h6 className="h--centered">
                Geen resultaten gevonden voor <strong>'{state.searchChanged?.sChangedFrom}'</strong>
            </h6>
            <p className="h--primary">
                Zoekopdracht is aangepast naar <strong>{state.searchChanged?.sChangedTo}</strong>
            </p>
        </div>;
    }
}


export function renderNoResults(element: HTMLElement) {
    return ReactDOM.render(<NoResults query={element.getAttribute('query' as string)} />, element);
}
