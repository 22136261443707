/* tslint:disable:no-var-requires max-line-length */
import { observer } from 'mobx-react';
import {UserManagementOverview} from './overview';
import {UserManagementForm} from './form';
import {state} from '../../state';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

@observer
export class UserManagement extends React.Component {
    public render() {
        if (state.location.hashPath) {
            if (state.location.hashPath === UserManagementOverview.createUserHashPath) {
                return <UserManagementForm/>;
            } else {
                return <UserManagementForm user={state.location.hashPath} />;
            }
        } else {
            return <UserManagementOverview/>;
        }
    }
}

export function renderUserManagement(element: HTMLElement) {
    return ReactDOM.render(<UserManagement />, element);
}
