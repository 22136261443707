import * as React from 'react';
import {observer} from 'mobx-react';
import {IReactionDisposer, observable, reaction} from 'mobx';
import {LineItem} from '../models/interfaces';

interface Props {
    item: LineItem;
    disabled: boolean;
    saveQuantity: (quantity: number, item: LineItem) => void;
}

@observer
export class CartItemQuantity extends React.Component<Props, {}> {

    @observable
    public value: number;
    private reactionDisposer?: IReactionDisposer;

    public constructor(props: Props) {
        super(props);
        this.value = props.item.quantity;
    }

    public componentDidMount() {
        this.reactionDisposer = reaction(() => this.props.item.quantity,
            quantity => {
                this.value = quantity;
            });
    }

    public componentWillUnmount() {
        this.reactionDisposer!();
    }

    public render() {
        return (

            <input type="number"
                   className="cart__buttons__input"
                   name={`quantity[${this.props.item.sku}]`}
                   value={this.value.toString()}
                   step={this.props.item.product!.quantity_increment}
                   min={this.props.item.product!.quantity_increment}
                   max="10000"
                   disabled={this.props.disabled}
                   onChange={evt => this.value = parseInt(evt.target.value, 10)}
                   onBlur={(evt) => {
                       if (this.value !== this.props.item.quantity) {
                           this.props.saveQuantity(this.value, this.props.item);
                       }
                   }}
                   onFocus={evt => {
                       evt.target.select();
                   }}
            />
        );
    }
}
