import {
    $$,
    $attr,
} from '../../components/src/utils/dom.utils';
import {
    computed,
    IComputedValue,
} from 'mobx';
import { state } from './state';
import 'hammerjs';
import { formatPrice } from './utils/format';

export function registerBehaviors(parent: HTMLElement | null = null) {

    $$('[data-bind]', parent).forEach(el => {

        const dataBindString = $attr(el, 'data-bind');
        const dataBinding: IComputedValue<{ [ attribute: string ]: any }> = (new Function(
            'computed', 'state', `return computed(() => ({${dataBindString}}))`))(computed, state);
        dataBinding.observe(change => {

            Object.keys(change.newValue).forEach(attribute => {
                const value = change.newValue[ attribute ];
                if (value === undefined || value === null) {
                    el.removeAttribute(attribute);
                } else {
                    el.setAttribute(attribute, value.toString());
                }
            });
        }, true);
    });

    $$('[data-product]', parent).forEach(async el => {

        const sku = $attr(el, 'data-product');
        const unit = el.getAttribute('data-product-unit');

        // We don't show price and stock info when not logged in
        if (!state.customer) {
            return;
        }

        const priceElement = el.querySelector<HTMLElement>('[data-price]');
        const stockElement = el.querySelector<HTMLElement>('[data-stock]');
        const addToCartElement = el.querySelector<HTMLElement>('[data-add-to-cart]');

        if (priceElement || stockElement) {
            el.classList.add('--loading');
            try {
                const priceStock = await state.price_stock.get_price_stock_result({
                    measureUnit: unit,
                    sku,
                    quantity: 1,
                });

                if (!priceStock) {
                    console.warn(`No price retrieved for product with sku ${sku}.`);
                    return;
                }

                if (addToCartElement) {
                    if (!priceStock.canBeOrdered) {
                        addToCartElement.remove();
                    }
                }

                if (priceElement) {
                    priceElement.innerText = formatPrice(priceStock.price);
                }
                const metaTag = document.getElementById('meta-price-tag_' + sku);
                if (metaTag) {
                    metaTag.setAttribute('content', formatPrice(priceStock.price));
                }

                if (stockElement) {
                    stockElement.title = priceStock.stockDescription || '';
                    stockElement.innerText = priceStock.stockTitle || 'Onbekend';
                    stockElement.style.color = priceStock.stockColor || '#CE3037';
                }
            } finally {
                el.classList.remove('--loading');
            }
        }
    });

    $$<HTMLAppSliderElement>('app-slider', parent)
        .forEach(sliderEl => {
            sliderEl.style.display = 'block';
            const mc = new Hammer(sliderEl);
            mc.on('swipeleft', () => sliderEl.next(true));
            mc.on('swiperight', () => sliderEl.prev(true));
        });

    $$<HTMLInputElement>('.input-wrap .input-wrap__input', parent)
        .forEach(input => {
            input.addEventListener('focus', () => input.parentElement!.classList.add('input-wrap--focus'));
            input.addEventListener('blur', () => input.parentElement!.classList.remove('input-wrap--focus'));
        });
}
