import apiClient, {getResponseData} from './client';
import {
    EmbedInfo,
    EmbedParams,
    MessageBoxMessage,
    Npv, NpvAgreementData,
    NpvOrganization,
    NpvSearchCriteria,
    NpvSearchResult, NpvSignature,
    PreNpv,
    SalesGroup,
    SalesGroupProduct,
    SalesGroupWithCategory,
    SelectOption,
} from '../models/interfaces';
import {DeepPartial} from '../../../../custom';

export async function getSalesGroups(outline?: string) {
    return getResponseData(await apiClient.get<SalesGroupWithCategory[]>(`de_klok/sales_groups`, {
        params: {
            outline,
        },
    }));
}

export async function getSalesGroupProducts(salesGroupId: string) {
    return getResponseData(await apiClient.get<SalesGroupProduct[]>(`de_klok/sales_groups/products`, {
        params: {
            salesGroupId,
        },
    }));
}

export async function getRootCategoryOptions() {
    return getResponseData(await apiClient.get<SelectOption[]>(`de_klok/sales_groups/category_options`));
}

export async function saveSalesGroup(salesGroup: DeepPartial<SalesGroup>) {
    await apiClient.put<void>(`de_klok/sales_groups`, salesGroup);
}

export async function updateSalesGroupPurchasePrices(salesGroupIds: string[]) {
    return getResponseData(await apiClient.post<SalesGroup[]>(`de_klok/sales_groups/purchase-prices`, salesGroupIds));
}

export async function deleteSalesGroup(id: string) {
    return apiClient.delete(`de_klok/sales_groups/${id}`);
}

export async function getNpv(id: string) {
    return getResponseData(await apiClient.get<Npv>(`de_klok/npv/${id}`));
}

export async function getNpvAgreementData(id: string) {
    return getResponseData(await apiClient.get<NpvAgreementData>(`de_klok/npv/agreement/${id}`));
}

export async function getNpvAgreementDataByCode(id: string, agreementCode: string) {
    return getResponseData(await apiClient.get<NpvAgreementData>(`de_klok/npv/agreement/${id}/${agreementCode}`));
}

export async function getNpvDistrictManagerMargin(id: string) {
    return getResponseData(await apiClient.get<number>(`de_klok/npv/${id}/districtManagerMargin`));
}

export async function searchNpv(searchCriteria: NpvSearchCriteria) {
    return getResponseData(await apiClient.post<NpvSearchResult>(`de_klok/npv/search`, searchCriteria));
}

export async function saveProspect(newProspect: NpvOrganization) {
    return getResponseData(await apiClient.post<NpvOrganization>(`de_klok/npv/prospect`, newProspect));
}

export async function savePreNpv(organization: string, npv: PreNpv) {
    return getResponseData(await apiClient.post<string>(`de_klok/npv/pre-npv?organization=${organization}`, npv));
}

export async function submitNpv(id: string) {
    return getResponseData(await apiClient.post<Npv>(`de_klok/npv/${id}/submit`));
}

export async function approveNpv(id: string) {
    return getResponseData(await apiClient.post<Npv>(`de_klok/npv/${id}/approve`));
}

export async function rejectNpv(id: string, comment: string) {
    return getResponseData(await apiClient.post<Npv>(`de_klok/npv/${id}/reject`, {}, {
        params: {
            comment,
        },
    }));
}

export async function saveNpv(npv: Npv) {
    return getResponseData(await apiClient.put<Npv>(`de_klok/npv`, npv));
}

export async function signNpv(id: string, signature: NpvSignature) {
    return getResponseData(await apiClient.put<Npv>(`de_klok/npv/${id}/sign`, signature));
}

export async function refreshNpv(npv: Npv) {
    return getResponseData(await apiClient.put<Npv>(`de_klok/npv/refresh`, npv));
}

export async function supportMco(id: string, comment?: string) {
    return getResponseData(await apiClient.post<boolean>(`de_klok/npv/${id}/support_mco`, {
        comment,
    }));
}

export async function getNpvOrganization(id: string) {
    return getResponseData(await apiClient.get<NpvOrganization>(`de_klok/npv/${id}/organization`));
}

export async function saveNpvOrganization(id: string, data: NpvOrganization) {
    return getResponseData(await apiClient.put<NpvOrganization>(`de_klok/npv/${id}/organization`, data));
}

export async function getMessagaBoxMessages() {
    return getResponseData(await apiClient.get<MessageBoxMessage[]>(`de_klok/message-box`));
}

export async function getPowerBiReportEmbedInfo() {
    return getResponseData(await apiClient.get<EmbedInfo>(`de_klok/powerbi/embed-info`));
}

