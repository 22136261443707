import {observer} from 'mobx-react';
import * as React from 'react';
import {getPowerBiReportEmbedInfo} from '../../api/npv';
import {factories, IEmbedConfiguration, service} from 'powerbi-client';
import {autorun, observable} from 'mobx';
import {EmbedInfo} from '../../models/interfaces';
import {BackgroundType, LayoutType, TokenType} from 'powerbi-models';
import {state} from '../../state';
import {MediaQuery} from '../../media-queries';
import {WindowPortal} from './windowPortal';
import {expandIcon, renderSvg} from '../../../images/images';

const powerbi = new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);

interface Props {
}

@observer
export class PowerBIReport extends React.Component<Props> {

    @observable
    private reportContainer = React.createRef<HTMLDivElement>();

    @observable
    private reportPopupContainer = React.createRef<HTMLDivElement>();

    @observable
    private loading = false;

    @observable
    private embedInfo: EmbedInfo | null = null;

    @observable
    private showInPopup = false;

    @observable
    private error: any | null = null;

    private refreshTokenTimeout: number | null = null;

    constructor(props: Props) {
        super(props);

        autorun(() => this.embed());

        this.loadEmbedInfo();
    }

    public get popupFeatures() {
        const width = Math.max(screen.availWidth, 1300);
        const height = Math.max(screen.availHeight, width * (9 / 16));
        const left = (screen.availWidth - width) / 2;
        const top = (screen.availHeight - height) / 2;
        return `width=${width},height=${height},top=${top},left=${left}`;
    }

    public async loadEmbedInfo() {
        this.loading = true;
        this.error = null;
        try {
            this.embedInfo = await getPowerBiReportEmbedInfo();
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.loading = false;
        }
    }

    public render() {
        if (this.loading) {
            return <p className="notice">Bezig met laden...</p>;
        }
        if (!this.embedInfo || this.error) {
            if (this.error?.response?.status === 401) { // Unauthorized
                return (
                    <p className="notice notice--warn">
                        U heeft geen toegang tot het dashboard.
                    </p>
                );
            }
            return (
                <p className="notice notice--warn">
                    Fout bij het laden
                    {this.error ?? <>:<br /><span>{this.error}</span></>}
                </p>
            );
        }

        const isMobile = state.mediaQueries.get(MediaQuery.Small) ||
            state.mediaQueries.get(MediaQuery.Medium);
        return (
            <>
                <div style={{height: '100%'}} className="canvas">
                    <div className="canvas__toolbar">
                        <span className="canvas__toolbar__title">
                            {this.embedInfo.title}
                        </span>
                        <div className="canvas__toolbar__buttons">
                            <button type="button"
                                    className="canvas__toolbar__buttons__button"
                                    title="Dashboard vergroten"
                                    onClick={() => this.showInPopup = true}>
                                {renderSvg(expandIcon, {
                                    className: 'icon icon--text-size',
                                })}
                            </button>
                        </div>
                    </div>
                    <div ref={this.reportContainer} className={isMobile ? '--ratio-9-16' : '--ratio-16-9'} />
                </div>
                {this.showInPopup && (
                    <WindowPortal
                        title={this.embedInfo.title}
                        closeWindowPortal={() => this.showInPopup = false}
                        fullScreen={true}
                        features={this.popupFeatures}
                        bodyStyle={{padding: 0}}>
                        <div ref={this.reportPopupContainer}
                             className={isMobile ? '--ratio-9-16' : '--ratio-16-9'}/>
                    </WindowPortal>
                )}
            </>
        );
    }

    private getLayoutType() {
        if (state.mediaQueries.get(MediaQuery.Medium)) {
            return LayoutType.MobileLandscape;
        }
        if (state.mediaQueries.get(MediaQuery.Small)) {
            return LayoutType.MobilePortrait;
        }
        return undefined;
    }

    private embed() {
        if (!this.embedInfo || !this.reportContainer.current) {
            return;
        }

        const reportLoadConfig: IEmbedConfiguration = {
            type: 'report',
            tokenType: TokenType.Embed,
            accessToken: this.embedInfo.embedParams.embedToken.token,
            embedUrl: this.embedInfo.embedParams.embedReport[0].embedUrl,
            pageView: 'fitToWidth',
            settings: {
                panes: {
                    filters: {
                        visible: false,
                    },
                },
                background: BackgroundType.Transparent,
                layoutType: this.getLayoutType(),
                ...(this.embedInfo.embedSettings || {}),
            },
        };

        const tokenExpiry = this.embedInfo.embedParams.embedToken.expiresIn;
        if (this.refreshTokenTimeout) {
            clearTimeout(this.refreshTokenTimeout);
        }
        this.refreshTokenTimeout = window.setTimeout(async () => {
            this.refreshTokenTimeout = null;
            await this.loadEmbedInfo();
        }, tokenExpiry * 1000);

        this.embedInElement(this.reportContainer.current, reportLoadConfig);
        if (this.showInPopup && this.reportPopupContainer.current) {
            this.embedInElement(this.reportPopupContainer.current, reportLoadConfig);
        }
    }

    private embedInElement(container: HTMLElement, reportLoadConfig: IEmbedConfiguration) {

        // Embed Power BI report when Access token and Embed URL are available
        const report = powerbi.embed(container, reportLoadConfig);
        report.iframe.style.border = '0';
        report.off('loaded');
        report.on('loaded', () => {
            console.log('Report load successful');
        });

        report.off('rendered');
        report.on('rendered', () => {
            console.log('Report render successful');
        });

        report.off('error');
        report.on('error', (event) => {
            const errorMsg = event.detail;
            // Use errorMsg variable to log error in any destination of choice
            console.error(errorMsg);
            return;
        });
    }
}
