/* tslint:disable:max-line-length */
import * as React from 'react';
import {observer} from 'mobx-react';
import {autorun, computed, observable, reaction} from 'mobx';
import {NpvFormStep, NpvStepProps} from './form-step';
import {FormRow, FormRowColumn, RowColumnAlign} from '../../common/form-row';
import {Npv, NpvDiscountScale} from '../../../models/interfaces';
import {Input} from '../../common/input';
import NpvDiscountScaleModel from '../../../models/npvDiscountScaleModel';
import {rangesOverlap} from '../../../utils/math';
import {FormType} from '../../../../../../custom';
import {RadioGroup} from '../../common/radio-group';
import * as SponsoringType from '../../../enums/npvSponsoringType';
import {DatePickerInput} from '../../common/date-picker-input';
import {debounce} from 'lodash';
import {formatPercentage} from '../../../utils/format';
import {NpvSponsoringType} from '../../../enums/npvSponsoringType';

type SponsoringFormData = Pick<Npv,
    'sponsoring' |
    'sponsoringPaymentDate' |
    'sponsoringType'>;

@observer
export class NpvForm3 extends NpvFormStep {

    @observable
    private newDiscountScaleFormData: FormType<NpvDiscountScale> = {
        fromAmount: '',
        toAmount: '',
        discountPercentage: '',
        id: null,
    };

    @observable
    private sponsoringFormData: FormType<SponsoringFormData>;

    private sponsoringForm = React.createRef<HTMLFormElement>();

    @computed
    private get discountScalesOrdered() {
        return this.props.npv.discountScales
            .slice()
            .sort((a, b) => (a.fromAmount || 0) - (b.fromAmount || 0));
    }

    private toAmountInput: Input | null = null;

    constructor(props: NpvStepProps) {
        super(props);

        this.sponsoringFormData = {
            sponsoringType: props.npv.sponsoringType === null ? '' : props.npv.sponsoringType,
            sponsoring: props.npv.sponsoring === null ? '' : props.npv.sponsoring,
            sponsoringPaymentDate: props.npv.sponsoringPaymentDate === null ? '' : props.npv.sponsoringPaymentDate,
        };
        autorun(() => {
            const npv = this.props.npv;
            npv.sponsoring = this.sponsoringFormData.sponsoring === '' ? null : this.sponsoringFormData.sponsoring;
            npv.sponsoringType = this.sponsoringFormData.sponsoringType === '' ? null : this.sponsoringFormData.sponsoringType;
            npv.sponsoringPaymentDate = this.sponsoringFormData.sponsoringPaymentDate === '' ? null : this.sponsoringFormData.sponsoringPaymentDate;
        });

        reaction(() => this.props.npv.discountScales.length, this.props.requestRefresh);
    }

    public isValid(): boolean | Promise<boolean> {
        if (this.sponsoringForm.current && !this.sponsoringForm.current.reportValidity()) {
            return false;
        }
        return super.isValid();
    }

    public render() {
        return (

            <div className="npv-form__step npv-form__step--3">
                <div className="row row--dark">
                    <form className="content-wrap" action="" onSubmit={evt => this.onNewDiscountScaleSubmit(evt)}>
                        <h2 className="h h--primary">Staffels</h2>
                        <p>Je mag zelf bepalen hoeveel staffels je aanmaakt, de staffels mogen niet overlappen. Deze
                            stap is net als de overige condities optioneel.</p>

                        <FormRow grow={false} columns={[
                            <FormRowColumn
                                label="Bedrag van"
                                labelAttributes={{htmlFor: 'new_fromAmount'}}>
                                <Input type="number"
                                       id="new_fromAmount"
                                       narrow={true}
                                       disabled={this.props.disabled}
                                       value={this.newDiscountScaleFormData.fromAmount ?? ''}
                                       onChange={evt => {
                                           this.newDiscountScaleFormData.fromAmount = evt.target.value !== '' ?
                                               parseFloat(evt.target.value) : '';
                                       }}
                                       onBlur={() => this.validateFromAmount()}
                                       name="fromAmount"
                                       inputPrefix={
                                           <span className="input-wrap__prefix">€</span>
                                       }
                                />
                            </FormRowColumn>,
                            <FormRowColumn
                                label="Bedrag tot"
                                labelAttributes={{htmlFor: 'new_toAmount'}}>
                                <Input type="number"
                                       id="new_toAmount"
                                       narrow={true}
                                       ref={x => this.toAmountInput = x}
                                       disabled={this.props.disabled}
                                       value={this.newDiscountScaleFormData.toAmount ?? ''}
                                       onChange={evt => {
                                           this.newDiscountScaleFormData.toAmount = evt.target.value !== '' ?
                                               parseFloat(evt.target.value) : '';
                                       }}
                                       onBlur={() => this.validateToAmount()}
                                       name="toAmount"
                                       inputPrefix={
                                           <span className="input-wrap__prefix">€</span>
                                       }
                                />
                            </FormRowColumn>,
                            <FormRowColumn
                                label="Kortingspercentage"
                                labelAttributes={{htmlFor: 'new_discountPercentage'}}
                                required={true}>
                                <Input type="number"
                                       id="new_discountPercentage"
                                       required
                                       narrow={true}
                                       disabled={this.props.disabled}
                                       value={this.newDiscountScaleFormData.discountPercentage !== ''
                                           ? Math.round(this.newDiscountScaleFormData.discountPercentage * 100).toString() : ''}
                                       onChange={evt => {
                                           this.newDiscountScaleFormData.discountPercentage = evt.target.value !== '' ?
                                               parseFloat(evt.target.value) / 100 : '';
                                       }}
                                       name="toAmount"
                                       inputSuffix={
                                           <span className="input-wrap__suffix">%</span>
                                       }
                                />
                            </FormRowColumn>,
                            {
                                align: RowColumnAlign.end,
                                content: (
                                    <FormRowColumn>
                                        <button className="button button--primary --m0"
                                                disabled={this.props.disabled}
                                                type="submit">
                                            +
                                        </button>
                                    </FormRowColumn>
                                ),
                            },
                        ]}/>

                    </form>

                </div>

                <div className="row">
                    <div className="content-wrap --relative">

                        {this.discountScalesOrdered.length > 0 && (
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>Bedrag van</th>
                                    <th>Bedrrag tot</th>
                                    <th>Kortingspercentage</th>
                                    <th>&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.discountScalesOrdered.map((discountScale, i) => (
                                    <tr key={i}>
                                        <td>€ {discountScale.fromAmount ?? '-'}</td>
                                        <td>€ {discountScale.toAmount ?? '-'}</td>
                                        <td>{formatPercentage(discountScale.discountPercentage)}</td>
                                        <td>
                                            <button className="button button--small button--primary"
                                                    disabled={this.props.disabled}
                                                    type="button"
                                                    onClick={() => this.props.npv.discountScales.remove(discountScale)}>
                                                x
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        )}

                        <h3>Overige condities</h3>
                        <p>Sponsoring, bruikleen?</p>

                        <form ref={this.sponsoringForm}>
                            <FormRow grow={false} columns={[
                                <FormRowColumn
                                    label="Sponsoring"
                                    labelAttributes={{htmlFor: 'sponsoring'}}>
                                    <Input type="number"
                                           id="sponsoring"
                                           narrow={true}
                                           required
                                           disabled={this.props.disabled}
                                           value={this.sponsoringFormData.sponsoring ?? ''}
                                           onChange={evt => {
                                               this.sponsoringFormData.sponsoring = evt.target.value !== '' ?
                                                   parseFloat(evt.target.value) : '';
                                           }}
                                           onBlur={() => this.validateToAmount()}
                                           name="toAmount"
                                           inputPrefix={
                                               <span className="input-wrap__prefix">€</span>
                                           }
                                    />
                                </FormRowColumn>,
                                <FormRowColumn
                                    label="Uitbetaling">
                                    <RadioGroup<NpvSponsoringType>
                                        name="sponsoringType"
                                        disabled={this.props.disabled}
                                        required
                                        value={this.sponsoringFormData.sponsoringType || undefined}
                                        options={SponsoringType.getOptions().map(x => ({
                                            label: SponsoringType.getLabel(x),
                                            value: x,
                                        }))}
                                        onChange={(sponsoringType, evt) => {
                                            this.sponsoringFormData.sponsoringType = sponsoringType;
                                        }}/>
                                </FormRowColumn>,
                            ]}/>

                            <FormRow grow={false} columns={[
                                <FormRowColumn
                                    label="Datum uitbetaling"
                                    labelAttributes={{htmlFor: 'sponsoringPaymentDate'}}>
                                    <DatePickerInput
                                        id="sponsoringPaymentDate"
                                        disabled={this.props.disabled}
                                        required
                                        selected={DatePickerInput.stringToDate(this.sponsoringFormData.sponsoringPaymentDate)}
                                        onChange={date => this.sponsoringFormData.sponsoringPaymentDate = DatePickerInput.dateToString(date)}
                                    />
                                </FormRowColumn>,
                            ]}/>
                        </form>

                        {this.props.buttons}

                    </div>
                </div>
            </div>
        );
    }

    private validateFromAmount() {
        let from = this.newDiscountScaleFormData.fromAmount === '' ? undefined : this.newDiscountScaleFormData.fromAmount;
        let to = this.newDiscountScaleFormData.toAmount === '' ? undefined : this.newDiscountScaleFormData.toAmount;
        if (this.newDiscountScaleFormData.toAmount && from && this.newDiscountScaleFormData.toAmount < from) {
            to = from;
        }
        if (!from) {
            return;
        }

        for (const discountScale of this.discountScalesOrdered) {
            if (rangesOverlap(from, to, discountScale.fromAmount, discountScale.toAmount)) {
                if (!discountScale.toAmount) {
                    this.newDiscountScaleFormData.fromAmount = '';
                    return;
                }
                from = discountScale.toAmount + 1;
                to = discountScale.toAmount + 1;
            }
        }
        this.newDiscountScaleFormData.fromAmount = from;
        this.newDiscountScaleFormData.toAmount = to === undefined ? '' : to;
    }

    private validateToAmount() {
        let from = this.newDiscountScaleFormData.fromAmount === '' ? undefined : this.newDiscountScaleFormData.fromAmount;
        let to = this.newDiscountScaleFormData.toAmount === '' ? undefined : this.newDiscountScaleFormData.toAmount;
        if (this.newDiscountScaleFormData.fromAmount && to && this.newDiscountScaleFormData.fromAmount > to) {
            from = to;
        }
        if (!to) {
            return;
        }

        for (const discountScale of this.discountScalesOrdered.slice().reverse()) {
            if (rangesOverlap(from, to, discountScale.fromAmount, discountScale.toAmount)) {
                if (!discountScale.fromAmount) {
                    this.newDiscountScaleFormData.toAmount = '';
                    return;
                }
                from = discountScale.fromAmount - 1;
                to = discountScale.fromAmount - 1;
            }
        }
        this.newDiscountScaleFormData.toAmount = to;
        this.newDiscountScaleFormData.fromAmount = from === undefined ? '' : from;
    }

    private onNewDiscountScaleSubmit(evt: React.FormEvent<HTMLFormElement>) {
        evt.preventDefault();
        const data = this.newDiscountScaleFormData as Required<NpvDiscountScale>;

        this.props.npv.discountScales.push(new NpvDiscountScaleModel({
            id: null,
            fromAmount: data.fromAmount,
            toAmount: data.toAmount,
            discountPercentage: data.discountPercentage,
        }));

        if (this.newDiscountScaleFormData.toAmount) {
            this.newDiscountScaleFormData.fromAmount = this.newDiscountScaleFormData.toAmount + 1;
            this.newDiscountScaleFormData.toAmount = '';
            this.validateFromAmount();
            this.toAmountInput?.focus();
        } else {
            this.newDiscountScaleFormData.fromAmount = '';
        }
        this.newDiscountScaleFormData.discountPercentage = '';
    }
}
