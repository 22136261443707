export enum ConditionUnit {
    Percentage = 'Percentage',
    Euro = 'Euro',
}

export function getLabel(unit: ConditionUnit) {
    switch (unit) {
        case ConditionUnit.Percentage:
            return '%';
        case ConditionUnit.Euro:
            return '€';

    }
}
