/* tslint:disable:max-line-length */
import {observer} from 'mobx-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {state} from '../../../state';
import {Npv, NpvOrganization} from '../../../models/interfaces';
import NpvModel from '../../../models/npvModel';
import {saveProspect} from '../../../api/npv';
import {NpvOrganizationForm} from '../organization/npv-organization-form';

interface Props {
    npv: Npv;
}

@observer
export class NpvNewProspect extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);

        this.state = {
            loading: false,
            saving: false,
            organizationFormData: {},
            addressFormData: {},
        };
    }

    public render() {
        return <NpvOrganizationForm
            npv={this.props.npv}
            submitText="Klant opslaan &amp; nieuwe NCW"
            onSubmit={data => this.onSubmit(data)}/>;
    }

    protected async onSubmit(data: NpvOrganization) {
        try {
            this.setState({
                saving: true,
            });
            const {id} = await saveProspect(data);
            location.href = state.urls.npv + `/new/pre-npv?organization=${id}`;
        } catch (e) {
            this.setState({
                saving: false,
            });
            throw e;
        }
    }
}

export function renderNpvNewProspect(element: HTMLElement) {
    if (!state.npv) {
        state.npv = new NpvModel();
    }
    (window as any).state = state.npv;
    return ReactDOM.render(<NpvNewProspect npv={state.npv}/>, element);
}
