/* tslint:disable:max-line-length */
import * as React from 'react';

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    suffix?: React.ReactNode;
    narrow?: boolean;
}

interface State {
}

export class Textarea extends React.Component<Props, State> {

    public render() {
        return (
            <div className={[
                'input-wrap',
                'input-wrap--bordered',
                'input-wrap--without-padding',
                this.props.suffix ? 'input-wrap--with-suffix' : '',
                this.props.narrow ? 'input-wrap--narrow' : '',
                ].join(' ')}>
                <textarea {...this.getTextareaAttributes()}>{this.props.value}</textarea>
                {this.props.suffix}
            </div>
        );
    }

    private getTextareaAttributes(): React.TextareaHTMLAttributes<HTMLTextAreaElement> {
        const attributes = {
            ...this.props,
            className: 'input-wrap__input ' + (this.props.className || ''),
        };
        delete attributes.narrow;
        delete attributes.suffix;
        delete attributes.value;
        return attributes;
    }
}
