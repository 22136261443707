import apiClient from './client';
import {RegistrationAccount, RegistrationAccountData} from './interfaces';

export async function registrateAccount(account: RegistrationAccount, token: string) {
    const data: RegistrationAccountData = {
        account,
        token,
    };
    await apiClient.post('de_klok/registration/account', data);
}
