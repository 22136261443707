import apiClient, {getResponseData} from './client';
import {AddPaymentCommand, OrderCreatedInfo, PaymentMethod} from '../models/interfaces';
import {ShoppingCart} from './interfaces';

export async function changeLineItemQuantity(lineItemId: string, quantity: number) {
    return getResponseData(await apiClient.put<ShoppingCart>('cart/items', {
        lineItemId,
        quantity,
    }));
}

export async function addLineItem(id: string, quantity: number) {
    return getResponseData(await apiClient.post<ShoppingCart>('cart/items', {
        id,
        quantity,
    }));
}

export async function getCart() {
    return getResponseData(await apiClient.get<ShoppingCart>('cart'));
}

export async function addCoupon(couponCode: string) {
    return await apiClient.post('cart/coupons/' + couponCode);
}

export async function removeLineItem(lineItemId: string) {
    await apiClient.delete('cart/items?lineItemId=' + lineItemId);
    return await getCart();
}

export async function createOrder() {
   return await apiClient.post<OrderCreatedInfo>('cart/createorder', {});
}

export async function getPaymentMethods() {
    return getResponseData(await apiClient.get<PaymentMethod[]>('cart/paymentmethods', {}));
}

export async function addPaymentMethod(command: AddPaymentCommand) {
    getResponseData(await apiClient.post('cart/createPayment', command));
}
