/* tslint:disable:no-var-requires max-line-length */
import {observer} from 'mobx-react';
import {Contact, SearchContactsResult, SortableSearchCriteria} from '../../api/interfaces';
import {state} from '../../state';
import {searchContacts, updateContactOrganizations} from '../../api/user-management';
import {Dialog, DialogEventArgs} from '../dialog';
import * as React from 'react';

const closeIcon = require('../../../images/icons8-multiply-50.svg');
const editIcon = require('../../../images/icons8-pencil-drawing-50.svg');

interface Props {
}

interface State {
    result: SearchContactsResult | null;
    loading: boolean;
    deletingContact: Contact | null;
}

enum DialogResult {
    Ok     = 'ok',
    Cancel = 'cancel',
}

@observer
export class UserManagementOverview extends React.Component<Props, State> {

    public static createUserHashPath = 'create-user';

    private searchTimeout: number | undefined;

    private searchCriteria: SortableSearchCriteria = {
        searchPhrase: '',
        sort: '',
        organizationId: state.current_organization!,
        pageNumber: 1,
        pageSize: 9000}; // Todo Pagination

    public constructor(props: Props) {
        super(props);
        this.state = {
            result: null,
            loading: false,
            deletingContact: null,
        };
    }

    public componentDidMount(): void {
        this.search(this.searchCriteria);
    }

    public render() {
        return <div className="content-wrap"> {
            this.state.result ? (
            <div className="page-header">
                <div className="page-header__subtitle order-overview__subtitle">De volgende gebruikers hebben toegang
                    tot {state.CurrentOrganization!.name}.
                </div>
                <table className="table table--bg-white table--border-rounded table--no-margin">
                    <thead>
                    <tr>
                        <th>Naam</th>
                        <th>E-mailadres</th>
                        <th>Rol</th>
                        <th>Status</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.result.items.length > 0 &&
                    this.state.result.items.map(x => this.renderUserRow(x))
                    }
                    </tbody>
                </table>
                <div className="row row--no-padding-top linear-layout row--dark">
                    <a href={`#${UserManagementOverview.createUserHashPath}`}
                       className="button button--primary button--float linear-layout__item linear-layout__item--end">
                        Nieuwe gebruiker
                    </a>
                </div>
                {this.renderDeleteDialog()}
            </div>
        ) : (<div className="page-header">
                <div className="row linear-layout">
                    <div className="row__loading"/>
                </div>
            </div>)
        } </div>;
    }

    private getUserRoles(contact: Contact) {
        if (contact.securityAccounts && contact.securityAccounts.length) {
            return contact.securityAccounts.reduce((acc, curr) => acc.concat(curr.roles), [] as string[]).join(', ');
        }
    }

    private renderUserRow(contact: Contact) {
        return (
            <tr key={contact.id}>
                <td>{contact.firstName} {contact.lastName}</td>
                <td>{contact.email}</td>
                <td>{this.getUserRoles(contact)}</td>
                <td>
                    {
                        this.hasConfirmedAccount(contact)
                            ? <span className="--success">Uitnodiging geaccepteerd</span>
                            : <span className="--warn">Uitnodiging nog niet geaccepteerd</span>
                    }
                </td>
                { contact.id !== state.customer!.contactId ? (
                    <>
                        <td>
                            <a href={`#${contact.email}`}
                               className="button button--float button--primary button--small">
                                <span className="icon button__icon icon--small" dangerouslySetInnerHTML={{__html: editIcon}}/>
                            </a>
                        </td>
                        <td>
                            <button type="button"
                                    className="button button--text button--warn"
                                    onClick={() => this.setState({deletingContact: contact})}>
                                <span className="icon button__icon" dangerouslySetInnerHTML={{__html: closeIcon}}/>
                            </button>
                        </td>
                        </>
                ) : <><td /><td /></>}
            </tr>
        );
    }

    private hasConfirmedAccount(contact: Contact) {
        return contact.securityAccounts && contact.securityAccounts
            .some(x => x.emailConfirmed === true);
    }

    private renderDeleteDialog() {
        if (this.state.deletingContact !== null) {
            return (
                <Dialog<DialogResult>
                    onResult={this.onDeleteContactDialogResult}
                    position="bottom"
                    title="Gebruiker verwijderen">
                    <p className="dialog-container__dialog__text">
                        Weet u zeker dat u het account voor <strong>{this.state.deletingContact.fullName}</strong> wilt verwijderen?
                    </p>
                    <div className="form-row">
                        <div className="button-group button-group--compact">
                            <button
                                disabled={this.state.loading}
                                className="button button--primary button-group__button button--float"
                                data-action={DialogResult.Ok}>Verwijderen
                            </button>
                            <button
                                disabled={this.state.loading}
                                className="button button--text-primary button-group__button"
                                data-action={DialogResult.Cancel}>Annuleren
                            </button>
                        </div>
                    </div>
                </Dialog>
            );
        }
    }

    private onDeleteContactDialogResult = async (evt: DialogEventArgs<DialogResult>) => {
        try {
            switch (evt.result) {
                case DialogResult.Ok:
                    this.setState({ loading: true });
                    await updateContactOrganizations({
                        emailAddress: this.state.deletingContact!.email,
                        linkOrganizations: this.state.deletingContact!.organizationsIds
                            .filter(x => x !== state.current_organization),
                    });
                    state.notifications.push(`De gebruiker ${this.state.deletingContact!.fullName} is verwijderd`);
                    await this.search(this.searchCriteria);
                    break;
                case DialogResult.Cancel:
                    break;
            }
        } finally {
            this.setState({ loading: false, deletingContact: null });
        }
    }

    private async search(searchCriteria: SortableSearchCriteria) {
        try {
            this.setState({loading: true});
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }
            this.searchTimeout = window.setTimeout(async () => {
                this.searchTimeout = undefined;
                const searchResults = await searchContacts(searchCriteria);
                this.setState({ result: searchResults, loading: false });
            }, 500);
        } finally {
            this.setState({loading: false});
        }
    }
}
