/* tslint:disable:no-var-requires max-line-length */
import {$getParent, $hasParent} from '../../../components/src/utils/dom.utils';
import * as React from 'react';
import {Portal} from 'react-portal';
import {getMessagaBoxMessages} from '../api/npv';
import {observable} from 'mobx';
import {MessageBoxMessage} from '../models/interfaces';

const closeIcon = require('../../images/icons8-multiply-50.svg');

interface Props<TResult extends (string | null)> {
    position?: string;
    onResult: (result: DialogEventArgs<TResult>) => Promise<any> | any;
    closable?: boolean;
    title?: string;
}

export enum DialogResult {
    Cancel = 'cancel',
}

export interface DialogEventArgs<TResult extends (string | null)> {
    result: TResult | null;
}

let mountedInstances = new Array<Dialog<any>>();

export class Dialog<TResult extends (string | null) = DialogResult> extends React.Component<Props<TResult>, {}> {

    private dialogElement!: HTMLDivElement;

    public constructor(props: Props<TResult>) {
        super(props);
    }

    public componentDidMount(): void {
        mountedInstances.unshift(this);
        setTimeout(() => {
            document.addEventListener('keydown', this.onDocumentKeyDown);
            document.addEventListener('click', this.onDocumentClick);
        });

        const input = this.dialogElement.getElementsByTagName('input').item(0);
        if (input) {
            input.focus();
        }
    }

    public componentWillUnmount(): void {
        document.removeEventListener('keydown', this.onDocumentKeyDown);
        document.removeEventListener('click', this.onDocumentClick);
        mountedInstances = mountedInstances.filter(x => x !== this);
    }

    public render() {
        return (
            <Portal node={document && document.querySelector('dialog-container')}>
                <div className={`dialog-container__dialog ${'dialog-container__dialog--' + (this.props.position
                    ? this.props.position
                    : 'bottom')} content-wrap`}
                     ref={el => this.dialogElement = el!}>
                    {this.props.title || this.props.closable ? <div className="dialog-container__dialog__header">
                        {this.props.title ?
                            <h3 className="dialog-container__dialog__header__title">{this.props.title}</h3> : null}
                        <span className="icon icon--small dialog-container__dialog__header__close-button"
                              onClick={() => this.triggerResult(DialogResult.Cancel as TResult)}
                              dangerouslySetInnerHTML={{__html: closeIcon}}/>
                    </div> : null}
                    {this.props.children}
                </div>
            </Portal>
        );
    }

    private async cancel() {
        if ((!('closable' in this.props) || this.props.closable)) {
            await this.triggerResult(DialogResult.Cancel as TResult);
        }
    }

    private onDocumentKeyDown = async (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            await this.cancel();
        }
    };

    private onDocumentClick = async (evt: Event) => {
        if (!this.dialogElement) {
            return;
        }
        const latestOpenDialog = mountedInstances[0];
        if (latestOpenDialog !== this) {
            return;
        }
        if (evt.target instanceof Element) {
            const actionButton = $getParent(evt.target, '.button[data-action]', true);
            if (actionButton !== null) {
                const action = actionButton.getAttribute('data-action') as TResult;
                await this.triggerResult(action);
                return;
            }

            if (this.dialogElement) {
                if (!$hasParent(evt.target, this.dialogElement, true)) {
                    await this.cancel();
                }
            }
        }
    };

    private async triggerResult(action: TResult) {
        const dialogEventArgs: DialogEventArgs<TResult> = {
            result: action,
        };

        if (this.props.onResult) {
            const onClose = this.props.onResult(dialogEventArgs);
            if (onClose instanceof Promise) {
                await onClose;
            }
        }
    }
}
