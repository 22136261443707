import {LineItem, Product} from './interfaces';
import {computed, observable} from 'mobx';
import {LineItemModel} from './lineItemModel';

export class CheckoutLineItemModel implements LineItem {
    @computed
    public get final_line_price(): string | null {
        return this.lineItem.final_line_price;
    }

    @computed
    public get final_price(): string | null {
        return this.lineItem.final_price;
    }

    @computed
    public get id(): string {
        return this.lineItem.id;
    }

    @computed
    public get name(): string {
        return this.lineItem.name;
    }

    @computed
    public get price(): number | null {
        return this.lineItem.price;
    }

    @computed
    public get product(): Product | null {
        return this.lineItem.product;
    }

    @computed
    public get sku(): string {
        return this.lineItem.sku;
    }

    @computed
    public get is_gift(): boolean {
        return this.lineItem.is_gift;
    }

    @observable
    public quantity: number;

    @observable
    public in_stock_quantity: number;

    @observable
    public measure_unit: string;

    public constructor(private lineItem: LineItemModel) {
        this.quantity = lineItem.quantity;
        this.measure_unit = lineItem.measure_unit;
        this.in_stock_quantity = lineItem.in_stock_quantity;
    }
}
