export enum PsInFoodAllergenLabel {
    Contains = '+',
    DoesNotContain = '-',
    MayContain = '+/-',
    NotSpecified = '0',
}

export const ContainsClass = 'contains';
export const NotContainsClass = 'not-contains';

export function getPsInFoodAllergen(unit: number) {
    switch (unit) {
        case 1:
            return {
                label: PsInFoodAllergenLabel.Contains,
                class: ContainsClass
            };
        case 2:
            return {
                label: PsInFoodAllergenLabel.DoesNotContain,
                class: NotContainsClass
            };
        case 3:
            return {
                label: PsInFoodAllergenLabel.MayContain,
                class: ContainsClass
            };
        case 4:
            return {
                label: PsInFoodAllergenLabel.NotSpecified,
                class: NotContainsClass
            };
        default:
            return {
                label: PsInFoodAllergenLabel.NotSpecified,
                class: NotContainsClass
            };
    }
}
