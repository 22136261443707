export const PREFIX = 'deklok.';

export function storageGet(key: string): string | null {
    return localStorage.getItem(PREFIX + key);
}

export function storageSet(key: string, value: string) {
    localStorage.setItem(PREFIX + key, value);
}

export function storageClear() {
    Object.keys(localStorage)
        .forEach((key => {
            if (key.indexOf(PREFIX) === 0) {
                localStorage.removeItem(key);
            }
        }));
}

export function storageRemove(key: string) {
    localStorage.removeItem(PREFIX + key);
}

export function storageGetObject<T>(key: string): T | null {
    const value = storageGet(key);
    if (value === null) {
        return null;
    }
    return JSON.parse(value) as T;
}

export function storageSetObject(key: string, object: any) {
    storageSet(key, JSON.stringify(object));
}

export async function storageGetOrCreateObject<T>(key: string, factory: () => Promise<T>) {
    let obj = storageGetObject<T>(key);
    if (obj === null) {
       obj = await factory();
       storageSetObject(key, obj);
    }
    return obj;
}
