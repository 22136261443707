export interface QueryablePromise<T> extends Promise<T> {
    isFulfilled(): boolean;
    isPending(): boolean;
    isRejected(): boolean;
}

export function makeQuerablePromise<T>(promise: Promise<T>): QueryablePromise<T> {
    // Don't modify any promise that has been already modified.
    // if ((promise as any).isResolved) return promise;

    // Set initial state
    let isPending = true;
    let isRejected = false;
    let isFulfilled = false;

    // Observe the promise, saving the fulfillment in a closure scope.
    const result = promise.then(v => {
            isFulfilled = true;
            isPending = false;
            return v;
        },
        e => {
            isRejected = true;
            isPending = false;
            throw e;
        },
    ) as QueryablePromise<T>;

    result.isFulfilled = () => isFulfilled;
    result.isPending = () => isPending;
    result.isRejected = () => isRejected;
    return result;
}

export async function awaitIfPromise<T>(value: T | Promise<T>) {
    if (value instanceof Promise) {
        return await value;
    }
    return value;
}

export function delay<T>(timeout: number, value?: T) {
    return new Promise(resolve => {
        window.setTimeout(resolve.bind(null, value), timeout);
    });
}

export class Queue {

    get busy(): boolean {
        return this._busy;
    }

    public onBusy?: (queue: Queue) => void;
    public onReady?: (queue: Queue) => void;

    private queue: Array<{
        promise: () => Promise<any>;
        resolve: (value: any) => void;
        reject: (err: any) => void;
    }> = [];
    private _workingOnPromise = false;
    private _busy = false;

    public async add<T>(promise: () => Promise<T>): Promise<T> {
        return new Promise((resolve, reject) => {
            this.queue.push({
                promise,
                resolve,
                reject,
            });
            this.dequeue();
        });
    }

    public async dequeue() {
        if (this._workingOnPromise) {
            return false;
        }
        const item = this.queue.shift();
        if (!item) {
            return false;
        }
        try {
            this._workingOnPromise = true;
            if (!this._busy) {
                this._busy = true;
                if (this.onBusy) {
                    this.onBusy(this);
                }
            }
            item.resolve(await item.promise());
        } catch (err) {
            item.reject(err);
        } finally {
            this._workingOnPromise = false;
            this.dequeue().then(r => {
                if (!r) {
                    this._busy = false;
                    if (this.onReady) {
                        this.onReady(this);
                    }
                }
            });
        }
        return true;
    }

    public clear() {
        this.queue = [];
        return this;
    }
}

