/* tslint:disable:max-line-length */
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import * as React from 'react';
import {MessageBoxMessage} from '../../models/interfaces';
import {formatDate} from '../../utils/format';
import {MessageBoxMessageComponent} from './message';
import {state} from '../../state';
import {MediaQuery} from '../../media-queries';

interface Props {
    loading?: boolean;
    messages?: MessageBoxMessage[];
}

@observer
export class MessageBox extends React.Component<Props, {}> {

    @observable
    public selectedItem: MessageBoxMessage | null = null;

    public render() {
        return (
            <div className="page row row--padding-small row--rounded-border">
                <div className="content-wrap">
                    {this.renderContent()}
                </div>
            </div>
        );
    }

    public renderContent() {
        if (this.props.loading) {
            return <div className="notice">Bezig met laden...</div>;
        }
        if (!this.props.messages) {
            return <div className="notice notice--error">Fout bij het laden</div>;
        }
        if (!this.props.messages.length) {
            return <div className="notice">Geen berichten</div>;
        }
        if (this.selectedItem) {
            return (
                <>
                    <button
                        className="button button--tiny button--primary"
                        onClick={() => this.selectedItem = null}>
                        &laquo; Terug
                    </button>
                    <MessageBoxMessageComponent message={this.selectedItem} />
                </>
            );
        }
        if (state.mediaQueries.get(MediaQuery.Small)) {
            return (
                <div className="message-box">
                    {this.props.messages.map((message, i) => (
                        <div key={i} onClick={() => this.selectedItem = message}>
                            {formatDate(message.publicationDate)} - {message.title}<br />
                            <button className="message-box-message__back button button--primary button--tiny"
                                    type="button"
                                    onClick={() => this.selectedItem = message}>
                                Bekijk
                            </button>
                            <hr />
                        </div>
                    ))}
                </div>
            );
        }
        return (
            <div className="message-box">
                <table className="table">
                    <thead>
                    <tr>
                        <th>Titel</th>
                        <th>Datum</th>
                        <th style={{width: 100}}>&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.messages.map((message, i) => (
                        <tr key={i}
                            className="--with-hover"
                            onClick={() => this.selectedItem = message}>
                            <td>{message.title}</td>
                            <td>{formatDate(message.publicationDate)}</td>
                            <td>
                                <button className="button button--primary button--tiny"
                                        type="button"
                                        onClick={() => this.selectedItem = message}>
                                    Bekijk
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    }
}
