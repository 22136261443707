/* tslint:disable:no-var-requires max-line-length */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {observer} from 'mobx-react';
import {state} from '../state';
import {MediaQuery} from '../media-queries';
import {LineItem} from '../models/interfaces';
import {computed} from 'mobx';
import {viewPrices} from '../security/representative-claims';
import {formatEuroPrice} from '../utils/format';

interface Props {
    items: LineItem[];
    mediaQueries: Map<MediaQuery, boolean>;
}

@observer
export class CheckoutProductTable extends React.Component<Props> {

    @computed
    public get showPrices() {
        return state.customer!.has_access(viewPrices) && !state.cart.hasSapErrorOccurred;
    }

    @computed
    public get linesSubtotal() {
        return this.props.items.reduce((a: number, b: any) =>
            a + ((b.price || ((b.product.gross_price! / 100) * b.quantity)) || 0), 0);;
    }

    public render() {
        const table = this.props.mediaQueries.get(MediaQuery.MediumUp) ? <table className="table cart__product-table">
                <thead>
                <tr>
                    <th>Naam</th>
                    <th>Aantal</th>
                    <th>Subtotaal</th>
                </tr>
                </thead>
                <tbody>
                {this.props.items.map(line => this.renderOrderLine(line))}
                {this.renderSubtotalLine()}
                </tbody>
            </table>
            :
            <div>
                {this.props.items.map(line => (
                    <div key={line.id} className="cart__product-table__small-screen-container">
                        <div>
                            {line.name}
                        </div>
                        <div className="linear-layout linear-layout--grow-items">
                            <div>
                                <span className="cart__product-table__small-screen-container__label">Aantal</span>
                                <span>{line.quantity}</span>
                            </div>
                            <div>
                                <span className="cart__product-table__small-screen-container__label">Subtotaal</span>
                                {
                                    <span>{this.showPrices ? this.renderPrice(line) : '-'}</span>
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </div>;

        return table;
    }

    private renderOrderLine(line: LineItem) {
        return (
            <tr key={line.id}>
                <td>
                    {line.name}
                </td>
                <td>
                    {line.quantity}
                </td>
                {this.showPrices ? this.renderPrice(line) : '-'}
            </tr>
        );
    }

    private renderSubtotalLine() {
        return (
            <tr>
                <td/>
                <td>
                    <strong>Subtotaal</strong>
                </td>
                {this.showPrices ? <td>{formatEuroPrice(this.linesSubtotal)}</td> : '-'}
            </tr>
        );
    }

    private renderPrice(line: LineItem) {
        if (this.props.items && state.cart.order_data) {
            const items = state.cart.items;
            if (items) {
                const item = items.find(i => i.sku === line.sku);
                if (item) {
                    const productPrice = item.price ? item.price : ((item.product!.gross_price! / 100 ) * item.quantity);
                    return <td>{formatEuroPrice(productPrice)}</td>;
                }
            }
        }
        return null;
    }
}

export function renderCheckoutProductTable(element: HTMLElement) {
    if (!state.cart) {
        throw new Error(`Cannot render cart: 'state.cart' not initialized.`);
    }
    // const backorder = element.hasAttribute('data-backorder'); // TODO
    // const items = backorder ? state.cart.backorder : state.cart.items; // TODO
    const items = state.cart.items;
    return ReactDOM.render(
        <CheckoutProductTable
            items={items}
            mediaQueries={state.mediaQueries}
        />, element);
}
