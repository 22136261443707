/* tslint:disable:no-var-requires max-line-length */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {observer} from 'mobx-react';
import {state} from '../state';
import {Dialog, DialogEventArgs} from './dialog';
import {Cart as CartState} from '../models/interfaces';
import {formatDate} from '../utils/format';
import {QuantityDialog} from './quantity-dialog';
import {VendorType} from '../enums/vendorType';

interface Props {
    productId: string;
    productSku: string;
    productUnit: string;
    productTitle: string;
    isVendorLight: boolean;
    compactVariant: boolean;
    buttonText: string | null;
    productQuantityIncrement: number;
    forceSimulation: boolean;
    cart: CartState;
}

interface State {
    quantity: number;
    loading: boolean;
    dialogView: DialogView;
}

enum DialogView {
    None,
    InBackorder,
    QuantityIncrement,
}

enum DialogResult {
    Ok     = 'ok',
    Cancel = 'cancel',
}

@observer
export class AddToCart extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {
            dialogView: DialogView.None,
            quantity: 1,
            loading: false,
        };
    }

    public render() {
        return (
            <div className={`add-to-cart ${this.props.compactVariant ? 'add-to-cart--compact' : ''} loading-container ${this.state.loading
                ? 'loading-container--loading'
                : ''}`}>
                <input type="number"
                       name="quantity"
                       disabled={this.state.loading}
                       min="1"
                       max="10000"
                       value={this.state.quantity}
                       onChange={evt => this.setState({
                           quantity: parseInt(evt.target.value,
                               10),
                       })}
                       className="input-wrap input-wrap__input input-wrap__input--bordered add-to-cart__item"/>

                {this.renderDialog()}
                <button type="button"
                        disabled={this.state.loading}
                        className={`button button__icon button--primary add-to-cart__item`}
                        onClick={() => this.checkBeforeAddToCart()}>
                        {this.props.buttonText}
                  <span className="icon add-to-cart__item__icon"
                        dangerouslySetInnerHTML={{ __html: require('../../images/icons8-shopping-cart-50.svg') }}/>
                </button>
            </div>
        );
    }

    protected renderDialog() {
        switch (this.state.dialogView) {
            case DialogView.InBackorder:
                return (
                    <Dialog<DialogResult>
                        onResult={this.onDialogClose}
                        title="Dit Artikel staat reeds in backorder">
                        <p className="dialog-container__dialog__text">
                            Let op! U heeft van dit product reeds {this.totalBackorderItemsQuantity} bij ons in
                            bestelling staan. {this.backorderItems.length > 1
                            ? `(verwachte leverdatum ${this.backorderItemDate})`
                            : ''}
                            <br/>Weet u zeker dat u dit artikel wilt toevoegen aan uw winkelwagen?
                        </p>
                        <div className="button-group button-group--compact">
                            <button
                                disabled={this.state.loading}
                                className="button button--primary button-group__button button--float"
                                data-action={DialogResult.Ok}>Artikel toevoegen
                            </button>
                            <button
                                disabled={this.state.loading}
                                className="button button--text-primary button-group__button"
                                data-action={DialogResult.Cancel}>Annuleren
                            </button>
                        </div>
                    </Dialog>
                );

            case DialogView.QuantityIncrement:
                return (
                    <QuantityDialog enteredValue={this.state.quantity}
                                    productName={this.props.productTitle}
                                    quantityIncrement={this.props.productQuantityIncrement}
                                    onValueChosen={this.onQuantityChanged}/>
                );
        }
    }

    public get backorderItems() {
        return state.backorderArray.filter(x => x.sku === this.props.productSku);
    }

    public get isProductInBackorder() {
        return this.backorderItems.length !== 0;
    }

    public get totalBackorderItemsQuantity() {
        return this.backorderItems.reduce((acc, prev) => prev.quantity + acc, 0);
    }

    public get backorderItemDate() {
        const mapped = this.backorderItems.map(x => formatDate(x.estimatedDeliveryDate));
        mapped.sort((a: string, b: string) => {
            return new Date(a).getTime() - new Date(b).getTime();
        });
        return mapped && mapped.length > 0 ? mapped[0] : null;
    }

    protected async checkBeforeAddToCart() {
        if (this.state.quantity < 1) {
            state.notifications.push(`U kunt geen ${this.state.quantity} producten toevoegen aan de winkelwagen`);
        } else if (this.state.quantity % this.props.productQuantityIncrement > 0) {
            this.setState({dialogView: DialogView.QuantityIncrement});
        } else if (this.props.isVendorLight) {
            await this.addToCart();
        } else if (this.isProductInBackorder) {
            this.setState({ dialogView: DialogView.InBackorder });
        } else {
            await this.addToCart();
        }
    }

    protected onDialogClose = async (evt: DialogEventArgs<DialogResult>) => {
        switch (evt.result) {
            case DialogResult.Ok:
                await this.addToCart();
                break;
            case DialogResult.Cancel:
                this.setState({ dialogView: DialogView.None });
                break;
        }
    }

    protected onQuantityChanged = async (chosenNumber: number | false) => {
        if (chosenNumber === false) {
            this.setState({
                dialogView: DialogView.None,
            });
            return;
        }

        this.setState({
            quantity: chosenNumber,
            dialogView: DialogView.None,
        });
        await this.checkBeforeAddToCart();
    }

    protected async addToCart() {
        try {
            this.setState({ loading: true });
            await this.props.cart.add_line_item(this.props.productId, this.state.quantity, this.props.forceSimulation);
            this.setState({ dialogView: DialogView.None });
            state.notifications.push(`${this.props.productTitle} is toegevoegd aan uw winkelwagen.`);
        } finally {
            this.setState({ loading: false });
        }
    }
}

export function renderAddToCart(element: HTMLElement) {
    if (!state.cart) {
        throw new Error(`Cannot render cart: 'state.cart' not initialized.`);
    }
    return ReactDOM.render(
        <AddToCart cart={state.cart}
                   buttonText={element.getAttribute('data-button-text') as string}
                   compactVariant={element.hasAttribute('data-compact-variant')}
                   forceSimulation={element.hasAttribute('data-force-simulation')}
                   productId={element.getAttribute('data-product-id') as string}
                   productSku={element.getAttribute('data-product-sku') as string}
                   productUnit={element.getAttribute('data-product-unit') as string}
                   isVendorLight={element.hasAttribute('data-product-vendor-light')}
                   productQuantityIncrement={
                       parseInt(element.getAttribute('data-product-quantity-increment') as string,
                   10)}
                   productTitle={element.getAttribute('data-product-title') as string}/>, element!);
}
