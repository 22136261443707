/* tslint:disable:no-var-requires max-line-length */
import {state} from '../state';
import {Customer, Organization} from '../models/interfaces';
import {observer} from 'mobx-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import {renderSvg, okIcon, cancelIcon} from '../../images/images';
import { formatDate } from '../utils/format';

interface Props {
    customer: Customer;
    statusTitle: string | null;
    statusActiveText: string | null;
    statusInactiveText: string | null;
    statusText: string | null;
    privacyTitle: string | null;
    privacyText: string | null;
    privacyLinkText: string | null;
}

@observer
export class TermsAndConditions extends React.Component<Props> {

    public constructor(props: Props) {
        super(props);
    }

    public render() {
        if (!this.props.customer) {
            throw new Error(`Cannot render customer details: 'state.customer' not initialized.`);
        }

        return (
            <div>
                <div className="row row--no-padding row--dark terms-and-conditions">
                    <div className="terms-and-conditions__row">
                        <div className="terms-and-conditions__column">
                            <div  className="terms-and-conditions__box row--rounded-border">
                                <div className="terms-and-conditions__status-title">
                                    <div><strong>{this.props.statusTitle}</strong></div>
                                    <div className="terms-and-conditions__status">
                                        {state.customer?.should_accept_updated_terms ? 
                                            <span className="inactive">
                                                {renderSvg(cancelIcon, {
                                                    className: 'terms-and-conditions__status__icon inactive',
                                                })}
                                                {this.props.statusInactiveText}
                                            </span>
                                             :
                                             <span className="active">
                                                {renderSvg(okIcon, {
                                                    className: 'terms-and-conditions__status__icon',
                                                })}
                                                {this.props.statusActiveText}
                                            </span>
                                        }  
                                    </div>
                                </div>
                                <div className="terms-and-conditions__text">
                                    <p>
                                        {this.getDate()} {this.props.statusText}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="terms-and-conditions__column">
                            <div  className="terms-and-conditions__box row--rounded-border">
                                <div className="terms-and-conditions__title">{this.props.privacyTitle} {this.getDate()}</div>
                                <div className="terms-and-conditions__content" dangerouslySetInnerHTML={{__html: this.props.privacyText || ''}}></div>
                                <div className="terms-and-conditions__fulltextlink">
                                    <a href={state.urls.home + 'c/privacy-policy'} target="_blank">{this.props.privacyLinkText}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private getDate(): string {
        if (state.customer?.updated_terms_accepted_on !== null) {
            return formatDate(state.customer?.updated_terms_accepted_on, 'DD-MM-YYYY')
        }
        return ''
    }

}

export function renderTermsAndConditions(element: HTMLElement) {
    return ReactDOM.render(
        <TermsAndConditions
            customer={state.customer!}
            statusTitle={element.getAttribute('status-title')}
            statusActiveText={element.getAttribute('status-active-text')}
            statusInactiveText={element.getAttribute('status-inactive-text')}
            statusText={element.getAttribute('status-text')}
            privacyTitle={element.getAttribute('privacy-title')}
            privacyText={element.getAttribute('privacy-text')}
            privacyLinkText={element.getAttribute('privacy-link-text')}
        />, element!);
}
