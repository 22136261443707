import axios, {AxiosResponse, Canceler, CancelTokenSource} from 'axios';
import {SuggestCriteria} from './interfaces';
const apiClient = axios.create({
    baseURL: window.STORE_DATA.urls!.api,
    headers: {
        'Content-Type': 'application/json',
    },
    xsrfHeaderName: 'X-XSRF-TOKEN',
    xsrfCookieName: 'XSRF-TOKEN',
    responseType: 'json',
});

export function getResponseData<T>(response: AxiosResponse<T>) {
    return response.data;
}
export default apiClient;

export const viewHttpClient = axios.create({
    baseURL: window.STORE_DATA.urls!.api,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
    responseType: 'text',
});


const CancelToken = axios.CancelToken;
let cancel: CancelTokenSource;

export async function canceablePost<T>(url: string, body?: any) {
    if (cancel !== undefined) {
        cancel.cancel();
    }

    cancel = CancelToken.source();
    return await apiClient.post<T>(url, body, {
        cancelToken: cancel.token,
    })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.dir(cancel );
            }
            const result = error.response;
            return null;
        });
}

