export function roundToPrecision(nr: number, precision = 0) {
    const f = precision * 10;
    return Math.round((nr + Number.EPSILON) * f) / f;
}

export function rangesOverlap(from1?: number | null, to1?: number | null, from2?: number | null, to2?: number | null) {
    if (from1 === undefined || from1 === null) { from1 = Number.MIN_SAFE_INTEGER; }
    if (to1 === undefined || to1 === null) { to1 = Number.MAX_SAFE_INTEGER; }
    if (from2 === undefined || from2 === null) { from2 = Number.MIN_SAFE_INTEGER; }
    if (to2 === undefined || to2 === null) { to2 = Number.MAX_SAFE_INTEGER; }

    return to1 >= from2 && to2 >= from1;
}
