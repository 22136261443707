/* tslint:disable:variable-name */
import {
    action,
    computed,
    observable,
} from 'mobx';
import OrganizationModel from './organizationModel';
import {
    Address,
    Customer, GetAddressesBody,
    Organization,
} from './interfaces';
import { DeepPartial } from '../../../../custom';
import { Permission } from '../security/permission';
import { ClaimRequirement } from '../security/claim-requirement';
import {getAddresses} from '../api/de-klok';
import {SapGetAddressesResult} from '../api/interfaces';
import {UserRole} from '../api/user-management';

export default class CustomerModel implements Customer {
    @observable
    public loading = false;

    @observable
    public id: string | null = null;

    @observable
    public contactId: string | null = null;

    @observable
    public first_name: string | null = null;

    @observable
    public middle_name: string | null = null;

    @observable
    public last_name: string | null = null;

    @observable
    public name: string | null = null;

    @observable
    public user_name: string | null = null;

    @observable
    public phone_number: string | null = null;

    @observable
    public addresses: Address[] = [];

    @observable
    public organizations: Organization[] = [];

    @observable
    public permissions: Permission[] = [];

    @observable
    public roles: UserRole[] = [];

    @observable
    public preferred_organization: string | null = null;

    @observable
    public updated_terms_accepted_on: string | null = null;

    @observable
    public should_accept_updated_terms: boolean| undefined;

    constructor(data?: DeepPartial<Customer> | null) {
        data = data || {};
        this.id = data.id || null;
        this.contactId = data.contactId || null;
        this.first_name = data.first_name || null;
        this.middle_name = data.middle_name || null;
        this.last_name = data.last_name || null;
        this.user_name = data.user_name || null;
        this.name = data.name || null;
        this.phone_number = data.phone_number || null;
        this.preferred_organization = data.preferred_organization || null;
        this.updated_terms_accepted_on = data.updated_terms_accepted_on || null;
        this.should_accept_updated_terms = data.should_accept_updated_terms;
        if (data.permissions) {
            this.permissions = data.permissions;
        }

        if (data.roles) {
            this.roles = data.roles;
        }

        if (data.organizations) {
            this.organizations = data.organizations.map(item => new OrganizationModel(item as Organization));
        }
    }

    public has_access(claimRequirement: ClaimRequirement): boolean {
        return claimRequirement.hasAccess(this.permissions);
    }

    @computed
    public get organization_characters() {
        let count = 1;
        while (true) {
            const organizationCharacters = this.organizations.map(x => x.name.substr(0, count));
            const isUnique = organizationCharacters.every((value, index, self) => self.indexOf(value) === index);
            if (isUnique || count === 3) {
                return organizationCharacters;
            }
            count++;
        }
    }

    @computed
    public get organizations_with_characters(): Array<Organization & {characters: string}> {
        const organizationCharacters = this.organization_characters;
        return this.organizations.map((x, i) => ({...x, characters: organizationCharacters[i]}));
    }

    @action
    public async fetchAddresses(): Promise<SapGetAddressesResult> {
        try {
            this.loading = true;
            return await getAddresses();
        } finally {
            this.loading = false;
        }
    }
}
