import apiClient, {getResponseData} from './client';
import {BackorderItem, OrderSimulationRequest, OrderSimulationResult, PriceQuantity,} from '../models/interfaces';
import {
    AddItemToOrderListBody,
    AppSettings,
    CartExtras,
    CreateCartBody,
    FormDefinition,
    GetInvoiceOverviewResult,
    GetOrderDetailQuery,
    GetOrderOverviewResult,
    GetOrderOverviewSearchCriteria,
    InvoiceDetail,
    OrderDetail,
    OrderList,
    OrderListProductSearchResult,
    PriceStockResult,
    RenameOrderListBody,
    SapGetAddressesResult,
    SearchOrderListQuery,
    SearchOrganizationsResult,
    SortableSearchCriteria, UpdatePriorityBody,
} from './interfaces';

export async function getPriceAndStock(products: PriceQuantity[]) {
    return getResponseData(await apiClient.post<PriceStockResult>('de_klok/price_and_stock', products));
}

export async function simulateOrder(request: OrderSimulationRequest, ignoreTransportSurcharge: boolean) {
    return getResponseData(await apiClient.post<OrderSimulationResult>('de_klok/order_simulation', request, {
        params: {
            ignoreTransportSurcharge,
        },
    }));
}

export async function getAddresses() {
    return getResponseData(await apiClient.get<SapGetAddressesResult>('de_klok/addresses'));
}

export async function getBackorder() {
    return getResponseData(await apiClient.get<BackorderItem[]>('de_klok/backorder'));
}

export async function getSettings() {
    return getResponseData(await apiClient.get<AppSettings>('de_klok/settings'));
}

export async function getInvoiceOverview(pageNumber: number, pageSize: number,
                                         getInvoiceOverviewSearchCriteria?: GetOrderOverviewSearchCriteria) {
    return getResponseData(await apiClient.post<GetInvoiceOverviewResult>(
        `de_klok/invoices?pageNumber=${pageNumber}&pageSize=${pageSize}`, getInvoiceOverviewSearchCriteria));
}

export async function getInvoiceDetail(invoiceId: string) {
    return getResponseData(await apiClient.get<InvoiceDetail>(
        `de_klok/invoices/detail/${invoiceId}`));
}

export async function getOrderOverview(pageNumber: number, pageSize: number,
                                       getOrderOverviewSearchCriteria?: GetOrderOverviewSearchCriteria) {
    return getResponseData(await apiClient.post<GetOrderOverviewResult>(
        `de_klok/orders?pageNumber=${pageNumber}&pageSize=${pageSize}`, getOrderOverviewSearchCriteria));
}

export async function getOrderDetail(query: GetOrderDetailQuery) {
    return getResponseData(await apiClient.post<OrderDetail>('de_klok/orders/detail', query));
}

export async function getOrderLists() {
    return getResponseData(await apiClient.get<OrderList[]>('de_klok/order_lists'));
}

export async function searchOrderList(orderListId: string, criteria: SearchOrderListQuery) {
    return getResponseData(await apiClient.post<OrderListProductSearchResult>(
        `de_klok/order_lists/${encodeURIComponent(orderListId)}/search`, criteria));
}

export async function searchOrderListWithQuery(orderListId: string, criteria: SearchOrderListQuery, query?: string) {
    return getResponseData(await apiClient.post<OrderListProductSearchResult>(
        `de_klok/order_lists/${encodeURIComponent(orderListId)}/search${query}`, criteria));
}

export async function createOrderList(cartBody: CreateCartBody) {
    return getResponseData(await apiClient.post<OrderList>(`de_klok/order_lists/add`, cartBody));
}

export async function setOrderCartItemPriority(cartId: string, body: UpdatePriorityBody) {
    return getResponseData(
        await apiClient.put(`de_klok/order_lists/${encodeURIComponent(cartId)}/priority`, body));
}

export async function addItemToOrderList(cartId: string, body: AddItemToOrderListBody) {
    await apiClient.post(`de_klok/order_lists/${cartId}`, body);
}

export async function deleteOrderList(cartId: string) {
    await apiClient.delete(`de_klok/order_lists/${cartId}`);
}

export async function renameOrderList(cartId: string, body: RenameOrderListBody) {
    await apiClient.post(`de_klok/order_lists/${cartId}/renameList`, body);
}

export async function removeItemFromOrderList(cartId: string, productId: string) {
    await apiClient.delete(`de_klok/order_lists/${cartId}/${productId}`);
}

export async function changeOrderListItemQuantity(cartId: string, itemId: string, quantity: number) {
    await apiClient.put(`de_klok/order_lists/${cartId}/${itemId}/quantity`, quantity);
}

export async function updateCartExtras(extras: CartExtras) {
    await apiClient.put(`de_klok/cart/extras`, extras);
}

export async function changeOrderListItemPreferredOrderQuantity(cartId: string, itemId: string, quantity: number) {
    await apiClient.put(`de_klok/order_lists/${cartId}/${itemId}/preferred_order_quantity`, quantity);
}

export async function deleteListsByIds(listIds: string[]) {
    return await apiClient.delete('lists/deletelistsbyids?listIds=' + listIds.join('&listIds='));
}

export async function mergeCartWithOrderList(orderListId: string) {
    await apiClient.put(`de_klok/order_lists/${orderListId}/merge`);
}

export async function searchOrganizations(searchCriteria: SortableSearchCriteria) {
    return getResponseData(await apiClient.post<SearchOrganizationsResult>(
        `de_klok/organization/search`, searchCriteria));
}

export async function getForm(name: string) {
    return getResponseData(await apiClient.get<FormDefinition>(
        `de_klok/form/${encodeURIComponent(name)}`));
}

export async function submitForm(name: string, recaptcha: string, formData: { [key: string]: string }) {
    return getResponseData(await apiClient.post<boolean>(
        `de_klok/form/${encodeURIComponent(name)}`, formData, {
            params: {
                recaptcha,
            },
        }));
}
