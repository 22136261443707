import {InvoiceDetails} from './interfaces';
import {InvoiceDetail} from '../api/interfaces';
import {action, observable} from 'mobx';
import {getInvoiceDetail} from '../api/de-klok';

export default class InvoiceDetailModel implements InvoiceDetails {

    @observable
    public invoice: InvoiceDetail | null = null;

    @observable
    public loading = false;

    @action
    public async getInvoiceDetails(invoiceId: string): Promise<void> {
        try {
            this.loading = true;
            this.invoice = await getInvoiceDetail(invoiceId);
        } finally {
            this.loading = false;
        }
    }
}
