/* tslint:disable:max-line-length */
// /* tslint:disable:max-line-length */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {Step, Stepper} from '../../common/stepper';
import {NpvForm1} from './npv-form-1';
import NpvModel from '../../../models/npvModel';
import {state} from '../../../state';
import {
    approveNpv,
    getNpv,
    getNpvDistrictManagerMargin,
    refreshNpv,
    rejectNpv,
    saveNpv,
    submitNpv,
} from '../../../api/npv';
import {NpvFormStep, NpvStepProps} from './form-step';
import {NpvForm2} from './npv-form-2';
import {getAbsoluteOffset} from '../../../utils/dom';
import {NpvForm3} from './npv-form-3';
import {computed, observable} from 'mobx';
import {Npv} from '../../../models/interfaces';
import {NpvForm4} from './npv-form-4';
import {formatEuroPrice, formatPercentage} from '../../../utils/format';
import {observer} from 'mobx-react';
import {NpvStatus} from '../../../enums/npvStatus';
import {Dialog, DialogEventArgs} from '../../dialog';
import {Textarea} from '../../common/textarea';
import {NpvForm5} from './npv-form-5';
import {awaitIfPromise} from '../../../utils/promise';
import {netPresentValueVerify} from '../../../security/representative-claims';

interface Props {
    id: string;
    activeStep?: number;
    onStepChange?: (step: number) => void;
}

interface NpvFormStepDefinition {
    stepper: Step;
    render: (buttons: React.ReactNode) => React.ReactElement<NpvFormStep<any>>;
}

enum DialogResult {
    Ok = 'ok',
    Cancel = 'cancel',
}

@observer
export class NpvForm extends React.Component<Props, {}> {

    private static editableStatuses = [NpvStatus.Preparation, NpvStatus.Rejected];

    private npv = new NpvModel();

    private refreshCounter = 0;

    private summaryElement = React.createRef<HTMLDivElement>();

    @observable
    private unchangedNpvJson: string | null = null;

    @observable
    private districtManagerMargin: number | null = null;

    @observable
    private loaded = false;

    @observable
    private loading = true;

    @observable
    private saving = false;

    @observable
    private activeStep: number;

    @observable
    private showDeclineComment = false;
    @observable
    private declineComment = '';

    private currentFormStep: NpvFormStep<any> | null = null;

    @computed
    private get steps(): NpvFormStepDefinition[] {
        return [
            {
                stepper: {title: 'Algemeen'},
                render: (buttons) => (
                    <NpvForm1 ref={el => this.currentFormStep = el} {...this.getNpvStepProps(buttons)}/>
                ),
            },
            {
                stepper: {title: 'Verkoopgroepen'},
                render: (buttons) => (
                    <NpvForm2 ref={el => this.currentFormStep = el} {...this.getNpvStepProps(buttons)}/>
                ),
            },
            {
                stepper: {title: 'Staffels'},
                render: (buttons) => (
                    <NpvForm3 ref={el => this.currentFormStep = el} {...this.getNpvStepProps(buttons)}/>
                ),
            },
            {
                stepper: {title: 'Winst- / verliesrekening'},
                render: (buttons) => (
                    <NpvForm4 ref={el => this.currentFormStep = el} {...this.getNpvStepProps(buttons)}/>
                ),
            },
            {
                stepper: {
                    title: 'Ondertekenen',
                    disabled: this.npv.status !== NpvStatus.ReadyForSignature && this.npv.status !== NpvStatus.Signed,
                },
                render: (buttons) => (
                    <NpvForm5 ref={el => this.currentFormStep = el} {...this.getNpvStepProps(buttons)}/>
                ),
            },
        ];
    }

    @computed
    private get currentNpvJson() {
        return JSON.stringify(this.npv);
    }

    @computed
    private get mayEdit() {
        return NpvForm.editableStatuses.includes(this.npv.status);
    }

    @computed
    private get isDirty() {
        return this.unchangedNpvJson !== null && this.unchangedNpvJson !== this.currentNpvJson;
    }

    public constructor(props: Props) {
        super(props);

        (window as any).npv = this.npv;
        if (this.props.activeStep !== undefined) {
            if (this.sanitizeStep(this.props.activeStep)) {
                this.activeStep = this.props.activeStep;
            } else {
                this.activeStep = 0;
            }
        } else {
            this.activeStep = 0;
        }
    }

    public async componentDidMount() {
        const subHeaderElement = document.querySelector<HTMLElement>('header.subheader');
        window.addEventListener('scroll', () => {
            const summaryEl = this.summaryElement.current;
            if (!summaryEl) {
                return;
            }
            const {y} = getAbsoluteOffset(summaryEl);
            const offset = window.scrollY - y + (subHeaderElement ? subHeaderElement.offsetHeight - 2 : 0);
            summaryEl.style.transform = `translateY(${Math.max(0, offset)}px)`;
            summaryEl.style.zIndex = '10';
        });
        try {
            const [npvData, districtManagerMargin] = await Promise.all([
                getNpv(this.props.id),
                getNpvDistrictManagerMargin(this.props.id),
            ]);
            this.districtManagerMargin = districtManagerMargin;
            this.updateNpvData(npvData);
        } finally {
            this.loading = false;
        }

    }

    public render() {

        if (this.loading) {
            return (
                <div className="npv content-wrap">
                    <div className="notice">Bezig met laden...</div>
                </div>
            );
        }

        return <div className="npv">
            <div className="row">
                <div className="content-wrap">

                    <Stepper steps={this.steps.map(x => x.stepper)}
                             onStepClick={this.onStepClick.bind(this)}
                             activeStep={this.activeStep}/>

                    <div className="box" ref={this.summaryElement}>
                        <div
                            className="linear-layout linear-layout--horizontal linear-layout--grow-items linear-layout--align-items-center">
                            <div className="linear-layout__item">
                                <strong>Jaaromzet</strong><br/>
                                {formatEuroPrice(this.npv.annualRevenue)}
                            </div>
                            <div className="linear-layout__item">
                                <strong>Inkoopwaarde</strong><br/>
                                {formatEuroPrice(this.npv.purchasingValue)}
                            </div>
                            <div className="linear-layout__item">
                                <strong>Kortingen</strong><br/>
                                {formatEuroPrice(this.npv.totalDiscountEuros)}
                            </div>
                            <div className="linear-layout__item"
                                 title={formatPercentage(this.npv.profitLossAccounts[0].marginPercentage)}>
                                <strong>Marge</strong><br/>
                                {formatEuroPrice(this.npv.profitLossAccounts[0].marginEuros)}
                            </div>
                            <div className="linear-layout__item">
                                <strong>EBITA</strong><br/>
                                {formatEuroPrice(this.npv.profitLossAccounts[0].ebita)}
                            </div>
                            <div className="linear-layout__item">
                                    <span
                                        className={`badge ${(this.npv.profitLossAccounts[0].marginPercentage >= this.districtManagerMargin! ? ' badge--success' : 'badge--error')}`}>
                                        {this.npv.profitLossAccounts[0].marginPercentage >= this.districtManagerMargin! ? 'Goedgekeurd' : 'Afgekeurd'}
                                    </span>
                            </div>
                        </div>
                    </div>

                    {this.npv.status === NpvStatus.Rejected && (
                        <div className="notice notice--warn --mt2">
                            De NCW is afgekeurd{this.npv.rejectComment && `, reden: ${this.npv.rejectComment}`}
                        </div>
                    )}
                </div>
            </div>

            {this.steps[this.activeStep].render(this.renderButtons())}

        </div>;
    }

    public async goto(requestedStep: number) {
        if (!this.sanitizeStep(requestedStep)) {
            return;
        }
        if (requestedStep > this.activeStep) {
            if (!await this.validateCurrentStep()) {
                return;
            }
        }
        if (this.isDirty && this.mayEdit) {
            await this.applyChanges();
        }
        this.activeStep = requestedStep;
        if (this.props.onStepChange) {
            this.props.onStepChange(requestedStep);
        }
    }

    protected sanitizeStep(requestedStep: number) {
        let step = requestedStep;
        if (step + 1 > this.steps.length) {
            step = this.steps.length - 1;
        }
        while (this.steps[step].stepper.disabled) {
            step--;
        }
        if (step !== requestedStep) {
            window.location.hash = (step + 1).toString();
            return false;
        }
        return true;
    }

    protected renderButtons() {
        if (this.npv.status === NpvStatus.ReadyForSignature) {
            return (
                <>
                    <div className="notice notice--success">
                        De NCW is klaar om ondertekend te worden.
                    </div>

                    <button className="button button--primary"
                            disabled={this.saving}
                            type="button"
                            onClick={() => this.goto(4)}>
                        Naar ondertekenen
                    </button>
                </>
            );
        } else if (this.npv.status === NpvStatus.Signed) {
            return (
                <>
                    <div className="notice notice--success">
                        De NCW is ondertekend.
                    </div>

                    <button className="button button--primary"
                            disabled={this.saving}
                            type="button"
                            onClick={() => this.goto(4)}>
                        Overeenkomst bekijken
                    </button>
                </>
            );
        } else if (this.npv.status === NpvStatus.VerificationRequested) {
            if (state.customer?.has_access(netPresentValueVerify)) {
                if (this.activeStep < 3) {
                    return (
                        <button className="button"
                                disabled={this.saving}
                                type="button"
                                onClick={() => this.goto(this.activeStep + 1)}>
                            Volgende
                        </button>
                    );
                } else {
                    return (
                        <div className="button-group button-group--compact --mt">
                            {this.showDeclineComment && (
                                <Dialog<DialogResult>
                                    onResult={evt => this.onDeclineResult(evt)}
                                    title="NCW afwijzen">
                                    <label htmlFor="reject_comment" className="dialog-container__dialog__text">
                                        Vul eventueel een opmerking in voor de rayonmanager.
                                    </label>
                                    <Textarea
                                        id="reject_comment"
                                        disabled={this.saving}
                                        placeholder="Opmerking"
                                        value={this.declineComment}
                                        onChange={evt => this.declineComment = evt.target.value}/>
                                    <div className="button-group button-group--compact">
                                        <button
                                            disabled={this.saving}
                                            className="button button--primary button-group__button button--float"
                                            data-action={DialogResult.Ok}>
                                            Afwijzen
                                        </button>
                                        <button
                                            disabled={this.saving}
                                            className="button button--text-primary button-group__button"
                                            data-action={DialogResult.Cancel}>
                                            Annuleren
                                        </button>
                                    </div>
                                </Dialog>
                            )}
                            <button className="button-group__button button button--error"
                                    onClick={() => this.showDeclineComment = true}
                                    disabled={this.saving}
                                    type="button">
                                Afwijzen
                            </button>
                            <button className="button-group__button button button--primary"
                                    onClick={() => this.approveNpv()}
                                    disabled={this.saving}
                                    type="button">
                                Akkoord
                            </button>
                        </div>
                    );
                }
            } else {
                return (
                    <div className="notice notice--success">
                        De NCW staat open voor accordering.
                    </div>
                );
            }
        }

        return (
            <div className="button-group button-group--compact --mt">
                {this.activeStep === 3 ? (
                    <button className="button-group__button button button--primary"
                            disabled={this.saving}
                            type="button"
                            onClick={() => this.submit()}>
                        Verzoek indienen
                    </button>
                ) : (
                    <button className="button-group__button button button--primary"
                            disabled={this.saving}
                            type="button"
                            onClick={() => this.save(true)}>
                        Opslaan &amp; verder
                    </button>
                )}
                <button className="button-group__button button button--text"
                        disabled={this.saving}
                        type="button"
                        onClick={() => this.save(false)}>
                    Opslaan &amp; sluiten
                </button>
            </div>
        );
    }

    protected updateNpvData(data: Npv) {
        this.loaded = true;
        this.npv.setData(data);
        this.unchangedNpvJson = JSON.stringify(this.npv);
        this.render();
    }

    protected getNpvStepProps(buttons: React.ReactNode): NpvStepProps {
        return {
            npv: this.npv,
            disabled: this.loading || this.saving || !this.mayEdit,
            requestApply: this.applyChanges.bind(this),
            requestRefresh: this.refresh.bind(this),
            buttons,
        };
    }

    protected async refresh() {
        const refreshId = ++this.refreshCounter;
        const npv = await refreshNpv(this.npv);
        if (refreshId === this.refreshCounter) {
            this.updateNpvData(npv);
        }
        return this.npv;
    }

    protected async applyChanges(postSave?: () => Promise<void | Npv>) {
        try {
            this.saving = true;
            let npv = await saveNpv(this.npv);
            if (postSave) {
                const postSaveResult = await postSave();
                if (postSaveResult) {
                    npv = postSaveResult;
                }
            }
            this.updateNpvData(npv);
            state.notifications.push('NCW opgeslagen');
            return this.npv;
        } finally {
            this.saving = false;
        }
    }

    protected async validateCurrentStep() {
        if (!this.currentFormStep) {
            return false;
        }
        return await awaitIfPromise(this.currentFormStep.isValid());
    }

    protected async save(continueNpv: boolean) {

        if (!await this.validateCurrentStep()) {
            return;
        }

        await this.applyChanges();

        if (!continueNpv) {
            location.href = state.urls.npv;
            return;
        }

        await this.goto(this.activeStep + 1);
    }

    protected async submit() {

        if (!await this.validateCurrentStep()) {
            return;
        }

        await this.applyChanges(() => submitNpv(this.npv.id!));

        if (this.npv.status === NpvStatus.ReadyForSignature) {
            await this.goto(this.activeStep + 1);
        }
    }

    private async onStepClick(step: Step, index: number, stepper: Stepper) {
        await this.goto(index);
    }

    private async onDeclineResult(evt: DialogEventArgs<DialogResult>) {
        if (evt.result === DialogResult.Ok) {
            try {
                this.saving = true;
                const newData = await rejectNpv(this.npv.id!, this.declineComment);
                this.updateNpvData(newData);
                state.notifications.push('De NCW is afgewezen');
                window.scrollTo({
                    top: 0,
                    behavior: 'auto',
                });
            } finally {
                this.saving = false;
            }
        }
        this.showDeclineComment = false;
    }

    private async approveNpv() {
        try {
            this.saving = true;
            const newData = await approveNpv(this.npv.id!);
            this.updateNpvData(newData);
        } finally {
            this.saving = false;
        }
    }
}

export function renderNpvForm(element: HTMLElement) {
    const id = element.getAttribute('data-id');
    if (id) {
        const getActiveStepFromHash = () => {
            const locationHashMatch = /^#(\d+)$/.exec(location.hash);
            return locationHashMatch ? parseInt(locationHashMatch[1], 10) - 1 : undefined;
        };

        const npvForm = ReactDOM.render<NpvForm>(<NpvForm
            id={id}
            activeStep={getActiveStepFromHash()}
            onStepChange={step => location.hash = (step + 1).toString()}
        />, element);
        window.addEventListener('hashchange', () => {
            const activeStep = getActiveStepFromHash();
            if (activeStep !== undefined) {
                (npvForm as any).goto(activeStep);
            }
        }, false);
    }
}
