/* tslint:disable:max-line-length */
// /* tslint:disable:max-line-length */
import * as React from 'react';
import {FormEvent} from 'react';
import * as ReactDOM from 'react-dom';
import NpvModel from '../../../models/npvModel';
import {observer} from 'mobx-react';
import {getNpvAgreementData, getNpvAgreementDataByCode, signNpv} from '../../../api/npv';
import {BusinessLocation, NpvOrganization, NpvSignature} from '../../../models/interfaces';
import {formatDate, formatEuroPrice, formatPercentage} from '../../../utils/format';
import {ConditionUnit} from '../../../enums/conditionUnit';
import SignatureCanvas from 'react-signature-canvas';
import {Input} from '../../common/input';
import {FormRow, FormRowColumn} from '../../common/form-row';
import {DropdownInput} from '../../common/dropdown-input';
import {getBusinessLocations} from '../../../api/business-location';
import {observable} from 'mobx';
import {DeliveryMoment} from '../../../enums/deliveryMoment';
import {state} from '../../../state';
import {Contact} from '../../../api/interfaces';
import {NpvStatus} from '../../../enums/npvStatus';

interface Props {
    id: string;
    code?: string;
}

@observer
export class NpvAgreement extends React.Component<Props, {}> {

    private customerSignatureRef = React.createRef<SignatureCanvas>();
    private sepaCustomerSignatureRef = React.createRef<SignatureCanvas>();
    private rayonManagerSignatureRef = React.createRef<SignatureCanvas>();

    private npv = new NpvModel();
    @observable
    private organization: NpvOrganization | null = null;
    @observable
    private businessLocations: BusinessLocation[] | null = null;
    @observable
    private selectedBusinessLocation: string | null = null;
    @observable
    private emailSubscribe = '';
    @observable
    private documentNr = '';
    @observable
    private documentType: string = 'Rijbewijs';
    @observable
    private city = '';
    @observable
    private contact: Contact | null = null;
    @observable
    private date: Date = new Date();
    @observable
    private loading = false;
    @observable
    private signed = false;
    @observable
    private receiveEmail = false;
    @observable
    private sepa: NpvSignature['sepa'] = {
        date: new Date(),
        city: '',
        debtorNumber: '',
        iban: '',
        organizationAddress: '',
        organizationCity: '',
        organizationName: '',
        organizationZipCode: '',
        customerSignature: '',
    };

    public constructor(props: Props) {
        super(props);

        (window as any).npv = this.npv;
    }

    public async componentDidMount() {
        const getAgreementDataPromise = this.props.code
            ? getNpvAgreementDataByCode(this.props.id, this.props.code)
            : getNpvAgreementData(this.props.id);
        const [agreementData, businessLocations] = await Promise.all([
            getAgreementDataPromise,
            getBusinessLocations(),
        ]);
        this.businessLocations = businessLocations;
        this.organization = agreementData.organization;
        this.contact = agreementData.contact;
        this.npv.setData(agreementData.npv);
        if (this.npv.signatureData) {
            this.documentType = this.npv.signatureData.documentType;
            this.documentNr = this.npv.signatureData.documentNr;
            this.emailSubscribe = this.npv.signatureData.emailSubscribe;
            this.date = new Date(this.npv.signatureData.date);
            this.city = this.npv.signatureData.city;
            this.selectedBusinessLocation = this.npv.signatureData.businessLocation;
            this.receiveEmail = this.npv.signatureData.receiveEmail;
            if (this.npv.signatureData.rayonManagerSignature) {
                this.rayonManagerSignatureRef.current?.fromDataURL(this.npv.signatureData.rayonManagerSignature);
            }
            if (this.npv.signatureData.customerSignature) {
                this.customerSignatureRef.current?.fromDataURL(this.npv.signatureData.customerSignature);
            }
            if (this.npv.signatureData.sepa) {
                this.sepa = this.npv.signatureData.sepa;
                if (this.npv.signatureData.sepa.customerSignature) {
                    this.sepaCustomerSignatureRef.current?.fromDataURL(this.npv.signatureData.sepa.customerSignature);
                }
            }
        } else {
            this.sepa.city = this.organization.address.city;
            this.sepa.organizationAddress = this.organization.address.companyLine1;
            this.sepa.organizationCity = this.organization.address.city;
            this.sepa.organizationZipCode = this.organization.address.zipCode;
            this.sepa.organizationName = this.organization.name;
        }
        this.signed = this.npv.status === NpvStatus.Signed;
        this.selectedBusinessLocation = this.businessLocations.find(x => x.main)?.name || null;
        document.documentElement.setAttribute('data-complete', '1');
    }

    public render() {

        return (
            <form className="npv" onSubmit={evt => this.onSubmit(evt)}>
                <div className="row">
                    <div className="content-wrap" style={{position: 'relative'}}>

                        {this.signed && (
                            <div style={{
                                color: '#0A9928',
                                border: '0.5rem solid #0A9928',
                                transform: 'rotate(-14deg) translateX(-50%)',
                                textTransform: 'uppercase',
                                position: 'absolute',
                                left: '50%',
                                padding: '.5em',
                                fontSize: '2em',
                                fontWeight: 'bold',
                                borderRadius: 0,
                            }} className="--no-print">
                                Ondertekend
                            </div>
                        )}
                        <p>
                            {this.organization?.name}<br/>
                            t.a.v. {this.npv.contactName}<br/>
                            {this.organization?.address.companyLine1}<br/>
                            {this.organization?.address.zipCode} {this.organization?.address.city}<br/>
                        </p>

                        <div className="--mt3 --mb2">
                            {this.signed ? this.selectedBusinessLocation : (
                                <>
                                    {this.businessLocations && (
                                        <DropdownInput
                                            className="--no-print"
                                            inline
                                            disabled={this.loading || this.signed}
                                            options={this.businessLocations.map(x => x.name)}
                                            value={this.selectedBusinessLocation}
                                            onValueSelected={value => this.selectedBusinessLocation = value}
                                        />
                                    )}
                                    <span className="--only-print">{this.selectedBusinessLocation}</span>
                                </>
                            )}
                            , {formatDate(this.date, 'D MMMM YYYY')}
                        </div>

                        <p>
                            Betreft: offerte<br/>
                            Referentienummer: {this.npv.reference}
                            {this.organization?.sapCustomerId && <>
                                <br/>Klantnummer: {this.organization?.sapCustomerId}</>}
                        </p>

                        <p>
                            Beste/Geachte {this.npv.contactName},
                        </p>
                        <p>
                            Met genoegen presenteert De Klok Dranken B.V. (hierna genoemd: De Klok) u deze offerte voor
                            uw inkoop en leverantie van ons dranken- en dienstenpakket, welke u kunt bestellen op onze
                            bestelportal www.deklokdranken.nl.
                            <em>Dit voorstel wordt gedaan in aanvulling op de uitgebrachte
                                offerte {this.npv.reference}
                                Grolsch d.d. {formatDate(this.date)} van Grolsche Bierbrouwerij Nederland B., 'D MMMM
                                YYYYV.
                                (hierna genoemd: Grolsch)</em>.
                        </p>

                        <p>
                            Hieronder vindt u het overzicht van de commerciële condities die van toepassing zijn op uw
                            online bestellingen geleverd op de locatie te ({this.organization?.address.zipCode})
                            {this.organization?.address.city} aan de {this.organization?.address.companyLine1}.<br/>
                            Voor de bepaling van de overige actuele prijzen verwijzen wij u naar de geldende bruto
                            handelsprijzen zoals vermeld op uw account binnen onze bestelportal.
                        </p>

                        <p>
                            <strong>Commerciële condities</strong><br/>
                            Wij zijn bereid om onderstaande bonuscondities/korting af factuur te verstrekken over de
                            geldende bruto handelsprijzen op het met u overeengekomen drankenassortiment (zie bijlage
                            a).
                        </p>

                        <ul>
                            {this.npv.salesGroups.map(salesGroup => (
                                <li key={salesGroup.id!}>
                                    {salesGroup.salesGroup.name}
                                    <span style={{float: 'right'}}>
                                        {this.renderConditions(salesGroup)}
                                    </span>
                                    <ul>
                                        {salesGroup.products.filter(x => x.customConditions).map(product => (
                                            <li key={product.id!}>
                                                {product.productName}
                                                <span style={{float: 'right'}}>
                                                {this.renderConditions(product)}
                                            </span>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ul>

                        <p>
                            Procentuele bonussen en/of kortingen worden niet verstrekt over accijns of andere door de
                            overheid eventueel opgelegde prijsverhogingen, alsmede zijn deze condities niet van
                            toepassing op producten die zijn afgenomen gedurende een actieperiode voor deze producten.
                        </p>
                        <p>
                            De Klok kan u deze condities aanbieden, zo lang u minimaal € 20.000,-- netto factuurwaarde
                            (minus overige bonuscondities, exclusief BTW en exclusief statiegeld) op jaarbasis inkoopt.
                            Indien u deze realisatie niet behaalt, is De Klok eenzijdig gerechtigd bovenstaande
                            condities stop te zetten.
                        </p>

                        <em>
                            <p>
                                <strong>Uitbetaling bonus</strong><br/>
                                De jaarbonus wordt in het eerste kwartaal van het kalenderjaar berekend en gecrediteerd
                                over de totaal afgenomen, gefactureerde en betaalde producten. Als voorwaarde voor
                                bonusuitkering geldt uiteraard dat u al uw verplichtingen jegens De Klok en Grolsch
                                correct nakomt, uw dranken afneemt van De Klok, en u bovendien de drankenleverantie(s)
                                correct aan De Klok betaalt via een automatische incasso. Dit betekent dat, indien u
                                gedurende een tijdvak in deze zin uw verplichtingen niet nakomt, De Klok zich het recht
                                voorbehoudt om u over dat tijdvak ook geen bonus uit te keren. Overigens is het niet
                                toegestaan uw aanspraak op bonus over te dragen aan een derde, op welke wijze dan ook.
                            </p>
                            <p>
                                Indien er sprake is van enige betalingsachterstand en/of vordering van De Klok en/of
                                Grolsch uit welke hoofde dan ook, hebben zij beide het recht de uit te keren bonus aan
                                te wenden ter voldoening van de achterstand en/of vordering. Dit betekent dat de gelden
                                niet aan u zullen worden uitgekeerd. Door ondertekening van deze offerte stemt u hiermee
                                uitdrukkelijk in.
                            </p>
                        </em>

                        <p>
                            <strong>Koeltechnische materialen</strong><br/>
                            Deze offerte is gebaseerd op het gegeven dat De Klok geen extra koelapparatuur en/of andere
                            faciliteiten moet overnemen c.q. dient te verstrekken aan u. Indien dit - in de toekomst -
                            wel het geval zal zijn, zullen wij deze investering bij u in rekening brengen c.q.
                            verrekenen
                            met de aan u geoffreerde faciliteiten.
                        </p>

                        <p>
                            <strong>Evenementenmaterialen</strong><br/>
                            Indien u wenst, adviseren wij u graag bij binnen- en buitenevenementen.<br/>
                            De evenementenmaterialen worden tegen de daarvoor geldende huurprijzen en voorwaarden
                            beschikbaar gesteld. Evenementenmaterialen staan in ons systeem gereserveerd indien u
                            hiervan
                            een schriftelijke bevestiging met reserveringsnummer ontvangen hebt. Wij verzoeken u, in een
                            zo vroeg mogelijk stadium, uw evenementenreservering bij Eventdesk@grolsch.nl te plaatsen,
                            zodat wij u zo goed mogelijk van dienst kunnen zijn.
                        </p>
                        <p>
                            De afzet- en tapmaterialen voor uw evenement zullen door Grolsch worden geleverd en
                            gefactureerd conform hetgeen hieronder is afgesproken onder betaalcondities en termijn.
                        </p>

                        <em>
                            <p>
                                <strong>Exclusieve afnameovereenkomst</strong><br/>
                                De Klok, in samenwerking met Grolsch, verstrekt u de genoemde financiële voordelen onder
                                de voorwaarde dat u gedurende de looptijd van de afnameovereenkomst zoals vermeld in de
                                offerte van Grolsch {this.npv.reference} d.d. {formatDate(this.date)} exclusief al , 'D
                                MMMM YYYYuw
                                in de
                                reguliere bedrijfsvoering benodigde producten afneemt. Bij De Klok worden die bepaald
                                in de bijgesloten assortimentslijst (zie bijlage a).
                            </p>
                        </em>

                        <p>
                            <strong>Bestellen en afleveringen</strong><br/>
                            Afleverfrequentie: {this.npv.yearlyDrops} keer per jaar<br/>
                            Aflevermomenten:
                        </p>

                        <table className="table">
                            <tbody>
                            {this.renderDeliveryMomentRow('Maandag', DeliveryMoment.MondayMorning, DeliveryMoment.MondayAfternoon)}
                            {this.renderDeliveryMomentRow('Dinsdag', DeliveryMoment.TuesdayMorning, DeliveryMoment.TuesdayAfternoon)}
                            {this.renderDeliveryMomentRow('Woensdag', DeliveryMoment.WednesdayMorning, DeliveryMoment.WednesdayAfternoon)}
                            {this.renderDeliveryMomentRow('Donderdag', DeliveryMoment.ThursdayMorning, DeliveryMoment.ThursdayAfternoon)}
                            {this.renderDeliveryMomentRow('Vrijdag', DeliveryMoment.FridayMorning, DeliveryMoment.FridayAfternoon)}
                            {this.renderDeliveryMomentRow('Zaterdag', DeliveryMoment.SaturdayMorning, DeliveryMoment.SaturdayAfternoon)}
                            {this.renderDeliveryMomentRow('Zondag', DeliveryMoment.SundayMorning, DeliveryMoment.SundayAfternoon)}
                            </tbody>
                        </table>
                        <p>
                            Uw bestellingen dienen uiterlijk iedere werkdag voor 17.00 uur via
                            onze bestelportal geplaatst te worden, zodat wij alle zorg aan uw bestelling kunnen geven en
                            op de afgesproken afleverdag kunnen leveren.
                        </p>
                        <p>
                            Vanaf € 350,-- (exclusief BTW en statiegeld per bestelling) zullen wij de bestelling gratis
                            bij u afleveren. Indien u niet aan dit bedrag komt zijn wij helaas genoodzaakt u € 25,--
                            transportkosten in rekening te brengen.
                        </p>
                        <p>
                            Voor bestellingen die in het weekend geleverd moeten worden kunt u op zaterdag en zondag
                            tot 10.00 uur de voicemail van onze weekenddienst (085-0471909) inspreken. Kies uw vestiging
                            in het keuzemenu en onze chauffeur komt in de loop van de dag uw bestelling afleveren. Voor
                            deze weekendservice brengen wij u € 50,-- in rekening.
                        </p>

                        <p>
                            <strong>Betaalcondities en termijn</strong><br/>
                            Facturen dienen te worden betaald binnen 8 dagen na factuurdatum. U verstrekt De Klok een
                            overeenkomst voor machtiging tot automatische incasso (bijlage b) van de openstaande
                            facturen na 8 dagen. Kosten per eventuele storno bedragen, vanaf de 2e storno, € 2,50 per
                            factuur.
                            Wij houden ons het recht voor over te laat betaalde facturen rente in rekening te brengen.
                        </p>
                        <p>
                            Over facturen die niet in één keer middels machtiging tot incasso zijn voldaan worden geen
                            bonussen, kortingen en/of andere in deze offerte genoemde faciliteiten berekend en
                            gecrediteerd.
                        </p>
                        <p>
                            Mocht u zich niet houden aan de overeengekomen betalingstermijn, dan behoudt De Klok zich
                            het
                            recht voor u over te zetten op iDEAL betaling binnen de betaalfunctionaliteit van onze
                            bestelportal. De Klok zal dan eerst leveren nadat u het volledige factuurbedrag van uw
                            bestelling per iDEAL betaling heeft voldaan.
                        </p>
                        <p>
                            In het kader van duurzaamheid versturen wij onze facturen digitaal naar het door u opgegeven
                            e-mailadres.
                        </p>

                        <p>
                            <strong>Privacy Policy</strong><br/>
                            Ter uitvoering van deze overeenkomst en in verband met eventuele toekomstige
                            bestellingen/orders verwerkt De Klok uw gegevens, waaronder begrepen uw eventuele
                            persoonsgegevens. Indien noodzakelijk voor de uitvoering van de overeenkomst of sprake is
                            van een gerechtvaardigd belang zullen wij uw persoonsgegevens met een of meerdere
                            leveranciers delen. Indien u uw exploitatie als natuurlijk persoon voert en niet akkoord
                            bent met het delen van deze informatie aan de leverancier op basis van het gerechtvaardigd
                            belang, dan kunt u dit vanzelfsprekend bij ons aangeven. Kijk op onze
                            site <a href="https://www.deklokdranken.nl/avg">www.deklokdranken.nl/avg</a> voor meer
                            informatie
                            met betrekking tot een lijst van onze leveranciers waarmee wij gegevens delen. Op onze site
                            vindt u ook onze Privacy Policy voor meer/overige informatie over de verwerking van
                            persoonsgegevens door De Klok en op welke wijze betrokkenen eenvoudig hun rechten kunnen
                            uitoefenen.
                        </p>
                        {this.signed ? (
                            this.receiveEmail ? (
                                <p>Ja, ik wil de nieuwsbrieven en acties van De Klok ontvangen
                                    op {this.emailSubscribe}</p>
                            ) : (
                                <p>Nee, ik wil geen nieuwsbrieven en acties van De Klok ontvangen</p>
                            )
                        ) : (
                            <>
                                <label className="label">
                                    <input
                                        type="checkbox"
                                        onChange={evt => this.receiveEmail = evt.target.checked}
                                        checked={this.receiveEmail}
                                        disabled={this.loading || this.signed}/>
                                    Ja, ik wil de nieuwsbrieven en acties van De Klok ontvangen op
                                </label>
                                <Input
                                    placeholder="E-mailadres"
                                    disabled={this.loading || this.signed}
                                    onChange={evt => this.emailSubscribe = evt.target.value}
                                    value={this.emailSubscribe}/>
                            </>
                        )}
                        <p>
                            <strong>Afhandeling</strong><br/>
                            Voor de goede orde wijzen wij u er op, dat deze offerte is uitgebracht onder uitdrukkelijk
                            voorbehoud van goedkeuring door de statutaire directie van De Klok Dranken B.V. Dit betekent
                            dat u, tot het moment waarop deze goedkeuring schriftelijk aan u kenbaar is gemaakt, geen
                            enkel recht aan deze offerte en het daarin verwoorde kunt afleiden. Het kan zo zijn dat
                            genoemde directie niet instemt met hetgeen u is aangeboden. Daarmee komt deze offerte te
                            vervallen.
                        </p>

                        <p>
                            <strong>Tot slot</strong><br/>
                            Wij zijn van mening dat wij u met deze offerte een passend voorstel hebben gedaan en hopen
                            een buitengewoon prettige samenwerking met u aan te gaan/voort te zetten. Indien u deze
                            mening met ons deelt en u zich met de inhoud van deze offerte kunt verenigen, verzoeken wij
                            u vriendelijk deze op de aangegeven plaats voor akkoord te ondertekenen, alsmede iedere
                            pagina en bijlage te voorzien van uw paraaf. Alle eventuele voorgaande
                            bonus/kortingscondities en afspraken komen alsdan
                            per {formatDate(this.npv.preNpv?.firstLoad)} , 'D MMMM YYYYte
                            vervallen. Deze offerte is persoonlijk en niet overdraagbaar bij verkoop of verhuur van de
                            exploitatie.
                        </p>
                        <p>
                            Deze offerte is 30 na dagtekening geldig, waarna deze offerte bij
                            niet-acceptatie komt te vervallen.
                        </p>

                        <p>Met vriendelijke groet,</p>

                        <p>
                            {state.customer?.first_name} {state.customer?.last_name}<br/>
                            De Klok Dranken B.V.
                        </p>

                        <div className="--page-break"></div>
                        <p>
                            <u>Acceptatie offerte en akkoordverklaring Algemene Voorwaarden alsmede Privacy
                                Policy</u>
                        </p>
                        <div className="--mb">
                            {this.signed ? this.city : (
                                <Input
                                    inline
                                    required
                                    disabled={this.loading}
                                    placeholder="Plaats exploitant"
                                    value={this.city}
                                    onChange={evt => this.city = evt.target.value}
                                />
                            )}, {formatDate(this.date, 'D MMMM YYYY')}
                        </div>
                        <p>
                            {this.organization?.name}
                        </p>
                        <p>
                            {this.npv.contactName}
                        </p>
                        <p>
                            Verklaart hierbij namens {this.npv.contactName} bevoegd te zijn tot het accepteren van
                            deze offerte.
                        </p>
                        <p>
                            {this.contact?.fullName} verklaart hierbij de identiteit en handtekening
                            van {this.npv.contactName} te hebben geverifieerd aan
                            de hand van een geldig legitimatiebewijs.
                        </p>

                        <strong>Handtekening rayonmanager</strong><br/>
                        {this.signed ? <img src={this.npv.signatureData?.rayonManagerSignature}/> : (
                            <>
                                <SignatureCanvas penColor="green"
                                                 ref={this.rayonManagerSignatureRef}
                                                 canvasProps={{
                                                     width: 500,
                                                     height: 200,
                                                     style: {
                                                         border: '1px solid black',
                                                         background: '#fff',
                                                         display: 'block',
                                                     },
                                                 }}/>
                                <button type="button" onClick={() => this.rayonManagerSignatureRef.current?.clear()}
                                        className="--no-print">
                                    Wissen
                                </button>
                            </>
                        )}

                        {this.signed ? (
                            <p>
                                Documentsoort: {this.documentType}<br/>
                                Documentnummer: {this.documentNr}
                            </p>
                        ) : (
                            <FormRow grow={false} columns={[
                                <FormRowColumn label="Documentsoort">
                                    <DropdownInput
                                        disabled={this.loading}
                                        options={[
                                            'Rijbewijs',
                                            'ID',
                                            'Paspoort',
                                        ]}
                                        value={this.documentType}
                                        onValueSelected={value => this.documentType = value}
                                    />
                                </FormRowColumn>,
                                <FormRowColumn label="Documentnummer">
                                    <Input
                                        required
                                        disabled={this.loading}
                                        value={this.documentNr}
                                        onChange={evt => this.documentNr = evt.target.value}/>
                                </FormRowColumn>,
                            ]}/>
                        )}

                        <p>
                            <u>Goedkeuring directie</u><br/>
                            Delfgauw, {formatDate(this.date, 'D MMMM YYYY')}
                        </p>
                        <p>
                            Frank Vonck/Mark van Veenhuizen<br/>
                            De Klok Dranken B.V.
                        </p>

                        <strong>Paraaf klant</strong><br/>
                        {this.signed ? <img src={this.npv.signatureData?.customerSignature}/> : (
                            <>
                                <SignatureCanvas penColor="green"
                                                 ref={this.customerSignatureRef}
                                                 canvasProps={{
                                                     width: 500,
                                                     height: 200,
                                                     style: {
                                                         border: '1px solid black',
                                                         background: '#fff',
                                                         display: 'block',
                                                     },
                                                 }}/>
                                <button type="button" onClick={() => this.customerSignatureRef.current?.clear()}
                                        className="--no-print">
                                    Wissen
                                </button>
                            </>
                        )}

                        {this.renderAttachments()}
                    </div>
                </div>

                <div className="row --no-print">
                    <div className="content-wrap">
                        {this.signed ? (
                            <button type="button"
                                    disabled={this.loading}
                                    onClick={() => location.href = `${state.urls.npv}/agreement/pdf?id=${this.props.id}`}
                                    className="button button--primary">
                                Download
                            </button>
                        ) : (
                            <button type="submit"
                                    disabled={this.loading}
                                    className="button button--primary">
                                Opslaan
                            </button>
                        )}
                    </div>
                </div>

            </form>
        );
    }

    protected renderAttachments() {
        return (
            <>
                <div className="--page-break">
                    <h4 style={{margin: 0}}>Bijlage A: Assortimentslijst De Klok Dranken</h4>
                    <div style={{fontSize: '.9em'}}>
                        <p>Het pakket overige dranken/producten bestaat uit het gehele assortiment dat naast de Grolsch
                            gerelateerde producten aangeboden wordt door De Klok Dranken. Dit assortiment kan in de
                            volgende
                            categorieën worden uitgesplitst:</p>
                        <ul>
                            <li>Overige speciaalbieren</li>
                            <li>Non-alcoholische dranken</li>
                            <li>Gedistilleerd</li>
                            <li>Wijnen/Aperitieven</li>
                            <li>Koffie/Thee/Zuivel</li>
                            <li>Horeca assortiment Food/Non-Food</li>
                        </ul>
                        <p>Binnen deze categorieën gelden de producten van onder andere doch niet uitsluitend de
                            volgende
                            partners/leveranciers:</p>

                        <strong>Overige Speciaalbieren:</strong><br/>
                        <div
                            className="linear-layout linear-layout--horizontal linear-layout--wrap linear-layout--2 linear-layout--collapsed --mb">
                            <span>Duvel Moortgat</span>
                            <span>Brouwerij Halve Maan</span>
                        </div>

                        <strong>De Kikvorsch Jopen</strong><br/>
                        <div
                            className="linear-layout linear-layout--horizontal linear-layout--wrap linear-layout--2 linear-layout--collapsed --mb">
                            <span>Bier & Co</span><span>Texels</span>
                            <span>Gulpener</span><span>De Leckere</span>
                            <span>Bavaria (incl. Palm)</span><span>Multibier</span>
                        </div>

                        <strong>Non-alcoholische dranken:</strong><br/>
                        <div
                            className="linear-layout linear-layout--horizontal linear-layout--wrap linear-layout--2 linear-layout--collapsed --mb">
                            <span>Coca-Cola Enterprises </span><span>Red Bull</span>
                            <span>Vrumona</span><span>United Soft Drinks</span>
                            <span>Friesland Campina</span><span>Spadel</span>
                            <span>Unilever</span><span>Wonderful (Fever Tree)</span>
                        </div>

                        <strong>Gedistilleerd:</strong><br/>
                        <div
                            className="linear-layout linear-layout--horizontal linear-layout--wrap linear-layout--2 linear-layout--collapsed --mb">
                            <span>Diageo</span><span>Disaronno International</span>
                            <span>Maxxium </span><span>FLS Company (Flügel)</span>
                            <span>Bacardi</span><span>Inspirits Premium Drinks</span>
                            <span>A Brand New Day</span><span>Hooghoudt</span>
                            <span>Pernod Ricard</span><span>Koninklijke De Kuyper</span>
                        </div>

                        <strong>Wijnen/Aperitieven:</strong><br/>
                        <div
                            className="linear-layout linear-layout--horizontal linear-layout--wrap linear-layout--2 linear-layout--collapsed --mb">
                            <span>De Klok Dranken Importwijnen</span><span>Vinites</span>
                            <span>Verbunt Wijnkopers</span><span>Walraven & Sax</span>
                        </div>

                        <strong>Koffie/Thee/Zuivel</strong><br/>
                        <div
                            className="linear-layout linear-layout--horizontal linear-layout--wrap linear-layout--2 linear-layout--collapsed --mb">
                            <span>Manetti</span><span>Unilever Food Solutions</span>
                            <span>Douwe Egberts Professional</span><span>Friesland Campina</span>
                        </div>

                        <strong>Assortiment Food/Non-Food:</strong><br/>
                        <em>Diverse producten in de categorieën:</em><br/>
                        <div
                            className="linear-layout linear-layout--horizontal linear-layout--wrap linear-layout--2 linear-layout--collapsed --mb">
                            <span>Koekjes</span><span>Papierwaren</span>
                            <span>Candybar/Chocolade</span><span>Reinigingsmiddelen</span>
                            <span>Chips/Noten</span><span>Glaswerk</span>
                            <span>Suikerwerk</span><span>Disposables</span>
                        </div>
                        <p>Een overzicht van ons volledige assortiment vindt u op onze bestelportal: <a
                            href="https://www.deklokdranken.nl">www.deklokdranken.nl</a></p>
                    </div>
                </div>
                <div className="--page-break">
                    <h4 style={{margin: 0}}>Bijlage B: SEPA EURO doorlopende machtiging</h4>

                    <div style={{fontSize: '.9em'}}>
                        <table className="table">
                            <tbody>
                            <tr>
                                <td>Naam Incassant</td>
                                <td>Grolsche Bierbrouwerij Nederland B.V. (hierna Grolsch)</td>
                            </tr>
                            <tr>
                                <td>Adres</td>
                                <td>Brouwerslaan 1</td>
                            </tr>
                            <tr>
                                <td>Postcode/Woonplaats</td>
                                <td>7548 XA Enschede</td>
                            </tr>
                            <tr>
                                <td>Land</td>
                                <td>Nederland</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>Incassant ID</td>
                                <td>NL63205060603810000</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>Kenmerk Machtiging</td>
                                <td>
                                    Debiteurnummer:
                                    {this.signed ? this.sepa.debtorNumber : (
                                        <Input
                                            inline
                                            required
                                            disabled={this.loading}
                                            value={this.sepa.debtorNumber}
                                            onChange={evt => this.sepa.debtorNumber = evt.target.value}
                                            placeholder="Debiteurnummer"
                                        />
                                    )}
                                    &nbsp;&nbsp;Datum:
                                    {formatDate(this.sepa.date, 'D MMMM YYYY')}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <em>
                                        Door ondertekening van dit formulier geeft u toestemming aan Grolsch om
                                        doorlopende
                                        incasso-opdrachten te sturen naar uw bank om een bedrag af te schrijven wegens:
                                        <ul>
                                            <li>ontvangen facturen van De Klok Dranken B.V.</li>
                                            <li>ontvangen facturen van Grolsche Bierbrouwerij Nederland B.V.</li>
                                        </ul>
                                        en uw bank om doorlopend een bedrag van uw rekening af te schrijven
                                        overeenkomstig
                                        de opdracht van Grolsch. Als u het niet eens bent met deze afschrijving kunt u
                                        deze
                                        laten terugboeken. Neem hiervoor binnen 8 weken contact op met uw bank. Vraag uw
                                        bank naar de voorwaarden.
                                    </em>
                                </td>
                            </tr>
                            <tr>
                                <td>Naam klant</td>
                                <td>
                                    {this.signed ? this.sepa.organizationName : (
                                        <Input
                                            inline
                                            required
                                            disabled={this.loading}
                                            placeholder="Naam klant"
                                            value={this.sepa.organizationName}
                                            onChange={evt => this.sepa.organizationName = evt.target.value}
                                        />
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>Adres</td>
                                <td>
                                    {this.signed ? this.sepa.organizationAddress : (
                                        <Input
                                            inline
                                            required
                                            disabled={this.loading}
                                            placeholder="Adres"
                                            value={this.sepa.organizationAddress}
                                            onChange={evt => this.sepa.organizationAddress = evt.target.value}
                                        />
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>Postcode/Woonplaats</td>
                                <td>
                                    {this.signed ? `${this.sepa.organizationZipCode} ${this.sepa.organizationCity}` : (
                                        <>
                                            <Input
                                                inline
                                                required
                                                disabled={this.loading}
                                                placeholder="Postcode"
                                                value={this.sepa.organizationZipCode}
                                                onChange={evt => this.sepa.organizationZipCode = evt.target.value}
                                            />
                                            <Input
                                                inline
                                                required
                                                disabled={this.loading}
                                                placeholder="Woonplaats"
                                                value={this.sepa.organizationCity}
                                                onChange={evt => this.sepa.organizationCity = evt.target.value}
                                            />
                                        </>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>Land</td>
                                <td>Nederland</td>
                            </tr>
                            <tr>
                                <td>IBAN</td>
                                <td>
                                    NL
                                    {this.signed ? this.sepa.iban : (
                                        <Input
                                            inline
                                            required
                                            disabled={this.loading}
                                            value={this.sepa.iban}
                                            onChange={evt => this.sepa.iban = evt.target.value}
                                        />
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td valign={'top'}>
                                    <strong>Plaats en datum</strong><br/>
                                    {this.signed ? this.sepa.city : (
                                        <Input
                                            inline
                                            required
                                            disabled={this.loading}
                                            placeholder="Plaats exploitant"
                                            value={this.sepa.city}
                                            onChange={evt => this.sepa.city = evt.target.value}
                                        />
                                    )}, {formatDate(this.sepa.date, 'D MMMM YYYY')}
                                </td>
                                <td valign={'top'}>
                                    <strong>Handtekening</strong><br/>
                                    {this.signed ? <img src={this.sepa?.customerSignature}/> : (
                                        <>
                                            <SignatureCanvas penColor="green"
                                                             ref={this.sepaCustomerSignatureRef}
                                                             canvasProps={{
                                                                 width: 500,
                                                                 height: 200,
                                                                 style: {
                                                                     border: '1px solid black',
                                                                     background: '#fff',
                                                                     display: 'block',
                                                                 },
                                                             }}/>
                                            <button type="button"
                                                    onClick={() => this.sepaCustomerSignatureRef.current?.clear()}
                                                    className="--no-print">
                                                Wissen
                                            </button>
                                        </>
                                    )}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p><em>Grolsche Bierbrouwerij Nederland B.V. is door De Klok Dranken B.V. gevolmachtigd om
                            namens
                            haar te incasseren. De factuurbedragen zullen op de vermelde vervaldatum op het
                            factuurdocument
                            van uw bankrekening worden afgeschreven.</em></p>
                    </div>
                </div>
                <div className="--page-break">
                    <h4 style={{margin: 0}}>Bijlage C: SEPA EURO doorlopende machtiging</h4>
                    <p><strong>Gevestigd te Enschede, (ingeschreven Handelsregister Kamer van Koophandel Enschede
                        dossier no. 17093426)<br/>
                        Gedeponeerd ter Griffie van de Rechtbank Overijssel, locatie Almelo, op 2 oktober 2019 onder
                        nummer 41/2019.
                    </strong></p>
                    <div style={{columnCount: 2, fontSize: '.7em', lineHeight: '1.6em'}}>
                        <em>Algemene bepalingen</em><br/>
                        <strong>Artikel 1: Werkingssfeer</strong><br/>
                        1. Deze voorwaarden zijn integraal van toepassing op alle verbintenissen, die voortvloeien uit
                        de overeenkomst waarop deze voorwaarden van toepassing zijn verklaard, tenzij schriftelijk
                        anders overeengekomen. Zij zijn tevens van toepassing op alle verbintenissen, die voortvloeien
                        uit tussen partijen gesloten en te sluiten overeenkomsten of precontractuele rechtsbetrekkingen.<br/>
                        2. Toepasselijkheid van de door afnemer gehanteerde voorwaarden wordt hierbij uitgesloten,
                        tenzij schriftelijk anders is overeengekomen. Bestaat er in dat laatste geval strijdigheid
                        tussen de onderhavige voorwaarden en de door afnemer gehanteerde voorwaarden, dan prevaleren de
                        onderhavige voorwaarden.<br/>
                        3. Indien enige bepaling van deze voorwaarden om welke reden dan ook niet geldig zou zijn, dan
                        blijven deze voorwaarden voor het overige van kracht en zullen partijen in onderling overleg
                        voorzien in een regeling ter vervanging van de ongeldige bepaling met zoveel mogelijk behoud van
                        de strekking daarvan.<br/>
                        <strong>Artikel 2: Partijen</strong>
                        In deze voorwaarden worden partijen aangeduid als "leverancier" en "afnemer". Onder
                        "leverancier" wordt verstaan de besloten vennootschap De Klok Dranken B.V. Onder "afnemer" wordt
                        verstaan de horecaondernemer, de detaillist, groothandel, of enige andere wederpartij niet
                        zijnde de consument.<br/>
                        <strong>Artikel 3: Registratie</strong><br/>
                        1. Om van het online (order)systeem van leverancier gebruik te kunnen maken dient afnemer zich
                        te registreren om een account aan te maken en inloggegevens te verkrijgen. Registratie geschiedt
                        middels invulling en verzending van het daarvoor bestemde (elektronische) formulier. Afnemer is
                        verplicht het registratieformulier naar waarheid in te vullen. Afnemer verklaart door
                        registratie bevoegd te zijn om orders te plaatsen en te handelen in de uitoefening van een
                        beroep of bedrijf.<br/>
                        2. Leverancier verstrekt de inloggegevens aan afnemer, in beginsel per e-mail. Afnemer is
                        verplicht enkel zelf in te loggen op en gebruik te maken van het account en het account
                        uitsluitend voor zakelijke doeleinden te gebruiken. Gebruik en/of misbruik van het account van
                        afnemer komt uitsluitend voor rekening en risico van afnemer. Afnemer is verplicht de
                        inloggegevens geheim te houden. Afnemer is aansprakelijk voor door leverancier te lijden schade
                        ten gevolge van gebruik door derden van het account van afnemer.<br/>
                        3. Afnemer dient leverancier op de hoogte te stellen indien en zodra afnemer weet of een
                        vermoeden heeft dat de inloggegevens in handen zijn gekomen van onbevoegden. Leverancier heeft
                        het recht daarop doeltreffende maatregelen te nemen.<br/>
                        <strong>Artikel 4: Aanbiedingen/orders</strong><br/>
                        1. Alle aanbiedingen, in welke vorm ook gedaan, zijn vrijblijvend, tenzij schriftelijk anders is
                        overeengekomen.<br/>
                        2. Orders zijn voor leverancier eerst bindend, wanneer zij door leverancier schriftelijk zijn
                        bevestigd, danwel bij feitelijke uitvoering.<br/>
                        3. Leverancier behoudt zich het recht voor om voor elk van de door hem te leveren goederen
                        minimumhoeveelheden en een minimumaankoopbedrag te bepalen.<br/>
                        <strong>Artikel 5: Prijzen</strong><br/>
                        1. Tenzij schriftelijk anders is overeengekomen en/of door leverancier is aangegeven, gelden
                        alle opgegeven prijzen
                        inclusief verzendkosten en alle heffingen, belastingen en accijnzen, etc. doch exclusief btw,
                        het statiegeld, waarborgsommen en voor zover van toepassing eventuele afzonderlijk
                        gespecificeerde toeslagen.<br/>
                        2. Per factuur kan leverancier verwerkingskosten in rekening brengen.<br/>
                        3. Vertegenwoordigt de levering een bedrag lager dan het door leverancier te bepalen minimum
                        bedrag/hoeveelheid als bedoeld in artikel 4 lid 3, dan kan een ordergroottetoeslag in rekening
                        worden gebracht.<br/>
                        4. Voor spoedbestellingen in het weekend, zijnde bestellingen die geleverd dienen te worden in
                        de periode tussen vrijdagavond 17.00 uur en maandagochtend 9.00 uur, geldt een
                        weekenddiensttoeslag.<br/>
                        5. Voor contante betalingen geldt een risico- en administratieve toeslag.<br/>
                        6. Actieartikelen worden niet retourgenomen. Actieartikelen zijn volledig uitgezonderd van
                        eventuele bonus- en/of kortingsafspraken.<br/>
                        7. Leverancier brengt handlingkosten in rekening bij het retourneren van volgoed.<br/>
                        8. Voor de door leverancier rechtstreeks aan afnemer geleverde producten gelden prijzen en
                        toeslagen zoals die op het tijdstip van levering in de prijscourant van leverancier zijn
                        vastgesteld. In de overeenkomst tussen leverancier en afnemer is de betreffende prijscourant van
                        toepassing verklaard.<br/>
                        9. Leverancier is te allen tijde gerechtigd prijswijzigingen door te voeren, aan welke
                        wijzigingen afnemer gebonden is.<br/>
                        <strong>Artikel 6: Levering/leveringstermijn</strong><br/>
                        1. Voor zover schriftelijk niet anders is overeengekomen geschiedt de aflevering franco huis aan
                        het met afnemer overeengekomen afleveradres. Leverancier is vrij in de keuze van de wijze van
                        vervoer van de af te leveren goederen. Vervoer of verplaatsing op het terrein en/of binnen de
                        bedrijfsruimten van afnemer zijn niet in vorenbedoelde aflevering begrepen en geschieden voor
                        rekening en risico van afnemer.<br/>
                        2. Het afleveradres c.q. losplaats, moet redelijk bereikbaar zijn voor die transportmiddelen,
                        die voor leveranties algemeen gebruikelijk zijn. Afnemer moet op het afleveradres voor voldoende
                        laad- en losfaciliteiten voor het onmiddellijk lossen c.q. laten lossen van de goederen
                        zorgdragen. Voor het lossen van de goederen en het laden van eventuele retourvrachten zal
                        afnemer voor eigen rekening en risico zorgdragen voor voldoende personeel en (mechanische)
                        hulpmiddelen. Afnemer zal al het mogelijke doen om te bereiken dat de wachttijd tussen het
                        tijdstip van melding van aankomst op het afleveradres en het tijdstip waarop met het lossen van
                        de af te leveren goederen kan worden begonnen tot een minimum wordt beperkt. Tussen afnemer en
                        leverancier worden de tijden waarop de vervoerder(s) in de gelegenheid worden gesteld om de
                        goederen op het adres van afnemer af te leveren en retouremballage mee te nemen, schriftelijk
                        vastgelegd.<br/>
                        3. Opgegeven levertijden zullen nimmer zijn te beschouwen als fatale termijnen, tenzij
                        schriftelijk anders is overeengekomen. De enkele overschrijding van de overeengekomen
                        leveringstermijn zal geen verzuim van leverancier opleveren. Indien afnemer wenst dat de
                        aflevering alsnog binnen een redelijke termijn zal plaatsvinden, dient afnemer dit schriftelijk
                        kenbaar te maken aan leverancier. Indien leverancier hiermee in gebreke blijft, is afnemer,
                        behoudens het bepaalde in artikel 13, gerechtigd eenzijdig de overeenkomst bij aangetekend
                        schrijven te ontbinden voor het niet uitgevoerde gedeelte. Afnemer heeft dit recht niet indien
                        hij van zijn kant in verzuim is. Leverancier is in geen geval aansprakelijk voor enige
                        (gevolg)schade van afnemer ten gevolge van overschrijding van de overeengekomen
                        leveringstermijn.<br/>
                        4. Leverancier heeft te allen tijde het recht om de goederen onder rembours af te leveren of om
                        vooruitbetaling of zekerheidsstelling in de door hem gewenste vorm, danwel contante betaling te
                        verlangen.<br/>
                        5. Retourzendingen van geleverde goederen zijn slechts toegestaan, indien leverancier daartoe
                        uitdrukkelijk voorafgaande schriftelijke toestemming heeft verleend.<br/>
                        6. Leverancier is gerechtigd bestellingen in gedeelten te leveren en te factureren, tenzij
                        schriftelijk anders is overeengekomen.<br/>
                        <strong>Artikel 7: Reclames</strong><br/>
                        1. Reclames betreffende de geleverde goederen en/of facturatie hebben geen gevolg indien zij
                        niet binnen zeven dagen na aflevering van de goederen of binnen zeven dagen na factuurdatum
                        schriftelijk bij leverancier zijn gemeld, onder nauwkeurige opgave van de aard en omvang van de
                        klachten. Na het verstrijken van deze termijn wordt afnemer geacht de geleverde goederen, ook
                        voor wat betreft de op de goederen vermelde houdbaarheidstermijnen, en het verschuldigde te
                        hebben goedgekeurd.<br/>
                        2. Indien leverancier van oordeel is dat een klacht gegrond is, is hij uitsluitend gehouden tot
                        zo spoedig mogelijke nieuwe levering (vervanging of levering van het ontbrekende), zonder dat
                        afnemer aanspraak heeft op schadevergoeding. Afnemer zal de goederen, waarop de klacht
                        betrekking heeft, ter beschikking van leverancier houden.<br/>
                        3. Het indienen van een reclame ontslaat afnemer niet van nakoming van zijn (betalings-)
                        verplichtingen.<br/>
                        <strong>Artikel 8: Betaling</strong><br/>
                        1. Tenzij uitdrukkelijk schriftelijk anders is overeengekomen, dient betaling van facturen op de
                        door leverancier aan te geven wijze te geschieden binnen acht dagen na de factuurdatum, dit
                        zodanig dat het verschuldigde bedrag binnen de gestelde termijn op de bankrekening van
                        leverancier is bijgeschreven. Bij niet tijdige betaling verkeert afnemer zonder nadere
                        aankondiging of ingebrekestelling in verzuim.<br/>
                        2. Eventuele geschillen tussen afnemer en leverancier over kwaliteit of uit anderen hoofde door
                        afnemer ingediende reclames geven afnemer niet het recht enige betaling op te schorten of te
                        verrekenen.<br/>
                        3. In geval van niet tijdige betaling is afnemer aan leverancier wettelijke handelsrente<br/>
                        verschuldigd als bedoeld in artikel 6:119a BW.
                        4. Leverancier bepaalt te allen tijde zelf aan welke openstaande vordering op afnemer hij enige
                        betaling toerekent.<br/>
                        5. Bonus- en/of kortingsbedragen ten gunste van afnemer komen te vervallen indien afnemer niet
                        tijdig en volledig betaalt.<br/>
                        6. Indien afnemer in gebreke of in verzuim is ten aanzien van de (tijdige) nakoming van zijn
                        verplichtingen, dan komen alle redelijke kosten ter verkrijging van voldoening buiten rechte
                        voor rekening van afnemer. Eventueel gemaakte gerechtelijke en executiekosten komen eveneens
                        voor rekening van afnemer. De buitengerechtelijke kosten bedragen tenminste vijftien procent van
                        het verschuldigde bedrag met een minimum van
                        € 500,00.<br/>
                        7. Uitsluitend leverancier is gerechtigd om verrekening toe te passen ten aanzien van alle
                        vorderingen en schulden die afnemer heeft of mocht krijgen, niet alleen van leverancier jegens
                        afnemer, doch ook van aan leverancier direct of indirect gelieerde rechtspersonen en/of
                        natuurlijke personen jegens afnemer.<br/>
                        8. Voor de afwikkeling van betalingen voor online bestellingen kan Grolsch gebruik maken van
                        diensten van derden. In voorkomende gevallen zullen de daarvoor noodzakelijke persoonsgegevens
                        aan deze derden worden verstrekt.
                        <br/>
                        <strong>Artikel 9: Risico/Eigendomsvoorbehoud</strong><br/>
                        1. Het risico ten aanzien van beschadiging en verloren gaan van de geleverde goederen en van
                        eventueel daaruit voortvloeiende schade gaat bij aflevering direct na aftekening van de
                        begeleidende vrachtbrieven over op afnemer, zijnde het moment waarop de goederen los-/laadgereed
                        aangeboden worden aan afnemer.<br/>
                        2. Alle door leverancier afgeleverde goederen blijven eigendom van leverancier totdat afnemer de
                        koopprijs – daaronder begrepen buitengerechtelijke kosten, rente en boetes, evenals enige andere<br/>
                        vordering als bedoeld in artikel 3:92 lid 2 BW – heeft voldaan. Afnemer heeft echter het
                        rechtvordering
                        de goederen in het kader van zijn normale bedrijfsvoering door te verkopen of te verwerken,
                        tenzij leverancier schriftelijk te kennen geeft dat afnemer deze goederen terstond ter
                        beschikking van leverancier moet stellen.<br/>
                        3. Indien afnemer in verzuim is ten aanzien van de prestaties als in dit artikel bedoeld, is
                        leverancier gerechtigd de zaken die aan hem toebehoren zelf en voor rekening van afnemer terug
                        te (doen) halen van de plaats waar zij zich bevinden. Afnemer zal hieraan medewerking verlenen.
                        Afnemer geeft reeds nu onvoorwaardelijk en onherroepelijke toestemming aan leverancier of door
                        leverancier aan te wijzen derden om al die plaatsen te betreden waar de eigendommen van
                        leverancier zich bevinden en die zaken terug te nemen.<br/>
                        <strong>Artikel 10: Verplichtingen van afnemer en verboden</strong><br/>
                        1. Afnemer is verplicht:<br/>
                        a. alle door leverancier in het verkeer gebrachte goederen uitsluitend te verhandelen in de
                        originele, van leverancier afkomstige verpakkingen, zonder enige verandering daaraan of
                        beschadiging daarvan en, voor zover van toepassing, aan zijn afnemers voor de emballage gelijke
                        bedragen aan statiegeld in rekening te brengen respectievelijk bij terugontvangst te vergoeden
                        als daarvoor in de prijscourant van leverancier voor het betreffende goed zijn vermeld;<br/>
                        b. zich te onthouden van gedragingen die naam, merken, producten of emballage van de door
                        leverancier geleverde goederen of het daarmee verbonden imago zouden kunnen schaden;<br/>
                        c. alle mogelijke zorg te besteden aan de juiste opslag en behandeling van de bieren, overige
                        dranken en overige goederen van leverancier, waartoe onder meer behoort het overdekt en
                        vorstvrij opslaan van de geleverde goederen. Leverancier is nimmer aansprakelijk voor gebreken
                        van de van hem afkomstige goederen die te wijten zijn aan onjuiste of onzorgvuldige opslag en
                        behandeling daarvan door of vanwege afnemer of door hem beleverde derden;<br/>
                        2. Het is afnemer niet toegestaan:<br/>
                        a. het vullen c.q. doen vullen van cilinders of het gebruiken anderszins dan ten behoeve van
                        reguliere tap - en schenkinstallaties;<br/>
                        b. cilinders geleverd door leverancier door te leveren of ter beschikking te stellen aan derden.<br/>
                        3. Afnemer verleent aan leverancier en zijn toeleverancier en/of producent onherroepelijk en
                        onvoorwaardelijk machtiging de bij hem krachtens welke titel ook in gebruik zijnde terreinen,
                        gebouwen, ruimten en dergelijke te betreden ter zake van inspectie van emballage,<br/>
                        1. waaronder eveneens begrepen cilinders, zowel voor wat betreft de wijze van opslag als de
                        gebruikswijze.<br/>
                        4. Voor iedere overtreding of niet strikte naleving van de in dit artikel genoemde
                        verplichtingen en verboden is afnemer verplicht aan leverancier een niet voor korting of
                        compensatie vatbare boete te betalen van € 1.000,00 (zegge: duizend euro) per overtreding en van
                        € 100,00 (zegge: honderd euro) voor iedere dag dat de overtreding voortduurt, en in zodanig
                        geval te aanvaarden dat leverancier het recht heeft (een) gesloten overeenkomst(en), te
                        annuleren of ontbonden te verklaren en/of afnemer van verdere levering van goederen uit te
                        sluiten, alles met recht op schadevergoeding, onverminderd het recht van leverancier om alsnog
                        nakoming te vorderen al dan niet met schadevergoeding.<br/>
                        <strong>Artikel 11: Omzetbonus</strong>
                        Bonus- en/of kortingsbedragen ten gunste van afnemer zijn niet eerder opeisbaar dan nadat alle
                        door afnemer verschuldigde bedragen, waarover de bonus en/of kortingen worden berekend, tijdig
                        en volledig aan leverancier zijn voldaan. Door afnemer nog verschuldigde factuurbedragen kunnen
                        door leverancier op de uit te keren bonus- en/of kortingsbedragen in mindering worden gebracht.
                        Ondanks dit artikel en/of artikel 8 lid 5 door leverancier betaalde of verrekende bonus- en/of
                        kortingsbedragen in enig jaar, creëren geen aanspraak voor afnemer in enig volgend jaar.<br/>
                        <strong>Artikel 12: Aansprakelijkheid</strong><br/>
                        1. Indien leverancier aansprakelijk is, is de omvang van de aansprakelijkheid van leverancier
                        per gebeurtenis in ieder geval beperkt tot de directe schade en geldt daarbij het volgende:<br/>
                        a. primair geldt dat indien in het desbetreffende geval een uitkering wordt gedaan door de
                        aansprakelijkheidsverzekeraar van leverancier, de aansprakelijkheid beperkt is tot dit
                        uitgekeerde bedrag, te vermeerderen met het bedrag van het eigen risico dat ingevolge de
                        polisvoorwaarden niet ten laste van die verzekeraar komt;<br/>
                        b. indien ter zake geen verzekering afgesloten is of anderszins geen dekking verleend wordt en
                        aldus geen uitkering wordt gedaan voor leverancier, dan is de schade beperkt tot maximaal het
                        bedrag van de voor die leverantie/overeenkomst bedongen prijs exclusief btw. Indien de
                        overeenkomst hoofdzakelijk een duurovereenkomst is met een looptijd van meer dan een jaar, dan
                        wordt de voor de overeenkomst bedongen prijs gesteld op het totaal van de vergoedingen bedongen
                        in een jaar exclusief btw.<br/>
                        2. Leverancier is nimmer aansprakelijk voor indirecte schade, daaronder begrepen:<br/>
                        a. gevolgschade;<br/>
                        b. gederfde winst;<br/>
                        c. gemiste besparingen;<br/>
                        d. schade door bedrijfsstagnatie;
                        Leverancier is evenmin aansprakelijk voor schade ontstaan:<br/>
                        e. door niet nakoming van afnemer van aan het gebruik van de geleverde cilinders en toebehoren
                        verbonden voorschriften inclusief tijdig ter beschikking stellen ten behoeve van periodieke
                        keuring, inspectie en controle, danwel het voorziene normale gebruik;<br/>
                        f. als gevolg van normale slijtage;<br/>
                        g. door toepasselijkheid van enig overheidsvoorschrift betreffende de aard of de kwaliteit van
                        de toegepaste materialen;<br/>
                        h. door materialen, zaken, werkwijzen en constructies, voor zover op instructie van afnemer
                        toegepast, danwel aangeleverd;<br/>
                        i. aan het geleverde, wanneer afnemer (of een derde) zonder voorafgaande schriftelijke
                        goedkeuring van leverancier over is gegaan tot herstel of andere werkzaamheden aan het geleverde
                        heeft (laten) verricht(en).<br/>
                        3. Voor schade veroorzaakt door hulppersonen van leverancier, is leverancier niet aansprakelijk.<br/>
                        4. Beperkingen van aansprakelijkheid van leverancier strekken ook ten gunste van de bestuurders,
                        werknemers en niet-ondergeschikte vertegenwoordigers en hulppersonen van leverancier.<br/>
                        5. Afnemer vrijwaart leverancier volledig ter zake aanspraken van derden jegens leverancier als
                        gevolg van de overeenkomst tussen leverancier en afnemer.<br/>
                        6. Met betrekking tot GTIN-codering is leverancier nimmer aansprakelijk, tenzij de voorschriften
                        van de GS1 door leverancier niet zijn opgevolgd.<br/>
                        7. De in deze voorwaarden opgenomen beperkingen van de aansprakelijkheid gelden niet indien en
                        voor zover de schade is te wijten aan opzet of bewuste roekeloosheid van leverancier.<br/>
                        <strong>Artikel 13: Overmacht</strong><br/>
                        1. Mocht leverancier als gevolg van overmacht niet in staat zijn om aan zijn
                        leveringsverplichting te voldoen, dan zal leverancier gerechtigd zijn om, zonder in gebreke te
                        zijn, noch gehouden te zijn tot enige vergoeding van schade, de aflevering van de goederen uit
                        te stellen tot het tijdstip waarop de overmachtstoestand zal zijn beëindigd.<br/>
                        2. Mocht de overmacht langer dan een maand duren, dan zal zowel leverancier als afnemer
                        gerechtigd zijn, door kennisgeving aan de andere partij, de overeenkomst eenzijdig voor het nog
                        niet uitgevoerde gedeelte ervan zonder rechterlijke tussenkomst te beëindigen.<br/>
                        3. Onder overmacht aan de zijde van leverancier wordt hier in ieder geval verstaan: oorlog,
                        revolutie, onlusten, brand, excessieve tijdelijke stijging van de vraag, weersomstandigheden,
                        watersnood, transportbelemmeringen, ziekte, overheidsmaatregelen met inbegrip van in- en
                        uitvoermaatregelen, misoogst, storing in de aanvoer of voorziening van grondstoffen, energie of
                        bedrijfsbenodigdheden, met inbegrip van tekortkoming van leveranciers van wie leverancier een en
                        ander betrekt, staking, gebreken aan of beschadiging van machines, evenals iedere andere storing
                        in het bedrijf van leverancier.<br/>
                        <strong>Artikel 14: Beëindiging</strong><br/>
                        1. Leverancier is bevoegd (een) gesloten overeenkomst(en) met afnemer op te zeggen, danwel
                        (geheel of gedeeltelijk) te ontbinden op grond van iedere tekortkoming door afnemer in de
                        nakoming van een overeenkomst, onverminderd het recht van leverancier om schadevergoeding te
                        vorderen.<br/>
                        2. Onverminderd het bepaalde in artikel 8 en de overige hem toekomende rechten zijn de
                        vorderingen van leverancier op afnemer terstond volledig opeisbaar, zonder dat ingebrekestelling
                        of rechterlijke tussenkomst zal zijn vereist, zonder dat leverancier tot enige schadevergoeding
                        gehouden zal zijn, onder andere in de volgende situaties:<br/>
                        a. in geval afnemer in staat van faillissement wordt verklaard, hij zijn eigen faillissement
                        aanvraagt, of indien hij surseance van betaling aanvraagt, of de wet schuldsanering natuurlijke
                        personen op afnemer van toepassing wordt verklaard;<br/>
                        b. in geval afnemer een natuurlijk persoon is en overlijdt of onder curatele of bewind wordt
                        gesteld;<br/>
                        c. indien na het sluiten van de overeenkomst leverancier ter kennis gekomen omstandigheden goede
                        grond geeft te vrezen dat afnemer zijn verplichtingen niet (behoorlijk) zal nakomen;<br/>
                        d. indien leverancier afnemer heeft verzocht zekerheid te stellen voor de voldoening van zijn
                        verplichtingen uit de overeenkomst en deze zekerheid uitblijft of naar het oordeel van
                        leverancier onvoldoende is;<br/>
                        e. indien afnemer ondanks aanmaning, per aangetekende brief met berichtgeving van ontvangst of
                        per deurwaardersexploot, in verzuim blijft zijn verplichtingen na te komen binnen de gestelde
                        termijn;<br/>
                        f. indien afnemer binnen zijn onderneming de zeggenschap verliest en/of de onderneming geheel of
                        gedeeltelijk wordt overgedragen of gestaakt.
                        In de gevallen a tot en met f is leverancier tevens bevoegd de verdere uitvoering van alle
                        overeenkomsten met afnemer op te schorten, danwel tot opzegging of (geheel of gedeeltelijke)
                        ontbinding van die overeenkomsten over te gaan, een en ander onverminderd het recht van
                        leverancier om volledige schadevergoeding te vorderen.<br/>
                        3. In het geval leverancier (een) gesloten overeenkomst(en) beëindigt, is leverancier gerechtigd
                        - indien hij inmiddels gedeeltelijk zijn verplichtingen uit de overeenkomst is nagekomen of deze
                        zal kunnen nakomen - om het reeds nagekomen respectievelijk ondanks beëindiging na te komen
                        gedeelte gescheiden te factureren. Afnemer is gehouden deze factuur te voldoen als ware het een
                        afzonderlijke overeenkomst.<br/>
                        <strong>Artikel 15: Eigendom emballage</strong><br/>
                        1. Retouremballage is emballage die kennelijk voor meermalig gebruik is bestemd, waaronder
                        bijvoorbeeld doch niet uitsluitend zijn begrepen: fusten, tanks, flessen, kratten en dozen,
                        displays, cilinders, evenals hulpmiddelen, zoals pallets en rolcontainers. Retouremballage
                        blijft eigendom van leverancier of de producent, ongeacht betaling van het statiegeld of
                        waarborgsom voor deze retouremballage door afnemer.<br/>
                        2. Tenzij afgeleverde emballage kennelijk voor eenmalig gebruik bestemd is, is afnemer verplicht
                        om zoveel ledig emballagemateriaal aan leverancier te retourneren als hij van leverancier ter
                        beschikking heeft gekregen, en wel gesorteerd overeenkomstig de aard van het betreffende
                        emballagemateriaal. Leverancier is verplicht om afnemer het overeengekomen, c.q. het
                        gebruikelijke statiegeld te betalen. Indien naar het oordeel van leverancier afnemer de
                        emballage onvoldoende gesorteerd inlevert, zal leverancier niet gehouden zijn de heffing te
                        voldoen.<br/>
                        3. Bij levering van verpakkingen in welke vorm dan ook afkomstig van toeleveranciers c.q.
                        producenten waarvoor leverancier statiegeld en een retouremballagevergoeding is verschuldigd
                        treedt leverancier te dien aanzien tegenover afnemer uitsluitend en alleen op als een
                        vertegenwoordiger van de toeleverancier c.q. producenten. Restitutie van statiegeld en
                        retouremballagevergoeding ter zake vindt dan ook plaats onder het voorbehoud dat leverancier het
                        door hem betaalde statiegeld en retouremballagevergoeding van de toeleverancier c.q. producenten
                        zal terugontvangen. Facturering c.q. creditering van retouremballage en overige goederen vindt
                        plaats aan de hand van de door afnemer en leverancier te tekenen afleveringsbon.<br/>
                        4. Indien afnemer de in lid 1 bedoelde emballage op enigerlei wijze verkrijgt, hetzij van
                        leverancier, hetzij rechtstreeks van toeleveranciers van leverancier, zal afnemer deze emballage
                        en de overige retourgoederen, gaan houden voor leverancier, die deze emballage op zijn beurt zal
                        gaan houden voor de toeleverancier c.q. producent als eigenaar.<br/>
                        5. Afnemer is niet gerechtigd de dit artikel bedoelde goederen aan derden in eigendom over te
                        dragen, noch voor andere doeleinden te (laten) gebruiken dan waarvoor zij zijn bestemd.<br/>
                        <strong>Artikel 16: Statiegeld en emballage</strong><br/>
                        1. Over emballage wordt statiegeld geheven, voor zover dit door leverancier kenbaar is
                        gemaakt.<br/>
                        2. In geen geval is statiegeld te beschouwen als vergoeding voor de reële waarde van
                        emballage.<br/>
                        3. Alle retouremballage dient na gebruik zo spoedig mogelijk aan leverancier te worden
                        geretourneerd, waarbij afnemer verplicht is zorg te dragen dat de flessen naar inhoudsmaat, vorm
                        en kleur, in de bijbehorende kratten, kisten of dozen gesorteerd zijn en dat de emballage naar
                        soort en kleur verpakking gepalletiseerd is. Losse flessen en flessen in andere verpakkingen dan
                        de kisten, kratten en dozen van leverancier, behoeven door deze niet te worden teruggenomen.
                        Indien afnemer - naar het oordeel van leverancier - de emballage onvoldoende gesorteerd, of
                        herhaaldelijk met niet/zeer moeilijk verwijderbare stickers inlevert, is leverancier niet
                        gehouden tot vergoeding of creditering van berekend statiegeld aan afnemer.<br/>
                        4. Ingeval van terugontvangst van beschadigde emballage vindt geen vergoeding of creditering van
                        berekend statiegeld plaats.<br/>
                        5. Leverancier behoudt zich het recht voor om over retouremballage en overige goederen, waarvoor
                        geen statiegeld in rekening is of wordt gebracht, in een later stadium alsnog statiegeld te
                        vorderen.<br/>
                        6. Facturering c.q. creditering van retouremballage en overige goederen vindt plaats aan de hand
                        van de door afnemer en de vervoerder te tekenen afleveringsbon.<br/>
                        7. Het in het bezit van leverancier zijnde exemplaar van voornoemde afleveringsbon wordt geacht
                        de juiste opgave te bevatten van de afgeleverde hoeveelheid goederen, echter met dien verstande
                        dat indien de retouremballage door afnemer op pallets of soortgelijke hulpmiddelen wordt
                        aangeboden, leverancier niet gebonden zal zijn aan de vermelding op de afleveringsbon van de
                        hoeveelheid op de pallets geplaatste emballage, indien en voor zover deze afwijkt van de
                        hoeveelheid, die bij de door leverancier uitgevoerde controles wordt geconstateerd. In dit geval
                        zal leverancier bij facturering uitgaan van de laatstgenoemde hoeveelheid.<br/>
                        8. Leverancier beoordeelt steekproefsgewijs of de retouremballage lege posities danwel vreemde
                        flessen, zijnde flessen die niet afkomstig zijn van leverancier, bevat. Indien hiervan sprake
                        is, is leverancier niet gehouden tot daarmee corresponderende vergoeding of creditering van
                        berekend statiegeld aan afnemer.<br/>
                        <strong>Artikel 17: Overdracht van rechten en plichten</strong><br/>
                        1. Leverancier behoudt zich het recht voor zijn verplichtingen te doen verrichten door
                        derden.<br/>
                        2. Afnemer kan zijn rechten en plichten slechts met voorafgaande schriftelijke toestemming van
                        leverancier overdragen aan derden.<br/>
                        3. Voorts zal afnemer in geval van overdracht van zijn verplichtingen gehouden zijn aan
                        leverancier desgevraagd passende garanties te bieden ten opzichte van de nakoming deze
                        verplichtingen.
                        Voor levering van gassen gelden de navolgende (aanvullende) bepalingen:<br/>
                        <strong>Artikel 18: Koop van gassen, in gebruik geven cilinders en waarborgsom</strong><br/>
                        1. De bepalingen in deze afdeling van de algemene voorwaarden gelden als specifieke aanvulling
                        (voor levering van gassen) op alle overige bepalingen in deze algemene voorwaarden. Behoudens
                        voor zover in deze afdeling uitdrukkelijk wordt afgeweken van de algemene voorwaarden, zijn de
                        overige bepalingen onverkort van kracht.<br/>
                        2. Afnemer betaalt voor het C02, N2 en gasmengsels de geldende prijs (te vermeerderen met btw)
                        conform de prijzen gehanteerd door leverancier. Deze prijzen kunnen (tussentijds) worden
                        aangepast. Deze prijs is inclusief de kosten voor afvullen en transportkosten.<br/>
                        3. Afnemer is verplicht aan leverancier een waarborgsom (niet zijnde statiegeld) te betalen voor
                        de geleverde cilinder met toebehoren, ook als de cilinder rechtstreeks door een toeleverancier
                        van leverancier is geleverd aan afnemer. De hoogte van de waarborgsom staat ter vrije keuze van
                        leverancier en kan dus (tussentijds) worden aangepast. Leverancier betaalt de waarborgsom na
                        retournering van de cilinder(s) indien afnemer kan aantonen dat de waarborgsom door hem zelf is
                        betaald en de cilinder correct en onbeschadigd is geretourneerd aan leverancier.<br/>
                        4. Leverancier is gerechtigd alle van overheidswege opgelegde en door/met zijn toeleverancier/de
                        producent overeengekomen/opgelegde prijsverhogingen/aanpassingen integraal aan afnemer door te
                        belasten.<br/>
                        5. De cilinders worden door middel van een unieke barcode geregistreerd door leverancier, zowel
                        bij uitlevering aan als bij terugname van afnemer. De cilinders worden met de drankenbestelling
                        afgeleverd aan afnemer.<br/>
                        6. De administratie van leverancier is doorslaggevend met betrekking tot de vaststelling van de
                        hoeveelheid afgenomen gas, cilinders en toebehoren.<br/>
                        <strong>Artikel 19: Retourneren van cilinders</strong><br/>
                        1. Afnemer is verplicht na gebruik de cilinders uitsluitend aan leverancier te retourneren.<br/>
                        2. Na beëindiging van een overeenkomst of de leverantierelatie is afnemer verplicht de al dan
                        niet (gedeeltelijk) lege cilinders aan leverancier te retourneren binnen een maand na
                        beëindiging van de betrokken overeenkomst of de leverantierelatie. Voor cilinders ingeleverd
                        later dan een maand na beëindiging van de overeenkomst of de leverantierelatie vervalt de
                        verplichting tot terugbetaling van de waarborgsom voor leverancier.<br/>
                        3. Zodra afnemer na drie maanden na beëindiging van de betrokken overeenkomst of de
                        leverantierelatie nog niet alle uitstaande cilinders aan leverancier heeft geretourneerd, is
                        afnemer zonder nadere aanmaning of ingebrekestelling een direct opeisbare vergoeding aan
                        leverancier verschuldigd, gelijk aan de op dat moment geldende inkoopprijs van de cilinder klaar
                        voor gebruik, dus geassembleerd inclusief afsluiter, beschermkap, keuring en dergelijke.<br/>
                        4. In geval van diefstal of vernietiging van een door leverancier aan afnemer geleverde cilinder
                        en/of toebehoren, is afnemer enerzijds gehouden de diefstal of vernietiging te bewijzen en
                        anderzijds verplicht tot betaling van een schadevergoeding aan leverancier, gelijk aan de op dat
                        moment geldende inkoopprijs van de cilinder klaar voor gebruik, dus geassembleerd inclusief
                        afsluiter, beschermkap, keuring en dergelijke.<br/>
                        <strong>Artikel 20: Veiligheidsnormen gassen en cilinders</strong><br/>
                        1. Afnemer is strikt gehouden alle benodigde veiligheidsmaatregelen te nemen bij de bewaring en
                        opslag, evenals bij het gebruik en/of verbruik van de door leverancier afgeleverde gassen en
                        cilinders met toebehoren. Afnemer is mitsdien verplicht kennis te nemen van de door leverancier
                        verstrekte veiligheidsinformatiebladen en andere veiligheidsaanwijzingen. Afnemer draagt
                        bovendien zorg dat de in deze bladen vermelde informatie ter hand wordt gesteld aan de directe
                        of indirecte gebruikers van de gassen, cilinders en toebehoren. Leverancier is gehouden op
                        eerste verzoek van afnemer de veiligheidsbladen aan afnemer ter beschikking te stellen. Afnemer
                        is gehouden instructies van leverancier in de ruimste zin des woords te allen tijde op te
                        volgen.<br/>
                        2. Afnemer zal leverancier in kennis stellen van eventuele onrechtmatige al dan niet frauduleuze
                        gedragingen van derden, in het bijzonder fraude met de waarborgidentificatie. De
                        waarborgidentificatie is eigendom van de toeleverancier van leverancier, Inlevering van enige
                        cilinder zonder waarborgidentificatie leidt tot verval van aanspraak op eventuele
                        waarborgsommen.<br/>
                        3. Afnemer ziet er op toe dat alle bij hem in gebruik zijnde cilinders geruime tijd voor het
                        verstrijken van de op de cilinder vermelde keuringstermijn en/of op eerste verzoek van
                        leverancier, de toeleverancier of producent aangegeven tussentijdse inspectie- en
                        controletermijnen, tijdig aan leverancier worden geretourneerd.<br/>
                        4. Door leverancier aan afnemer geleverde cilinders mogen, tenzij schriftelijk anders is
                        overeengekomen, uitsluitend in Nederland worden gebruikt.<br/>
                        5. Alle schade en kosten in geval van het niet voldoen door afnemer aan het bepaalde in dit
                        artikel, komen integraal voor rekening en risico van afnemer.
                        Voor de huur en verhuur van koelmaterialen gelden de navolgende (aanvullende) bepalingen:<br/>
                        <strong>Artikel 21: Huur van koelmaterialen</strong><br/>
                        1. De bepalingen in deze afdeling van de algemene voorwaarden gelden als specifieke aanvulling
                        (voor huur en verhuur van koelmaterialen) op alle overige bepalingen in deze algemene
                        voorwaarden. Behoudens voor zover in deze afdeling uitdrukkelijk wordt afgeweken van de algemene
                        voorwaarden, zijn de overige bepalingen onverkort van kracht.<br/>
                        2. Afnemer huurt op basis van bestelling koelmaterialen van leverancier. De huurvergoeding wordt
                        achteraf, na de gebruiksperiode, in rekening gebracht.<br/>
                        3. De huur van koelmaterialen geschiedt voornamelijk, doch niet uitsluitend, in het kader van
                        een of meer evenementen die door afnemer (mede) worden georganiseerd.<br/>
                        4. Bij aflevering van de koelmaterialen zal afnemer of een door afnemer aangewezen persoon
                        aanwezig zijn en de koelmaterialen in ontvangst nemen. Bij aflevering zal afnemer (of de door
                        afnemer aangewezen persoon) tekenen voor ontvangst van de goederen en diensten. Indien niemand
                        aanwezig is, danwel niemand namens afnemer tekent, dan wordt dit door de medewerker van
                        leverancier genoteerd en gaat afnemer als gevolg daarvan akkoord met het door leverancier
                        geleverde.<br/>
                        <strong>Artikel 22: Voorzieningen noodzakelijk voor aflevering, installatie en aansluiting van
                            de</strong>
                        koelmaterialen<br/>
                        1. Afnemer zal tijdig, dat wil zeggen voorafgaande aan de datum van aflevering, voor eigen
                        rekening en risico, zorgdragen voor de voorzieningen die noodzakelijk zijn voor de aflevering,
                        installatie en aansluiting van de koelmaterialen.<br/>
                        2. Afnemer staat er voor in dat de medewerkers en door leverancier aangewezen derde(n) die
                        namens leverancier de werkzaamheden verrichten in het kader van de plaatsing en retournering van
                        de koelmaterialen, hun werkzaamheden uit kunnen voeren in een omgeving die voldoet aan de
                        (wettelijke) regelgeving rondom arbeidsomstandigheden en veiligheid.<br/>
                        3. Voor wat betreft koelaanhangers en koelcontainers dienen in elk geval de volgende
                        voorzieningen door afnemer te worden getroffen:<br/>
                        a. een (ver)harde ondergrond voor de koelaanhangers en/of koelcontainers;<br/>
                        b. bij natte omstandigheden zorgt afnemer voor geschikte rijplaten;<br/>
                        c. een vrije en toegankelijke (afzet-)ruimte voor de aanhanger of container op de/het
                        overeengekomen datum en tijdstip;<br/>
                        d. afhankelijk van de aanhanger of container zorgt afnemer voor voldoende capaciteit voor wat
                        betreft de benodigde elektriciteit.<br/>
                        4. Leverancier is gerechtigd nadere voorzieningen te verlangen van afnemer, zulks ter
                        beoordeling van leverancier.<br/>
                        5. Indien uitzonderingen op de voorzieningen in lid 3 genoodzaakt zijn dan dient afnemer
                        leverancier daar ten tijde van de bestelling reeds schriftelijk van in kennis te stellen.
                        Uitzonderingen als voornoemd zijn slechts mogelijk na instemming van leverancier.<br/>
                        <strong>Artikel 23: Zorgplicht koelmaterialen</strong><br/>
                        1. Afnemer dient zorgvuldig met de geplaatste koelmaterialen om te gaan en dient er voor zorg te
                        dragen dat deze zaken in goede staat worden behouden. Afnemer verplicht zich het koelmateriaal
                        gedurende de gehele huurperiode overeenkomstig de eventuele aanwijzingen en richtlijnen van
                        leverancier in gebruik te hebben en te houden. Afnemer is, zonder toestemming van leverancier,
                        niet gerechtigd de koelmaterialen te verplaatsen, tenzij vooraf anders is overeengekomen.
                        Evenmin is het afnemer toegestaan om op of aan de koelmaterialen prijslijsten, aankondigingen of
                        andere (reclame-)uitingen te plaatsen en/of te bevestigen. Kosten voor verwijdering van
                        voornoemde zaken worden integraal aan afnemer doorbelast.<br/>
                        2. Afnemer is niet gerechtigd de koelmaterialen op enigerlei wijze aan derden in gebruik te
                        geven, deze te verhuren, of daarop enig zakelijk of persoonlijk genots-, gebruiks- of
                        zekerheidsrecht te (doen) vestigen.<br/>
                        3. Een uitzondering op lid 2 is slechts mogelijk voor zover het ziet op het in gebruik geven aan
                        derden indien leverancier hiermee schriftelijk heeft ingestemd en slechts ingeval afnemer de
                        organisatie - of een gedeelte daarvan - van een evenement heeft uitbesteed aan een derde . In
                        dat geval staat afnemer er voor in dat deze algemene voorwaarden door voornoemde derde worden
                        nageleefd.<br/>
                        4. Afnemer is aansprakelijk voor beschadiging, diefstal en verlies van de door leverancier
                        verstrekte koelmaterialen, onverschillig op welke wijze dit is ontstaan. Afnemer verplicht zich
                        al het mogelijke te doen ter voorkoming en beperking van schade, diefstal en verlies.<br/>
                        5. Afnemer zal de koelmaterialen op zijn kosten ten behoeve van leverancier verzekeren en
                        gedurende de periode tussen levering en retourname door leverancier verzekerd houden bij een
                        (wettelijk) erkende Nederlandse verzekeringsmaatschappij tegen gebruikelijke risico's van
                        beschadiging, diefstal en verlies. Afnemer is gehouden op eerste verzoek van leverancier
                        (voorafgaand aan de ontvangst van de apparatuur) de polis van verzekering aan leverancier ter
                        inzage te verschaffen.<br/>
                        6. Storingen, beschadigingen en gebreken aan koelmaterialen, alsmede vermissing ervan, zullen
                        door afnemer onmiddellijk schriftelijk worden gemeld aan leverancier. Storingen kunnen eveneens
                        worden gemeld via het storingstelefoonnummer zoals vermeld op de koelmaterialen. Afnemer is niet
                        gerechtigd zelf reparaties aan de koelmaterialen uit te voeren of wijzigingen aan de
                        koelmaterialen aan te brengen.<br/>
                        7. Alle kosten verbonden aan vervanging, reparaties en herstelwerkzaamheden anders dan het
                        opheffen van een technische storing, zijn volledig voor rekening van afnemer. Voorts zijn voor
                        rekening van afnemer de kosten verbonden aan het vervangen van sloten en sleutels, leidingen en
                        haspels.<br/>
                        8. Het niet of niet behoorlijk functioneren van de koelmaterialen geeft afnemer nimmer het recht
                        de overeenkomst te ontbinden.<br/>
                        9. Indien derden rechten doen gelden op de koelmaterialen, daaronder begrepen het leggen van
                        (conservatoir) beslag, is afnemer verplicht deze derden mede te delen dat de koelmaterialen
                        eigendom zijn van leverancier terwijl afnemer voorts verplicht is leverancier onverwijld
                        schriftelijk van deze aanspraken van derden in kennis te stellen.<br/>
                        10. Door leverancier aan afnemer verstrekte koelmaterialen mogen, tenzij schriftelijk anders is
                        overeengekomen, uitsluitend in Nederland worden gebruikt.<br/>
                        <strong>Artikel 24: Door afnemer verschuldigde bedragen</strong><br/>
                        1. Betaling van de huurprijs – inclusief de eventueel verschuldigde additionele kosten – dient
                        op de door leverancier aan te geven wijze te geschieden binnen acht dagen na de factuurdatum,
                        dit zodanig dat het verschuldigde bedrag binnen de gestelde termijn op de bankrekening van
                        leverancier is bijgeschreven. Bij niet tijdige betaling verkeert afnemer zonder nadere
                        aankondiging of ingebrekestelling in verzuim.<br/>
                        2. Afnemer dient ervoor zorg te dragen dat de complete correcte aanvraag voor het huren van
                        koelmaterialen uiterlijk drie weken voorafgaand aan de datum van aflevering door leverancier
                        ontvangen is.<br/>
                        3. In de volgende gevallen is afnemer “additionele kosten” verschuldigd:<br/>
                        a. wanneer leverancier een voorbelasting/aanbetaling in rekening brengt;<br/>
                        b. ingeval afnemer een bestelling plaatst waarbij de datum van aflevering minder dan drie weken
                        na de besteldatum ligt;<br/>
                        c. ingeval afnemer de bestelling bij leverancier annuleert minder dan een week voorafgaande aan
                        de datum van aflevering;<br/>
                        d. ingeval afnemer de bestelling bij leverancier annuleert tenminste een week, maar minder dan
                        drie weken voorafgaande aan de datum van aflevering;<br/>
                        e. ingeval afnemer een geplaatste bestelling wijzigt minder dan drie weken voorafgaande aan de
                        datum van aflevering;<br/>
                        f. indien de medewerker van leverancier of de door leverancier aangewezen derde(n) op de datum
                        en het tijdstip van aflevering van de bestelling ter plaatse op de locatie van aflevering dienen
                        te wachten totdat een bestelling kan worden afgeleverd.<br/>
                        4. Eventuele kosten voor meerwerk (zoals bijvoorbeeld veroorzaakt door benodigde aanpassingen of
                        wachttijden) zullen door leverancier integraal worden doorbelast aan afnemer. Leverancier zal
                        dit waar mogelijk altijd voorafgaand aan de doorbelasting overleggen met afnemer.
                        Slotbepalingen<br/>
                        <strong>Artikel 25: Wijzigingen</strong><br/>
                        Deze algemene voorwaarden kunnen door leverancier te allen tijde worden aangevuld of gewijzigd.
                        De aangevulde of gewijzigde algemene voorwaarden zullen vervolgens van toepassing zijn, vanaf
                        het moment dat deze door leverancier aan afnemer ter hand zijn gesteld.<br/>
                        <strong>Artikel 26: Geschillen</strong><br/>
                        1. Op deze algemene voorwaarden, op elke overeenkomst tussen leverancier en afnemer en alle
                        daarmee samenhangende overeenkomsten is uitsluitend Nederlands recht van toepassing. De
                        bepalingen van het verdrag der Verenigde Naties inzake Internationale koopovereenkomsten
                        betreffende roerende zaken (Weens koopverdrag) zijn - voor zover overigens al van toepassing -
                        uitgesloten. Datzelfde geldt voor enige bestaande of toekomstige nationale of internationale
                        regeling inzake koop van roerende zaken waarvan de werking door partijen kan worden uitgesloten.<br/>
                        2. Alle geschillen tussen partijen samenhangend met de overeenkomst, of daarmee in rechtstreeks
                        of zijdelings verband staand, zullen in eerste instantie worden beslecht door de bevoegde
                        rechter van de Rechtbank Overijssel, locatie Almelo.
                    </div>
                </div>
            </>
        );
    }

    protected async onSubmit(evt: FormEvent) {
        evt.preventDefault();
        this.loading = true;
        try {
            const data = await signNpv(this.props.id, {
                businessLocation: this.selectedBusinessLocation!,
                city: this.city,
                customerSignature: this.customerSignatureRef.current!.getTrimmedCanvas().toDataURL(),
                date: this.date.toISOString(),
                documentNr: this.documentNr,
                documentType: this.documentType,
                receiveEmail: this.receiveEmail,
                emailSubscribe: this.emailSubscribe,
                rayonManagerSignature: this.rayonManagerSignatureRef.current!.getTrimmedCanvas().toDataURL(),
                sepa: {
                    ...this.sepa,
                    customerSignature: this.sepaCustomerSignatureRef.current!.getTrimmedCanvas().toDataURL(),
                },
            });
            this.npv.setData(data);
            state.notifications.push('De overeenkomst is getekend');
            this.signed = true;
        } finally {
            this.loading = false;
        }
    }

    protected renderDeliveryMomentRow(dayName: string, morning: DeliveryMoment, afternoon: DeliveryMoment) {
        return (
            <tr>
                <td>
                    {dayName}
                </td>
                <td>
                    <label className="label">
                        <input type="checkbox"
                               disabled
                               checked={(this.npv.deliveryMoment & morning) !== 0}
                        />
                        Ochtend
                    </label>
                </td>
                <td>
                    <label className="label">
                        <input type="checkbox"
                               disabled
                               checked={(this.npv.deliveryMoment & afternoon) !== 0}
                        />
                        Middag
                    </label>
                </td>
            </tr>
        );
    }

    private renderConditions(obj: {
        netCondition: number,
        netConditionUnit: ConditionUnit,
        bonusCondition: number,
        bonusConditionUnit: ConditionUnit,
    }) {
        return (
            <>
                <span>Net: </span>
                <span>
                    {obj.netConditionUnit === ConditionUnit.Euro
                        ? formatEuroPrice(obj.netCondition)
                        : formatPercentage(obj.netCondition)}
                </span>
                &nbsp;/&nbsp;
                <span>Bonus: </span>
                <span>
                    {obj.bonusConditionUnit === ConditionUnit.Euro
                        ? formatEuroPrice(obj.bonusCondition)
                        : formatPercentage(obj.bonusCondition)}
                </span>
            </>
        );
    }
}

export async function renderNpvAgreement(element: HTMLElement) {
    const id = element.getAttribute('data-id');
    const code = element.getAttribute('data-code');
    if (id && code) {
        return ReactDOM.render(<NpvAgreement
            id={id}
            code={code}
        />, element);
    }
}
