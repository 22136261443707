import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {observer} from 'mobx-react';
import {InvoiceDetail, InvoiceProduct} from '../api/interfaces';
import {state} from '../state';
import {MediaQuery} from '../media-queries';
import {formatDate, formatEuroPrice} from '../utils/format';
import {getSapOrderStatusLabel} from '../enums/sapInvoiceOrderStatus';

// tslint:disable-next-line:no-var-requires
const chevronLeft = require('../../images/icons8-chevron-left-50.svg');
// tslint:disable-next-line:no-var-requires
const printIcon = require('../../images/icons8-print-50.svg');

interface State {
    loading: boolean;
    invoiceDetail: InvoiceDetail | null;
}

interface Props {
    mediaQueries: Map<MediaQuery, boolean>;
}

@observer
export class InvoiceDetails extends React.Component<Props, State> {

    private invoice: InvoiceDetail | null = null;
    private invoiceNumber: string = '';

    public constructor(props: Props) {
        super(props);
        this.state = {
            loading: state.invoice!.loading,
            invoiceDetail: state.invoice!.invoice,
        };
    }

    public componentDidMount(): void {
        if (state.invoice) {
            this.getInvoiceDetails();
        }
    }

    public render() {
        if (this.state.loading) {
            return this.renderPlaceholders();
        }
        return this.state.invoiceDetail && (
            <div className="order-detail">
                {this.renderBreadcrumb(this.invoiceNumber)}
                <div className="content-wrap order-detail__details">
                    <div className="order-detail__header">
                        <h1 className="order-detail__header__title">Factuur #{this.invoiceNumber}</h1>
                        <button className="button button--text-primary orderlist__header__buttons__button"
                                onClick={() => window.print()}>
                            <span className="icon button__icon icon--small"
                                  dangerouslySetInnerHTML={{__html: printIcon}}/>
                            Print factuur
                        </button>
                    </div>
                    {this.renderInvoiceDetails()}
                    {this.renderFooter()}
                </div>
                <div className="row row--dark order-detail__products">
                    <div className="content-wrap">
                        <h2 className="checkout__products__title">Producten</h2>
                        {this.renderInvoiceProducts()}
                        {this.renderInvoiceSummary()}
                        {this.renderFooter()}
                    </div>
                </div>
            </div>);
    }

    private renderInvoiceDetails() {
        return <div className="linear-layout linear-layout--grow-items linear-layout--wrap">
            {this.renderAddress()}
            <div className="order-detail__details__information">
                <div className="order-detail__details__title">
                    Factuurdetails
                </div>
                <div className="order-detail__details__information__columns">
                    {this.renderInvoiceInfo()}
                    {this.renderInvoiceStatus()}
                </div>
            </div>
        </div>;
    }

    private renderInvoiceInfo() {
        return this.invoice
            ? <div className="order-detail__details__information__columns__column">
                <div className="checkout__credentials__references__title">Factuurdatum</div>
                <span>{formatDate(this.invoice.creationDate, 'DD MMMM YYYY HH:mm')}</span>
                <div className="checkout__credentials__references__title">Factuurnummer</div>
                <span>{this.invoiceNumber}</span>
            </div>
            : null;
    }

    private renderInvoiceStatus() {
        return this.invoice && (
            <div className="order-detail__details__information__columns__column">
                <div className="checkout__credentials__references__title">Orderstatus</div>
                <span>{this.invoice.status ? getSapOrderStatusLabel(this.invoice.status) : '-'}</span>
            </div>
        );
    }

    private renderInvoiceProducts() {
        return this.invoice &&
        (state.mediaQueries.get(MediaQuery.MediumUp) || state.mediaQueries.get(MediaQuery.Print)) ?
            <table className="table order-detail__products__table">
                <thead>
                <tr>
                    <th>Productnaam</th>
                    <th>Artikelnummer</th>
                    <th>Aantal</th>
                    <th>Subtotaal</th>
                </tr>
                </thead>
                {this.invoice &&
                <tbody>
                {this.invoice.products.length > 0 ? this.invoice.products.map(
                    (item, i) => this.renderItem(item, i)) : this.renderNoProducts()}
                </tbody>}
            </table> : this.renderMobileInvoiceProducts();
    }

    private renderNoProducts() {
        return <div className="content-wrap">
            <div className="page-header">
                <div className="page-header__subtitle">Deze factuur bevat geen producten.</div>
            </div>
        </div>;
    }

    private renderItem(item: InvoiceProduct, index: number) {
        return item && <tr key={index}>
            <td>{item.name}</td>
            <td>{item.sku}</td>
            <td>{item.quantity} {item.unit}</td>
            <td>{formatEuroPrice(item.price)}</td>
        </tr>;
    }

    private renderMobileInvoiceProducts() {
        return this.invoice && [
            this.invoice.products.length > 0 ?
                <div className="order-detail__products">{this.invoice.products.map((item) =>
                    <div className="order-detail__products__product">
                        <div className="order-detail__products__product__info">
                            <span className="order-detail__products__product__info__item">
                                <strong>{item.name}</strong>
                            </span>
                            <span className="order-detail__products__product__info__item">{item.sku}</span>
                            <span className="order-detail__products__product__info__item">{item.quantity}x</span>
                        </div>
                        <span className="order-detail__products__product__price">{formatEuroPrice(item.price)}</span>
                    </div>)}
                </div> : this.renderNoProducts()];
    }

    private renderInvoiceSummary() {
        const invoiceDetails = state.invoice!.invoice;
        return invoiceDetails &&
            <div className="linear-layout linear-layout--justify-content-end
            checkout__products__description__price-summary">
                <div className="cart__price__summary">
                    <table className="cart__price__summary__table">
                        <tbody>
                        <tr>
                            <th>Subtotaal</th>
                            <td>{formatEuroPrice(invoiceDetails.subTotal)}</td>
                        </tr>
                        <tr>
                            <th>BTW</th>
                            <td>{formatEuroPrice(invoiceDetails.vat)}</td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <th>Totaal</th>
                            <td>{formatEuroPrice(invoiceDetails.total)}</td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>;
    }

    private renderFooter() {
        return <div className="linear-layout checkout__button-group">
            <a href={state.urls.invoices} className="button button--text-primary">
                <span className="icon" dangerouslySetInnerHTML={{__html: chevronLeft}}/>
                Terug naar mijn facturen
            </a>
        </div>;
    }

    private renderPlaceholders() {
        return <div className="order-detail">
            {this.renderBreadcrumb('')}
            <div className="content-wrap">
                <div className="order-detail__header">
                    <h1 className="order-detail__header__title">Factuur</h1>
                </div>
                <div className="order-detail--loading"/>
            </div>
        </div>;
    }

    private renderBreadcrumb(invoiceId: string) {
        return <nav className="breadcrumb content-wrap" role="navigation" aria-label="breadcrumbs">
            <a href={state.urls.home} title="Terug naar home">Home</a>
            <span className="divider" aria-hidden="true">/</span>
            <a href={state.urls.account} title="Terug naar account">Account</a>
            <span className="divider" aria-hidden="true">/</span>
            <a href={state.urls.invoices} title="Terug naar facturen">Facturen</a>
            <span className="divider" aria-hidden="true">/</span>
            <span>{invoiceId}</span>
        </nav>;
    }

    private async getInvoiceDetails() {
        this.setState({loading: true});
        const urlParts = window.location.href.split('/');
        this.invoiceNumber = urlParts[urlParts.length - 1];
        await state.invoice!.getInvoiceDetails(this.invoiceNumber);
        this.setState({loading: false});
        if (state.invoice && state.invoice.invoice) {
            if (state.invoice.invoice.billingAddressId === '') {
                window.location.href = state.urls.invoices;
                return;
            }
            this.invoice = state.invoice!.invoice;
            this.setState({invoiceDetail: state.invoice!.invoice})
        }
    }

    private renderAddress() {
        let address;
        if (this.invoice && this.invoice.address) {
            address = this.invoice.address;
        }
        return address && <div className="order-detail__details__address">
            <div className="order-detail__details__title">
                Leveringsadres
            </div>
            <ul className="address__list">
                <li className="address__list--company">{address.companyLine1}</li>
                <li>{address.street} {address.houseNumber}</li>
                <li>{address.zipCode} {address.city?.toUpperCase()}</li>
                <li>{address.country}</li>
                <li>T: {address.telNumber}</li>
            </ul>
        </div>;
    }
}

export function renderInvoiceDetails(element: HTMLElement) {
    if (!state.invoice) {
        throw new Error(`Cannot render invoice detail: 'state.invoice' not initialized.`);
    }
    return ReactDOM.render(<InvoiceDetails mediaQueries={state.mediaQueries}/>, element);
}
