/* tslint:disable:max-line-length */
import {observer} from 'mobx-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {state} from '../../../state';
import {Npv} from '../../../models/interfaces';
import {NpvType as NpvTypeEnum} from '../../../enums/npvType';
import NpvModel from '../../../models/npvModel';
import {SearchOrganization} from '../../search-organization';

interface Props {
    npv: Npv;
}

interface State {
    loading: boolean;
    noSearchResults: boolean;
}

@observer
export class NpvSearchCustomer extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = {
            loading: false,
            noSearchResults: false,
        };
    }

    public render() {
        return <div className="npv">
            <div className="option-list content-wrap">
                <div className="option-list__heading">Selecteer een optie</div>
                <div className="option-list__options">
                    <label className="option-list__options__option">
                        <input type="radio"
                               name="npvType"
                               value={NpvTypeEnum.Acquisition}
                               onChange={evt => {
                                   const input = evt.target as HTMLInputElement;
                                   if (input.checked) {
                                       this.props.npv.npvType = NpvTypeEnum.Acquisition;
                                   }
                               }}/>
                        Acquisitie
                    </label>
                    <label className="option-list__options__option">
                        <input type="radio"
                               name="npvType"
                               value={NpvTypeEnum.ContractExtensions}
                               onChange={evt => {
                                   const input = evt.target as HTMLInputElement;
                                   if (input.checked) {
                                       this.props.npv.npvType = NpvTypeEnum.ContractExtensions;
                                   }
                               }}/>
                        Verlenging contract
                    </label>
                </div>
            </div>

            {this.props.npv.npvType !== null &&
            <div className="row row--dark">
                <div className="content-wrap">
                    <h2 className="h h--primary">Klant zoeken</h2>
                    <SearchOrganization
                        searchLabel={'Zoeken...'}
                        showCity={true}
                        noResultsText={this.props.npv.npvType === NpvTypeEnum.Acquisition
                            ? 'Geen klant gevonden, wil je een nieuwe klant aanmaken?'
                            : 'Geen klant gevonden.'}
                        generateLink={organization => {
                            return `${state.urls.npv}/new/pre-npv?organization=${organization.id}&type=${this.props.npv.npvType}`;
                        }}
                        onResults={res => {
                            this.setState({noSearchResults: !res.items.length});
                        }}/>

                    {this.props.npv.npvType === NpvTypeEnum.Acquisition && this.state.noSearchResults &&
                        <a href={`${state.urls.npv}/new/new-prospect`} className="button button--primary --mt">
                            Nieuwe klant
                        </a>
                    }
                </div>
            </div>
            }
        </div>;
    }
}

export function renderNpvSearchCustomer(element: HTMLElement) {
    if (!state.npv) {
        state.npv = new NpvModel();
    }
    (window as any).state = state.npv;
    return ReactDOM.render(<NpvSearchCustomer npv={state.npv}/>, element);
}
