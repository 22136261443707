/* tslint:disable:max-line-length */
import * as React from 'react';
import {renderSvg, sortDownIcon, sortUpIcon} from '../../images/images';
import {CatalogCategory} from '../models/interfaces';
import {getSortByTypeLabel, getSortByTypeValue, SortByType} from '../enums/sortByType';

interface Props {
    title: string;
    hidden: boolean;
}

interface State {
    hidden: boolean;
}

export class FilterListSort extends React.Component<Props, State> {

    private sortTypes: SortByType[] =
        [   SortByType.Default,
            SortByType.GrossPriceAsc,
            SortByType.GrossPriceDesc,
            SortByType.TitleAsc,
            SortByType.TitleDesc,
        ];
    public constructor(props: Props) {
        super(props);
        this.state = {
            hidden: this.props.hidden,
        };
    }

    public render() {
        return <div className="filter-list__content">
            <div className="filter-list__content__header" onClick={() => this.toggleHidden()}>
                <div className="filter-list__content__header__title">{this.props.title}</div>
                <div className="filter-list__content__header__icon">
                    {renderSvg(this.state.hidden ? sortDownIcon : sortUpIcon, {
                        className: 'filter-list__content__header__icon',
                    })}
                </div>
            </div>

            <div
                className={`filter-list__content__tags ${this.state.hidden ? 'filter-list__content__tags--hidden' : ''}`}>
                {this.sortTypes.sort((a, b) => {
                    const aActive = this.isActiveSort(a) ? 1 : 0;
                    const bActive = this.isActiveSort(b) ? 1 : 0;
                    return bActive - aActive;
                }).map((type: SortByType, index: number) => {
                    return <div
                        key={`${getSortByTypeLabel(type)}__${index}`}
                        className={`filter-list__content__tag
                            ${this.isActiveSort(type) ? 'filter-list__content__tag--active' : ''}`}>
                        <a className="filter-list__content__tag__name"
                           href={this.isActiveSort(type) ? this.getSortUrlWithQueryParams(type, true) : this.getSortUrlWithQueryParams(type)}>
                            {getSortByTypeLabel(type)}
                        </a>
                    </div>;
                })}
            </div>
        </div>;
    }

    private toggleHidden() {
        this.setState({hidden: !this.state.hidden});
    }

    private getSortUrlWithQueryParams(type: SortByType, reset: boolean = false): string {
        const url = new URL(window.location.href);
        url.searchParams.delete('sort_by');
        url.searchParams.append('sort_by', getSortByTypeValue(type));
        return url.toString();
    }

    private isActiveSort(type: SortByType) {
        const url = new URL(window.location.href);
        return url.searchParams.get('sort_by') === getSortByTypeValue(type);
    }
}
