/* tslint:disable:variable-name */
import { observable } from 'mobx';
import {Product, Vendor} from './interfaces';
import {DeepPartial} from '../../../../custom';

export class ProductModel implements Product {

    @observable
    public id = '';

    @observable
    public name = '';

    @observable
    public is_promotion = false;

    @observable
    public gross_price: number = 0;

    @observable
    public badge_start_date: string | null = null;

    @observable
    public badge_end_date: string | null = null;

    @observable
    public badge_text: string | null = null;

    @observable
    public quantity_increment: number = 1;

    @observable
    public buyable = false;

    @observable
    public vendor: Vendor | null = null;

    @observable
    public url = '';

    public constructor(data: DeepPartial<Product>) {
        if (data.id) {
            this.id = data.id;
        }
        if (data.name) {
            this.name = data.name;
        }
        if (data.is_promotion) {
            this.is_promotion = true;
        }
        if (data.badge_end_date) {
            this.badge_end_date = data.badge_end_date;
        }
        if (data.badge_start_date) {
            this.badge_start_date = data.badge_start_date;
        }
        if (data.badge_text) {
            this.badge_text = data.badge_text;
        }
        if (data.quantity_increment) {
            this.quantity_increment = data.quantity_increment;
        }
        if (data.vendor) {
            this.vendor = data.vendor as Vendor;
        }
        if (data.url) {
            this.url = data.url;
        }
        if (data.gross_price) {
            this.gross_price = data.gross_price;
        }
        if (data.buyable) {
            this.buyable = data.buyable;
        }
    }
}
