import * as moment from 'moment'
import * as localization from 'moment/locale/nl'
import { roundToPrecision } from './math'
moment.updateLocale('nl', localization)
moment.locale('nl')

export function formatPrice(cents: number | null) {
    if (cents === null) {
        return '-'
    }
    return formatEuroPrice(cents / 100)
}

export function formatEuroPrice(euros: number | null) {
    if (euros === null || euros === undefined) {
        return '-'
    }
    return `€ ${euros.toFixed(2).replace('.', ',')}`
}

export function parseDate(input: string, format: string = 'D MMMM') {
    if (!input) return undefined
    return moment(input, format)
}

export function formatDate(input: moment.MomentInput, format: string = 'D MMMM') {
    if (!input) return ''

    const date = moment(input).parseZone().local(true)
    return date.format(format)
}

export function formatPercentage(nr?: number | null) {
    if (nr === undefined || nr === null) {
        return '-'
    }
    return (
        roundToPrecision(nr * 100, 2)
            .toString()
            ?.replace('.', ',') + '%'
    )
}
