/* tslint:disable:no-var-requires max-line-length */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {observer} from 'mobx-react';
import 'moment';
import {RegistrationAccount} from '../api/interfaces';
import {computed} from 'mobx';
import {registrateAccount} from '../api/registration';
import {load, ReCaptchaInstance} from 'recaptcha-v3';
import {state} from '../state';
import {MediaQuery} from '../media-queries';
import {LeadGenerationOptions} from '../models/interfaces';

interface State {
    branch: string;
    channel: string;
    interest: string;
    accountNumber: string;
    kvkNumber: string;
    companyName: string;
    name: string;
    email: string;
    phone: string;
    street: string;
    houseNumber: string;
    postalCode: string;
    city: string;
    orderStreet: string;
    orderHouseNumber: string;
    orderPostalCode: string;
    orderCity: string;
    comment: string;
    token: string;
    existingCustomer: boolean;
    differentOrderAddress: boolean;
    errors?: ModelStateErrors;
}

interface ModelStateErrors {
    [field: string]: string[];
}

interface Props {
    siteKey: string;
}

@observer
export class Registration extends React.Component<Props, State> {
    private formEl: HTMLFormElement | null = null;
    private recaptcha: ReCaptchaInstance | undefined;
    private sortDownIcon = require('../../images/icons8-sort-down-50.svg');
    private branches: LeadGenerationOptions = {listOptions: [], placeHolder: ''};
    private channels: LeadGenerationOptions = {listOptions: [], placeHolder: ''};
    private interests: LeadGenerationOptions = {listOptions: [], placeHolder: ''};

    public constructor(props: Props) {
        super(props);
        this.state = {
            accountNumber: '',
            kvkNumber: '',
            companyName: '',
            name: '',
            email: '',
            phone: '',
            street: '',
            houseNumber: '',
            postalCode: '',
            city: '',
            orderStreet: '',
            orderHouseNumber: '',
            orderPostalCode: '',
            orderCity: '',
            comment: '',
            token: '',
            existingCustomer: false,
            differentOrderAddress: false,
            branch: '',
            channel: '',
            interest: '',
        };
        this.branches = state.branches;
        this.channels = state.channels;
        this.interests = state.interests;
    }

    public async componentDidMount() {
        this.recaptcha = await load(this.props.siteKey);
    }

    public render() {
        return <form ref={form => this.formEl = form} className="register" method="post"
                     onSubmit={(evt) => this.sendAccount(evt)}>
            <div className="row row--dark row--form">
                <div className="content-wrap content-wrap--small">
                    <h2 className="row__title row__title--no-padding-left row__title--padding-bottom-small">
                        Persoonlijke gegevens
                    </h2>
                    <div className="switch-container">
                        <label className="switch">
                            <input type="checkbox" checked={this.state.existingCustomer}
                                   onChange={evt => {
                                       this.setState({existingCustomer: (evt.target as HTMLInputElement).checked});
                                   }}/>
                            <span className="toggle round"/>
                        </label>
                        <span className="switch-container__label switch-container__label--padding-small">Ik ben al een klant en wil graag een account</span>
                    </div>
                    {state.mediaQueries.get(MediaQuery.MediumUp) ? [
                        <div className="form-row form-row--label-on-top">
                            <div className="linear-layout linear-layout--horizontal linear-layout--large-gutters">
                                {this.state.existingCustomer ? [
                                    <div className="linear-layout__item linear-layout__item--grow">
                                        <label className="form-row__label label--required">Klantnummer</label>
                                        <div className="form-row__input">
                                            <input type="text" value={this.state.accountNumber}
                                                   onChange={evt => {
                                                       this.setState({accountNumber: evt.target.value});
                                                   }}
                                                   required
                                                   className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                                        </div>
                                    </div>] : [
                                    <div className="linear-layout__item linear-layout__item--grow">
                                        <label className="form-row__label label--required">KVK nummer</label>
                                        <div className="form-row__input">
                                            <input type="text" value={this.state.kvkNumber}
                                                   required
                                                   onChange={evt => {
                                                       this.setState({kvkNumber: evt.target.value});
                                                   }}
                                                   className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                                        </div>
                                    </div>,
                                ]}
                                <div className="filters__head__flex-column">
                                    <span
                                        className="form-row__label form-row__label--padding label--required">{this.branches.placeHolder}</span>
                                    <app-dropdown content-class="dropdown__content--light form-row__label--padding">
                                        <span slot="trigger"
                                              className={`label filters__tags__tag__label filters__tags__tag__label--padded filters__tags__tag__label--large ${state.mediaQueries.get(MediaQuery.Small) ? '' : ''}`}>
                                            {this.state.branch ? this.state.branch : 'Selecteer'}
                                            <span className="icon icon--text-size dropdown__arrow"
                                                  dangerouslySetInnerHTML={{__html: this.sortDownIcon}}/>
                                        </span>
                                        {this.branches.listOptions.map((x, i) => (
                                            <a href="#"
                                               key={i}
                                               className={this.state.branch === x.label ? 'active' : ''}
                                               onClick={evt => {
                                                   evt.preventDefault();
                                                   this.setState({branch: x.label});
                                               }}>{x.label}</a>
                                        ))}
                                    </app-dropdown>
                                </div>
                            </div>
                        </div>,
                        <div className="form-row form-row--label-on-top">
                            <div className="linear-layout linear-layout--horizontal linear-layout--large-gutters">
                                <div className="linear-layout__item linear-layout__item--grow">
                                    <label className="label--required form-row__label">Bedrijfsnaam</label>
                                    <div className="form-row__input">
                                        <input type="text" value={this.state.companyName}
                                               onChange={evt => {
                                                   this.setState({companyName: evt.target.value});
                                               }}
                                               required
                                               className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                                    </div>
                                </div>
                                <div className="linear-layout__item linear-layout__item--grow">
                                    <label className="label--required form-row__label">Naam</label>
                                    <div className="form-row__input">
                                        <input type="text" value={this.state.name}
                                               onChange={evt => {
                                                   this.setState({name: evt.target.value});
                                               }}
                                               required
                                               className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row form-row--label-on-top">
                                <div className="linear-layout linear-layout--horizontal linear-layout--large-gutters">
                                    <div className="linear-layout__item linear-layout__item--grow">
                                        <label
                                            className="label--required form-row__label">E-mailadres</label>
                                        <div className="form-row__input">
                                            <input type="email" value={this.state.email}
                                                   onChange={evt => {
                                                       this.setState({email: evt.target.value});
                                                   }}
                                                   required
                                                   className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                                        </div>
                                    </div>
                                    <div className="linear-layout__item linear-layout__item--grow">
                                        <label className="label--required form-row__label">Telefoonnummer</label>
                                        <div className="form-row__input">
                                            <input type="phone" value={this.state.phone}
                                                   onChange={evt => {
                                                       this.setState({phone: evt.target.value});
                                                   }}
                                                   required
                                                   className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>] : [
                        <div className="form-row form-row--label-on-top">
                            {this.state.existingCustomer ? [
                                <div className="linear-layout__item linear-layout__item--grow">
                                    <label className="form-row__label label--required">Klantnummer</label>
                                    <div className="form-row__input">
                                        <input type="text" value={this.state.accountNumber}
                                               onChange={evt => {
                                                   this.setState({accountNumber: evt.target.value});
                                               }}
                                               required
                                               className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                                    </div>
                                </div>] : [
                                <div className="linear-layout__item linear-layout__item--grow">
                                    <label className="form-row__label">KVK nummer</label>
                                    <div className="form-row__input">
                                        <input type="text" value={this.state.kvkNumber}
                                               onChange={evt => {
                                                   this.setState({kvkNumber: evt.target.value});
                                               }}
                                               className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                                    </div>
                                </div>,
                            ]}
                            <div className="linear-layout__item linear-layout__item--grow">
                                <label className="label--required form-row__label">Bedrijfsnaam</label>
                                <div className="form-row__input">
                                    <input type="text" value={this.state.companyName}
                                           onChange={evt => {
                                               this.setState({companyName: evt.target.value});
                                           }}
                                           required
                                           className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                                </div>
                            </div>
                        </div>,
                        <div className="form-row form-row--label-on-top">
                            <div className="linear-layout__item linear-layout__item--grow">
                                <label className="label--required form-row__label">Naam</label>
                                <div className="form-row__input">
                                    <input type="text" value={this.state.name}
                                           onChange={evt => {
                                               this.setState({name: evt.target.value});
                                           }}
                                           required
                                           className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                                </div>
                            </div>
                            <div className="linear-layout__item linear-layout__item--grow">
                                <label
                                    className="label--required form-row__label">E-mailadres</label>
                                <div className="form-row__input">
                                    <input type="email" value={this.state.email}
                                           onChange={evt => {
                                               this.setState({email: evt.target.value});
                                           }}
                                           required
                                           className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                                </div>
                            </div>
                            <div className="form-row form-row--label-on-top">
                                <div
                                    className="linear-layout__item linear-layout__item--grow linear-layout__item--form">
                                    <label className="label--required form-row__label">Telefoonnummer</label>
                                    <div className="form-row__input">
                                        <input type="phone" value={this.state.phone}
                                               onChange={evt => {
                                                   this.setState({phone: evt.target.value});
                                               }}
                                               required
                                               className="input-wrap__input input-wrap__input--bordered input-wrap__input--half-width"/>
                                    </div>
                                </div>
                            </div>
                        </div>]}
                </div>
            </div>
            <div className={`row row--form  ${!this.state.existingCustomer ? 'row--no-padding-bottom' : ''}`}>
                <div className="content-wrap content-wrap--small">
                    <h3 className="row__title row__title--dark row__title--no-padding-left">
                        Adres
                    </h3>
                    {state.mediaQueries.get(MediaQuery.MediumUp) ? [
                        <div className="form-row form-row--label-on-top">
                            <div className="linear-layout linear-layout--horizontal linear-layout--large-gutters">
                                <div className="linear-layout__item linear-layout__item--grow">
                                    <label className="label--required form-row__label">Straat</label>
                                    <div className="form-row__input">
                                        <input type="text" value={this.state.street}
                                               onChange={evt => {
                                                   this.setState({street: evt.target.value});
                                               }}
                                               required
                                               className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                                    </div>
                                </div>
                                <div className="linear-layout__item">
                                    <label className="label--required form-row__label"
                                           htmlFor="house_number">Huisnummer</label>
                                    <div className="form-row__input">
                                        <input type="text" value={this.state.houseNumber}
                                               onChange={evt => {
                                                   this.setState({houseNumber: evt.target.value});
                                               }}
                                               required
                                               className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                                    </div>
                                </div>
                            </div>
                        </div>,
                        <div className="form-row form-row--label-on-top">
                            <div className="linear-layout linear-layout--horizontal linear-layout--large-gutters">
                                <div className="linear-layout__item">
                                    <label className="label--required form-row__label"
                                           htmlFor="postal_code">Postcode</label>
                                    <div className="form-row__input">
                                        <input type="text"
                                               value={this.state.postalCode}

                                               onChange={evt => {
                                                   this.setState({postalCode: evt.target.value});
                                               }}
                                               required
                                               className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                                    </div>
                                </div>

                                <div className="linear-layout__item linear-layout__item--grow">
                                    <label className="label--required form-row__label"
                                           htmlFor="city">Plaats</label>
                                    <div className="form-row__input">
                                        <input type="text"
                                               value={this.state.city}
                                               onChange={evt => {
                                                   this.setState({city: evt.target.value});
                                               }}
                                               required
                                               className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                                    </div>
                                </div>
                            </div>
                        </div>] : [
                        <div className="linear-layout__item linear-layout__item--grow">
                            <label className="label--required form-row__label">Straat</label>
                            <div className="form-row__input">
                                <input type="text" value={this.state.street}
                                       onChange={evt => {
                                           this.setState({street: evt.target.value});
                                       }}
                                       required
                                       className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                            </div>
                        </div>,
                        <div className="linear-layout__item">
                            <label className="label--required form-row__label"
                                   htmlFor="house_number">Huisnummer</label>
                            <div className="form-row__input">
                                <input type="text" value={this.state.houseNumber}
                                       onChange={evt => {
                                           this.setState({houseNumber: evt.target.value});
                                       }}
                                       required
                                       className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                            </div>
                        </div>,
                        <div className="linear-layout__item">
                            <label className="label--required form-row__label"
                                   htmlFor="postal_code">Postcode</label>
                            <div className="form-row__input">
                                <input type="text"
                                       value={this.state.postalCode}

                                       onChange={evt => {
                                           this.setState({postalCode: evt.target.value});
                                       }}
                                       required
                                       className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                            </div>
                        </div>,
                        <div className="linear-layout__item linear-layout__item--grow">
                            <label className="label--required form-row__label"
                                   htmlFor="city">Plaats</label>
                            <div className="form-row__input">
                                <input type="text"
                                       value={this.state.city}
                                       onChange={evt => {
                                           this.setState({city: evt.target.value});
                                       }}
                                       required
                                       className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                            </div>
                        </div>]}
                </div>
            </div>
            {!this.state.existingCustomer ? [
                <div className="row row--no-padding-top row--form">
                    <div className="content-wrap content-wrap--small">
                        {state.mediaQueries.get(MediaQuery.MediumUp) ?
                            <div className="switch-container switch-container--padding">
                                <label className="switch">
                                    <input type="checkbox" checked={this.state.differentOrderAddress}
                                           onChange={evt => {
                                               this.setState({differentOrderAddress: (evt.target as HTMLInputElement).checked});
                                           }}/>
                                    <span className="toggle round"/>
                                </label>
                                <span className="switch-container__label switch-container__label--padding-small">Vink aan indien afleveradres niet hetzelfde is als het factuuradres</span>
                            </div> :
                            <div className="switch-container">
                                <label className="switch">
                                    <input type="checkbox" checked={this.state.differentOrderAddress}
                                           onChange={evt => {
                                               this.setState({differentOrderAddress: (evt.target as HTMLInputElement).checked});
                                           }}/>
                                    <span className="toggle round"/>
                                </label>
                                <span className="switch-container__label switch-container__label--padding-left">Vink aan indien afleveradres niet hetzelfde is als het factuuradres</span>
                            </div>}
                        {this.state.differentOrderAddress ? [
                            <h3 className="row__title row__title--dark row__title--no-padding-left">
                                Factuuradres
                            </h3>,
                            <div className="form-row form-row--label-on-top">
                                <div className="linear-layout linear-layout--horizontal">
                                    <div className="linear-layout__item linear-layout__item--grow">
                                        <label className="label--required form-row__label">Straat</label>
                                        <div className="form-row__input">
                                            <input type="text" value={this.state.orderStreet}
                                                   onChange={evt => {
                                                       this.setState({orderStreet: evt.target.value});
                                                   }}
                                                   required
                                                   className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                                        </div>
                                    </div>
                                    <div className="linear-layout__item">
                                        <label className="label--required form-row__label"
                                               htmlFor="house_number">Huisnummer</label>
                                        <div className="form-row__input">
                                            <input type="text" value={this.state.orderHouseNumber}
                                                   onChange={evt => {
                                                       this.setState({orderHouseNumber: evt.target.value});
                                                   }}
                                                   required
                                                   className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                                        </div>
                                    </div>
                                </div>
                            </div>,
                            <div className="form-row form-row--label-on-top">
                                <div className="linear-layout linear-layout--horizontal">
                                    <div className="linear-layout__item">
                                        <label className="label--required form-row__label"
                                               htmlFor="postal_code">Postcode</label>
                                        <div className="form-row__input">
                                            <input type="text"
                                                   value={this.state.orderPostalCode}
                                                   onChange={evt => {
                                                       this.setState({orderPostalCode: evt.target.value});
                                                   }}
                                                   required
                                                   className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                                        </div>
                                    </div>

                                    <div className="linear-layout__item linear-layout__item--grow">
                                        <label className="label--required form-row__label"
                                               htmlFor="city">Plaats</label>
                                        <div className="form-row__input">
                                            <input type="text"
                                                   value={this.state.orderCity}
                                                   onChange={evt => {
                                                       this.setState({orderCity: evt.target.value});
                                                   }}
                                                   required
                                                   className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"/>
                                        </div>
                                    </div>
                                </div>
                            </div>] : null}
                    </div>
                </div>] : null}
            <div className="row row--dark row--form">
                <div className="content-wrap content-wrap--small">
                    <div className="form-row form-row--label-on-top">
                        <div className="linear-layout linear-layout--vertical">
                            <div className="linear-layout__item account__registration__info-filter-row">
                                <div className="filters__head__flex-column">
                                    <span
                                        className="form-row__label form-row__label--padding label--required">{this.channels.placeHolder}</span>
                                    <app-dropdown content-class="dropdown__content--light form-row__label--padding">
                                        <span slot="trigger"
                                              className={`label filters__tags__tag__label filters__tags__tag__label--padded filters__tags__tag__label--large ${state.mediaQueries.get(MediaQuery.Small) ? '' : ''}`}>
                                            {this.state.channel ? this.state.channel : 'Selecteer'}
                                            <span className="icon icon--text-size dropdown__arrow"
                                                  dangerouslySetInnerHTML={{__html: this.sortDownIcon}}/>
                                        </span>
                                        {this.channels.listOptions.map((x, i) => (
                                            <a href="#"
                                               key={i}
                                               className={this.state.channel === x.label ? 'active' : ''}
                                               onClick={evt => {
                                                   evt.preventDefault();
                                                   this.setState({channel: x.label});
                                               }}>{x.label}</a>
                                        ))}
                                    </app-dropdown>
                                </div>
                                <div className="filters__head__flex-column">
                                    <span
                                        className="form-row__label form-row__label--padding label--required">{this.interests.placeHolder}</span>
                                    <app-dropdown content-class="dropdown__content--light form-row__label--padding">
                                        <span slot="trigger"
                                              className={`label filters__tags__tag__label filters__tags__tag__label--padded filters__tags__tag__label--large ${state.mediaQueries.get(MediaQuery.Small) ? '' : ''}`}>
                                            {this.state.interest ? this.state.interest : 'Selecteer'}
                                            <span className="icon icon--text-size dropdown__arrow"
                                                  dangerouslySetInnerHTML={{__html: this.sortDownIcon}}/>
                                        </span>
                                        {this.interests.listOptions.map((x, i) => (
                                            <a href="#"
                                               key={i}
                                               className={this.state.interest === x.label ? 'active' : ''}
                                               onClick={evt => {
                                                   evt.preventDefault();
                                                   this.setState({interest: x.label});
                                               }}>{x.label}</a>
                                        ))}
                                    </app-dropdown>
                                </div>
                            </div>
                            <div className="linear-layout__item linear-layout__item--grow">
                                <label className="form-row__label"
                                       htmlFor="comment">Opmerking(en)</label>
                                <div className="form-row__input">
                                        <textarea rows={5}
                                                  value={this.state.comment}
                                                  onChange={evt => {
                                                      this.setState({comment: evt.target.value});
                                                  }}
                                                  className="input-wrap__input input-wrap__input--bordered input-wrap__input--full-width"
                                        />
                                </div>
                            </div>
                            <div className="linear-layout__item">
                                <button
                                    disabled={!this.isFormValid() || !this.state.branch || !this.state.channel || !this.state.interest}
                                    className="button button--primary button--float"
                                    type="submit">Aanvraag
                                    versturen
                                </button>
                            </div>
                            {this.state.errors ?
                                <div>
                                    De volgende fouten zijn opgetreden:
                                    <div className="error">
                                        {this.renderErrors()}
                                    </div>
                                </div>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </form>;
    }

    private isFormValid() {
        let valid = false;

        if (this.formEl) {
            valid = this.formEl.checkValidity();
        }

        return valid;
    }

    private renderErrors() {
        if (!this.state.errors) {
            return null;
        }

        return <div>
            {
                Object.entries(this.state.errors)
                    .map(([field, errors]) => <span key={field}>{field}: {errors.join(', ')}<br/></span>)
            }
        </div>;
    }

    @computed
    private get registrationAccount(): RegistrationAccount {
        return {
            accountNumber: this.state.accountNumber,
            kvkNumber: this.state.kvkNumber,
            companyName: this.state.companyName,
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            street: this.state.street,
            houseNumber: this.state.houseNumber,
            postalCode: this.state.postalCode,
            city: this.state.city,
            orderStreet: this.state.orderStreet,
            orderHouseNumber: this.state.orderHouseNumber,
            orderPostalCode: this.state.orderPostalCode,
            orderCity: this.state.orderCity,
            comment: this.state.comment,
            branchType: this.state.branch,
            knowThrough: this.state.channel,
            interestedIn: this.state.interest,
        };
    }

    private async sendAccount(evt: React.FormEvent) {
        evt.preventDefault();

        const token = await this.recaptcha!.execute('registration');
        try {
            await registrateAccount(this.registrationAccount, token);
            window.location.href = state.urls.accountRequested;
        } catch (error) {
            this.setState({
                errors: error.response.data,
            });
        }
    }
}

export function renderRegistration(element: HTMLElement) {
    return ReactDOM.render(
        <Registration
            siteKey={element.getAttribute('data-site-key') as string}/>, element);
}
