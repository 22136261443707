import {observer} from 'mobx-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {Category } from '../models/interfaces';
import {state} from '../state';
import {BaseMobileHeader, BaseMobileHeaderProps} from './base-mobile-header';

interface Props extends BaseMobileHeaderProps {}

@observer
export class MobileHeader extends BaseMobileHeader<Props> {
    protected title: string;

    public constructor(props: Props) {
        super(props);
        this.state = {
            isMenuOpen: false,
        };
        this.title = 'Menu';
    }


    public renderHeader(): any {
        return [
            <h3 key={'title'} className="h--primary h--no-margin">Ons Assortiment</h3>,
        ];
    }

    public renderContent(): any {
        return [this.props.data.map((x: Category, y: number) =>
            <a key={y} href={x.url} className="mobile-header__menu__items__item">{x.name}</a>,
        ),
            <a key={'sub-action'} className="mobile-header__menu__items__item mobile-header__menu__items__item--promotions --all-caps"
               href={this.props.urls.promotions}>Acties</a>,
        ];
    }

    public renderFooter(): any {
        return [
            <a key={'newsAndInspiration'} href={this.props.urls.newsAndInspiration}>Nieuws & Inspiratie</a>,
            <a key={'customerService'} href={this.props.urls.customerService}>Klantenservice</a>,
        ];
    }
}


export function renderMobileHeader(element: HTMLElement) {
    return ReactDOM.render(<MobileHeader urls={state.urls} data={state.categories}/>, element);
}
