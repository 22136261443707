/* tslint:disable:no-var-requires max-line-length */
import {observer} from 'mobx-react';
import {formatEuroPrice} from '../utils/format';
import {LineItem, OrderSimulationResult} from '../models/interfaces';
import {state} from '../state';
import {viewPrices} from '../security/representative-claims';
import {VendorType} from '../enums/vendorType';
import * as React from 'react';

interface Props {
    order_data: OrderSimulationResult;
}

@observer
export class CartPriceSummary extends React.Component<Props> {
    private data = this.props.order_data;

    public componentDidMount(): void {
        state.cart.groupedItemsByVendor.forEach((items: LineItem[]) => {
            const totalOrderPriceByVendor = items.reduce((a: number, b: any) =>
                a + ((b.price || ((b.product.gross_price! / 100) * b.quantity)) || 0), 0);
            const vendor = items.map(v => v.product!.vendor).pop();
            if (vendor) {
                if ((vendor.minimal_order_price_for_free_transport - totalOrderPriceByVendor) > 0
                    && vendor.vendor_type !== VendorType.DeKlokDranken) {
                        this.data.shippingCosts! += vendor.transport_price;
                        this.data.grandTotal! += vendor.transport_price;
                }
                if (vendor.vendor_type === VendorType.Light) {
                    this.data.subTotal! += totalOrderPriceByVendor;
                    this.data.grandTotal! += totalOrderPriceByVendor;
                }
            }
        });
    }

    public render() {
        if (!state.customer!.has_access(viewPrices)) {
            return null;
        }
        return (
            <div className="cart__price__summary">
                <table className="cart__price__summary__table">
                    <tbody>
                    <tr>
                        <th>Subtotaal</th>
                        <td>{formatEuroPrice(this.data.subTotal!)}</td>
                    </tr>
                    <tr>
                        <th>Transporttoeslag</th>
                        <td>{formatEuroPrice(this.data.shippingCosts!)}</td>
                    </tr>
                    <tr>
                        <th>Emballage</th>
                        <td>{formatEuroPrice(this.data.emballage!)}</td>
                    </tr>
                    <tr>
                        <th>BTW</th>
                        <td>{formatEuroPrice(this.data.vat!)}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <th>Totaal</th>
                        <td>{formatEuroPrice(this.data.grandTotal!)}</td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        );
    }
}
