import {observer} from 'mobx-react';
import {state} from '../state';
import {Cart, Urls} from '../models/interfaces';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {observable} from 'mobx';
import {shoppingCartIcon} from '../../images/images';
import {MediaQuery} from '../media-queries';

interface Props {
    cart: Cart;
    mediaQueries: Map<MediaQuery, boolean>;
    urls: Urls;
}

@observer
export class CartQuantity extends React.Component<Props, { }> {

    @observable
    private animating = false;

    public constructor(props: Props) {
        super(props);
    }

    public render() {
        const totalCount = this.props.cart.total_count;
        return this.props.mediaQueries.get(MediaQuery.MediumDown) ?
            this.renderMobile(totalCount) : this.renderDesktop(totalCount);
    }

    private renderDesktop(totalCount: number) {
        return totalCount > 0 ?
            <span className="subheader__icon subheader__icon--round subheader__icon--green">
                {totalCount}
            </span>
            : <span className="subheader__icon icon"
                    dangerouslySetInnerHTML={{__html: shoppingCartIcon}}/>;
    }

    private renderMobile(totalCount: number) {
        return totalCount > 0 ?
            <a href={this.props.urls.cart}
               className="mobile-header__buttons__button mobile-header__buttons__button--cart">
                {totalCount}
            </a> : <a href={this.props.urls.cart} dangerouslySetInnerHTML={{__html: shoppingCartIcon}}
               className="mobile-header__buttons__button icon icon--small"/>;
    }
}

export function renderCartQuantity(element: HTMLElement) {
    if (!state.cart) {
        throw new Error(`Cannot render cart: 'state.cart' not initialized.`);
    }
    return ReactDOM.render(<CartQuantity
        cart={state.cart}
        mediaQueries={state.mediaQueries}
        urls={state.urls}/>, element!);
}
