import {App, OrderOverview} from './interfaces';
import {GetOrderOverviewSearchCriteria, Order, PagedList} from '../api/interfaces';
import {action, observable} from 'mobx';
import {getOrderOverview} from '../api/de-klok';


export default class OrderOverviewModel implements OrderOverview {

    @observable
    public loading = false;

    @observable
    public orders: Order[] | null = null;

    @observable
    public metaData: PagedList | null = null;

    // TODO pageNumber en pageSize komen indirect ook al terug in GetOrderOverviewSearchCriteria
    @action
    public async fetchOrders(getOrderOverviewSearchCriteria: GetOrderOverviewSearchCriteria,
                             pageNumber: number, pageSize: number): Promise<void> {
        try {
            this.loading = true;
            const result = await getOrderOverview(pageNumber, pageSize, getOrderOverviewSearchCriteria);
            this.orders = result.items;
            this.metaData = result.metaData;
        } finally {
            this.loading = false;
        }
    }
}
