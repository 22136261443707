import { Permission } from './permission';

export enum ClaimRequirementOperator {
    All,
    Any,
}

export class ClaimRequirement {

    public static byAny(...claims: Array<Permission | ClaimRequirement>): ClaimRequirement {
        return new ClaimRequirement(ClaimRequirementOperator.Any, ...claims);
    }

    public static byAll(...claims: Array<Permission | ClaimRequirement>): ClaimRequirement {
        return new ClaimRequirement(ClaimRequirementOperator.All, ...claims);
    }

    private readonly _operatorFn: (callback: (c: Permission | ClaimRequirement) => boolean) => boolean;
    private readonly _claims: Array<string | ClaimRequirement>;

    constructor(operator: ClaimRequirementOperator, ...claims: Array<Permission | ClaimRequirement>) {
        this._operatorFn = (operator === ClaimRequirementOperator.All ? claims.every : claims.some).bind(claims);
        this._claims = claims;
    }

    public get claims() {
        return this._claims;
    }

    public hasAccess(availableClaims: Permission[]): boolean {
        return this._operatorFn(c => {
            if (c instanceof ClaimRequirement) {
                return c.hasAccess(availableClaims);
            } else {
                return availableClaims.includes(c);
            }
        });
    }
}
