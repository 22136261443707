export enum MediaQuery {
    Small = 'only screen and (max-width: 768px)',
    SmallUp = 'only screen',
    MediumDown = 'only screen and (max-width: 1024px)',
    Medium = 'only screen and (min-width: 769px) and (max-width: 992px)',
    MediumUp = 'only screen and (min-width: 769px)',
    Large = '(min-width: 993px)',
    Print = 'print',
}

const defaultClasses = {
    [MediaQuery.Small]: '--sm',
    [MediaQuery.SmallUp]: '--sm-up',
    [MediaQuery.MediumDown]: '--md-down',
    [MediaQuery.Medium]: '--md',
    [MediaQuery.MediumUp]: '--md-up',
    [MediaQuery.Large]: '--lg --lg-up',
    [MediaQuery.Print]: '--print',
};

export class MediaQueries {

    public static get(mediaQuery: MediaQuery): MediaQueryList {
        if (!MediaQueries._mq[ mediaQuery ]) {
            MediaQueries._mq[ mediaQuery ] = window.matchMedia(mediaQuery);
        }
        return MediaQueries._mq[ mediaQuery ];
    }

    public static bindMap(map: Map<MediaQuery, boolean>) {
        for (const mediaQueryName in MediaQuery) {
            if (isNaN(Number(mediaQueryName))) {
                const mediaQuery = MediaQuery[mediaQueryName as keyof typeof MediaQuery] as MediaQuery;
                const mql = MediaQueries.get(mediaQuery);
                const updater = () => map.set(mediaQuery, mql.matches);
                updater();
                mql.addListener(updater);
            }
        }
    }

    public static addGlobalClassNameListeners(classes?: {[mq: string]: string}) {
        Object
            .entries({...defaultClasses, ...classes})
            .forEach(([mq, className]) => {
                this.addGlobalClassListener(mq as MediaQuery, className);
            });
    }

    private static _mq: { [ name: string ]: MediaQueryList } = {};

    private static addGlobalClassListener(mediaQuery: MediaQuery, className: string) {
        const mql = MediaQueries.get(mediaQuery);
        const globalClassToggler = () => {
            className.split(' ').forEach(x => {
                document.documentElement.classList.toggle(x, mql.matches);
            });
        };
        mql.addListener(globalClassToggler);
        globalClassToggler();
    }
}
