/* tslint:disable:no-var-requires max-line-length */
import {observer} from 'mobx-react';
import {computed} from 'mobx';
import {BaseOrderInvoice, GetInvoiceOverviewSearchCriteria} from '../api/interfaces';
import {state} from '../state';
import {BaseOverview, Props} from './base-overview';
import {formatDate, formatEuroPrice} from '../utils/format';
import {
    getSapOrderStatusLabel,
    getSapOrderStatusLabelColor,
    SapInvoiceOrderStatus
} from '../enums/sapInvoiceOrderStatus';
import {MediaQuery} from '../media-queries';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

@observer
export class InvoiceOverview extends BaseOverview {

    protected pageTitle: string;

    public constructor(props: Props) {
        super(props);
        this.state = {
            loading: state.invoices!.loading,
            status: this.status,
        };
        this.pageTitle = 'Facturen';
    }

    @computed
    public get searchCriteria(): Partial<GetInvoiceOverviewSearchCriteria> {
        return {
            invoiceId: this.id,
            to: this.to,
            from: this.from,
            status: this.status,
        };
    }

    @computed
    public get isFiltering() {
        return Object.values(this.searchCriteria).filter(x => x).length !== 0;
    }

    public renderList() {
        return state.invoices && (
            <>
                <table className="order-overview__table">
                    <thead>
                    <tr>
                        <th>Factuurnummer</th>
                        <th>Datum</th>
                        <th>Totaal bedrag</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    {state.invoices && state.invoices.invoices && !state.invoices.loading ?
                        <tbody>
                        {state.invoices.invoices.length > 0 ? state.invoices.invoices.map((item) => this.renderItem(item)) : (
                            <tr>
                                <td colSpan={4}>
                                    {this.renderNoItems()}
                                </td>
                            </tr>
                        )}
                        </tbody> :
                        <tbody>{this.renderPlaceholders(4)}</tbody>}
                </table>
                <div className="row row--no-padding">
                    <div className="row__content linear-layout linear-layout--justify-content-center">
                        {state.invoices.metaData && this.renderPagination(state.invoices.metaData)}</div>
                </div>
            </>
        );
    }

    public renderItem(item: BaseOrderInvoice) {
        return <tr key={item.id}>
            <td><a href="" onClick={(evt) => {
                evt.preventDefault();
                this.onItemSelect(item.id);
            }}>{item.id}</a></td>
            <td>{formatDate(item.date, 'DD/MM/YYYY')}</td>
            <td>{formatEuroPrice(+item.total)}</td>
            <td style={{color: getSapOrderStatusLabelColor(item.status)}}>{getSapOrderStatusLabel(item.status)}</td>
        </tr>;
    }


    public renderFilters() {
        return <div className="filters__head">
            <div className="filters__input-wrap filters__input-wrap--one-third">
                <label htmlFor="invoice_number" className="filters__input-wrap__label">Factuurnummer</label>
                <input type="text"
                       placeholder="Factuurnummer"
                       value={this.id}
                       onChange={(evt) => this.id = evt.target.value}
                       className="input-wrap__input input-wrap__input--bordered filters__input-wrap__search"
                       id="invoice_number"/>
                {this.id.length > 0 &&
                <span className="filters__input-wrap__clear-input"
                      onClick={() => this.id = ''}/>
                }
            </div>
            <div className="filters__input-wrap filters__input-wrap--one-third">
                <label htmlFor="date_from" className="filters__input-wrap__label">Datum van</label>
                <input type="date"
                       placeholder="Van"
                       value={this.from}
                       onChange={(evt) => this.from = evt.target.value}
                       className="input-wrap__input input-wrap__input--bordered"
                       id="date_from"/>
            </div>
            <div className="filters__input-wrap filters__input-wrap--one-third">
                <label htmlFor="date_to" className="filters__input-wrap__label">Datum tot</label>
                <input type="date"
                       placeholder="Tot"
                       value={this.to}
                       onChange={(evt) => this.to = evt.target.value}
                       className="input-wrap__input input-wrap__input--bordered"
                       id="date_to"/>
            </div>
            <div className="filters__dropdown">
                <span className="filters__dropdown__label">Status</span>
                <app-pick-list className="pick-list-item--active dropdown">
                        <span slot="trigger"
                              className={`label filters__tags__tag__label filters__tags__tag__label--padded ${state.mediaQueries.get(MediaQuery.Small) ? '' : 'filters__tags__tag__label--dark'}`}>
                            {getSapOrderStatusLabel(this.status as SapInvoiceOrderStatus)}
                            <span className="icon icon--text-size dropdown__arrow"
                                  dangerouslySetInnerHTML={{__html: this.sortDownIcon}}/>
                        </span>
                    {this.statuses.map((status, index) => {
                        return (
                            <app-pick-list-item key={index}>
                                <a href="#"
                                   key={'a' + index}
                                   className={this.status === status ? 'active' : ''}
                                   onClick={evt => {
                                       evt.preventDefault();
                                       this.status = status;
                                   }}>{getSapOrderStatusLabel(status)}</a>
                            </app-pick-list-item>
                        );
                    })}
                </app-pick-list>
            </div>
            <div className="filters__head__sort filters__head__sort--compact">
                <app-dropdown content-class="dropdown__content--light">
                    <span slot="trigger" className="label">
                        {this.pageSize}
                        <span className="order-overview__filters__sort__label">Per pagina</span>
                        <span className="icon icon--text-size dropdown__arrow"
                              dangerouslySetInnerHTML={{__html: this.sortDownIcon}}/>
                    </span>
                    {this.sortings.map((x, i) => (
                        <a href="#"
                           key={i}
                           className={this.pageSize === x ? 'active' : ''}
                           onClick={evt => {
                               evt.preventDefault();
                               this.pageSize = x;
                               this.pageNumber = state.invoices!.metaData!.pageNumber;
                           }}>{x}</a>
                    ))}
                </app-dropdown>
            </div>
        </div>;
    }

    public renderMobileList() {
        return state.invoices && !state.invoices.loading ? [
            state.invoices.invoices && state.invoices.invoices.length > 0 ?
                <div className="order-overview__list">{state.invoices.invoices && state.invoices.invoices.map((item) =>
                    <div className="order-overview__list__order" onClick={(evt) => {
                        evt.preventDefault();
                        this.onItemSelect(item.id);
                    }}>
                        <div className="order-overview__list__order__info">
                            <span>{formatDate(item.date, 'll')}</span>
                            <span
                                className="order-overview__list__order__info order-overview__list__order__info--status"
                                style={{color: getSapOrderStatusLabelColor(item.status)}}>{getSapOrderStatusLabel(item.status)}</span>
                        </div>
                        <div className="order-overview__list__order__total">
                        <span className="order-overview__list__order__total order-overview__list__order__total--price">
                            {formatEuroPrice(+item.total)}</span>
                            <span className="icon order-overview__list__order__total__icon"
                                  dangerouslySetInnerHTML={{__html: this.chevron}}/>
                        </div>
                    </div>)}
                </div> : this.renderNoItems(),
            <div className="row row--dark row--padding-half">
                <div className="row__content linear-layout linear-layout--justify-content-center">
                    {state.invoices.metaData && this.renderPagination(state.invoices.metaData)}</div>
            </div>,
        ] : this.renderPlaceholders(4);
    }

    public fetchItems() {
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }

        this.searchTimeout = window.setTimeout(async () => {
            this.searchTimeout = undefined;

            this.setState({
                loading: true,
            });

            await state.invoices!.fetchInvoices(
                {...this.searchCriteria, ...this.pagingCriteria},
                this.pageNumber,
                this.pageSize,
            );
            
            this.setState({
                loading: false,
            });
        }, 1500);
    }

    public renderHeader() {
        return state.invoices && <div className="page-header">
            {state.invoices.metaData &&
            <div className="page-header__subtitle order-overview__subtitle">
                {this.pageTitle} {state.invoices.metaData.lastItemOnPage === 0 ? 0 : state.invoices.metaData.firstItemOnPage} tot {state.invoices.metaData.lastItemOnPage} van {state.invoices.metaData.totalItemCount} in
                totaal
            </div>}
        </div>;
    }

    public onItemSelect(id: string) {
        location.href = state.urls.invoiceDetail.replace(':invoiceId', id);
    }
}

export function renderInvoiceOverview(element: HTMLElement) {
    return ReactDOM.render(<InvoiceOverview mediaQueries={state.mediaQueries}/>, element);
}
