import {SelectOption} from '../models/interfaces';

export enum NpvContractType {
    Unknown = 'Unknown',
    Liberal = 'Liberal',
    Permanent = 'Permanent',
}

export function getLabel(npvContractType: NpvContractType): string {
    switch (npvContractType) {
        case NpvContractType.Unknown:
            return 'Onbekend';
        case NpvContractType.Liberal:
            return 'Liberaal contract';
        case NpvContractType.Permanent:
            return 'Vast contract';
    }
}

export function getOptions(): NpvContractType[] {
    return [NpvContractType.Permanent, NpvContractType.Liberal];
}

export function getSelectOptions(): Array<SelectOption<NpvContractType>> {
    return getOptions()
        .map(x => ({id: x, label: getLabel(x)} as SelectOption<NpvContractType>));
}
