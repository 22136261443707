/* tslint:disable:no-var-requires max-line-length */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {observer} from 'mobx-react';
import {state} from '../state';
import {Dialog, DialogEventArgs} from './dialog';
import {Cart as CartState, LineItem} from '../models/interfaces';
import {formatDate} from '../utils/format';
import {QuantityDialog} from './quantity-dialog';
import {reaction} from 'mobx';

interface Props {
    productId: string;
    productSku: string;
    productUnit: string;
    productTitle: string;
    isVendorLight: boolean;
    isLarge: boolean;
    isHorizontal: boolean;
    buttonText: string | null;
    productQuantityIncrement: number;
    forceSimulation: boolean;
    cart: CartState;
}

interface State {
    quantity: number;
    loading: boolean;
    hasClicked: boolean;
    dialogView: DialogView;
}

enum DialogView {
    None,
    InBackorder,
    QuantityIncrement,
}

enum DialogResult {
    Ok = 'ok',
    Cancel = 'cancel',
}

@observer
export class AddToCartV2 extends React.Component<Props, State> {
    protected quantityTimeOut?: number;
    protected hasClicked?: number;

    public constructor(props: Props) {
        super(props);
        const itemInState = state.cart.items.find(x => x.sku === props.productSku);
        this.state = {
            dialogView: DialogView.None,
            quantity: itemInState ? itemInState.quantity : 0,
            loading: false,
            hasClicked: false,
        };
    }

    public componentDidMount(): void {
        reaction(() => state.cart.itemsList.map(item => item.quantity),
            () => {
                const itemInState = state.cart.itemsList.find(x => x.sku === this.props.productSku);
                this.setState({
                    quantity: itemInState ? itemInState.quantity : 0,
                });
            });
    }

    public render() {
        return <div
            className={`add-to-cart-v2-container ${this.props.isLarge ? 'add-to-cart-v2-container--large' : ''}`}>
            {this.renderQuantity()}
            {this.props.isLarge || this.props.isHorizontal ? null : <div
                className={`add-to-cart-v2-background ${this.state.hasClicked ? 'add-to-cart-v2-background--full-width' : ''}`}/>}
        </div>;
    }

    public componentWillUnmount() {
        if (this.hasClicked) {
            clearTimeout(this.hasClicked);
        }
        if (this.quantityTimeOut) {
            clearTimeout(this.quantityTimeOut);
        }
    }

    public setTimer(closeAllDialogs: boolean) {
        if (this.hasClicked) {
            clearTimeout(this.hasClicked);
        }

        this.hasClicked = window.setTimeout(() => {
            this.hasClicked = undefined;
            this.setState({hasClicked: false});
            if (closeAllDialogs) {
                this.setState({dialogView: DialogView.None});
            }
        }, 2000);
    }

    public updateQuantity() {
        if (this.quantityTimeOut) {
            clearTimeout(this.quantityTimeOut);
        }
        this.setTimer(true);
        this.quantityTimeOut = window.setTimeout(() => {
            this.quantityTimeOut = undefined;
            state.notifications.push(`${this.props.productTitle} is bijgewerkt in uw winkelwagen.`);
            if (this.props.isHorizontal) {
                state.cart.update_quantity_force_sim(this.props.productSku, this.state.quantity);
            } else {
                state.cart.update_quantity_by_sku(this.props.productSku, this.state.quantity);
            }
        }, 2000);
    }

    private productQuantityIncrement(): number {
        return (this.props.productQuantityIncrement != null && this.props.productQuantityIncrement > 0) ? this.props.productQuantityIncrement : 1;
    }

    private renderQuantity() {
        if (this.props.isHorizontal) {
            return this.renderHorizontal();
        }

        if (this.props.isLarge) {
            return this.renderLarge();
        }

        return <div className={`add-to-cart-v2 ${this.state.hasClicked ? 'add-to-cart-v2--full-width' : ''}`}>
            {this.renderDialog()}
            <button
                className={`add-to-cart-v2__item add-to-cart-v2__item--plus
                ${!this.state.hasClicked && this.state.quantity <= 0 ? 'add-to-cart-v2__item--single' : ''}`}
                aria-label="Product toevoegen" aria-disabled="false" type="button"
                onClick={() => {
                    if (this.state.quantity !== 9999) {
                        this.setState({
                            quantity: this.state.quantity + this.productQuantityIncrement(),
                            hasClicked: true,
                        });
                        this.checkBeforeAddToCart();
                    } else {
                        state.notifications.push('U kunt niet meer dan 9999 artikelen bestellen');
                    }
                }}>
                {this.state.hasClicked && this.state.quantity > 0 ?
                    <span className="icon add-to-cart-v2__item__icon add-to-cart-v2__item__icon--light"
                          dangerouslySetInnerHTML={{__html: require('../../images/icons8-plus-50.svg')}}/> :
                    <span className="icon add-to-cart-v2__item__icon add-to-cart-v2__item__icon--light"
                          dangerouslySetInnerHTML={{__html: require('../../images/icons8-shopping-cart-50.svg')}}/>}
            </button>
            <input name="quantity"
                   className={`add-to-cart-v2__item add-to-cart-v2__item--quantity-input
                   ${this.state.quantity > 0 ? 'add-to-cart-v2__item--has-quantity' : ''}`}
                   type="tel"
                   maxLength={4}
                   autoComplete="false"
                   inputMode="numeric"
                   pattern="^[0-9]*$"
                   aria-label="hoeveelheid aanpassen"
                   min={0}
                   max={10000}
                   value={this.state.quantity}
                   onBlur={() => this.setTimer(false)}
                   onClick={() => {
                       this.setState({
                           hasClicked: true,
                       });
                       clearTimeout(this.hasClicked);
                   }}
                   onKeyPress={(evt) => {
                       if (!/^[0-9]*$/.test(evt.key)) {
                           evt.preventDefault();
                       }
                   }}
                   onChange={evt => {
                       if (!Number.isNaN(parseInt(evt.target.value,
                           10))) {
                           this.setState({
                               quantity: parseInt(evt.target.value,
                                   10),
                           });
                           this.checkBeforeAddToCart();
                       }
                   }}/>
            <button
                className="add-to-cart-v2__item add-to-cart-v2__item--minus"
                aria-label="Product verwijderen" aria-disabled="false" type="button"
                onClick={() => {
                    if (this.state.quantity > 0) {
                        this.setState({
                            quantity: this.state.quantity - this.productQuantityIncrement(),
                        });
                        this.checkBeforeAddToCart();
                    }
                }}>
                <span className="icon add-to-cart-v2__item__icon add-to-cart-v2__item__icon--light"
                      dangerouslySetInnerHTML={{__html: require('../../images/icons8-minus-50.svg')}}/>
            </button>
        </div>;
    }

    private renderHorizontal() {
        return <div
            className={`add-to-cart-v2-horizontal ${this.state.hasClicked ? 'add-to-cart-v2-horizontal--full-width' : ''}`}>
            {this.renderDialog()}
            <button
                className={`add-to-cart-v2-horizontal__item add-to-cart-v2-horizontal__item--plus
                   ${!this.state.hasClicked && this.state.quantity <= 0 ? 'add-to-cart-v2__item--single' : ''}`}
                aria-label="Product toevoegen" aria-disabled="false" type="button"
                onClick={() => {
                    if (this.state.quantity !== 9999) {
                        this.setState({
                            quantity: this.state.quantity + this.productQuantityIncrement(),
                            hasClicked: true,
                        });
                        this.checkBeforeAddToCart();
                    } else {
                        state.notifications.push('U kunt niet meer dan 9999 artikelen bestellen');
                    }
                }}>
                {this.state.hasClicked && this.state.quantity > 0 ?
                    <span className="icon add-to-cart-v2__item__icon"
                          dangerouslySetInnerHTML={{__html: require('../../images/icons8-plus-50.svg')}}/> :
                    <span className="icon add-to-cart-v2__item__icon"
                          dangerouslySetInnerHTML={{__html: require('../../images/icons8-shopping-cart-50.svg')}}/>}
            </button>
            <input name="quantity"
                   className={`add-to-cart-v2-horizontal__item add-to-cart-v2-horizontal__item--quantity-input
                   ${this.state.quantity > 0 && !this.state.hasClicked ? 'add-to-cart-v2-horizontal__item--has-quantity' : ''}`}
                   type="tel"
                   maxLength={4}
                   inputMode="numeric"
                   pattern="^[0-9]*$"
                   aria-label="hoeveelheid aanpassen"
                   disabled={this.state.loading}
                   min={0}
                   max="10000"
                   value={this.state.quantity}
                   onBlur={() => this.setTimer(false)}
                   onClick={() => {
                       this.setState({
                           hasClicked: true,
                       });
                       clearTimeout(this.hasClicked);
                   }}
                   onKeyPress={(evt) => {
                       if (!/^[0-9]*$/.test(evt.key)) {
                           evt.preventDefault();
                       }
                   }}
                   onChange={evt => {
                       if (!Number.isNaN(parseInt(evt.target.value,
                           10))) {
                           this.setState({
                               quantity: parseInt(evt.target.value,
                                   10),
                           });
                           this.checkBeforeAddToCart();
                       }
                   }}/>
            <button
                className={`add-to-cart-v2-horizontal__item add-to-cart-v2-horizontal__item--minus`}
                aria-label="Product verwijderen" aria-disabled="false" type="button"
                onClick={() => {
                    if (this.state.quantity > 0) {
                        this.setState({
                            quantity: this.state.quantity - this.productQuantityIncrement(),
                        });
                        this.checkBeforeAddToCart();
                    }
                }}>
                <span className="icon add-to-cart-v2__item__icon"
                      dangerouslySetInnerHTML={{__html: require('../../images/icons8-minus-50.svg')}}/>
            </button>
        </div>;
    }

    private renderLarge() {
        return <div
            className={`add-to-cart-v2-large ${this.state.hasClicked || this.state.quantity > 0 ? 'add-to-cart-v2-large--full-width' : ''}`}>
            {this.renderDialog()}
            <button
                className={`add-to-cart-v2-large__item add-to-cart-v2-large__item--plus
                   ${this.state.hasClicked && this.state.quantity <= 0 ? 'add-to-cart-v2-large__item--single' : ''}`}
                aria-label="Product toevoegen" aria-disabled="false" type="button"
                onClick={() => {
                    if (this.state.quantity !== 9999) {
                        this.setState({
                            quantity: this.state.quantity + this.productQuantityIncrement(),
                            hasClicked: true,
                        });
                        this.checkBeforeAddToCart();
                    } else {
                        state.notifications.push('U kunt niet meer dan 9999 artikelen bestellen');
                    }
                }}>
                {(this.state.hasClicked || this.props.isLarge) && this.state.quantity > 0 ?
                    <span className="icon add-to-cart-v2-large__item__icon"
                          dangerouslySetInnerHTML={{__html: require('../../images/icons8-plus-50.svg')}}/> :
                    <div className="add-to-cart-v2-large__item__button">
                        <span className="icon icon--small icon--with-text add-to-cart-v2-large__item__icon"
                              dangerouslySetInnerHTML={{__html: require('../../images/icons8-shopping-cart-50.svg')}}/>
                        {this.props.isLarge ?
                            <p className="add-to-cart-v2-large__item__button__text">Voeg toe</p> : null}
                    </div>}
            </button>
            <input name="quantity"
                   className={`add-to-cart-v2-large__item add-to-cart-v2-large__item--quantity-input
                   ${this.state.quantity > 0 ? 'add-to-cart-v2-large__item--has-quantity' : ''}`}
                   type="tel"
                   maxLength={4}
                   inputMode="numeric"
                   pattern="^[0-9]*$"
                   aria-label="hoeveelheid aanpassen"
                   disabled={this.state.loading}
                   min={0}
                   max="10000"
                   value={this.state.quantity}
                   onBlur={() => this.setState({hasClicked: false})}
                   onClick={() => {
                       if (this.state.quantity !== 0) {
                           this.setState({
                               hasClicked: true,
                           });
                           clearTimeout(this.hasClicked);
                       }
                   }}
                   onFocus={x => (x.target as HTMLInputElement).select()}
                   onKeyPress={(evt) => {
                       if (!/^[0-9]*$/.test(evt.key)) {
                           evt.preventDefault();
                       }
                   }}
                   onChange={evt => {
                       if (!Number.isNaN(parseInt(evt.target.value,
                           10))) {
                           this.setState({
                               quantity: parseInt(evt.target.value,
                                   10),
                           });
                           this.checkBeforeAddToCart();
                       }
                   }}/>
            <button
                className={`add-to-cart-v2-large__item add-to-cart-v2-large__item--minus`}
                aria-label="Product verwijderen" aria-disabled="false" type="button"
                hidden={this.state.hasClicked && this.state.quantity === 0}
                onClick={() => {
                    if (this.state.quantity > 0) {
                        this.setState({
                            quantity: this.state.quantity - this.productQuantityIncrement(),
                        });
                        this.checkBeforeAddToCart();
                    }
                }}>
                <span className="icon add-to-cart-v2-large__item__icon"
                      dangerouslySetInnerHTML={{__html: require('../../images/icons8-minus-50.svg')}}/>
            </button>
        </div>;
    }

    private renderDialog() {
        switch (this.state.dialogView) {
            case DialogView.InBackorder:
                return (
                    <Dialog<DialogResult>
                        onResult={this.onDialogClose}
                        closable={false}
                        title="Dit Artikel staat reeds in backorder">
                        <p className="dialog-container__dialog__text">
                            Let op! U heeft van dit product reeds {this.totalBackorderItemsQuantity} bij ons in
                            bestelling staan. {this.backorderItems.length > 1
                            ? `(verwachte leverdatum ${this.backorderItemDate})`
                            : ''}
                            <br/>Weet u zeker dat u dit artikel wilt toevoegen aan uw winkelwagen?
                        </p>
                        <div className="button-group button-group--compact">
                            <button
                                disabled={this.state.loading}
                                className="button button--primary button-group__button button--float"
                                data-action={DialogResult.Ok}>Artikel toevoegen
                            </button>
                            <button
                                disabled={this.state.loading}
                                className="button button--text-primary button-group__button"
                                data-action={DialogResult.Cancel}>Annuleren
                            </button>
                        </div>
                    </Dialog>
                );

            case DialogView.QuantityIncrement:
                return (
                    <QuantityDialog enteredValue={!Number.isNaN(this.state.quantity) ? this.state.quantity : 0}
                                    productName={this.props.productTitle}
                                    quantityIncrement={this.props.productQuantityIncrement}
                                    onValueChosen={this.onQuantityChanged}/>
                );
        }
    }

    public get backorderItems() {
        return (state.backorderArray || []).filter(x => x.sku === this.props.productSku);
    }

    public get isProductInBackorder() {
        return this.backorderItems.length !== 0;
    }

    public get totalBackorderItemsQuantity() {
        return this.backorderItems.reduce((acc, prev) => prev.quantity + acc, 0);
    }

    public get backorderItemDate() {
        const mapped = this.backorderItems.map(x => formatDate(x.estimatedDeliveryDate));
        mapped.sort((a: string, b: string) => {
            return new Date(a).getTime() - new Date(b).getTime();
        });
        return mapped && mapped.length > 0 ? mapped[0] : null;
    }

    private checkBeforeAddToCart() {
        setTimeout(() => {
            const item = state.cart.items.find(x => x.sku === this.props.productSku);
            if (item && this.state.quantity === 0) {
                this.removeFromCart(item);
                return;
            }
            if (Number.isNaN(this.state.quantity)) {
                this.setState({dialogView: DialogView.QuantityIncrement});
                return;
            }
            if (this.state.quantity < 1) {
                state.notifications.push(`U kunt geen ${this.state.quantity} producten toevoegen aan de winkelwagen`);
                this.setState({hasClicked: false});
            } else if (this.state.quantity % this.props.productQuantityIncrement > 0) {
                this.setState({dialogView: DialogView.QuantityIncrement});
            } else if (this.isProductInBackorder) {
                this.setState({dialogView: DialogView.InBackorder});
            } else if (item) {
                this.updateQuantity();
            } else {
                this.addToCart();
            }
        });
    }

    private onDialogClose = async (evt: DialogEventArgs<DialogResult>) => {
        const item = state.cart.items.find(x => x.sku === this.props.productSku);
        switch (evt.result) {
            case DialogResult.Ok:
                if (item) {
                    await this.updateQuantity();
                    state.notifications.push(`${item.name} is bijgewerkt in uw winkelwagen.`);
                } else {
                    await this.addToCart();
                    state.notifications.push(`${this.props.productTitle} is toegevoegd aan uw winkelwagen.`);
                }
                break;
            case DialogResult.Cancel:
                if (item && item.quantity >= 1) {
                    this.setState({quantity: item.quantity});
                } else {
                    this.setState({quantity: 0});
                }
                break;
        }
        this.setState({dialogView: DialogView.None, hasClicked: false});
    }

    private onQuantityChanged = async (chosenNumber: number | false) => {
        const item = state.cart.items.find(x => x.sku === this.props.productSku);
        if (chosenNumber === false) {
            if (item && item.quantity >= 1) {
                this.setState({
                    dialogView: DialogView.None,
                    quantity: item.quantity,
                });
                this.setState({quantity: item.quantity});
            } else {
                this.setState({
                    dialogView: DialogView.None,
                    quantity: 0,
                });
            }
            return;
        }

        this.setState({
            quantity: chosenNumber,
            dialogView: DialogView.None,
        });
        await this.checkBeforeAddToCart();
    }

    private async addToCart() {
        if (this.quantityTimeOut) {
            clearTimeout(this.quantityTimeOut);
        }
        this.quantityTimeOut = window.setTimeout(async () => {
            this.quantityTimeOut = undefined;
            try {
                this.setState({loading: true});
                await this.props.cart.add_line_item(this.props.productId, this.state.quantity, this.props.forceSimulation);
                this.setState({dialogView: DialogView.None});
                state.notifications.push(`${this.props.productTitle} is toegevoegd aan uw winkelwagen.`);
                this.setTimer(true);
            } finally {
                this.setState({loading: false});
            }
        }, 1000);
    }

    private removeFromCart(item: LineItem) {
        state.notifications.push(`${this.props.productTitle} is verwijderd uit uw winkelwagen`);

        this.props.cart.remove_line_item(item!.id);
        this.setState({hasClicked: false});
    }


}

export function renderAddToCartV2(element: HTMLElement) {
    if (!state.cart) {
        throw new Error(`Cannot render cart: 'state.cart' not initialized.`);
    }
    return ReactDOM.render(
        <AddToCartV2 cart={state.cart}
                     buttonText={element.getAttribute('data-button-text') as string}
                     forceSimulation={element.hasAttribute('data-force-simulation')}
                     productId={element.getAttribute('data-product-id') as string}
                     productSku={element.getAttribute('data-product-sku') as string}
                     productUnit={element.getAttribute('data-product-unit') as string}
                     isHorizontal={element.hasAttribute('data-horizontal-variant')}
                     isVendorLight={element.hasAttribute('data-product-vendor-light')}
                     isLarge={element.hasAttribute('data-add-to-cart-large')}
                     productQuantityIncrement={
                         parseInt(element.getAttribute('data-product-quantity-increment') as string,
                             10)}
                     productTitle={element.getAttribute('data-product-title') as string}/>, element!);
}
